var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-dropdown-item",
        {
          attrs: {
            to: "/educacion/trayectoria-academica",
            "active-class": "active",
          },
        },
        [_c("span", [_vm._v("Trayectoria académica")])]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/educacion/cursos", "active-class": "active" } },
        [_c("span", [_vm._v("Cursos y certificaciones médicas")])]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/educacion/logros", "active-class": "active" } },
        [_c("span", [_vm._v("Logros")])]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/educacion/idiomas", "active-class": "active" } },
        [_c("span", [_vm._v("Idiomas")])]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }