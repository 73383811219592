import Component, { mixins } from 'vue-class-component';
import PropertyCommons from '../commons/property.model';
import { Inject, Prop, PropSync, Ref } from 'vue-property-decorator';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import CatalogoService from '@/shared/catalogo/catalogo.service';
import type AlertaComponent from '@/components/alerta/alerta.component';
import EntityFactory from '@/shared/entity-commons/entity.factory';

const VALIDATIONS = function () {
  return {
    dependientesNuevo: {
      nombre: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(50),
      },
      primerApellido: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(50),
      },
      segundoApellido: {
        minLength: minLength(2),
        maxLength: maxLength(50),
      },
      fechaNacimiento: {
        required,
      },
      sexo: {},
      parentesco: {
        required,
      },
    },
  };
};

@Component({
  validations: VALIDATIONS,
})
export default class DependientesComponent extends mixins(PropertyCommons) {
  @Inject('catalogoService') private catalogoService: () => CatalogoService;

  @Ref()
  alerta!: AlertaComponent;

  @Prop({ default: '' })
  title: string;

  @Prop({ default: true })
  formShow: boolean;

  @Prop({ required: false, default: 1 })
  public readonly min!: number;

  @Prop({ required: false, default: false, type: Boolean })
  public readonly readonly!: boolean;

  public catTipoDeContacto: Array<any> = [];
  correoBandera = false;
  public labelTipo = this.$t('dependientes.labelCorreoTelefono');
  public placeholderTipo = this.$t('dependientes.labelCorreoTelefono');
  @PropSync('value', { type: Array, default: () => [] })
  public listadoDependientes!: Array<any>;

  public dependientesCard: Array<any> = [];

  public dependientesNuevo = EntityFactory.getDependientesEconomicos();

  public isEdit = false;
  public editingIndex = null;
  public isSaving = false;

  public sexoDisabled = true;
  public parentestcoOption: Array<any> = [];
  public sexoOptions: Array<any> = [];
  public edad = 0;

  public minAgeDate = new Date();
  public startDate = new Date().getFullYear();
  public starMonth = new Date().getMonth();

  private language = this.$store.getters.currentLanguage;

  mounted() {
    this.initCatalogos();
    this.mapCards();
    this.validateDate();
  }

  public mapCards() {
    if (this.listadoDependientes) {
      this.listadoDependientes.forEach(e => {
        this.dependientesCard.push(this.creaRegistroCard(e));
      });
    } else {
      this.listadoDependientes = [];
    }
  }

  public initCatalogos() {
    this.parentestcoOption = EntityFactory.getCatParentescoDependiente(); //TODO modificar con catalogo de SACI cuendo se tenga
    this.catalogoService()
      .get('getTipoContactos')
      .then(res => {
        this.catTipoDeContacto = res;
      });
    this.catalogoService()
      .get('getGeneros')
      .then(result => {
        this.sexoOptions = result;
      });
  }

  public eliminaDependiente(removeId): void {
    this.alerta.mostrar(this.$t('dependientes.alert.delete'), 'warning', 20);
    this.dependientesCard.splice(removeId, 1);
    this.listadoDependientes.splice(removeId, 1);
    this.resetForm();
  }

  //Se crean modelos y se envian al CARD y EMIT
  public agregaDependiente(): void {
    if (!this.$v.dependientesNuevo.$invalid) {
      if (this.editingIndex === null) {
        this.dependientesCard.push(this.creaRegistroCard(this.dependientesNuevo));
        this.listadoDependientes.push(this.creaDependiente(this.dependientesNuevo));
        this.alerta.mostrar(this.$t('dependientes.alert.save'), 'success', 20);
        this.$emit('agregaDependiente', this.listadoDependientes);
      } else {
        const nmc = this.dependientesNuevo;
        this.$set(this.listadoDependientes, this.editingIndex, this.creaDependiente(nmc));
        this.$set(this.dependientesCard, this.editingIndex, this.creaRegistroCard(nmc));
        this.editingIndex = null;
        this.alerta.mostrar(this.$t('dependientes.alert.edit'), 'success', 20);
      }
      this.resetForm();
    } else {
      this.$v.$touch();
    }
  }

  //Crea modelo que se va a guardar
  private creaDependiente(dependiente) {
    return {
      nombre: dependiente.nombre,
      primerApellido: dependiente.primerApellido,
      segundoApellido: dependiente.segundoApellido,
      fechaNacimiento: dependiente.fechaNacimiento,
      edad: dependiente.edad,
      sexo: dependiente.sexo,
      parentesco: dependiente.parentesco,
    };
  }
  //Crea modelo para CARD
  private creaRegistroCard(contacto): any {
    const segApellido = contacto.segundoApellido ? contacto.segundoApellido : '';
    return {
      title: `${contacto.nombre} ${contacto.primerApellido} ${segApellido}`,
      description: `${this.formatDate(contacto.fechaNacimiento)}`,
      footer: `Fecha de nacimiento`,
      hidden: true,
      icon: 'icon-usuario',
      badge: [
        {
          badge: contacto.parentesco.nombre,
          primary: true,
        },
      ],
    };
  }

  public resetForm(): void {
    (this.dependientesNuevo.nombre = null),
      (this.dependientesNuevo.primerApellido = null),
      (this.dependientesNuevo.segundoApellido = null),
      (this.dependientesNuevo.fechaNacimiento = null),
      (this.dependientesNuevo.sexo = null),
      (this.dependientesNuevo.parentesco = null);
    this.editingIndex = null;
    this.edad = 0;
    setTimeout(() => {
      this.$v.dependientesNuevo.$reset();
    }, 10);
  }

  /**
   * edit
   */
  public edit() {
    this.isEdit = !this.isEdit;
  }

  public prepareDependiente(index) {
    this.editingIndex = index;
    this.dependientesNuevo = this.shallowCopy(this.listadoDependientes.at(this.editingIndex));
    this.edit();
  }

  /** Copia las propiedades de un objeto */
  public shallowCopy<T extends object>(source: T): T {
    return {
      ...source,
    };
  }

  /**
   * Setea y deshabilita el campo sexo dependiendo el id del catalogo parentesco
   * Habilita campo sexo cuando la opcion es conyuge
   * @param e valor del catalogo parentesco
   */
  public sexoParentescoChange(e) {
    if (e.id == '1') {
      this.dependientesNuevo.sexo = { id: 'F', nombre: 'Femenino' };
      this.sexoDisabled = true;
    } else if (e.id == '2') {
      this.dependientesNuevo.sexo = { id: 'M', nombre: 'Masculino' };
      this.sexoDisabled = true;
    } else {
      this.dependientesNuevo.sexo = null;
      this.sexoDisabled = false;
    }
  }

  /**
   * Regresa fecha con 18 años menos para que no se pueda seleccionar un menor de edad
   */
  public validateDate() {
    const currentDate = new Date();
    this.startDate = currentDate.getFullYear() - 18;
    this.starMonth = currentDate.getMonth();
    this.minAgeDate.setFullYear(this.startDate);
  }

  public changeEdad() {
    const fechaNacimiento = new Date(this.dependientesNuevo.fechaNacimiento);
    if (!isNaN(fechaNacimiento.getTime())) {
      this.edad = this.calcularEdad(fechaNacimiento);
    } else {
      this.edad = 0;
    }
  }

  public calcularEdad(fechaNacimiento: Date): number {
    const hoy = new Date();
    let edad = hoy.getFullYear() - fechaNacimiento.getFullYear();
    const mesActual = hoy.getMonth();
    const diaActual = hoy.getDate();
    const mesNacimiento = fechaNacimiento.getMonth();
    const diaNacimiento = fechaNacimiento.getDate();

    // Restar un año si el mes actual es menor al mes de nacimiento
    if (mesActual < mesNacimiento || (mesActual === mesNacimiento && diaActual < diaNacimiento)) {
      edad--;
    }
    return edad;
  }

  public formatDate(input) {
    if (input) {
      const arrayFecha = input.split('T');
      if (arrayFecha.length == 1) {
        input = input + 'T00:00:00';
      }
      const date = new Date(input);
      const ye = new Intl.DateTimeFormat(`${this.language}`, { year: 'numeric' }).format(date);
      const mo = new Intl.DateTimeFormat(`${this.language}`, { month: 'short' }).format(date);
      const da = new Intl.DateTimeFormat(`${this.language}`, { day: '2-digit' }).format(date);
      return `${da} ${mo} ${ye}`;
    } else {
      return ` `;
    }
  }
}
