import { Component, Inject, Prop, PropSync, Ref, Vue, Watch } from 'vue-property-decorator';
import CatalogoService from '@/shared/catalogo/catalogo.service';
import AlertService from '@/shared/alert/alert.service';
import EntityFactory from '@/shared/entity-commons/entity.factory';

import { required, minLength, maxLength, numeric, helpers, requiredIf } from 'vuelidate/lib/validators';
import AlertaComponent from '../alerta/alerta.component';
import { isEqual } from 'lodash';
import DatePick from 'vue-date-pick';

const CURP = helpers.regex(
  'curp',
  /^[A-Z]{1}[AEIOUX]{1}[A-Z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM]{1}(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[B-DF-HJ-NP-TV-Z]{3}[0-9A-Z]{1}[0-9]{1}$/
);

const VALIDATIONS = function () {
  return {
    dependientesNuevo: {
      esExtranjero: {
        required,
      },
      curp: {
        required: requiredIf(() => !this.curpReadOnly),
        minLength: minLength(1),
        maxLength: maxLength(18),
        CURP,
      },
      nombre: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(50),
      },
      primerApellido: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(50),
      },
      segundoApellido: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(50),
      },
      fechaNacimiento: {
        required,
      },
      sexo: {
        required,
      },
      parentesco: {
        required,
      },
      orden: {
        numeric,
        required,
        maxLength: maxLength(3),
      },
    },
  };
};

@Component({
  validations: VALIDATIONS,
  components: {
    DatePick,
  },
})
export default class DependientesEconomicosComponent extends Vue {
  @Inject('alertService') private alertService: () => AlertService;
  @Inject('catalogoService') private catalogoService: () => CatalogoService;

  @Ref()
  alerta!: AlertaComponent;

  @PropSync('value', { type: Array, default: () => [] })
  public dependientesEconomicos;

  @Prop({ required: false, default: null })
  index: number;

  @Watch('index', { immediate: true, deep: true })
  handler(value: any) {
    if (value || value == 0) {
      this.prepareDependienteEconomico();
    }
  }

  public sexoOptions: Array<any> = [];
  public sexoDisabled = true;
  public minAgeDate = new Date();
  public startDate = new Date().getFullYear();
  public starMonth = new Date().getMonth();

  public isEdit = false;
  public editingIndex = null;

  public parentestcoOption: Array<any> = [];
  public nacionalidadOptions: Array<any> = [];
  public curpReadOnly = false;
  public dependientesNuevo = EntityFactory.getDependientesEconomicos();
  public alertaDependienteId;

  public format = 'YYYY-MM-DD';
  public displayFormat = 'DD-MM-YYYY';

  public edad = 0;
  public existeCambioOrdenEdit = false;

  mounted() {
    this.initCatalogos();
  }

  //Se crean modelos y se envian al CARD
  public addDependiente(): void {
    if (!this.$v.dependientesNuevo.$invalid) {
      if (this.validacionesCurp(this.dependientesNuevo)) {
        if (!this.validaDuplicados(this.dependientesNuevo)) {
          if (this.validaOrden(this.dependientesNuevo)) {
            if (this.compararFechaNacimiento(this.dependientesNuevo.curp, this.dependientesNuevo.fechaNacimiento)) {
              if (this.editingIndex === null) {
                this.dependientesEconomicos.push(this.dependientesNuevo);
              } else {
                const nde = this.dependientesNuevo;
                this.$set(this.dependientesEconomicos, this.editingIndex, nde);
                this.editingIndex = null;
                this.existeCambioOrdenEdit = false;
              }
              this.resetForm();
            } else {
              this.alerta.mostrar(this.$t('dependientes-economicos.alerts.fecha-nacimiento'), 'warning', 20);
            }
          } else {
            this.alerta.mostrar(this.$t('autores.alert.orden'), 'warning', 20);
          }
        } else {
          this.alerta.mostrar(this.$t('dependientes-economicos.alerts.duplicate'), 'warning', 20);
        }
      } else {
        this.alerta.mostrar(this.$t('dependientes-economicos.alerts.curp-denegada'), 'warning', 20);
      }
    } else {
      this.$v.$touch();
    }
  }

  /**
   * Selecciona el objeto del arreglo para edición
   * @param index posición del arreglo para editar
   */
  public prepareDependienteEconomico(): void {
    if (this.index || this.index == 0) {
      this.editingIndex = this.index;

      const dependienteEconomicoInit = EntityFactory.getDependientesEconomicos();
      const dependienteEconomicoClonado = JSON.parse(JSON.stringify(this.dependientesEconomicos.at(this.editingIndex)));
      this.dependientesNuevo = {
        ...dependienteEconomicoInit,
        ...dependienteEconomicoClonado,
      };
      this.curpReadOnly = this.dependientesNuevo.esExtranjero ? this.dependientesNuevo.esExtranjero.valueOf() : false;
    }
  }

  /**
   * validaDuplicadosduplicadosExactos
   * duplicadosExactos: busca dentro del arreglo coicidencias y devuelve true o false
   * duplicadosNombres: busca dentro del arreglo que no se repitan nombres y apellidos, devuelve true o false
   */
  public validaDuplicados(val): boolean {
    let duplicadosNombres = false;
    const duplicadosExactos = this.dependientesEconomicos.some(m => isEqual(m, val));
    for (let i = 0; i < this.dependientesEconomicos.length; i++) {
      if (i === this.editingIndex) {
        continue;
      }
      if (
        this.dependientesEconomicos[i].nombre.toUpperCase() == val.nombre.toUpperCase() &&
        this.dependientesEconomicos[i].primerApellido.toUpperCase() == val.primerApellido.toUpperCase() &&
        this.dependientesEconomicos[i].segundoApellido.toUpperCase() == val.segundoApellido.toUpperCase()
      )
        duplicadosNombres = true;
    }
    return duplicadosExactos || duplicadosNombres;
  }

  public resetForm(): void {
    this.dependientesNuevo = EntityFactory.getDependientesEconomicos();
    this.editingIndex = null;
    setTimeout(() => {
      this.$v.dependientesNuevo.$reset();
    }, 10);
    this.mostrarFormDependienteEconomicoChange();
  }

  //emit a componente padre
  public mostrarFormDependienteEconomicoChange() {
    this.$emit('mostrarFormDependienteEconomicoChange');
  }

  public initCatalogos(): void {
    this.catalogoService()
      .get('getGeneros')
      .then(result => {
        this.sexoOptions = result;
      });
    this.parentestcoOption = EntityFactory.getCatParentescoDependiente();
    this.nacionalidadOptions = EntityFactory.getEsExtranjeroBooleanOptions();
    this.setMexicano();
  }

  setMexicano() {
    this.dependientesNuevo.esExtranjero = false;
  }

  public changeEdad() {
    const fechaNacimiento = new Date(this.dependientesNuevo.fechaNacimiento);
    if (!isNaN(fechaNacimiento.getTime())) {
      this.edad = this.calcularEdad(fechaNacimiento);
    } else {
      this.edad = 0;
    }
  }

  public calcularEdad(fechaNacimiento: Date): number {
    const hoy = new Date();
    let edad = hoy.getFullYear() - fechaNacimiento.getFullYear();
    const mesActual = hoy.getMonth();
    const diaActual = hoy.getDate();
    const mesNacimiento = fechaNacimiento.getMonth();
    const diaNacimiento = fechaNacimiento.getDate();

    // Restar un año si el mes actual es menor al mes de nacimiento
    if (mesActual < mesNacimiento || (mesActual === mesNacimiento && diaActual < diaNacimiento)) {
      edad--;
    }
    return edad;
  }

  /**
   * Setea y deshabilita el campo sexo dependiendo el id del catalogo parentesco
   * Habilita campo sexo cuando la opcion es conyuge, concubino y los demás.
   * @param e valor del catalogo parentesco
   */
  public sexoParentescoChange(e) {
    if (e.id == '1' || e.id == '5') {
      this.dependientesNuevo.sexo = { id: 'F', nombre: 'Femenino' };
      this.sexoDisabled = true;
    } else if (e.id == '2' || e.id == '6') {
      this.dependientesNuevo.sexo = { id: 'M', nombre: 'Masculino' };
      this.sexoDisabled = true;
    } else {
      this.dependientesNuevo.sexo = null;
      this.sexoDisabled = false;
    }
  }

  numbersOnly(evt) {
    const charCode = evt.which ? evt.which : evt.keyCode;
    // Bloquea el ingreso del número 0 como único dígito y cualquier carácter que no sea un número ni el punto decimal
    if (charCode === 48 && evt.target.value.length === 0) {
      evt.preventDefault();
    } else if ((charCode < 48 || charCode > 57) && charCode !== 46) {
      evt.preventDefault();
    } else {
      return true;
    }
  }

  capitalsOnly() {
    this.dependientesNuevo.curp = this.dependientesNuevo.curp.toUpperCase();
  }

  public cambiaOrden() {
    this.existeCambioOrdenEdit = true;
  }

  /**
   * Validaciones hechas al Orden, previas al guardado y editado
   * de registros de dependientes economicos
   * @param dependiente registro dependiente-economico
   */
  public validaOrden(dependiente) {
    let res = true;
    if (this.dependientesEconomicos.length != 0) {
      if (this.editingIndex === null) {
        this.dependientesEconomicos.some(c => {
          if (c.orden == dependiente.orden) res = false;
        });
      } else {
        for (let i = 0; i < this.dependientesEconomicos.length; i++) {
          if (i === this.editingIndex) {
            continue;
          }
          if (this.dependientesEconomicos[i].orden == dependiente.orden) res = false;
        }
      }
    }
    return res;
  }

  /**
   * Validaciones hechas a la curp, previas al guardado y editado
   * de registros de dependientes economicos
   * @param dependiente registro dependiente-economico
   */
  public validacionesCurp(dependiente): boolean {
    if (!this.dependientesNuevo.esExtranjero) {
      let res = true;
      if (this.dependientesEconomicos.length === 0) {
        // TODO verificar esta manera rápida de recuperar info del componente padre
        if (this.$store.getters.account?.curp == dependiente.curp) res = false;
      } else {
        if (this.editingIndex === null) {
          this.dependientesEconomicos.some(c => {
            if (c.curp == dependiente.curp || this.$store.getters.account?.curp == dependiente.curp) res = false;
          });
        } else {
          for (let i = 0; i < this.dependientesEconomicos.length; i++) {
            if (i === this.editingIndex) {
              continue;
            }
            if (this.dependientesEconomicos[i].curp == dependiente.curp || this.$store.getters.account?.curp == dependiente.curp)
              res = false;
          }
        }
      }
      return res;
    }
    return true;
  }

  public changeNacionalidad(e) {
    this.curpReadOnly = e;
    this.dependientesNuevo.esExtranjero = e;
    if (e) this.dependientesNuevo.curp = null;
  }

  /**
   * Validaciones hechas a la fecha de nacimiento con respecto a la
   * fecha de la curp para cotejar si corresponde
   */
  public compararFechaNacimiento(curp, fechaNacimiento): boolean {
    if (!this.dependientesNuevo.esExtranjero) {
      // Extraer año, mes y día de la CURP
      const anoCurp = parseInt(curp.slice(4, 6));
      const mesCurp = parseInt(curp.slice(6, 8));
      const diaCurp = parseInt(curp.slice(8, 10));
      // Convertir la fecha de nacimiento proporcionada a objeto Date
      const fechaNacimientoDate = new Date(fechaNacimiento + 'T00:00:00');
      // Comparar año, mes y día
      return (
        anoCurp === fechaNacimientoDate.getFullYear() % 100 && //Obtiene los últimos digitos del año AAAA -> AA
        mesCurp === fechaNacimientoDate.getMonth() + 1 && //Meses van del 0 al 11
        diaCurp === fechaNacimientoDate.getDate()
      );
    }
    return true;
  }
}
