import Component from 'vue-class-component';
import { mapGetters } from 'vuex';
import Ribbon from '@/core/ribbon/ribbon.vue';
import JhiFooter from '@/core/jhi-footer/jhi-footer.vue';
import JhiNavbar from '@/core/jhi-navbar/jhi-navbar.vue';
import SidebarComponent from '@/core/sidebar/sidebar.vue';
import MenuTabbarComponent from '@/core/menu-tabbar/menu-tabbar.vue';

import '@/shared/config/dayjs';
import DataUserComponent from '@/core/user/data-user.vue';
import ResumenProductosComponent from './entities/msPerfil/solicitudes/resumen-productos-evaluar/resumen.vue';
import Vue from 'vue';
import LoginService from './account/login.service';
import { Inject, Prop, Watch } from 'vue-property-decorator';
import router from './router';
import { DatosGeneralesApi, Solicitud, SolicitudApi, AvisosApi } from './shared/perfil-usuario';
import AlertService from './shared/alert/alert.service';
import EntitySolicitudFactory from './shared/entity-commons/entity-solicitud.factory';
import PerfilService from './shared/perfil/perfil.service';
import EntityFactory from './shared/entity-commons/entity.factory';
import { required } from 'vuelidate/lib/validators';

const VALIDATIONS = function () {
  return {
    aviso: {
      aceptado: {
        required,
      },
      aceptaTransferencia: {},
    },
  };
};

@Component({
  validations: VALIDATIONS,
  components: {
    ribbon: Ribbon,
    'jhi-navbar': JhiNavbar,
    'jhi-footer': JhiFooter,
    'menu-tabbar': MenuTabbarComponent,
    sidebar: SidebarComponent,
    dataUser: DataUserComponent,
    'resumen-productos': ResumenProductosComponent,
  },
  computed: {
    ...mapGetters(['indicadorSolicitud', 'estadoSolicitudEnviada', 'solicitudTotalesBandera', 'authenticated', 'account']),
  },
})
export default class App extends Vue {
  @Inject('loginService') private loginService: () => LoginService;
  @Inject('alertService') private alertService: () => AlertService;
  @Inject('solicitudApi') private solicitudApi: () => SolicitudApi;
  @Inject('miPerfilApi') private miPerfilApi: () => DatosGeneralesApi;
  @Inject('perfilService') private perfilService: () => PerfilService;
  @Inject('avisosApi') private avisosApi: () => AvisosApi;

  @Prop({ default: 'Solicitud.pdf' })
  public nombrePdf: string;
  public documentoBase64 = '';
  public estadoSolicitudEnviada;
  public account;
  public solicitudes: Solicitud[] = [];
  public solicitud;
  public INGRESO = EntitySolicitudFactory.tipoSolucion()[4].value;
  public EMERITO = EntitySolicitudFactory.tipoSolucion()[3].value;
  public ENVIADA = EntitySolicitudFactory.estadoSolicitudEnum().enviada;
  public CANCELADA = EntitySolicitudFactory.estadoSolicitudEnum().cancelada;
  public AVISO_PRIVACIDAD_INTEGRAL = EntityFactory.tipoAvisoEnum().AVISO_PRIVACIDAD_INTEGRAL;
  public AVISO_PRIVACIDAD_SIMPLIFICADO = EntityFactory.tipoAvisoEnum().AVISO_PRIVACIDAD_SIMPLIFICADO;
  public aviso = EntityFactory.getAviso();

  public solicitudTotalesBandera;
  public banderaa;

  public isSolicitudDialogActivated = false;

  @Watch('authenticated', { immediate: true })
  handler(value: any) {
    if (value) {
      this.getAvisos(this.AVISO_PRIVACIDAD_SIMPLIFICADO);
    }
  }

  beforeMount() {
    window.addEventListener('keydown', this.preventF5);
  }

  preventF5(e: KeyboardEvent) {
    if (e.key === 'F5') {
      e.preventDefault();
      this.alertService().showWarning(this, this.$t('global.messages.warning.detailF5').toString());
    }
  }

  destroyed() {
    window.removeEventListener('keydown', this.preventF5);
  }

  public openLogin(): void {
    this.loginService().login();
  }

  public toggleDialog() {
    this.isSolicitudDialogActivated = !this.isSolicitudDialogActivated;
  }

  public complementoSolicitud() {
    if (this.$store.getters.indicadorSolicitud == this.INGRESO) {
      router.push({ name: 'DatosEvaluarIngresoEdit', params: { idSolicitud: this.$store.getters.solicitud.id } }).catch(() => {});
    } else {
      router.push({ name: 'DatosEvaluarEmerito' });
    }
    this.$bvModal.hide('complementoSolicitudModal');
  }

  public vistaPrevia() {
    this.solicitud = this.$store.getters.solicitud;
    this.$bvModal.hide('complementoSolicitudModal');
  }

  public guardaroPU() {
    this.miPerfilApi()
      .getReportePerfil()
      .then(res => {
        const documentoPdfPu = {
          nombre: this.$store.getters.account.cvu + '.pdf',
          content: res.data.contenidoBase64,
        };

        this.perfilService()
          .dispatchDocumentoV2(this.$store.getters.account.cvu, documentoPdfPu)
          .then(res => {
            if (res.status === 201) {
              this.alertService().showSuccess(this, this.$t('solicitud.documento-dms').toString() + res.headers.location);
            }
          })
          .catch(() => {
            this.alertService().showError(this, this.$t('perfil-unico.error-descarga').toString());
          });
      });
  }

  public consultarDocumentoSolicitud(): void {
    this.$store.commit('setsolicitudTotalesBandera', true);
    this.solicitudApi()
      .getReporteSolicitud(this.$store.getters.solicitud.id, '1')
      .then(res => {
        this.documentoBase64 = res.data.contenidoBase64;
        this.downloadPDFSolicitud(this.documentoBase64);
        this.$store.commit('setsolicitudTotalesBandera', false);
      })
      .catch(() => {
        this.alertService().showError(this, this.$t('pdf-solicitud.error-descarga').toString());
        this.$store.commit('setsolicitudTotalesBandera', false);
      });
  }

  public consultarDocumentoSolicitudEviada(): void {
    if (this.$store.getters.solicitud.documentoSolicitud.documento.uri) {
      const url = this.$store.getters.solicitud.documentoSolicitud.documento.uri;
      window.open(url, '_blank');
    } else {
      this.alertService().showError(this, this.$t('pdf-solicitud.error-descarga').toString());
    }
  }

  public downloadPDFSolicitud(pdf) {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement('a');
    const fileName = this.nombrePdf;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  public onHidden() {
    // Return focus to the button once hidden
  }

  public changeAcepto(e) {
    if (!e) {
      this.aviso.aceptaTransferencia = false;
    }
  }

  public getAvisos(aviso) {
    this.avisosApi()
      .getUltimoAviso(aviso)
      .then(res => {
        if (!res.data) {
          this.$bvModal.show('avisoPrivacidadModal');
        }
      })
      .catch(err => {
        this.alertService().showError(this, err.messages);
      });
  }

  public aceptoAviso() {
    this.aviso.cvu = this.account.cvu;
    this.aviso.tipo = this.AVISO_PRIVACIDAD_SIMPLIFICADO;
    this.avisosApi()
      .saveAviso(this.aviso)
      .then(res => {
        this.alertService().showSuccess(this, this.$t('aviso.alert.succes').toString());
        this.$bvModal.hide('avisoPrivacidadModal');
      })
      .catch(() => {
        this.alertService().showError(this, this.$t('aviso.alert.danger').toString());
      });
  }

  public scrollPosition: number = window.scrollY;

  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  }

  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  private handleScroll() {
    this.scrollPosition = window.scrollY;
  }
}
