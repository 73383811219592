import TranslationService from '@/locale/translation.service';
import Component from 'vue-class-component';
import { Inject, Vue } from 'vue-property-decorator';
import { mapActions, mapGetters } from 'vuex';
import EntitiesMenu from '@/entities/entities-menu.vue';
import AccountService from '@/account/account.service';
import AdminMenu from '@/core/sidebar/admin-menu/admin-menu.vue';
import DataUserSidebarComponent from '@/core/user/data-user-sidebar.vue';
import userProfileMenu from '@/core/sidebar/user-profile-menu/user-profile-menu.vue';
import PerfilService from '@/shared/perfil/perfil.service';

@Component({
  components: {
    'entities-menu': EntitiesMenu,
    'admin-menu': AdminMenu,
    'data-user-sidebar': DataUserSidebarComponent,
    userProfileMenu,
  },
  computed: {
    ...mapGetters(['account']),
  },
})
export default class SidebarComponent extends Vue {
  @Inject('translationService') private translationService: () => TranslationService;
  @Inject('accountService') private accountService: () => AccountService;
  @Inject('perfilService') private perfilService: () => PerfilService;

  // public version = 'v' + VERSION;
  public version = 'v0.0.1';
  private currentLanguage = this.$store.getters.currentLanguage;
  private languages: any = this.$store.getters.languages;
  private hasAnyAuthorityValue = false;
  public popOverDisabled = true;
  public centerWidth;

  //Reset var closeSidebar, popOverDisabled para evitar conflicto en dimecion < 768
  mounted() {
    window.onresize = () => {
      this.centerWidth = document.documentElement.clientWidth;
      if (this.centerWidth < 768) {
        this.$store.dispatch('closeSidebar');
        this.popOverDisabled = true;
      }
    };
  }

  created() {
    this.translationService().refreshTranslation(this.currentLanguage);
  }

  public subIsActive(input) {
    const paths = Array.isArray(input) ? input : [input];
    return paths.some(path => {
      return this.$route.path.indexOf(path) === 0; // current path starts with this path string
    });
  }

  public changeLanguage(newLanguage: string): void {
    this.translationService().refreshTranslation(newLanguage);
  }

  public isActiveLanguage(key: string): boolean {
    return key === this.$store.getters.currentLanguage;
  }

  public get authenticated(): boolean {
    return this.$store.getters.authenticated;
  }

  public hasAnyAuthority(authorities: any): boolean {
    this.accountService()
      .hasAnyAuthorityAndCheckAuth(authorities)
      .then(value => {
        this.hasAnyAuthorityValue = value;
        this.perfilService().retrieveInicialPerfil();
      });
    return this.hasAnyAuthorityValue;
  }

  public get openAPIEnabled(): boolean {
    return this.$store.getters.activeProfiles.indexOf('api-docs') > -1;
  }

  public get inProduction(): boolean {
    return this.$store.getters.activeProfiles.indexOf('prod') > -1;
  }
}
