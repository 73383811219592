var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-dropdown-item",
        { attrs: { to: "/acerca-de-mi/mi-perfil", "active-class": "active" } },
        [
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(_vm.$t("acerca-de-mi.mi-perfil.title")),
              },
            },
            [_vm._v("Mi perfil")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/acerca-de-mi/grupos", "active-class": "active" } },
        [
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("acerca-de-mi.grupos-vulnerables.title")
                ),
              },
            },
            [_vm._v("Grupos Vulnerables")]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }