/* tslint:disable */
/* eslint-disable */
/**
 * msPerfil-rizoma
 * Versión desarrollo :: Perfil de usuario rizoma
 *
 * The version of the OpenAPI document: 1.4.8
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * Informacion para los Adeudos - Sanciones
 * @export
 * @interface AdeudoSancion
 */
export interface AdeudoSancion {
    /**
     * id
     * @type {string}
     * @memberof AdeudoSancion
     */
    'id'?: string;
    /**
     * Numero de cvu del usuario que tiene un adeudo - sanción
     * @type {string}
     * @memberof AdeudoSancion
     */
    'cvu'?: string;
    /**
     * Nombre completo del usuario
     * @type {string}
     * @memberof AdeudoSancion
     */
    'nombreCompleto'?: string;
    /**
     * Tipo de Adeudo - sanción
     * @type {string}
     * @memberof AdeudoSancion
     */
    'tipo'?: string;
    /**
     * Descripcion del adeudo o sanción
     * @type {string}
     * @memberof AdeudoSancion
     */
    'descripcion'?: string;
    /**
     * Fecha en la que se dio de alta el adeudo - sanción
     * @type {string}
     * @memberof AdeudoSancion
     */
    'fechaAltaAdeudo'?: string;
    /**
     * Estatus del adeudo - sanción
     * @type {string}
     * @memberof AdeudoSancion
     */
    'estatus'?: string;
    /**
     * IND Estatus Descripción
     * @type {string}
     * @memberof AdeudoSancion
     */
    'indEstatusDescripcion'?: string;
}
/**
 * Descripción de un apoyo a solicitar
 * @export
 * @interface Apoyo
 */
export interface Apoyo {
    /**
     * Tipo o nivel académico para el que se solicita el apoyo
     * @type {BaseCatalogo}
     * @memberof Apoyo
     */
    'tipoApoyo'?: BaseCatalogo;
    /**
     * País
     * @type {BaseCatalogoExtendido}
     * @memberof Apoyo
     */
    'pais'?: BaseCatalogoExtendido;
    /**
     * Institución
     * @type {BaseCatalogoExtendido}
     * @memberof Apoyo
     */
    'institucion'?: BaseCatalogoExtendido;
    /**
     * Institución cuenta con convenio CONAHCYT
     * @type {boolean}
     * @memberof Apoyo
     */
    'convenio'?: boolean;
    /**
     * Categoría o clasificación de un programa
     * @type {string}
     * @memberof Apoyo
     */
    'categoria'?: string;
    /**
     * Programa de estudios
     * @type {string}
     * @memberof Apoyo
     */
    'programa'?: string;
    /**
     * ¿Obtuvo anteriormente un apoyo del CONAHCYT para realizar algun estudio de posgrado?
     * @type {boolean}
     * @memberof Apoyo
     */
    'obtuvoApoyoPrevio'?: boolean;
    /**
     * En caso de contar con Dependiente económico, ¿su dependiente económico es actualmente persona becaria de CONAHCYT?
     * @type {boolean}
     * @memberof Apoyo
     */
    'dependienteBecarioActual'?: boolean;
    /**
     * En caso de contar con Dependiente económico, ¿su dependiente económico está solicitando simultaneamente beca CONAHCYT?
     * @type {boolean}
     * @memberof Apoyo
     */
    'dependienteSolicitanteSimultaneo'?: boolean;
}
/**
 * Objeto genérico para hacer referencia a archivos
 * @export
 * @interface Archivo
 */
export interface Archivo {
    /**
     * Nombre de archivo
     * @type {string}
     * @memberof Archivo
     */
    'nombre': string;
    /**
     * MIME types de los documentos
     * @type {string}
     * @memberof Archivo
     */
    'contentType'?: string;
    /**
     * Ubicación del documento
     * @type {string}
     * @memberof Archivo
     */
    'uri': string;
    /**
     * Contiene la definición de documento con la cual se puede determinar el tipo de documento
     * @type {string}
     * @memberof Archivo
     */
    'definicionDocumento'?: string;
    /**
     * Contiene la definición del tamanio del documento
     * @type {number}
     * @memberof Archivo
     */
    'size'?: number;
    /**
     * Indica si el documento es requerido o no en una solicitud
     * @type {boolean}
     * @memberof Archivo
     */
    'requerido'?: boolean;
    /**
     * Etiqueta descriptiva del documento
     * @type {string}
     * @memberof Archivo
     */
    'etiqueta'?: string;
    /**
     * Tamaño maximo permitido por documento
     * @type {number}
     * @memberof Archivo
     */
    'maxSize'?: number;
    /**
     * expresión para validar si el documento es visible
     * @type {string}
     * @memberof Archivo
     */
    'expresion'?: string;
    /**
     * Indica si el documento es visible o no en una solicitud
     * @type {boolean}
     * @memberof Archivo
     */
    'visible'?: boolean;
}
/**
 * Niveles de área de conocimiento
 * @export
 * @interface AreaConocimiento
 */
export interface AreaConocimiento {
    /**
     * Área de conocimiento
     * @type {BaseCatalogoExtendido & object}
     * @memberof AreaConocimiento
     */
    'area': BaseCatalogoExtendido & object;
    /**
     * Campo
     * @type {BaseCatalogoExtendido}
     * @memberof AreaConocimiento
     */
    'campo': BaseCatalogoExtendido;
    /**
     * Disciplina
     * @type {BaseCatalogoExtendido}
     * @memberof AreaConocimiento
     */
    'disciplina': BaseCatalogoExtendido;
    /**
     * Subdisciplina
     * @type {BaseCatalogoExtendido}
     * @memberof AreaConocimiento
     */
    'subdisciplina': BaseCatalogoExtendido;
}
/**
 * Área de conocimiento
 * @export
 * @interface AreaConocimientoPrograma
 */
export interface AreaConocimientoPrograma {
    /**
     * 
     * @type {boolean}
     * @memberof AreaConocimientoPrograma
     */
    'esPrincipal'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AreaConocimientoPrograma
     */
    'cveArea'?: string;
    /**
     * 
     * @type {string}
     * @memberof AreaConocimientoPrograma
     */
    'area'?: string;
    /**
     * 
     * @type {string}
     * @memberof AreaConocimientoPrograma
     */
    'cveCampo'?: string;
    /**
     * 
     * @type {string}
     * @memberof AreaConocimientoPrograma
     */
    'campo'?: string;
    /**
     * 
     * @type {string}
     * @memberof AreaConocimientoPrograma
     */
    'cveDisciplina'?: string;
    /**
     * 
     * @type {string}
     * @memberof AreaConocimientoPrograma
     */
    'disciplina'?: string;
}
/**
 * información sobre un aviso o carta
 * @export
 * @interface Aviso
 */
export interface Aviso {
    /**
     * Identificador único
     * @type {string}
     * @memberof Aviso
     */
    'id'?: string;
    /**
     * Fecha de publicación vigente
     * @type {string}
     * @memberof Aviso
     */
    'fechaPublicacion'?: string;
    /**
     * CVU del usuario
     * @type {string}
     * @memberof Aviso
     */
    'cvu'?: string;
    /**
     * Fecha en que se aceptó/rechazo el aviso
     * @type {string}
     * @memberof Aviso
     */
    'fechaAceptacion'?: string;
    /**
     * 
     * @type {TipoAviso}
     * @memberof Aviso
     */
    'tipo': TipoAviso;
    /**
     * Indicador para identificar aceptación o rechazo
     * @type {boolean}
     * @memberof Aviso
     */
    'aceptado': boolean;
    /**
     * Indicador para identificar si otorga consentimiento para transferencia de datos
     * @type {boolean}
     * @memberof Aviso
     */
    'aceptaTransferencia': boolean;
}
/**
 * Objeto utilizado por todos los campos provenientes de catálogos
 * @export
 * @interface BaseCatalogo
 */
export interface BaseCatalogo {
    /**
     * Identificador único
     * @type {string}
     * @memberof BaseCatalogo
     */
    'id': string;
    /**
     * Nombre o descripción del campo
     * @type {string}
     * @memberof BaseCatalogo
     */
    'nombre': string;
}
/**
 * Objeto utilizado para incluir datos adicionales (clave, versión) de BaseCatalogo
 * @export
 * @interface BaseCatalogoExtendido
 */
export interface BaseCatalogoExtendido {
    /**
     * Identificador único
     * @type {string}
     * @memberof BaseCatalogoExtendido
     */
    'id': string;
    /**
     * Nombre o descripción del campo
     * @type {string}
     * @memberof BaseCatalogoExtendido
     */
    'nombre': string;
    /**
     * Clave del campo
     * @type {string}
     * @memberof BaseCatalogoExtendido
     */
    'clave'?: string;
}
/**
 * 
 * @export
 * @interface BaseCatalogoExtendidoAllOf
 */
export interface BaseCatalogoExtendidoAllOf {
    /**
     * Clave del campo
     * @type {string}
     * @memberof BaseCatalogoExtendidoAllOf
     */
    'clave'?: string;
}
/**
 * Objeto utilizado para catálogos con clave MIIC
 * @export
 * @interface BaseCatalogoLegado
 */
export interface BaseCatalogoLegado {
    /**
     * Clave
     * @type {string}
     * @memberof BaseCatalogoLegado
     */
    'clave': string;
    /**
     * Nombre
     * @type {string}
     * @memberof BaseCatalogoLegado
     */
    'nombre': string;
}
/**
 * Calendario de publicación. Esquema temporal alterno para consumo de apeiron desde msperfil.
 * @export
 * @interface Calendario
 */
export interface Calendario {
    /**
     * Fecha de inicio de convocatoria
     * @type {string}
     * @memberof Calendario
     */
    'fechaInicio'?: string;
    /**
     * Fecha de fin de recepción de solicitudes
     * @type {string}
     * @memberof Calendario
     */
    'fechaFinSolicitud'?: string;
    /**
     * Fecha de fin de revisión
     * @type {string}
     * @memberof Calendario
     */
    'fechaFinRevision'?: string;
}
/**
 * Descripción de una certificacion médica
 * @export
 * @interface CertificacionMedica
 */
export interface CertificacionMedica {
    /**
     * Identificador
     * @type {string}
     * @memberof CertificacionMedica
     */
    'id'?: string;
    /**
     * Id de referencia del origen MIIC
     * @type {string}
     * @memberof CertificacionMedica
     */
    'migracionId'?: string;
    /**
     * 
     * @type {TipoFormacionContinua}
     * @memberof CertificacionMedica
     */
    'tipo'?: TipoFormacionContinua;
    /**
     * Nombre de la certificación
     * @type {string}
     * @memberof CertificacionMedica
     */
    'nombre'?: string;
    /**
     * Folio
     * @type {string}
     * @memberof CertificacionMedica
     */
    'folio'?: string;
    /**
     * Consejo certificador
     * @type {BaseCatalogo}
     * @memberof CertificacionMedica
     */
    'certificador'?: BaseCatalogo;
    /**
     * Especialidad dentro del consejo certificador
     * @type {BaseCatalogo}
     * @memberof CertificacionMedica
     */
    'especialidad'?: BaseCatalogo;
    /**
     * Inicio de vigencia de la certificación
     * @type {string}
     * @memberof CertificacionMedica
     */
    'inicioVigencia'?: string;
    /**
     * Fin de vigencia de la certificación
     * @type {string}
     * @memberof CertificacionMedica
     */
    'finVigencia'?: string;
}
/**
 * Objeto con los totales de citas para la solicitud
 * @export
 * @interface Citas
 */
export interface Citas {
    /**
     * 
     * @type {TotalesCitas}
     * @memberof Citas
     */
    'articulo'?: TotalesCitas;
    /**
     * 
     * @type {TotalesCitas}
     * @memberof Citas
     */
    'libro'?: TotalesCitas;
    /**
     * 
     * @type {TotalesCitas}
     * @memberof Citas
     */
    'capitulo'?: TotalesCitas;
    /**
     * 
     * @type {TotalesCitas}
     * @memberof Citas
     */
    'totalesCitas'?: TotalesCitas;
}
/**
 * Descripción de las colaboraciones con Ecosistemas Nacionales Informáticos (ENI), Gestor de Mapas (Gema) , Sistema de Diseño y Accesibilidad para la Investigación (Sisdai)
 * @export
 * @interface Colaboracion
 */
export interface Colaboracion {
    /**
     * Identificador
     * @type {string}
     * @memberof Colaboracion
     */
    'id'?: string;
    /**
     * Tipo de colaboracon eni gema sisdai
     * @type {BaseCatalogo}
     * @memberof Colaboracion
     */
    'tipo': BaseCatalogo;
    /**
     * url de la colaboración
     * @type {string}
     * @memberof Colaboracion
     */
    'url'?: string;
    /**
     * Descripción de que tipo fue la colaboración con sisdai y en que opcion de sisdai
     * @type {string}
     * @memberof Colaboracion
     */
    'descripcion'?: string;
    /**
     * Opciones de sisdai css, componentes, graficas , mapas
     * @type {Array<BaseCatalogo>}
     * @memberof Colaboracion
     */
    'opcionesSisdai'?: Array<BaseCatalogo>;
}
/**
 * Contenido de las colaboraciones eni sisdai gema
 * @export
 * @interface Colaboraciones
 */
export interface Colaboraciones {
    /**
     * Colaboraciones
     * @type {Array<Colaboracion>}
     * @memberof Colaboraciones
     */
    'colaboraciones'?: Array<Colaboracion>;
}
/**
 * Concepto de rubro
 * @export
 * @enum {string}
 */

export const ConceptoRubro = {
    MANUTENCION: 'MANUTENCION',
    COLEGIATURA: 'COLEGIATURA',
    SEGURO_MEDICO: 'SEGURO_MEDICO',
    BOLETO_AVION: 'BOLETO_AVION'
} as const;

export type ConceptoRubro = typeof ConceptoRubro[keyof typeof ConceptoRubro];


/**
 * Descripción de un congreso
 * @export
 * @interface Congreso
 */
export interface Congreso {
    /**
     * Identificador
     * @type {string}
     * @memberof Congreso
     */
    'id'?: string;
    /**
     * Id de referencia del origen MIIC
     * @type {string}
     * @memberof Congreso
     */
    'migracionId'?: string;
    /**
     * Nombre del congreso
     * @type {string}
     * @memberof Congreso
     */
    'nombre': string;
    /**
     * Título del trabajo presentado
     * @type {string}
     * @memberof Congreso
     */
    'tituloTrabajo': string;
    /**
     * Tipo de participación en congreso
     * @type {BaseCatalogo}
     * @memberof Congreso
     */
    'tipoParticipacion': BaseCatalogo;
    /**
     * País sede del congreso
     * @type {BaseCatalogo}
     * @memberof Congreso
     */
    'pais': BaseCatalogo;
    /**
     * Fecha
     * @type {string}
     * @memberof Congreso
     */
    'fecha': string;
    /**
     * Indicador de si es un producto principal
     * @type {boolean}
     * @memberof Congreso
     */
    'productoPrincipal'?: boolean;
}
/**
 * Descripción de un medio de contacto
 * @export
 * @interface Contacto
 */
export interface Contacto {
    /**
     * Tipo de contacto
     * @type {BaseCatalogo}
     * @memberof Contacto
     */
    'tipo'?: BaseCatalogo;
    /**
     * Valor o número del contacto
     * @type {string}
     * @memberof Contacto
     */
    'valor'?: string;
    /**
     * Indicador para identificar si es el medio de contacto principal
     * @type {boolean}
     * @memberof Contacto
     */
    'principal'?: boolean;
    /**
     * Indicador para elegir compartir por red rizoma
     * @type {boolean}
     * @memberof Contacto
     */
    'compartir'?: boolean;
}
/**
 * Definición de una persona como contacto de emergencias
 * @export
 * @interface ContactoEmergencias
 */
export interface ContactoEmergencias {
    /**
     * Tipo de contacto
     * @type {BaseCatalogo}
     * @memberof ContactoEmergencias
     */
    'tipoContacto'?: BaseCatalogo;
    /**
     * Nombre del contacto
     * @type {string}
     * @memberof ContactoEmergencias
     */
    'nombre'?: string;
    /**
     * Primer apellido del contacto
     * @type {string}
     * @memberof ContactoEmergencias
     */
    'primerApellido'?: string;
    /**
     * Segundo apellido del contacto
     * @type {string}
     * @memberof ContactoEmergencias
     */
    'segundoApellido'?: string;
    /**
     * Parentesco del contacto con el usuario
     * @type {BaseCatalogo}
     * @memberof ContactoEmergencias
     */
    'parentesco'?: BaseCatalogo;
    /**
     * Fecha de nacimiento del contacto
     * @type {string}
     * @memberof ContactoEmergencias
     */
    'fechaNacimiento'?: string;
    /**
     * Sexo del contacto
     * @type {BaseCatalogo}
     * @memberof ContactoEmergencias
     */
    'sexo'?: BaseCatalogo;
    /**
     * Correo electrónico del contacto
     * @type {string}
     * @memberof ContactoEmergencias
     */
    'correo'?: string;
    /**
     * Lada del contacto
     * @type {string}
     * @memberof ContactoEmergencias
     */
    'lada'?: string;
    /**
     * Teléfono del contacto
     * @type {string}
     * @memberof ContactoEmergencias
     */
    'telefono'?: string;
    /**
     * Celular del contacto
     * @type {string}
     * @memberof ContactoEmergencias
     */
    'celular'?: string;
    /**
     * Domicilio del contacto de emergencia
     * @type {DomicilioEmergencia}
     * @memberof ContactoEmergencias
     */
    'domicilio'?: DomicilioEmergencia;
}
/**
 * 
 * @export
 * @interface CorreoAyudaRequest
 */
export interface CorreoAyudaRequest {
    /**
     * Descripción del incidente
     * @type {string}
     * @memberof CorreoAyudaRequest
     */
    'incidente': string;
    /**
     * Descripción detallada
     * @type {string}
     * @memberof CorreoAyudaRequest
     */
    'descripcion': string;
    /**
     * Ruta dentro de la aplicación
     * @type {string}
     * @memberof CorreoAyudaRequest
     */
    'routeName': string;
    /**
     * Arreglo de imágenes en Base64
     * @type {Array<string>}
     * @memberof CorreoAyudaRequest
     */
    'imagenes': Array<string>;
}
/**
 * Descripción de un curso recibido
 * @export
 * @interface Curso
 */
export interface Curso {
    /**
     * Identicador
     * @type {string}
     * @memberof Curso
     */
    'id'?: string;
    /**
     * Id de referencia del origen MIIC
     * @type {string}
     * @memberof Curso
     */
    'migracionId'?: string;
    /**
     * Tipo de formación
     * @type {BaseCatalogo}
     * @memberof Curso
     */
    'formacion'?: BaseCatalogo;
    /**
     * Nombre del curso
     * @type {string}
     * @memberof Curso
     */
    'nombre'?: string;
    /**
     * Año
     * @type {string}
     * @memberof Curso
     */
    'anio'?: string;
    /**
     * Horas totales
     * @type {number}
     * @memberof Curso
     */
    'horasTotales'?: number;
    /**
     * Institución donde se realizó el curso
     * @type {Institucion}
     * @memberof Curso
     */
    'institucion'?: Institucion;
}
/**
 * Descripción de un curso impartido
 * @export
 * @interface CursoImpartido
 */
export interface CursoImpartido {
    /**
     * Identificador
     * @type {string}
     * @memberof CursoImpartido
     */
    'id'?: string;
    /**
     * Id de referencia del origen MIIC
     * @type {string}
     * @memberof CursoImpartido
     */
    'migracionId'?: string;
    /**
     * 
     * @type {TipoPrograma}
     * @memberof CursoImpartido
     */
    'tipoPrograma'?: TipoPrograma;
    /**
     * Clave de institución del Sistema Nacional de Posgrados (SNP). En caso de que sea una institución SNP.
     * @type {string}
     * @memberof CursoImpartido
     */
    'claveInstitucionSnp'?: string;
    /**
     * Programa de una institución del Sistema Nacinoal de Posgrados (SNP). En caso de que sea una institución SNP.
     * @type {ProgramaSnp}
     * @memberof CursoImpartido
     */
    'programaSnp'?: ProgramaSnp;
    /**
     * Nombre del curso
     * @type {string}
     * @memberof CursoImpartido
     */
    'nombreCurso': string;
    /**
     * Horas totales impartidas
     * @type {number}
     * @memberof CursoImpartido
     */
    'horasTotales': number;
    /**
     * Fecha de inicio
     * @type {string}
     * @memberof CursoImpartido
     */
    'fechaInicio': string;
    /**
     * Fecha de fin
     * @type {string}
     * @memberof CursoImpartido
     */
    'fechaFin': string;
    /**
     * Nivel de escolaridad
     * @type {BaseCatalogo}
     * @memberof CursoImpartido
     */
    'nivelEscolaridad'?: BaseCatalogo;
    /**
     * Institucion donde se impartió el curso. En caso de que sea una institución no SNP.
     * @type {Institucion}
     * @memberof CursoImpartido
     */
    'institucion'?: Institucion;
    /**
     * Nombre del programa de la institución no SNP. En caso de que sea una institución no SNP.
     * @type {string}
     * @memberof CursoImpartido
     */
    'nombrePrograma'?: string;
    /**
     * Indicador de si es un producto principal
     * @type {boolean}
     * @memberof CursoImpartido
     */
    'productoPrincipal'?: boolean;
}
/**
 * Contenedor de cursos y certificaciones de formación continua
 * @export
 * @interface Cursos
 */
export interface Cursos {
    /**
     * Cursos recibidos
     * @type {Array<Curso>}
     * @memberof Cursos
     */
    'cursos'?: Array<Curso>;
    /**
     * Certificaciones o recertificaciones médicas
     * @type {Array<CertificacionMedica>}
     * @memberof Cursos
     */
    'certificacionesMedicas'?: Array<CertificacionMedica>;
}
/**
 * Definición de un registro de whitelist
 * @export
 * @interface CvuReenviable
 */
export interface CvuReenviable {
    /**
     * Cvu del usuario
     * @type {string}
     * @memberof CvuReenviable
     */
    'cvu': string;
    /**
     * Id de solucion o convocatoria a participar
     * @type {string}
     * @memberof CvuReenviable
     */
    'idSolucion': string;
}
/**
 * Definición de un dependiente económico
 * @export
 * @interface DependienteEconomico
 */
export interface DependienteEconomico {
    /**
     * CURP del beneficiario
     * @type {string}
     * @memberof DependienteEconomico
     */
    'curp'?: string;
    /**
     * Nombre
     * @type {string}
     * @memberof DependienteEconomico
     */
    'nombre'?: string;
    /**
     * Primer apellido
     * @type {string}
     * @memberof DependienteEconomico
     */
    'primerApellido'?: string;
    /**
     * Segundo apellido
     * @type {string}
     * @memberof DependienteEconomico
     */
    'segundoApellido'?: string;
    /**
     * Fecha de nacimiento
     * @type {string}
     * @memberof DependienteEconomico
     */
    'fechaNacimiento'?: string;
    /**
     * Sexo
     * @type {BaseCatalogo}
     * @memberof DependienteEconomico
     */
    'sexo'?: BaseCatalogo;
    /**
     * Parentesco
     * @type {BaseCatalogo}
     * @memberof DependienteEconomico
     */
    'parentesco'?: BaseCatalogo;
    /**
     * Orden del beneficiario
     * @type {number}
     * @memberof DependienteEconomico
     */
    'orden'?: number;
    /**
     * Indicador para identificar si un dependiente-económico es extranjero o mexicano
     * @type {boolean}
     * @memberof DependienteEconomico
     */
    'esExtranjero'?: boolean;
}
/**
 * Cuestionario de participaciones de desarrollo institucional
 * @export
 * @interface DesarrolloInstitucional
 */
export interface DesarrolloInstitucional {
    /**
     * Participación en proyectos que amplíen la capacidad de investigación
     * @type {string}
     * @memberof DesarrolloInstitucional
     */
    'participacionProyectos'?: string;
    /**
     * Participación en la creación o diseño de planes de estudio
     * @type {string}
     * @memberof DesarrolloInstitucional
     */
    'participacionPlanes'?: string;
    /**
     * 
     * @type {EjeProgramatico}
     * @memberof DesarrolloInstitucional
     */
    'eje'?: EjeProgramatico;
    /**
     * 
     * @type {Archivo}
     * @memberof DesarrolloInstitucional
     */
    'documentoProbatorio'?: Archivo;
}
/**
 * Descripción de un diplomado
 * @export
 * @interface Diplomado
 */
export interface Diplomado {
    /**
     * Identificador
     * @type {string}
     * @memberof Diplomado
     */
    'id'?: string;
    /**
     * Id de referencia del origen MIIC
     * @type {string}
     * @memberof Diplomado
     */
    'migracionId'?: string;
    /**
     * Institución
     * @type {Institucion}
     * @memberof Diplomado
     */
    'institucion'?: Institucion;
    /**
     * Nombre del diplomado
     * @type {string}
     * @memberof Diplomado
     */
    'nombreDiplomado': string;
    /**
     * Nombre del curso
     * @type {string}
     * @memberof Diplomado
     */
    'nombreCurso': string;
    /**
     * Año
     * @type {string}
     * @memberof Diplomado
     */
    'anio': string;
    /**
     * Horas totales
     * @type {number}
     * @memberof Diplomado
     */
    'horasTotales': number;
    /**
     * Indicador de si es un producto principal
     * @type {boolean}
     * @memberof Diplomado
     */
    'productoPrincipal'?: boolean;
}
/**
 * Definición de un documento solicitado en una solución
 * @export
 * @interface DocumentoConfiguracion
 */
export interface DocumentoConfiguracion {
    /**
     * nombre
     * @type {string}
     * @memberof DocumentoConfiguracion
     */
    'nombre'?: string;
    /**
     * nombre de propiedad
     * @type {string}
     * @memberof DocumentoConfiguracion
     */
    'nombrePropiedad'?: string;
    /**
     * requerido
     * @type {boolean}
     * @memberof DocumentoConfiguracion
     */
    'requerido'?: boolean;
    /**
     * max
     * @type {number}
     * @memberof DocumentoConfiguracion
     */
    'max'?: number;
    /**
     * mediaTypes
     * @type {Array<string>}
     * @memberof DocumentoConfiguracion
     */
    'mediaTypes'?: Array<string>;
    /**
     * expresión para validar si el documento es visible
     * @type {string}
     * @memberof DocumentoConfiguracion
     */
    'expresion'?: string;
}
/**
 * Documento a exportar del perfil único en formato PDF  y HTML
 * @export
 * @interface DocumentoExport
 */
export interface DocumentoExport {
    /**
     * Nombre de archivo
     * @type {string}
     * @memberof DocumentoExport
     */
    'nombre'?: string;
    /**
     * MIME types de los documentos
     * @type {string}
     * @memberof DocumentoExport
     */
    'contentType'?: string;
    /**
     * Contiene el documento en contenidoBase64
     * @type {string}
     * @memberof DocumentoExport
     */
    'contenidoBase64'?: string;
    /**
     * PDF o HTML
     * @type {string}
     * @memberof DocumentoExport
     */
    'tipo'?: string;
}
/**
 * Descripción de un documento probatorio
 * @export
 * @interface DocumentoProbatorio
 */
export interface DocumentoProbatorio {
    /**
     * Identificador
     * @type {string}
     * @memberof DocumentoProbatorio
     */
    'id'?: string;
    /**
     * Documento Probatorio
     * @type {Archivo}
     * @memberof DocumentoProbatorio
     */
    'documento'?: Archivo;
}
/**
 * Descripción completa de un domicilio nacional o extranjero
 * @export
 * @interface Domicilio
 */
export interface Domicilio {
    /**
     * 
     * @type {TipoUbicacion}
     * @memberof Domicilio
     */
    'tipoUbicacion'?: TipoUbicacion;
    /**
     * Código postal de un domicilio nacional
     * @type {string}
     * @memberof Domicilio
     */
    'codigoPostal'?: string;
    /**
     * Estado o entidad federativa de un domicilio nacional
     * @type {BaseCatalogoExtendido}
     * @memberof Domicilio
     */
    'estado'?: BaseCatalogoExtendido;
    /**
     * Municipio de un domicilio nacional
     * @type {BaseCatalogoExtendido}
     * @memberof Domicilio
     */
    'municipio'?: BaseCatalogoExtendido;
    /**
     * Localidad de un domicilio nacional
     * @type {BaseCatalogoExtendido}
     * @memberof Domicilio
     */
    'localidad'?: BaseCatalogoExtendido;
    /**
     * Asentamiento de un domicilio nacional
     * @type {BaseCatalogo}
     * @memberof Domicilio
     */
    'asentamiento'?: BaseCatalogo;
    /**
     * Tipo de asentamiento de un domicilio nacional
     * @type {BaseCatalogo}
     * @memberof Domicilio
     */
    'tipoAsentamiento'?: BaseCatalogo;
    /**
     * Indicador para elegir compartir por red rizoma
     * @type {boolean}
     * @memberof Domicilio
     */
    'compartir'?: boolean;
    /**
     * 
     * @type {TipoDomicilio}
     * @memberof Domicilio
     */
    'tipoDomicilio'?: TipoDomicilio;
    /**
     * Tipo de vialidad para domicilio urbano
     * @type {BaseCatalogo}
     * @memberof Domicilio
     */
    'tipoVialidadUrbano'?: BaseCatalogo;
    /**
     * Tipo de vialidad para domicilio rural
     * @type {BaseCatalogo}
     * @memberof Domicilio
     */
    'tipoVialidadRural'?: BaseCatalogo;
    /**
     * Nombe de vialidad
     * @type {string}
     * @memberof Domicilio
     */
    'nombreVialidad'?: string;
    /**
     * Número exterior anterior, en caso de que haya cambiado la numeración del domicilio
     * @type {string}
     * @memberof Domicilio
     */
    'exteriorAnterior'?: string;
    /**
     * Número exterior
     * @type {string}
     * @memberof Domicilio
     */
    'exteriorNumerico'?: string;
    /**
     * Número exterior alfanumérico
     * @type {string}
     * @memberof Domicilio
     */
    'exteriorAlfanumerico'?: string;
    /**
     * Número interior
     * @type {string}
     * @memberof Domicilio
     */
    'interiorNumerico'?: string;
    /**
     * Número interior alfanumérico
     * @type {string}
     * @memberof Domicilio
     */
    'interiorAlfanumerico'?: string;
    /**
     * Indicador para identificar si el domicilio requiere mayor detalle de identificación, comúnmente utilizado cuando no existen vialidades o numeraciones concretas
     * @type {boolean}
     * @memberof Domicilio
     */
    'requiereDetalle'?: boolean;
    /**
     * Tipo de referencia 1
     * @type {BaseCatalogo}
     * @memberof Domicilio
     */
    'tipoReferencia01'?: BaseCatalogo;
    /**
     * Nombre de referencia 1
     * @type {string}
     * @memberof Domicilio
     */
    'nombreReferencia01'?: string;
    /**
     * Tipo de referencia 2
     * @type {BaseCatalogo}
     * @memberof Domicilio
     */
    'tipoReferencia02'?: BaseCatalogo;
    /**
     * Nombre de referencia 2
     * @type {string}
     * @memberof Domicilio
     */
    'nombreReferencia02'?: string;
    /**
     * Tipo de referencia 3
     * @type {BaseCatalogo}
     * @memberof Domicilio
     */
    'tipoReferencia03'?: BaseCatalogo;
    /**
     * Nombre de referencia 3
     * @type {string}
     * @memberof Domicilio
     */
    'nombreReferencia03'?: string;
    /**
     * Descripción
     * @type {string}
     * @memberof Domicilio
     */
    'descripcion'?: string;
    /**
     * Tipo de administración
     * @type {BaseCatalogo}
     * @memberof Domicilio
     */
    'administracion'?: BaseCatalogo;
    /**
     * Derecho de tránsito
     * @type {BaseCatalogo}
     * @memberof Domicilio
     */
    'derechoTransito'?: BaseCatalogo;
    /**
     * Código
     * @type {string}
     * @memberof Domicilio
     */
    'codigo'?: string;
    /**
     * Origen de tramo
     * @type {string}
     * @memberof Domicilio
     */
    'origenTramo'?: string;
    /**
     * Destino de tramo
     * @type {string}
     * @memberof Domicilio
     */
    'destinoTramo'?: string;
    /**
     * Cadenamiento, kilómetro y metraje en el que se ubica el domicilio
     * @type {string}
     * @memberof Domicilio
     */
    'cadenamiento'?: string;
    /**
     * Márgen
     * @type {BaseCatalogo}
     * @memberof Domicilio
     */
    'margen'?: BaseCatalogo;
    /**
     * País del domicilio extranjero
     * @type {BaseCatalogo}
     * @memberof Domicilio
     */
    'pais'?: BaseCatalogo;
    /**
     * Código postal de un domicilio extranjero
     * @type {string}
     * @memberof Domicilio
     */
    'cpExtranjero'?: string;
    /**
     * Estado de un país para un domicilio extranjero
     * @type {string}
     * @memberof Domicilio
     */
    'estadoExtranjero'?: string;
    /**
     * Ciudad de un domicilio extranjero
     * @type {string}
     * @memberof Domicilio
     */
    'ciudadExtranjero'?: string;
    /**
     * Condado de un domicilio extranjero, puede ser sinónimo de Región, municipalidad, delegación, o estación
     * @type {string}
     * @memberof Domicilio
     */
    'condadoExtranjero'?: string;
    /**
     * Calle de un domicilio extranjero
     * @type {string}
     * @memberof Domicilio
     */
    'calleExtranjero'?: string;
    /**
     * Número de un domicilio extranjero
     * @type {string}
     * @memberof Domicilio
     */
    'numeroExtranjero'?: string;
}
/**
 * Descripción completa de un domicilio nacional o extranjero para contacto de emergencia
 * @export
 * @interface DomicilioEmergencia
 */
export interface DomicilioEmergencia {
    /**
     * 
     * @type {TipoUbicacion}
     * @memberof DomicilioEmergencia
     */
    'tipoUbicacion'?: TipoUbicacion;
    /**
     * Código postal de un domicilio nacional
     * @type {string}
     * @memberof DomicilioEmergencia
     */
    'codigoPostal'?: string;
    /**
     * Estado o entidad federativa de un domicilio nacional
     * @type {BaseCatalogoExtendido}
     * @memberof DomicilioEmergencia
     */
    'estado'?: BaseCatalogoExtendido;
    /**
     * Municipio de un domicilio nacional
     * @type {BaseCatalogoExtendido}
     * @memberof DomicilioEmergencia
     */
    'municipio'?: BaseCatalogoExtendido;
    /**
     * Asentamiento de un domicilio nacional
     * @type {BaseCatalogo}
     * @memberof DomicilioEmergencia
     */
    'asentamiento'?: BaseCatalogo;
    /**
     * Tipo de asentamiento de un domicilio nacional
     * @type {BaseCatalogo}
     * @memberof DomicilioEmergencia
     */
    'tipoAsentamiento'?: BaseCatalogo;
    /**
     * País del domicilio extranjero
     * @type {BaseCatalogo}
     * @memberof DomicilioEmergencia
     */
    'pais'?: BaseCatalogo;
    /**
     * Código postal de un domicilio extranjero
     * @type {string}
     * @memberof DomicilioEmergencia
     */
    'cpExtranjero'?: string;
    /**
     * Estado de un país para un domicilio extranjero
     * @type {string}
     * @memberof DomicilioEmergencia
     */
    'estadoExtranjero'?: string;
    /**
     * Ciudad de un domicilio extranjero
     * @type {string}
     * @memberof DomicilioEmergencia
     */
    'ciudadExtranjero'?: string;
    /**
     * Condado de un domicilio extranjero, puede ser sinónimo de Región, municipalidad, delegación, o estación
     * @type {string}
     * @memberof DomicilioEmergencia
     */
    'condadoExtranjero'?: string;
    /**
     * Calle de un domicilio extranjero
     * @type {string}
     * @memberof DomicilioEmergencia
     */
    'calleExtranjero'?: string;
    /**
     * Número de un domicilio extranjero
     * @type {string}
     * @memberof DomicilioEmergencia
     */
    'numeroExtranjero'?: string;
    /**
     * Referencias del domicilio extranjero
     * @type {string}
     * @memberof DomicilioEmergencia
     */
    'referenciasExtranjero'?: string;
}
/**
 * Eje programático CONACYT
 * @export
 * @enum {string}
 */

export const EjeProgramatico = {
    FORTALECIMIENTO_COMUNIDAD: 'FORTALECIMIENTO_COMUNIDAD',
    IMPULSO_FRONTERAS: 'IMPULSO_FRONTERAS',
    INCIDENCIA_PROBLEMATICAS: 'INCIDENCIA_PROBLEMATICAS',
    DESARRROLLO_TECNOLOGIAS: 'DESARRROLLO_TECNOLOGIAS',
    DIFUSION_CIENCIA: 'DIFUSION_CIENCIA'
} as const;

export type EjeProgramatico = typeof EjeProgramatico[keyof typeof EjeProgramatico];


/**
 * Descripción de un empleo
 * @export
 * @interface Empleo
 */
export interface Empleo {
    /**
     * Identificador
     * @type {string}
     * @memberof Empleo
     */
    'id'?: string;
    /**
     * Id de referencia del origen MIIC
     * @type {string}
     * @memberof Empleo
     */
    'migracionId'?: string;
    /**
     * Indicador para identificar si es el empleo actual
     * @type {boolean}
     * @memberof Empleo
     */
    'esActual'?: boolean;
    /**
     * Indicador para identificar si es el empleo principal, puede marcarse como positivo sólo cuando el empleo es actual
     * @type {boolean}
     * @memberof Empleo
     */
    'esPrincipal'?: boolean;
    /**
     * Perfil o puesto como se desempeño
     * @type {BaseCatalogo}
     * @memberof Empleo
     */
    'perfil'?: BaseCatalogo;
    /**
     * Institución
     * @type {Institucion}
     * @memberof Empleo
     */
    'institucion': Institucion;
    /**
     * Fecha de inicio del empleo
     * @type {string}
     * @memberof Empleo
     */
    'fechaInicio': string;
    /**
     * Fecha de fin del empleo
     * @type {string}
     * @memberof Empleo
     */
    'fechaFin'?: string;
    /**
     * Nombramiento
     * @type {string}
     * @memberof Empleo
     */
    'nombramiento': string;
    /**
     * Logros obtenidos
     * @type {string}
     * @memberof Empleo
     */
    'logros'?: string;
    /**
     * Documento Probatorio para empleo actual
     * @type {Archivo}
     * @memberof Empleo
     */
    'documento'?: Archivo;
}
/**
 * Descripción de una regla de validación que no se cumple
 * @export
 * @interface ErrorValidacion
 */
export interface ErrorValidacion {
    /**
     * 
     * @type {TipoValidacion}
     * @memberof ErrorValidacion
     */
    'tipo'?: TipoValidacion;
    /**
     * Mensaje que describe la regla de validación
     * @type {string}
     * @memberof ErrorValidacion
     */
    'descripcion'?: string;
}
/**
 * Registro estadístico de escolaridad familiar
 * @export
 * @interface EscolaridadFamiliar
 */
export interface EscolaridadFamiliar {
    /**
     * Parentesco
     * @type {BaseCatalogo}
     * @memberof EscolaridadFamiliar
     */
    'parentesco'?: BaseCatalogo;
    /**
     * Escolaridad
     * @type {BaseCatalogo}
     * @memberof EscolaridadFamiliar
     */
    'escolaridad'?: BaseCatalogo;
    /**
     * Ocupación
     * @type {BaseCatalogo}
     * @memberof EscolaridadFamiliar
     */
    'ocupacion'?: BaseCatalogo;
}
/**
 * Estado de ajuste una beca para su tratamiento cuando ocurrió un envío incorrecto
 * @export
 * @enum {string}
 */

export const EstadoAjusteBeca = {
    VIGENCIA_BECA: 'VIGENCIA_BECA',
    FUERA_PERIODO_ENVIO: 'FUERA_PERIODO_ENVIO',
    PROGRAMA_NO_ELEGIBLE: 'PROGRAMA_NO_ELEGIBLE',
    PROGRAMA_INCORRECTO: 'PROGRAMA_INCORRECTO'
} as const;

export type EstadoAjusteBeca = typeof EstadoAjusteBeca[keyof typeof EstadoAjusteBeca];


/**
 * Estado de solicitud
 * @export
 * @enum {string}
 */

export const EstadoSolicitud = {
    CREADA: 'CREADA',
    EN_CAPTURA: 'EN_CAPTURA',
    PREASIGNADA: 'PREASIGNADA',
    ASIGNADA: 'ASIGNADA',
    EN_ACTUALIZACION: 'EN_ACTUALIZACION',
    ENVIADA: 'ENVIADA',
    CANCELADA: 'CANCELADA',
    REVISADA: 'REVISADA',
    APROBADA: 'APROBADA',
    RECHAZADA: 'RECHAZADA',
    BAJA: 'BAJA',
    RECLASIFICADA: 'RECLASIFICADA',
    FORMALIZADA: 'FORMALIZADA',
    OPERACION_EN_PROCESO: 'OPERACION_EN_PROCESO',
    TERMINADO: 'TERMINADO',
    IMPROCEDENTE: 'IMPROCEDENTE',
    VALIDADA: 'VALIDADA',
    EN_CORRECCION: 'EN_CORRECCION',
    EN_FIRMA: 'EN_FIRMA',
    DICTAMINADA: 'DICTAMINADA',
    FINALIZADA: 'FINALIZADA',
    FIRMADA: 'FIRMADA',
    EN_EVALUACION: 'EN_EVALUACION',
    EVALUADA: 'EVALUADA',
    EN_ASIGNACION: 'EN_ASIGNACION',
    PREFORMALIZADA: 'PREFORMALIZADA',
    ACTUALIZADA: 'ACTUALIZADA'
} as const;

export type EstadoSolicitud = typeof EstadoSolicitud[keyof typeof EstadoSolicitud];


/**
 * Objeto con la información del estatus de la solicitud mas cvu del usuario
 * @export
 * @interface EstadoSolicitudCompleto
 */
export interface EstadoSolicitudCompleto {
    /**
     * 
     * @type {EstadoSolicitud}
     * @memberof EstadoSolicitudCompleto
     */
    'estado'?: EstadoSolicitud;
    /**
     * cvu/pu del solicitante que guarda
     * @type {string}
     * @memberof EstadoSolicitudCompleto
     */
    'cvuAlta'?: string;
    /**
     * Fecha del cambio en el estado de la solicitud
     * @type {string}
     * @memberof EstadoSolicitudCompleto
     */
    'fechaAlta'?: string;
}
/**
 * Estado de una solución. Esquema temporal alterno para consumo de apeiron desde msperfil.
 * @export
 * @enum {string}
 */

export const EstadoSolucion = {
    EN_CAPTURA: 'EN_CAPTURA',
    PUBLICADA: 'PUBLICADA',
    ARCHIVADA: 'ARCHIVADA',
    CERRADA: 'CERRADA',
    CANCELADA: 'CANCELADA'
} as const;

export type EstadoSolucion = typeof EstadoSolucion[keyof typeof EstadoSolucion];


/**
 * Descripción de una estancia
 * @export
 * @interface Estancia
 */
export interface Estancia {
    /**
     * Identificador
     * @type {string}
     * @memberof Estancia
     */
    'id'?: string;
    /**
     * Id de referencia del origen MIIC
     * @type {string}
     * @memberof Estancia
     */
    'migracionId'?: string;
    /**
     * Institucion donde se realizó la estancia
     * @type {Institucion}
     * @memberof Estancia
     */
    'institucion': Institucion;
    /**
     * Nombre de la estancia
     * @type {string}
     * @memberof Estancia
     */
    'nombre': string;
    /**
     * Fecha de inicio
     * @type {string}
     * @memberof Estancia
     */
    'fechaInicio': string;
    /**
     * Fecha de fin
     * @type {string}
     * @memberof Estancia
     */
    'fechaFin': string;
    /**
     * Tipo de estancia
     * @type {BaseCatalogo}
     * @memberof Estancia
     */
    'tipo': BaseCatalogo;
    /**
     * Logro profesional obtenido
     * @type {string}
     * @memberof Estancia
     */
    'logros': string;
    /**
     * 
     * @type {Scian}
     * @memberof Estancia
     */
    'scian'?: Scian;
    /**
     * 
     * @type {Ocde}
     * @memberof Estancia
     */
    'ocde'?: Ocde;
}
/**
 * Descripción de una evaluación realizada
 * @export
 * @interface Evaluacion
 */
export interface Evaluacion {
    /**
     * Identificador
     * @type {string}
     * @memberof Evaluacion
     */
    'id'?: string;
    /**
     * Id de referencia del origen MIIC
     * @type {string}
     * @memberof Evaluacion
     */
    'migracionId'?: string;
    /**
     * 
     * @type {TipoEntidad}
     * @memberof Evaluacion
     */
    'tipo': TipoEntidad;
    /**
     * Nombre del fondo o programa CONACYT
     * @type {string}
     * @memberof Evaluacion
     */
    'nombreFondo'?: string;
    /**
     * Fecha de asignación
     * @type {string}
     * @memberof Evaluacion
     */
    'fechaAsignacion'?: string;
    /**
     * Fecha de aceptación
     * @type {string}
     * @memberof Evaluacion
     */
    'fechaAceptacion'?: string;
    /**
     * Fecha de evaluación
     * @type {string}
     * @memberof Evaluacion
     */
    'fechaEvaluacion'?: string;
    /**
     * Descripción de la evaluación
     * @type {string}
     * @memberof Evaluacion
     */
    'descripcion'?: string;
    /**
     * Nombre de la institución
     * @type {string}
     * @memberof Evaluacion
     */
    'nombreInstitucion'?: string;
    /**
     * Producto evaluado
     * @type {BaseCatalogo}
     * @memberof Evaluacion
     */
    'productoEvaluado'?: BaseCatalogo;
    /**
     * Tipo de evaluación realizada
     * @type {BaseCatalogo}
     * @memberof Evaluacion
     */
    'tipoEvaluacion'?: BaseCatalogo;
    /**
     * Nombre del producto evaluado
     * @type {string}
     * @memberof Evaluacion
     */
    'nombreProducto'?: string;
    /**
     * Fecha de inicio
     * @type {string}
     * @memberof Evaluacion
     */
    'fechaInicio'?: string;
    /**
     * Fecha de fin
     * @type {string}
     * @memberof Evaluacion
     */
    'fechaFin'?: string;
    /**
     * Cargo desempeñado o nomber del puesto que ocupo
     * @type {string}
     * @memberof Evaluacion
     */
    'cargo'?: string;
}
/**
 * Es utilizado para crear los criterios de búsqueda que son empleados para filtrar información
 * @export
 * @interface Filtro
 */
export interface Filtro {
    /**
     * Id de la institución del empleo principal de un usuario rizoma
     * @type {number}
     * @memberof Filtro
     */
    'idInstitucion'?: number;
    /**
     * Nombre de la institución del empleo principal de un usuario rizoma
     * @type {string}
     * @memberof Filtro
     */
    'nombreInstitucion'?: string;
    /**
     * Nombre de usuario
     * @type {string}
     * @memberof Filtro
     */
    'nombre'?: string;
    /**
     * Primer Apellido de usuario
     * @type {string}
     * @memberof Filtro
     */
    'primerApellido'?: string;
    /**
     * Segundo Apellido de usuario
     * @type {string}
     * @memberof Filtro
     */
    'segundoApellido'?: string;
    /**
     * Nombre del area (Area de conocimiento)
     * @type {string}
     * @memberof Filtro
     */
    'area'?: string;
    /**
     * Nombre del campo (Area de conocimiento)
     * @type {string}
     * @memberof Filtro
     */
    'campo'?: string;
    /**
     * Nombre del disciplina (Area de conocimiento)
     * @type {string}
     * @memberof Filtro
     */
    'disciplina'?: string;
    /**
     * Nombre del subdisciplina (Area de conocimiento)
     * @type {string}
     * @memberof Filtro
     */
    'subdisciplina'?: string;
}
/**
 * Descripción de un grado académico
 * @export
 * @interface GradoAcademico
 */
export interface GradoAcademico {
    /**
     * Identicador
     * @type {string}
     * @memberof GradoAcademico
     */
    'id'?: string;
    /**
     * Id de referencia del origen MIIC
     * @type {string}
     * @memberof GradoAcademico
     */
    'migracionId'?: string;
    /**
     * Nivel de escolaridad
     * @type {BaseCatalogo}
     * @memberof GradoAcademico
     */
    'nivelEscolaridad': BaseCatalogo;
    /**
     * Nombre del título
     * @type {string}
     * @memberof GradoAcademico
     */
    'titulo': string;
    /**
     * Estatus actual del grado académico
     * @type {BaseCatalogo}
     * @memberof GradoAcademico
     */
    'estatus': BaseCatalogo;
    /**
     * Número de cedula profesional
     * @type {string}
     * @memberof GradoAcademico
     */
    'cedulaProfesional'?: string;
    /**
     * Opción de titulación elegida
     * @type {BaseCatalogo}
     * @memberof GradoAcademico
     */
    'opcionTitulacion'?: BaseCatalogo;
    /**
     * Título de tésis
     * @type {string}
     * @memberof GradoAcademico
     */
    'tituloTesis'?: string;
    /**
     * Fecha de obtención del grado académico
     * @type {string}
     * @memberof GradoAcademico
     */
    'fechaObtencion'?: string;
    /**
     * Institución que otorgó el grado académico
     * @type {Institucion}
     * @memberof GradoAcademico
     */
    'institucion': Institucion;
    /**
     * Documento Probatorio para el grado academico
     * @type {Archivo}
     * @memberof GradoAcademico
     */
    'documento'?: Archivo;
}
/**
 * Cuestionario de variables sociodemograficas
 * @export
 * @interface Grupos
 */
export interface Grupos {
    /**
     * ¿tiene mucha dificultad o no puede realizar alguna de las tareas de la vida diaria?
     * @type {Respuesta}
     * @memberof Grupos
     */
    'disDif'?: Respuesta;
    /**
     * Caminar, subir o bajar escalones usando sus piernas
     * @type {Respuesta}
     * @memberof Grupos
     */
    'disCam'?: Respuesta;
    /**
     * Ver, aún usando lentes
     * @type {Respuesta}
     * @memberof Grupos
     */
    'disVer'?: Respuesta;
    /**
     * Mover o usar sus brazos o manos
     * @type {Respuesta}
     * @memberof Grupos
     */
    'disMov'?: Respuesta;
    /**
     * Aprender, recordar o concentrarse por alguna condición intelectual
     * @type {Respuesta}
     * @memberof Grupos
     */
    'disApr'?: Respuesta;
    /**
     * Oír, aún usando un aparato auditivo
     * @type {Respuesta}
     * @memberof Grupos
     */
    'disOir'?: Respuesta;
    /**
     * Hablar o comunicarse (entender o ser entendido(a)) por otros
     * @type {Respuesta}
     * @memberof Grupos
     */
    'disHab'?: Respuesta;
    /**
     * Bañarse, vestirse o comer
     * @type {Respuesta}
     * @memberof Grupos
     */
    'disBan'?: Respuesta;
    /**
     * Realizar sus actividades diarias por alguna condición emocional o mental (p.ej, esquizofrenia o depresión)
     * @type {Respuesta}
     * @memberof Grupos
     */
    'disRea'?: Respuesta;
    /**
     * Por sus costumbres y/o tradiciones ¿usted se considera indígena?
     * @type {Respuesta}
     * @memberof Grupos
     */
    'perteCost'?: Respuesta;
    /**
     * ¿A qué pueblo originario pertenece?
     * @type {BaseCatalogo}
     * @memberof Grupos
     */
    'pertePue'?: BaseCatalogo;
    /**
     * ¿Cual es su autodenominación?
     * @type {BaseCatalogo}
     * @memberof Grupos
     */
    'autodenominacion'?: BaseCatalogo;
    /**
     * ¿Usted habla alguna lengua originaria?
     * @type {Respuesta}
     * @memberof Grupos
     */
    'perteUste'?: Respuesta;
    /**
     * ¿Usted se considera afromexicano(a), negro(a) o afrodescendiente?
     * @type {Respuesta}
     * @memberof Grupos
     */
    'perteAnte'?: Respuesta;
    /**
     * ¿Usted es madre jefa de familia?
     * @type {Respuesta}
     * @memberof Grupos
     */
    'madreFamilia'?: Respuesta;
    /**
     * ¿Su formación académica básica (primaria) en que tipo de Institución(es) se llevó acabo?
     * @type {Respuesta}
     * @memberof Grupos
     */
    'formacionBasica'?: Respuesta;
    /**
     * ¿Su formación académica básica (secundaria) en que tipo de Institución(es) se llevó acabo?
     * @type {Respuesta}
     * @memberof Grupos
     */
    'formacionSecundaria'?: Respuesta;
    /**
     * ¿Su formación académica media superior (preparatoria) en que tipo de Institución(es) se llevó acabo?
     * @type {Respuesta}
     * @memberof Grupos
     */
    'formacionMedia'?: Respuesta;
    /**
     * ¿Su formación académica como Técnico superior en que tipo de Institución(es) se llevó acabo?
     * @type {Respuesta}
     * @memberof Grupos
     */
    'formacionTecnica'?: Respuesta;
}
/**
 * Descripción de un idioma
 * @export
 * @interface Idioma
 */
export interface Idioma {
    /**
     * Identificador
     * @type {string}
     * @memberof Idioma
     */
    'id'?: string;
    /**
     * Id de referencia del origen MIIC
     * @type {string}
     * @memberof Idioma
     */
    'migracionId'?: string;
    /**
     * Nombre del idioma de acuerdo al catálogo
     * @type {BaseCatalogo}
     * @memberof Idioma
     */
    'nombre'?: BaseCatalogo;
    /**
     * Nivel de dominio
     * @type {BaseCatalogo}
     * @memberof Idioma
     */
    'dominio'?: BaseCatalogo;
    /**
     * Nivel de conversación
     * @type {BaseCatalogo}
     * @memberof Idioma
     */
    'conversacion'?: BaseCatalogo;
    /**
     * Nivel de lectura
     * @type {BaseCatalogo}
     * @memberof Idioma
     */
    'lectura'?: BaseCatalogo;
    /**
     * Nivel de escritura
     * @type {BaseCatalogo}
     * @memberof Idioma
     */
    'escritura'?: BaseCatalogo;
    /**
     * Indicador para conocer si cuenta con certificación
     * @type {boolean}
     * @memberof Idioma
     */
    'esCertificado'?: boolean;
    /**
     * Nombre de institución que otorgó el certificado
     * @type {string}
     * @memberof Idioma
     */
    'nombreInstitucion'?: string;
    /**
     * Fecha de evaluación
     * @type {string}
     * @memberof Idioma
     */
    'fechaEvaluacion'?: string;
    /**
     * Descripción del documento probatorio
     * @type {string}
     * @memberof Idioma
     */
    'documentoProbatorio'?: string;
    /**
     * Inicio de vigencia del certificado
     * @type {string}
     * @memberof Idioma
     */
    'inicioVigencia'?: string;
    /**
     * Fin de vigencia del certificado
     * @type {string}
     * @memberof Idioma
     */
    'finVigencia'?: string;
    /**
     * Puntuación obtenida en evaluación
     * @type {string}
     * @memberof Idioma
     */
    'puntuacion'?: string;
    /**
     * Nivel Conferido en evaluación
     * @type {string}
     * @memberof Idioma
     */
    'nivelConferido'?: string;
}
/**
 * Contenedor de idiomas y lenguas
 * @export
 * @interface Idiomas
 */
export interface Idiomas {
    /**
     * Idiomas
     * @type {Array<Idioma>}
     * @memberof Idiomas
     */
    'idiomas'?: Array<Idioma>;
    /**
     * Lenguas
     * @type {Array<Lengua>}
     * @memberof Idiomas
     */
    'lenguas'?: Array<Lengua>;
}
/**
 * Descripción de una institución
 * @export
 * @interface Institucion
 */
export interface Institucion {
    /**
     * Identificador único
     * @type {number}
     * @memberof Institucion
     */
    'id': number;
    /**
     * Clave de la institución, puede ser nula en algunos casos
     * @type {string}
     * @memberof Institucion
     */
    'clave'?: string;
    /**
     * Nombre de la institución
     * @type {string}
     * @memberof Institucion
     */
    'nombre': string;
    /**
     * Tipo de institución
     * @type {BaseCatalogo}
     * @memberof Institucion
     */
    'tipo': BaseCatalogo;
    /**
     * País de la institución de acuerdo a catálogo
     * @type {BaseCatalogo}
     * @memberof Institucion
     */
    'pais': BaseCatalogo;
    /**
     * Entidad federativa donde se ubica una institución nacional
     * @type {BaseCatalogoLegado}
     * @memberof Institucion
     */
    'entidad'?: BaseCatalogoLegado;
    /**
     * Municipio donde se ubica una institución nacional
     * @type {BaseCatalogoLegado}
     * @memberof Institucion
     */
    'municipio'?: BaseCatalogoLegado;
    /**
     * Descripción del primer nivel de sector de una institución
     * @type {BaseCatalogo}
     * @memberof Institucion
     */
    'nivelUno'?: BaseCatalogo;
    /**
     * Descripción del segundo nivel de sector o ámbito de una institución
     * @type {BaseCatalogo}
     * @memberof Institucion
     */
    'nivelDos'?: BaseCatalogo;
}
/**
 * Institución
 * @export
 * @interface InstitucionPrograma
 */
export interface InstitucionPrograma {
    /**
     * 
     * @type {string}
     * @memberof InstitucionPrograma
     */
    'claveInstitucion'?: string;
    /**
     * 
     * @type {string}
     * @memberof InstitucionPrograma
     */
    'nombreInstitucion'?: string;
}
/**
 * Descripción de una lengua
 * @export
 * @interface Lengua
 */
export interface Lengua {
    /**
     * Identificador
     * @type {string}
     * @memberof Lengua
     */
    'id'?: string;
    /**
     * Id de referencia del origen MIIC
     * @type {string}
     * @memberof Lengua
     */
    'migracionId'?: string;
    /**
     * Nombre de la lengua de acuerdo al catálogo
     * @type {BaseCatalogo}
     * @memberof Lengua
     */
    'nombre'?: BaseCatalogo;
    /**
     * Nivel de dominio
     * @type {BaseCatalogo}
     * @memberof Lengua
     */
    'dominio'?: BaseCatalogo;
    /**
     * Nivel de conversación
     * @type {BaseCatalogo}
     * @memberof Lengua
     */
    'conversacion'?: BaseCatalogo;
    /**
     * Nivel de lectura
     * @type {BaseCatalogo}
     * @memberof Lengua
     */
    'lectura'?: BaseCatalogo;
    /**
     * Nivel de escritura
     * @type {BaseCatalogo}
     * @memberof Lengua
     */
    'escritura'?: BaseCatalogo;
    /**
     * Indicador para conocer si cuenta con certificación
     * @type {boolean}
     * @memberof Lengua
     */
    'esCertificado'?: boolean;
    /**
     * Fecha de evaluación
     * @type {string}
     * @memberof Lengua
     */
    'fechaEvaluacion'?: string;
    /**
     * Descripción del documento probatorio
     * @type {string}
     * @memberof Lengua
     */
    'documentoProbatorio'?: string;
    /**
     * Inicio de vigencia del certificado
     * @type {string}
     * @memberof Lengua
     */
    'inicioVigencia'?: string;
    /**
     * Fin de vigencia del certificado
     * @type {string}
     * @memberof Lengua
     */
    'finVigencia'?: string;
    /**
     * Puntuación obtenida en evaluación
     * @type {string}
     * @memberof Lengua
     */
    'puntuacion'?: string;
}
/**
 * Descripción de Logro académico
 * @export
 * @interface Logro
 */
export interface Logro {
    /**
     * Identificador
     * @type {string}
     * @memberof Logro
     */
    'id'?: string;
    /**
     * Id de referencia del origen MIIC
     * @type {string}
     * @memberof Logro
     */
    'migracionId'?: string;
    /**
     * 
     * @type {TipoEntidad}
     * @memberof Logro
     */
    'tipo': TipoEntidad;
    /**
     * Nombre del logro
     * @type {BaseCatalogo}
     * @memberof Logro
     */
    'nombre'?: BaseCatalogo;
    /**
     * Año de obtención
     * @type {string}
     * @memberof Logro
     */
    'anio': string;
    /**
     * Distinción recibida
     * @type {string}
     * @memberof Logro
     */
    'distincion'?: string;
    /**
     * Institución
     * @type {Institucion}
     * @memberof Logro
     */
    'institucion'?: Institucion;
    /**
     * País
     * @type {BaseCatalogo}
     * @memberof Logro
     */
    'pais'?: BaseCatalogo;
    /**
     * Descripción del premio o distinción
     * @type {string}
     * @memberof Logro
     */
    'descripcion'?: string;
}
/**
 * Información general de perfil de un usuario
 * @export
 * @interface MiPerfil
 */
export interface MiPerfil {
    /**
     * Fotografía o imagen de perfil
     * @type {Archivo}
     * @memberof MiPerfil
     */
    'fotografia'?: Archivo;
    /**
     * Semblanza
     * @type {string}
     * @memberof MiPerfil
     */
    'semblanza': string;
    /**
     * Medios de contacto
     * @type {Array<Contacto>}
     * @memberof MiPerfil
     */
    'mediosContacto': Array<Contacto>;
    /**
     * Identificador de autor OrcId
     * @type {string}
     * @memberof MiPerfil
     */
    'orcId'?: string;
    /**
     * Identificador de autor ResearcherIdThomson
     * @type {string}
     * @memberof MiPerfil
     */
    'researcherIdThomson'?: string;
    /**
     * Identificador de autor ArXivAuthorId
     * @type {string}
     * @memberof MiPerfil
     */
    'arXivAuthorId'?: string;
    /**
     * Identificador de autor PubMedAuthorId
     * @type {string}
     * @memberof MiPerfil
     */
    'pubMedAuthorId'?: string;
    /**
     * Identificador de autor OpenId
     * @type {string}
     * @memberof MiPerfil
     */
    'openId'?: string;
    /**
     * CURP
     * @type {string}
     * @memberof MiPerfil
     */
    'curp'?: string;
    /**
     * Nombre del usuario
     * @type {string}
     * @memberof MiPerfil
     */
    'nombre'?: string;
    /**
     * Primer apellido del usuario
     * @type {string}
     * @memberof MiPerfil
     */
    'primerApellido'?: string;
    /**
     * Segundo apellido del usuario
     * @type {string}
     * @memberof MiPerfil
     */
    'segundoApellido'?: string;
    /**
     * Fecha de nacimiento del usuario
     * @type {string}
     * @memberof MiPerfil
     */
    'fechaNacimiento'?: string;
    /**
     * Sexo
     * @type {BaseCatalogo}
     * @memberof MiPerfil
     */
    'sexo'?: BaseCatalogo;
    /**
     * País de nacimiento del usuario
     * @type {BaseCatalogo}
     * @memberof MiPerfil
     */
    'paisNacimiento'?: BaseCatalogo;
    /**
     * Entidad federativa de nacimiento del usuario
     * @type {BaseCatalogoExtendido}
     * @memberof MiPerfil
     */
    'entidadFederativa'?: BaseCatalogoExtendido;
    /**
     * RFC
     * @type {string}
     * @memberof MiPerfil
     */
    'rfc'?: string;
    /**
     * Estado civil
     * @type {BaseCatalogo}
     * @memberof MiPerfil
     */
    'estadoCivil': BaseCatalogo;
    /**
     * Nacionalidad
     * @type {BaseCatalogo}
     * @memberof MiPerfil
     */
    'nacionalidad'?: BaseCatalogo;
    /**
     * 
     * @type {Domicilio}
     * @memberof MiPerfil
     */
    'domicilio': Domicilio;
    /**
     * 
     * @type {AreaConocimiento}
     * @memberof MiPerfil
     */
    'areaConocimiento': AreaConocimiento;
    /**
     * 
     * @type {Archivo}
     * @memberof MiPerfil
     */
    'documentoExtranjero'?: Archivo;
    /**
     * Contactos de emergencia
     * @type {Array<ContactoEmergencias>}
     * @memberof MiPerfil
     */
    'contactosEmergencia'?: Array<ContactoEmergencias>;
    /**
     * Dependientes Económicos
     * @type {Array<DependienteEconomico>}
     * @memberof MiPerfil
     */
    'dependientesEconomicos'?: Array<DependienteEconomico>;
}
/**
 * Identificador de moneda o divisa
 * @export
 * @enum {string}
 */

export const Moneda = {
    EUR: 'EUR',
    GBP: 'GBP',
    USD: 'USD'
} as const;

export type Moneda = typeof Moneda[keyof typeof Moneda];


/**
 * Monto calculado para un año posterior durante el apoyo de beca
 * @export
 * @interface MontoPosterior
 */
export interface MontoPosterior {
    /**
     * Edad
     * @type {number}
     * @memberof MontoPosterior
     */
    'edad'?: number;
    /**
     * Monto
     * @type {number}
     * @memberof MontoPosterior
     */
    'monto'?: number;
    /**
     * Año
     * @type {number}
     * @memberof MontoPosterior
     */
    'anio'?: number;
}
/**
 * Motivo para solicitar un apoyo de beca
 * @export
 * @interface Motivo
 */
export interface Motivo {
    /**
     * Orden
     * @type {number}
     * @memberof Motivo
     */
    'orden'?: number;
    /**
     * 
     * @type {MotivoEnum}
     * @memberof Motivo
     */
    'tipoMotivo'?: MotivoEnum;
    /**
     * Valor o respuesta del motivo
     * @type {string}
     * @memberof Motivo
     */
    'valor'?: string;
}
/**
 * Identificador de un motivo
 * @export
 * @enum {string}
 */

export const MotivoEnum = {
    EXPERIENCIA_LABORAL: 'EXPERIENCIA_LABORAL',
    IMPORTANCIA_APOYO: 'IMPORTANCIA_APOYO',
    VENTAJAS_PROGRAMA: 'VENTAJAS_PROGRAMA',
    ALINEACION_AREAS_PRIORITARIAS: 'ALINEACION_AREAS_PRIORITARIAS'
} as const;

export type MotivoEnum = typeof MotivoEnum[keyof typeof MotivoEnum];


/**
 * Contenedor de categorías de la Organización para la Cooperación y el Desarrollo Económicos (OCDE)
 * @export
 * @interface Ocde
 */
export interface Ocde {
    /**
     * Sector económico de la Ocde
     * @type {BaseCatalogoExtendido}
     * @memberof Ocde
     */
    'sector'?: BaseCatalogoExtendido;
    /**
     * Division dependiente de un sector
     * @type {BaseCatalogoExtendido}
     * @memberof Ocde
     */
    'division'?: BaseCatalogoExtendido;
    /**
     * Grupo dependiente de una división
     * @type {BaseCatalogoExtendido}
     * @memberof Ocde
     */
    'grupo'?: BaseCatalogoExtendido;
    /**
     * Clase dependiente de un grupo
     * @type {BaseCatalogoExtendido}
     * @memberof Ocde
     */
    'clase'?: BaseCatalogoExtendido;
}
/**
 * Información sobre una participación
 * @export
 * @interface ParticipacionBrief
 */
export interface ParticipacionBrief {
    /**
     * 
     * @type {string}
     * @memberof ParticipacionBrief
     */
    'id'?: string;
    /**
     * Identificador de la solicitud
     * @type {string}
     * @memberof ParticipacionBrief
     */
    'solicitudId'?: string;
    /**
     * Sub clasificador de la participación
     * @type {string}
     * @memberof ParticipacionBrief
     */
    'contenedorId'?: string;
    /**
     * 
     * @type {RolAutoridad}
     * @memberof ParticipacionBrief
     */
    'rol'?: RolAutoridad;
    /**
     * 
     * @type {Participante}
     * @memberof ParticipacionBrief
     */
    'participante'?: Participante;
}
/**
 * Participante
 * @export
 * @interface Participante
 */
export interface Participante {
    /**
     * CVU
     * @type {string}
     * @memberof Participante
     */
    'cvu'?: string;
    /**
     * Correo de login
     * @type {string}
     * @memberof Participante
     */
    'login'?: string;
    /**
     * Nombramiento profesional
     * @type {string}
     * @memberof Participante
     */
    'titulo'?: string;
    /**
     * Nombre
     * @type {string}
     * @memberof Participante
     */
    'nombre'?: string;
    /**
     * Apellido paterno
     * @type {string}
     * @memberof Participante
     */
    'apellidoPaterno'?: string;
    /**
     * Apellido materno
     * @type {string}
     * @memberof Participante
     */
    'apellidoMaterno'?: string;
    /**
     * CURP
     * @type {string}
     * @memberof Participante
     */
    'curp'?: string;
    /**
     * RFC
     * @type {string}
     * @memberof Participante
     */
    'rfc'?: string;
    /**
     * Género
     * @type {string}
     * @memberof Participante
     */
    'genero'?: string;
    /**
     * Correo de contacto
     * @type {string}
     * @memberof Participante
     */
    'correo'?: string;
    /**
     * Nivel académico
     * @type {string}
     * @memberof Participante
     */
    'nivelAcademico'?: string;
    /**
     * Nombre del grado académico
     * @type {string}
     * @memberof Participante
     */
    'gradoAcademico'?: string;
    /**
     * Nacionalidad
     * @type {string}
     * @memberof Participante
     */
    'nacionalidad'?: string;
    /**
     * Código de nacionalidad
     * @type {string}
     * @memberof Participante
     */
    'nacionalidadCodigo'?: string;
    /**
     * País de residencia
     * @type {string}
     * @memberof Participante
     */
    'paisResidencia'?: string;
    /**
     * Código de país de residencia
     * @type {string}
     * @memberof Participante
     */
    'paisResidenciaCodigo'?: string;
    /**
     * Nivel SNI
     * @type {string}
     * @memberof Participante
     */
    'nivelsni'?: string;
    /**
     * Número de RCEA
     * @type {string}
     * @memberof Participante
     */
    'rcea'?: string;
    /**
     * Fecha del Inicio de estudios
     * @type {string}
     * @memberof Participante
     */
    'inicioEstudios'?: string;
    /**
     * Fecha en que obtuvo el grado
     * @type {string}
     * @memberof Participante
     */
    'obtencionGrado'?: string;
    /**
     * Reincorporación
     * @type {string}
     * @memberof Participante
     */
    'reincorporacion'?: string;
    /**
     * Fecha fin de estudios
     * @type {string}
     * @memberof Participante
     */
    'finEstudios'?: string;
    /**
     * Fecha de inicio de la generación
     * @type {string}
     * @memberof Participante
     */
    'inicioGeneracion'?: string;
    /**
     * Tiempo de dedidación
     * @type {string}
     * @memberof Participante
     */
    'tiempoDedicacion'?: string;
    /**
     * Promedio anterior
     * @type {string}
     * @memberof Participante
     */
    'promedioAnterior'?: string;
    /**
     * 
     * @type {Programa}
     * @memberof Participante
     */
    'programa'?: Programa;
    /**
     * 
     * @type {InstitucionPrograma}
     * @memberof Participante
     */
    'institucion'?: InstitucionPrograma;
    /**
     * 
     * @type {Sede}
     * @memberof Participante
     */
    'sede'?: Sede;
}
/**
 * 
 * @export
 * @interface ParticipanteAllOf
 */
export interface ParticipanteAllOf {
    /**
     * Fecha del Inicio de estudios
     * @type {string}
     * @memberof ParticipanteAllOf
     */
    'inicioEstudios'?: string;
    /**
     * Fecha en que obtuvo el grado
     * @type {string}
     * @memberof ParticipanteAllOf
     */
    'obtencionGrado'?: string;
    /**
     * Reincorporación
     * @type {string}
     * @memberof ParticipanteAllOf
     */
    'reincorporacion'?: string;
    /**
     * Fecha fin de estudios
     * @type {string}
     * @memberof ParticipanteAllOf
     */
    'finEstudios'?: string;
    /**
     * Fecha de inicio de la generación
     * @type {string}
     * @memberof ParticipanteAllOf
     */
    'inicioGeneracion'?: string;
    /**
     * Tiempo de dedidación
     * @type {string}
     * @memberof ParticipanteAllOf
     */
    'tiempoDedicacion'?: string;
    /**
     * Promedio anterior
     * @type {string}
     * @memberof ParticipanteAllOf
     */
    'promedioAnterior'?: string;
    /**
     * 
     * @type {Programa}
     * @memberof ParticipanteAllOf
     */
    'programa'?: Programa;
    /**
     * 
     * @type {InstitucionPrograma}
     * @memberof ParticipanteAllOf
     */
    'institucion'?: InstitucionPrograma;
    /**
     * 
     * @type {Sede}
     * @memberof ParticipanteAllOf
     */
    'sede'?: Sede;
}
/**
 * Información completa del perfil de un usuario
 * @export
 * @interface Perfil
 */
export interface Perfil {
    /**
     * id
     * @type {string}
     * @memberof Perfil
     */
    'id'?: string;
    /**
     * Cvu del usuario rizoma
     * @type {string}
     * @memberof Perfil
     */
    'cvu'?: string;
    /**
     * Id rizoma del usuario
     * @type {string}
     * @memberof Perfil
     */
    'idRizoma'?: string;
    /**
     * Correo login de usuario
     * @type {string}
     * @memberof Perfil
     */
    'login'?: string;
    /**
     * 
     * @type {BaseCatalogoExtendido}
     * @memberof Perfil
     */
    'nivelSni'?: BaseCatalogoExtendido;
    /**
     * Nivel académico del usuario
     * @type {string}
     * @memberof Perfil
     */
    'nivelAcademico'?: PerfilNivelAcademicoEnum;
    /**
     * Nombre abreviado del grado académico del usuario
     * @type {string}
     * @memberof Perfil
     */
    'titulo'?: string;
    /**
     * 
     * @type {MiPerfil}
     * @memberof Perfil
     */
    'principal'?: MiPerfil;
    /**
     * 
     * @type {Grupos}
     * @memberof Perfil
     */
    'cuestionarioVariables'?: Grupos;
    /**
     * 
     * @type {DesarrolloInstitucional}
     * @memberof Perfil
     */
    'desarrolloInstitucional'?: DesarrolloInstitucional;
    /**
     * 
     * @type {DocumentoProbatorio}
     * @memberof Perfil
     */
    'tesisDirigidasDocumento'?: DocumentoProbatorio;
    /**
     * 
     * @type {DocumentoProbatorio}
     * @memberof Perfil
     */
    'cursosImpartidosDocumento'?: DocumentoProbatorio;
    /**
     * 
     * @type {DocumentoProbatorio}
     * @memberof Perfil
     */
    'diplomadosImpartidosDocumento'?: DocumentoProbatorio;
    /**
     * 
     * @type {DocumentoProbatorio}
     * @memberof Perfil
     */
    'divulgacionesDocumento'?: DocumentoProbatorio;
    /**
     * 
     * @type {DocumentoProbatorio}
     * @memberof Perfil
     */
    'congresosDocumento'?: DocumentoProbatorio;
    /**
     * Lista de la trayectoria académica del usuario rizoma
     * @type {Array<GradoAcademico>}
     * @memberof Perfil
     */
    'trayectoriaAcademica'?: Array<GradoAcademico>;
    /**
     * 
     * @type {Cursos}
     * @memberof Perfil
     */
    'formacionContinua'?: Cursos;
    /**
     * Lista de los logros académicos del usuario rizoma
     * @type {Array<Logro>}
     * @memberof Perfil
     */
    'logros'?: Array<Logro>;
    /**
     * 
     * @type {Idiomas}
     * @memberof Perfil
     */
    'idiomaLengua'?: Idiomas;
    /**
     * Lista de los empleos del usuario rizoma
     * @type {Array<Empleo>}
     * @memberof Perfil
     */
    'trayectoriaProfesional'?: Array<Empleo>;
    /**
     * Lista de las evaluaciones realizadas por el usuario rizoma
     * @type {Array<Evaluacion>}
     * @memberof Perfil
     */
    'evaluacionesOtorgadas'?: Array<Evaluacion>;
    /**
     * Lista de las estancias del usuario rizoma
     * @type {Array<Estancia>}
     * @memberof Perfil
     */
    'estancias'?: Array<Estancia>;
    /**
     * Lista de los cursos impartidos por el usuario rizoma
     * @type {Array<CursoImpartido>}
     * @memberof Perfil
     */
    'cursosImpartidos'?: Array<CursoImpartido>;
    /**
     * Lista de las tesis dirigidas del usuario rizoma
     * @type {Array<TesisDirigida>}
     * @memberof Perfil
     */
    'tesisDirigidas'?: Array<TesisDirigida>;
    /**
     * Lista de los diplomados impartidos del usuario rizoma
     * @type {Array<Diplomado>}
     * @memberof Perfil
     */
    'diplomadosImpartidos'?: Array<Diplomado>;
    /**
     * Lista de los congresos del usuario rizoma
     * @type {Array<Congreso>}
     * @memberof Perfil
     */
    'congresos'?: Array<Congreso>;
    /**
     * Lista de los trabajos de divulgación del usuario rizoma
     * @type {Array<TrabajoDivulgacion>}
     * @memberof Perfil
     */
    'divulgacion'?: Array<TrabajoDivulgacion>;
    /**
     * Fecha de creación (primer acceso en PU)
     * @type {string}
     * @memberof Perfil
     */
    'createdDate'?: string;
    /**
     * Última fecha de actualización
     * @type {string}
     * @memberof Perfil
     */
    'lastModifiedDate'?: string;
}

export const PerfilNivelAcademicoEnum = {
    Primaria: 'PRIMARIA',
    Secundaria: 'SECUNDARIA',
    Preparatoria: 'PREPARATORIA',
    TecnicoSuperior: 'TECNICO_SUPERIOR',
    Licenciatura: 'LICENCIATURA',
    Especialidad: 'ESPECIALIDAD',
    Maestria: 'MAESTRIA',
    Doctorado: 'DOCTORADO'
} as const;

export type PerfilNivelAcademicoEnum = typeof PerfilNivelAcademicoEnum[keyof typeof PerfilNivelAcademicoEnum];

/**
 * Información breve del perfil de un usuario
 * @export
 * @interface PerfilBrief
 */
export interface PerfilBrief {
    /**
     * Cvu del usuario rizoma
     * @type {string}
     * @memberof PerfilBrief
     */
    'cvu'?: string;
    /**
     * login del usuario rizoma
     * @type {string}
     * @memberof PerfilBrief
     */
    'login'?: string;
    /**
     * Curp del usuario rizoma
     * @type {string}
     * @memberof PerfilBrief
     */
    'curp'?: string;
    /**
     * Nombre completo del usuario rizoma
     * @type {string}
     * @memberof PerfilBrief
     */
    'nombreCompleto'?: string;
    /**
     * Género del usuario rizoma
     * @type {string}
     * @memberof PerfilBrief
     */
    'genero'?: string;
    /**
     * RFC del usuario rizoma
     * @type {string}
     * @memberof PerfilBrief
     */
    'rfc'?: string;
    /**
     * Entidad federativa actual (del domicilio registrado)
     * @type {string}
     * @memberof PerfilBrief
     */
    'entidad'?: string;
    /**
     * Nacionalidad del usuario rizoma
     * @type {string}
     * @memberof PerfilBrief
     */
    'nacionalidad'?: string;
    /**
     * Edad determinada en años cumplidos
     * @type {string}
     * @memberof PerfilBrief
     */
    'edad'?: string;
    /**
     * 
     * @type {AreaConocimiento}
     * @memberof PerfilBrief
     */
    'areaConocimiento'?: AreaConocimiento;
    /**
     * Medios de contacto
     * @type {Array<Contacto>}
     * @memberof PerfilBrief
     */
    'mediosContacto'?: Array<Contacto>;
    /**
     * Institución que corresponde al empleo actual y principal
     * @type {Institucion}
     * @memberof PerfilBrief
     */
    'institucion'?: Institucion;
}
/**
 * Información de periodo de beca
 * @export
 * @interface PeriodoBeca
 */
export interface PeriodoBeca {
    /**
     * Fecha de inicio de estudios
     * @type {string}
     * @memberof PeriodoBeca
     */
    'fechaInicioEstudios'?: string;
    /**
     * Fecha de fin de estudios
     * @type {string}
     * @memberof PeriodoBeca
     */
    'fechaFinEstudios'?: string;
    /**
     * Fecha de inicio de beca
     * @type {string}
     * @memberof PeriodoBeca
     */
    'fechaInicioBeca'?: string;
    /**
     * Fecha de fin de beca
     * @type {string}
     * @memberof PeriodoBeca
     */
    'fechaFinBeca'?: string;
    /**
     * Duración de beca en meses
     * @type {number}
     * @memberof PeriodoBeca
     */
    'duracionBeca'?: number;
    /**
     * Duración de estudios en meses
     * @type {number}
     * @memberof PeriodoBeca
     */
    'duracionEstudios'?: number;
    /**
     * Duración de los periodos lectivos
     * @type {string}
     * @memberof PeriodoBeca
     */
    'periodicidad'?: string;
    /**
     * Indica si el rango de beca fue modificado por el usuario
     * @type {boolean}
     * @memberof PeriodoBeca
     */
    'rangoFechasBecaEditado'?: boolean;
}
/**
 * Identificador de periodo de pago
 * @export
 * @enum {string}
 */

export const PeriodoPagoEnum = {
    MENSUAL: 'MENSUAL',
    SEMESTRAL: 'SEMESTRAL',
    ANUAL: 'ANUAL'
} as const;

export type PeriodoPagoEnum = typeof PeriodoPagoEnum[keyof typeof PeriodoPagoEnum];


/**
 * Descripción de una persona
 * @export
 * @interface Persona
 */
export interface Persona {
    /**
     * CVU
     * @type {string}
     * @memberof Persona
     */
    'cvu'?: string;
    /**
     * Correo de login
     * @type {string}
     * @memberof Persona
     */
    'login'?: string;
    /**
     * Nombramiento profesional
     * @type {string}
     * @memberof Persona
     */
    'titulo'?: string;
    /**
     * Nombre
     * @type {string}
     * @memberof Persona
     */
    'nombre'?: string;
    /**
     * Apellido paterno
     * @type {string}
     * @memberof Persona
     */
    'apellidoPaterno'?: string;
    /**
     * Apellido materno
     * @type {string}
     * @memberof Persona
     */
    'apellidoMaterno'?: string;
    /**
     * CURP
     * @type {string}
     * @memberof Persona
     */
    'curp'?: string;
    /**
     * RFC
     * @type {string}
     * @memberof Persona
     */
    'rfc'?: string;
    /**
     * Género
     * @type {string}
     * @memberof Persona
     */
    'genero'?: string;
    /**
     * Correo de contacto
     * @type {string}
     * @memberof Persona
     */
    'correo'?: string;
    /**
     * Nivel académico
     * @type {string}
     * @memberof Persona
     */
    'nivelAcademico'?: string;
    /**
     * Nombre del grado académico
     * @type {string}
     * @memberof Persona
     */
    'gradoAcademico'?: string;
    /**
     * Nacionalidad
     * @type {string}
     * @memberof Persona
     */
    'nacionalidad'?: string;
    /**
     * Código de nacionalidad
     * @type {string}
     * @memberof Persona
     */
    'nacionalidadCodigo'?: string;
    /**
     * País de residencia
     * @type {string}
     * @memberof Persona
     */
    'paisResidencia'?: string;
    /**
     * Código de país de residencia
     * @type {string}
     * @memberof Persona
     */
    'paisResidenciaCodigo'?: string;
    /**
     * Nivel SNI
     * @type {string}
     * @memberof Persona
     */
    'nivelsni'?: string;
    /**
     * Número de RCEA
     * @type {string}
     * @memberof Persona
     */
    'rcea'?: string;
}
/**
 * 
 * @export
 * @interface Problem
 */
export interface Problem {
    /**
     * An absolute URI that identifies the problem type.  When dereferenced, it SHOULD provide human-readable documentation for the problem type (e.g., using HTML). 
     * @type {string}
     * @memberof Problem
     */
    'type'?: string;
    /**
     * A short, summary of the problem type. Written in english and readable for engineers (usually not suited for non technical stakeholders and not localized); example: Service Unavailable 
     * @type {string}
     * @memberof Problem
     */
    'title'?: string;
    /**
     * The HTTP status code generated by the origin server for this occurrence of the problem. 
     * @type {number}
     * @memberof Problem
     */
    'status'?: number;
    /**
     * A human readable explanation specific to this occurrence of the problem. 
     * @type {string}
     * @memberof Problem
     */
    'detail'?: string;
    /**
     * An absolute URI that identifies the specific occurrence of the problem. It may or may not yield further information if dereferenced. 
     * @type {string}
     * @memberof Problem
     */
    'instance'?: string;
}
/**
 * Informacion de un programa
 * @export
 * @interface Programa
 */
export interface Programa {
    /**
     * id de solicitud
     * @type {string}
     * @memberof Programa
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Programa
     */
    'clave'?: string;
    /**
     * 
     * @type {string}
     * @memberof Programa
     */
    'nombre'?: string;
    /**
     * 
     * @type {string}
     * @memberof Programa
     */
    'grado'?: string;
    /**
     * 
     * @type {string}
     * @memberof Programa
     */
    'tipo'?: string;
    /**
     * 
     * @type {string}
     * @memberof Programa
     */
    'cveGrado'?: string;
    /**
     * 
     * @type {string}
     * @memberof Programa
     */
    'periodoLectivo'?: string;
    /**
     * 
     * @type {string}
     * @memberof Programa
     */
    'duracionOficial'?: string;
    /**
     * 
     * @type {string}
     * @memberof Programa
     */
    'totalPeriodosLectivos'?: string;
    /**
     * 
     * @type {string}
     * @memberof Programa
     */
    'fechaTerminoVigencia'?: string;
    /**
     * 
     * @type {string}
     * @memberof Programa
     */
    'caracteristica'?: string;
    /**
     * 
     * @type {string}
     * @memberof Programa
     */
    'orientacion'?: string;
    /**
     * 
     * @type {string}
     * @memberof Programa
     */
    'modalidad'?: string;
    /**
     * Categoría en la que se encuentra el programa
     * @type {string}
     * @memberof Programa
     */
    'categoria'?: string;
    /**
     * Estatus del programa
     * @type {string}
     * @memberof Programa
     */
    'estatus'?: string;
    /**
     * 
     * @type {Array<AreaConocimientoPrograma>}
     * @memberof Programa
     */
    'areasConocimiento'?: Array<AreaConocimientoPrograma>;
    /**
     * Identificador de tipo de convocatoria especial asociado a un programa
     * @type {string}
     * @memberof Programa
     */
    'tipoEspecial'?: string;
}
/**
 * Descripción de un programa del Sistema Nacional de Posgrados (SNP)
 * @export
 * @interface ProgramaSnp
 */
export interface ProgramaSnp {
    /**
     * Clave de sede
     * @type {string}
     * @memberof ProgramaSnp
     */
    'claveSede'?: string;
    /**
     * Clave de dependencia
     * @type {string}
     * @memberof ProgramaSnp
     */
    'claveDependencia'?: string;
    /**
     * Referencia de programa
     * @type {string}
     * @memberof ProgramaSnp
     */
    'referenciaPrograma'?: string;
}
/**
 * Respuesta de cuestionario
 * @export
 * @interface Respuesta
 */
export interface Respuesta {
    /**
     * Valor de la respuesta
     * @type {BaseCatalogo}
     * @memberof Respuesta
     */
    'valor'?: BaseCatalogo;
    /**
     * Indicador para elegir compartir por red rizoma
     * @type {boolean}
     * @memberof Respuesta
     */
    'compartir'?: boolean;
}
/**
 * Rol que se le asigna a una autoridad
 * @export
 * @enum {string}
 */

export const RolAutoridad = {
    SISTEMA: 'SISTEMA',
    PROPIETARIO: 'PROPIETARIO',
    ADMINISTRADOR: 'ADMINISTRADOR',
    SOLICITANTE: 'SOLICITANTE',
    OPERADOR: 'OPERADOR',
    REVISOR: 'REVISOR',
    APROBADOR: 'APROBADOR',
    SOPORTE: 'SOPORTE',
    VALIDADOR_DOCUMENTAL: 'VALIDADOR_DOCUMENTAL',
    DIRECTOR: 'DIRECTOR',
    SUBDIRECTOR: 'SUBDIRECTOR',
    JEFE_DEPARTAMENTO: 'JEFE_DEPARTAMENTO',
    APOYO: 'APOYO',
    COORDINADOR_PROGRAMA: 'COORDINADOR_PROGRAMA',
    BECARIO: 'BECARIO',
    ALUMNO: 'ALUMNO',
    PROFESOR: 'PROFESOR',
    AYUDANTE: 'AYUDANTE',
    AUDITOR: 'AUDITOR'
} as const;

export type RolAutoridad = typeof RolAutoridad[keyof typeof RolAutoridad];


/**
 * Descripción de un rubro de apoyo
 * @export
 * @interface Rubro
 */
export interface Rubro {
    /**
     * 
     * @type {ConceptoRubro}
     * @memberof Rubro
     */
    'concepto'?: ConceptoRubro;
    /**
     * 
     * @type {PeriodoPagoEnum}
     * @memberof Rubro
     */
    'periodo'?: PeriodoPagoEnum;
    /**
     * 
     * @type {Moneda}
     * @memberof Rubro
     */
    'moneda'?: Moneda;
    /**
     * monto total
     * @type {number}
     * @memberof Rubro
     */
    'montoTotal'?: number;
    /**
     * Costo total anual de un rubro cuando el monto real excede al solicitado. Es requerido cuando el rubro es colegiatura
     * @type {number}
     * @memberof Rubro
     */
    'costoTotalAnual'?: number;
    /**
     * Cálculo de montos para años posteriores durante la duración del apoyo de beca
     * @type {Array<MontoPosterior>}
     * @memberof Rubro
     */
    'montosPosteriores'?: Array<MontoPosterior>;
}
/**
 * Contenedor con los niveles del Sistema de Clasificación Industrial de América del Norte-SCIAN
 * @export
 * @interface Scian
 */
export interface Scian {
    /**
     * Sector económico Scian
     * @type {BaseCatalogoExtendido}
     * @memberof Scian
     */
    'sector'?: BaseCatalogoExtendido;
    /**
     * Subsector dependiente de un sector
     * @type {BaseCatalogoExtendido}
     * @memberof Scian
     */
    'subsector'?: BaseCatalogoExtendido;
    /**
     * Rama Scian
     * @type {BaseCatalogoExtendido}
     * @memberof Scian
     */
    'rama'?: BaseCatalogoExtendido;
    /**
     * Subrama dependiente de una Rama
     * @type {BaseCatalogoExtendido}
     * @memberof Scian
     */
    'subrama'?: BaseCatalogoExtendido;
    /**
     * Clase económica Scian
     * @type {BaseCatalogoExtendido}
     * @memberof Scian
     */
    'clase'?: BaseCatalogoExtendido;
}
/**
 * Sector de una institución
 * @export
 * @enum {string}
 */

export const SectorInstitucional = {
    PUBLICA: 'PUBLICA',
    PRIVADA: 'PRIVADA',
    AMBAS: 'AMBAS'
} as const;

export type SectorInstitucional = typeof SectorInstitucional[keyof typeof SectorInstitucional];


/**
 * Sede de la institución
 * @export
 * @interface Sede
 */
export interface Sede {
    /**
     * 
     * @type {string}
     * @memberof Sede
     */
    'claveSede'?: string;
    /**
     * 
     * @type {string}
     * @memberof Sede
     */
    'sede'?: string;
    /**
     * 
     * @type {string}
     * @memberof Sede
     */
    'nombreDependencia'?: string;
}
/**
 * Objeto con la información del solicitante
 * @export
 * @interface Solicitante
 */
export interface Solicitante {
    /**
     * Registro Federal de Contribuyente del solicitante
     * @type {string}
     * @memberof Solicitante
     */
    'rfc'?: string;
    /**
     * Clave Única de Registro de Población del solicitante
     * @type {string}
     * @memberof Solicitante
     */
    'curp'?: string;
    /**
     * Correo del solicitante
     * @type {string}
     * @memberof Solicitante
     */
    'correo'?: string;
    /**
     * cvu/pu del solicitante
     * @type {string}
     * @memberof Solicitante
     */
    'cvu'?: string;
    /**
     * Nombres del solicitante
     * @type {string}
     * @memberof Solicitante
     */
    'nombre'?: string;
    /**
     * Primer apellido del solicitante
     * @type {string}
     * @memberof Solicitante
     */
    'primerApellido'?: string;
    /**
     * Segundo apellido del solicitante
     * @type {string}
     * @memberof Solicitante
     */
    'segundoApellido'?: string;
    /**
     * Edad del solicitante
     * @type {number}
     * @memberof Solicitante
     */
    'edad'?: number;
    /**
     * 
     * @type {BaseCatalogo}
     * @memberof Solicitante
     */
    'sexo'?: BaseCatalogo;
    /**
     * 
     * @type {BaseCatalogoExtendido}
     * @memberof Solicitante
     */
    'entidadNacimiento'?: BaseCatalogoExtendido;
    /**
     * 
     * @type {BaseCatalogo}
     * @memberof Solicitante
     */
    'nacionalidad'?: BaseCatalogo;
    /**
     * Estado civil
     * @type {BaseCatalogo}
     * @memberof Solicitante
     */
    'estadoCivil'?: BaseCatalogo;
    /**
     * Fecha de nacimiento del usuario
     * @type {string}
     * @memberof Solicitante
     */
    'fechaNacimiento'?: string;
    /**
     * Entidad federativa de nacimiento del usuario
     * @type {BaseCatalogoExtendido}
     * @memberof Solicitante
     */
    'lugarNacimiento'?: BaseCatalogoExtendido;
    /**
     * País de nacimiento del usuario
     * @type {BaseCatalogo}
     * @memberof Solicitante
     */
    'paisNacimiento'?: BaseCatalogo;
    /**
     * Medios de contacto
     * @type {Array<Contacto>}
     * @memberof Solicitante
     */
    'mediosContacto'?: Array<Contacto>;
    /**
     * 
     * @type {Domicilio}
     * @memberof Solicitante
     */
    'domicilio'?: Domicilio;
    /**
     * 
     * @type {GradoAcademico}
     * @memberof Solicitante
     */
    'gradoAcademico'?: GradoAcademico;
    /**
     * Idiomas
     * @type {Array<Idioma>}
     * @memberof Solicitante
     */
    'idiomas'?: Array<Idioma>;
}
/**
 * Registro de una solicitud
 * @export
 * @interface Solicitud
 */
export interface Solicitud {
    /**
     * id
     * @type {string}
     * @memberof Solicitud
     */
    'id'?: string;
    /**
     * 
     * @type {BaseCatalogo}
     * @memberof Solicitud
     */
    'nivelAspira'?: BaseCatalogo;
    /**
     * 
     * @type {BaseCatalogo}
     * @memberof Solicitud
     */
    'tipoPerfil'?: BaseCatalogo;
    /**
     * Consideraciones académicas
     * @type {string}
     * @memberof Solicitud
     */
    'consideracionAcademica'?: string;
    /**
     * Participación en el desarrollo institucional o comité de evaluación.
     * @type {string}
     * @memberof Solicitud
     */
    'comiteEvaluacion'?: string;
    /**
     * Contribuciones fundamentales en el fortalecimiento y consolidación de comunidad humanística...
     * @type {string}
     * @memberof Solicitud
     */
    'fortalecimientoComunidad'?: string;
    /**
     * Contribuciones en el acceso universal al conocimiento y beneficios sociales.
     * @type {string}
     * @memberof Solicitud
     */
    'accesoUniversal'?: string;
    /**
     * Contribuciones en el impulso a la investigación de frontera.
     * @type {string}
     * @memberof Solicitud
     */
    'investigacionFrontera'?: string;
    /**
     * 
     * @type {AreaConocimiento}
     * @memberof Solicitud
     */
    'areaConocimiento'?: AreaConocimiento;
    /**
     * 
     * @type {BaseCatalogo}
     * @memberof Solicitud
     */
    'tipoSolicitud'?: BaseCatalogo;
    /**
     * Lista del histórico de estados de la solicitud
     * @type {Array<EstadoSolicitudCompleto>}
     * @memberof Solicitud
     */
    'estadoSolicitud'?: Array<EstadoSolicitudCompleto>;
    /**
     * Número de la solicitud
     * @type {string}
     * @memberof Solicitud
     */
    'numeroSolicitud'?: string;
    /**
     * Fecha de creación de la solicitud
     * @type {string}
     * @memberof Solicitud
     */
    'fechaCreacion'?: string;
    /**
     * 
     * @type {DocumentoProbatorio}
     * @memberof Solicitud
     */
    'documentoSolicitud'?: DocumentoProbatorio;
    /**
     * 
     * @type {DocumentoProbatorio}
     * @memberof Solicitud
     */
    'documentoPu'?: DocumentoProbatorio;
    /**
     * 
     * @type {DocumentoProbatorio}
     * @memberof Solicitud
     */
    'documentoNacionalidad'?: DocumentoProbatorio;
    /**
     * 
     * @type {SolucionSolicitud}
     * @memberof Solicitud
     */
    'solucionSolicitud'?: SolucionSolicitud;
    /**
     * 
     * @type {Solicitante}
     * @memberof Solicitud
     */
    'solicitante'?: Solicitante;
    /**
     * 
     * @type {AdeudoSancion}
     * @memberof Solicitud
     */
    'adeudoSancion'?: AdeudoSancion;
    /**
     * Acreditacion del solicitante, lista de empleos que cumplen para solicitud
     * @type {Array<Empleo>}
     * @memberof Solicitud
     */
    'acreditacion'?: Array<Empleo>;
    /**
     * Formación académica, lista de grados obtenidos válidos en solicitud
     * @type {Array<GradoAcademico>}
     * @memberof Solicitud
     */
    'formacionAcademica'?: Array<GradoAcademico>;
    /**
     * 
     * @type {TotalesProduccionCientifica}
     * @memberof Solicitud
     */
    'totalesProduccionCientifica'?: TotalesProduccionCientifica;
    /**
     * 
     * @type {TotalesProduccionTecnologica}
     * @memberof Solicitud
     */
    'totalesProduccionTecnologica'?: TotalesProduccionTecnologica;
    /**
     * 
     * @type {TotalesAccesoUniversal}
     * @memberof Solicitud
     */
    'totalesAccesoUniversal'?: TotalesAccesoUniversal;
    /**
     * 
     * @type {TotalesFormacionComunidad}
     * @memberof Solicitud
     */
    'totalesFormacionComunidad'?: TotalesFormacionComunidad;
    /**
     * 
     * @type {Citas}
     * @memberof Solicitud
     */
    'citas'?: Citas;
    /**
     * URL con Reporte/Perfil de citas publicadas.
     * @type {string}
     * @memberof Solicitud
     */
    'reporteGlobalCitas'?: string;
    /**
     * 
     * @type {BaseCatalogoLegado}
     * @memberof Solicitud
     */
    'nivelSniActual'?: BaseCatalogoLegado;
    /**
     * 
     * @type {BaseCatalogoLegado}
     * @memberof Solicitud
     */
    'tipoVigenciaActual'?: BaseCatalogoLegado;
    /**
     * Correo de login
     * @type {string}
     * @memberof Solicitud
     */
    'login'?: string;
    /**
     * URL donde el solicitante comparte un enlace externo que contiene los documentos probatorios de su solicitud
     * @type {string}
     * @memberof Solicitud
     */
    'urlDocumentosProbatorios'?: string;
    /**
     * 
     * @type {EstadoSolicitud}
     * @memberof Solicitud
     */
    'estadoActual'?: EstadoSolicitud;
    /**
     * 
     * @type {ParticipacionBrief}
     * @memberof Solicitud
     */
    'programa'?: ParticipacionBrief;
    /**
     * Fecha de última actualización del programa SNP-apeiron en que se consultó
     * @type {string}
     * @memberof Solicitud
     */
    'fechaActualizacionPrograma'?: string;
    /**
     * 
     * @type {PeriodoBeca}
     * @memberof Solicitud
     */
    'periodo'?: PeriodoBeca;
    /**
     * Lista de dependientes económicos
     * @type {Array<DependienteEconomico>}
     * @memberof Solicitud
     */
    'dependientesEconomicos'?: Array<DependienteEconomico>;
    /**
     * Contactos de emergencia
     * @type {Array<ContactoEmergencias>}
     * @memberof Solicitud
     */
    'contactosEmergencia'?: Array<ContactoEmergencias>;
    /**
     * Lista de escolaridad familiar
     * @type {Array<EscolaridadFamiliar>}
     * @memberof Solicitud
     */
    'escolaridadFamiliar'?: Array<EscolaridadFamiliar>;
    /**
     * Id de solicitud apeiron
     * @type {string}
     * @memberof Solicitud
     */
    'idApeiron'?: string;
    /**
     * 
     * @type {EstadoAjusteBeca}
     * @memberof Solicitud
     */
    'estadoAjusteBeca'?: EstadoAjusteBeca;
    /**
     * 
     * @type {TipoBecaExtranjero}
     * @memberof Solicitud
     */
    'tipoBecaExtranjero'?: TipoBecaExtranjero;
    /**
     * 
     * @type {Apoyo}
     * @memberof Solicitud
     */
    'apoyo'?: Apoyo;
    /**
     * Calificación promedio de grado anterior o calificación ENARM (especialidades médicas)
     * @type {string}
     * @memberof Solicitud
     */
    'calificacion'?: string;
    /**
     * Folio ENARM
     * @type {string}
     * @memberof Solicitud
     */
    'folioENARM'?: string;
    /**
     * Rubros de beca
     * @type {Array<Rubro>}
     * @memberof Solicitud
     */
    'rubros'?: Array<Rubro>;
    /**
     * Motivos por los que se solicita una beca
     * @type {Array<Motivo>}
     * @memberof Solicitud
     */
    'motivos'?: Array<Motivo>;
    /**
     * Documentos solicitados por convocatoria
     * @type {Array<Archivo>}
     * @memberof Solicitud
     */
    'documentos'?: Array<Archivo>;
}
/**
 * Contiene el objeto para la cancelación de la solicitud
 * @export
 * @interface SolicitudCancelada
 */
export interface SolicitudCancelada {
    /**
     * id
     * @type {string}
     * @memberof SolicitudCancelada
     */
    'id'?: string;
    /**
     * id de la solicitud a cancelar
     * @type {string}
     * @memberof SolicitudCancelada
     */
    'idSolicitud': string;
    /**
     * Motivo de cancelación
     * @type {string}
     * @memberof SolicitudCancelada
     */
    'comentario': string;
    /**
     * 
     * @type {Archivo}
     * @memberof SolicitudCancelada
     */
    'documentoProbatorio'?: Archivo;
}
/**
 * Información básica de una solución apeiron. Esquema temporal alterno para consumo de apeiron desde msperfil.
 * @export
 * @interface Solucion
 */
export interface Solucion {
    /**
     * 
     * @type {string}
     * @memberof Solucion
     */
    'id'?: string;
    /**
     * Título de la solución
     * @type {string}
     * @memberof Solucion
     */
    'titulo'?: string;
    /**
     * Descripción de una solución
     * @type {string}
     * @memberof Solucion
     */
    'descripcion'?: string;
    /**
     * 
     * @type {EstadoSolucion}
     * @memberof Solucion
     * @deprecated
     */
    'estado'?: EstadoSolucion;
    /**
     * 
     * @type {TipoSolucion}
     * @memberof Solucion
     * @deprecated
     */
    'tipo'?: TipoSolucion;
    /**
     * Tags
     * @type {Array<string>}
     * @memberof Solucion
     */
    'tags'?: Array<string>;
    /**
     * 
     * @type {Calendario}
     * @memberof Solucion
     * @deprecated
     */
    'calendario'?: Calendario;
    /**
     * 
     * @type {Array<DocumentoConfiguracion>}
     * @memberof Solucion
     */
    'documentos'?: Array<DocumentoConfiguracion>;
    /**
     * Parámetros adicionales de la solución
     * @type {object}
     * @memberof Solucion
     */
    'params'?: object;
}
/**
 * Objeto con la información de la solución
 * @export
 * @interface SolucionSolicitud
 */
export interface SolucionSolicitud {
    /**
     * Identificador de la solución
     * @type {string}
     * @memberof SolucionSolicitud
     */
    'id'?: string;
    /**
     * Nombre de la convocatoria proveniente de la solución
     * @type {string}
     * @memberof SolucionSolicitud
     */
    'nombreConvocatoria'?: string;
    /**
     * 
     * @type {TipoSolucion}
     * @memberof SolucionSolicitud
     * @deprecated
     */
    'tipoSolucion'?: TipoSolucion;
}
/**
 * Descripción de una tesis dirigida
 * @export
 * @interface TesisDirigida
 */
export interface TesisDirigida {
    /**
     * Identificador
     * @type {string}
     * @memberof TesisDirigida
     */
    'id'?: string;
    /**
     * Id de referencia del origen MIIC
     * @type {string}
     * @memberof TesisDirigida
     */
    'migracionId'?: string;
    /**
     * 
     * @type {TipoPrograma}
     * @memberof TesisDirigida
     */
    'tipoPrograma'?: TipoPrograma;
    /**
     * Catálogo para especificar el tipo de trabajo de titulación que dirigió o coordinó el usuario
     * @type {BaseCatalogo}
     * @memberof TesisDirigida
     */
    'tipoTrabajoTitulacion': BaseCatalogo;
    /**
     * Clave de institución del Sistema Nacional de Posgrados (SNP). En caso de que sea una institución SNP.
     * @type {string}
     * @memberof TesisDirigida
     */
    'claveInstitucionSnp'?: string;
    /**
     * Programa de una institución del Sistema Nacinoal de Posgrados (SNP). En caso de que sea una institución SNP.
     * @type {ProgramaSnp}
     * @memberof TesisDirigida
     */
    'programaSnp'?: ProgramaSnp;
    /**
     * titulo
     * @type {string}
     * @memberof TesisDirigida
     */
    'titulo': string;
    /**
     * estado
     * @type {BaseCatalogo}
     * @memberof TesisDirigida
     */
    'estado': BaseCatalogo;
    /**
     * Fecha de aprobación de tesis
     * @type {string}
     * @memberof TesisDirigida
     */
    'fechaAprobacion'?: string;
    /**
     * Fecha de obtención de grado
     * @type {string}
     * @memberof TesisDirigida
     */
    'fechaObtencionGrado'?: string;
    /**
     * Nombre de la persona autora
     * @type {string}
     * @memberof TesisDirigida
     */
    'nombre': string;
    /**
     * Primer apellido de la persona autora
     * @type {string}
     * @memberof TesisDirigida
     */
    'primerApellido': string;
    /**
     * Segundo apellido de la persona autora
     * @type {string}
     * @memberof TesisDirigida
     */
    'segundoApellido'?: string;
    /**
     * Indicador de si es un producto principal
     * @type {boolean}
     * @memberof TesisDirigida
     */
    'productoPrincipal'?: boolean;
    /**
     * Grado académico obtenido
     * @type {BaseCatalogo}
     * @memberof TesisDirigida
     */
    'gradoAcademico': BaseCatalogo;
    /**
     * Rol de participación
     * @type {BaseCatalogo}
     * @memberof TesisDirigida
     */
    'rol': BaseCatalogo;
    /**
     * Institucion donde se desarrollo la tesis. En caso de que sea una institución no SNP.
     * @type {Institucion}
     * @memberof TesisDirigida
     */
    'institucion'?: Institucion;
}
/**
 * Tipo de artículo
 * @export
 * @enum {string}
 */

export const TipoArticulo = {
    DIFUSION: 'DIFUSION',
    DIVULGACION: 'DIVULGACION',
    REVISTA_INDEXADA: 'REVISTA_INDEXADA'
} as const;

export type TipoArticulo = typeof TipoArticulo[keyof typeof TipoArticulo];


/**
 * Tipo de Aviso
 * @export
 * @enum {string}
 */

export const TipoAviso = {
    AVISO_PRIVACIDAD_INTEGRAL: 'AVISO_PRIVACIDAD_INTEGRAL',
    AVISO_PRIVACIDAD_SIMPLIFICADO: 'AVISO_PRIVACIDAD_SIMPLIFICADO'
} as const;

export type TipoAviso = typeof TipoAviso[keyof typeof TipoAviso];


/**
 * Definición de tipo de solicitud para una convocatoria de beca extranjero
 * @export
 * @enum {string}
 */

export const TipoBecaExtranjero = {
    ESPECIALIDADES_MEDICAS_CUBA: 'ESPECIALIDADES_MEDICAS_CUBA',
    CIENCIAS_HUMANIDADES: 'CIENCIAS_HUMANIDADES',
    ESPECIALIDADES_MEDICAS: 'ESPECIALIDADES_MEDICAS',
    CREACION_FUTURO: 'CREACION_FUTURO'
} as const;

export type TipoBecaExtranjero = typeof TipoBecaExtranjero[keyof typeof TipoBecaExtranjero];


/**
 * Tipo de domicilio
 * @export
 * @enum {string}
 */

export const TipoDomicilio = {
    RURAL: 'RURAL',
    URBANO: 'URBANO'
} as const;

export type TipoDomicilio = typeof TipoDomicilio[keyof typeof TipoDomicilio];


/**
 * Tipo de entidad pública
 * @export
 * @enum {string}
 */

export const TipoEntidad = {
    CONACYT: 'CONACYT',
    OTROS: 'OTROS'
} as const;

export type TipoEntidad = typeof TipoEntidad[keyof typeof TipoEntidad];


/**
 * Tipo de formación contínua
 * @export
 * @enum {string}
 */

export const TipoFormacionContinua = {
    CURSO: 'CURSO',
    CERTIFICACION_MEDICA: 'CERTIFICACION_MEDICA',
    RECERTIFICACION_MEDICA: 'RECERTIFICACION_MEDICA'
} as const;

export type TipoFormacionContinua = typeof TipoFormacionContinua[keyof typeof TipoFormacionContinua];


/**
 * Tipo de innovación
 * @export
 * @enum {string}
 */

export const TipoInnovacion = {
    DISRUPTIVA: 'DISRUPTIVA',
    INCREMENTAL: 'INCREMENTAL'
} as const;

export type TipoInnovacion = typeof TipoInnovacion[keyof typeof TipoInnovacion];


/**
 * Tipo de material
 * @export
 * @enum {string}
 */

export const TipoMaterial = {
    DIDACTICO: 'DIDACTICO',
    APOYO: 'APOYO'
} as const;

export type TipoMaterial = typeof TipoMaterial[keyof typeof TipoMaterial];


/**
 * Tipo de programa
 * @export
 * @enum {string}
 */

export const TipoPrograma = {
    SNP: 'SNP',
    NO_SNP: 'NO_SNP'
} as const;

export type TipoPrograma = typeof TipoPrograma[keyof typeof TipoPrograma];


/**
 * Tipo de solicitud
 * @export
 * @enum {string}
 */

export const TipoSolicitud = {
    INGRESO: 'INGRESO',
    PERMANENCIA: 'PERMANENCIA'
} as const;

export type TipoSolicitud = typeof TipoSolicitud[keyof typeof TipoSolicitud];


/**
 * Tipo de solución. Esquema temporal alterno para consumo de apeiron desde msperfil.
 * @export
 * @enum {string}
 */

export const TipoSolucion = {
    AYUDANTES: 'AYUDANTES',
    INGRESO_PERMANENCIA_PROMOCION: 'INGRESO_PERMANENCIA_PROMOCION',
    EMERITOS: 'EMERITOS',
    BECAS_NACIONALES: 'BECAS_NACIONALES',
    BECAS_EXTRANJERAS: 'BECAS_EXTRANJERAS',
    POSGRADO_NACIONAL: 'POSGRADO_NACIONAL',
    POSDOCTORADO: 'POSDOCTORADO',
    CUENTAS_BANCARIAS: 'CUENTAS_BANCARIAS',
    ACREDITACIONES: 'ACREDITACIONES',
    ACREDITACIONES_SIN_INST: 'ACREDITACIONES_SIN_INST',
    REGISTRO_MATRICULA_SNP: 'REGISTRO_MATRICULA_SNP',
    MOVIMIENTOS_SITUACION: 'MOVIMIENTOS_SITUACION',
    INSTITUCIONES: 'INSTITUCIONES',
    ACREDITACIONES_PROGRAMAS: 'ACREDITACIONES_PROGRAMAS'
} as const;

export type TipoSolucion = typeof TipoSolucion[keyof typeof TipoSolucion];


/**
 * Tipo de ubicación de domicilio
 * @export
 * @enum {string}
 */

export const TipoUbicacion = {
    NACIONAL: 'NACIONAL',
    EXTRANJERA: 'EXTRANJERA'
} as const;

export type TipoUbicacion = typeof TipoUbicacion[keyof typeof TipoUbicacion];


/**
 * Tipo de validacion
 * @export
 * @enum {string}
 */

export const TipoValidacion = {
    DATOS_GENERALES: 'DATOS_GENERALES',
    DATOS_DOMICILIO: 'DATOS_DOMICILIO',
    DATOS_IDIOMAS: 'DATOS_IDIOMAS',
    NIVEL_ACTUAL: 'NIVEL_ACTUAL',
    VIGENCIA_ACTIVA: 'VIGENCIA_ACTIVA',
    EDAD_REQUERIDA: 'EDAD_REQUERIDA',
    GRADOS_ACADEMICOS: 'GRADOS_ACADEMICOS',
    APOYO_VIGENTE: 'APOYO_VIGENTE',
    MISMO_APOYO: 'MISMO_APOYO',
    MODALIDAD_PROGRAMA: 'MODALIDAD_PROGRAMA',
    GRADO_NO_PERMITIDO: 'GRADO_NO_PERMITIDO',
    GRADO_ANTERIOR: 'GRADO_ANTERIOR',
    SOLICITUD: 'SOLICITUD'
} as const;

export type TipoValidacion = typeof TipoValidacion[keyof typeof TipoValidacion];


/**
 * Objeto que es utilizado por todos los totales de los productos y productos principales
 * @export
 * @interface TotalProductos
 */
export interface TotalProductos {
    /**
     * Total de productos tesis
     * @type {number}
     * @memberof TotalProductos
     */
    'totalTesis'?: number;
    /**
     * Total de desarrollo institucional
     * @type {number}
     * @memberof TotalProductos
     */
    'totalDesarrolloInstitucional'?: number;
    /**
     * Total de productos principales de tesis
     * @type {number}
     * @memberof TotalProductos
     */
    'totalTesisPrincipal'?: number;
    /**
     * Total de productos divulgacion
     * @type {number}
     * @memberof TotalProductos
     */
    'totalDivulgacion'?: number;
    /**
     * Total de productos congresos
     * @type {number}
     * @memberof TotalProductos
     */
    'totalCongresos'?: number;
    /**
     * Total de productos docencia
     * @type {number}
     * @memberof TotalProductos
     */
    'totalDocencia'?: number;
    /**
     * Total de productos principales de docencia
     * @type {number}
     * @memberof TotalProductos
     */
    'totalDocenciaPrincipal'?: number;
    /**
     * Total de productos diplomados
     * @type {number}
     * @memberof TotalProductos
     */
    'totalDiplomados'?: number;
    /**
     * Total de productos principales de diplomados
     * @type {number}
     * @memberof TotalProductos
     */
    'totalDiplomadosPrincipal'?: number;
    /**
     * Total de productos principales de congresos
     * @type {number}
     * @memberof TotalProductos
     */
    'totalCongresosPrincipal'?: number;
    /**
     * Total de productos principales de divulgacion
     * @type {number}
     * @memberof TotalProductos
     */
    'totalDivulgacionPrincipal'?: number;
}
/**
 * Objeto con los totales de la producción de acceso universal para la solicitud
 * @export
 * @interface TotalesAccesoUniversal
 */
export interface TotalesAccesoUniversal {
    /**
     * 
     * @type {TotalesProductoYPrincipal}
     * @memberof TotalesAccesoUniversal
     */
    'articulosDifusion'?: TotalesProductoYPrincipal;
    /**
     * 
     * @type {TotalesProductoYPrincipal}
     * @memberof TotalesAccesoUniversal
     */
    'capitulosDifusion'?: TotalesProductoYPrincipal;
    /**
     * 
     * @type {TotalesProductoYPrincipal}
     * @memberof TotalesAccesoUniversal
     */
    'librosDifusion'?: TotalesProductoYPrincipal;
    /**
     * 
     * @type {TotalesProductoYPrincipal}
     * @memberof TotalesAccesoUniversal
     */
    'divulgacion'?: TotalesProductoYPrincipal;
    /**
     * 
     * @type {TotalesProductoYPrincipal}
     * @memberof TotalesAccesoUniversal
     */
    'participacionCongresos'?: TotalesProductoYPrincipal;
    /**
     * 
     * @type {TotalesProductoYPrincipal}
     * @memberof TotalesAccesoUniversal
     */
    'total'?: TotalesProductoYPrincipal;
}
/**
 * Objeto genérico utilizado para los totales de citas en la solicitud
 * @export
 * @interface TotalesCitas
 */
export interface TotalesCitas {
    /**
     * Número correspondiente a la cantidad de registros
     * @type {number}
     * @memberof TotalesCitas
     */
    'cantidad'?: number;
    /**
     * Número total de citas tipo A
     * @type {number}
     * @memberof TotalesCitas
     */
    'citasA'?: number;
    /**
     * Número total de citas tipo B
     * @type {number}
     * @memberof TotalesCitas
     */
    'citasB'?: number;
    /**
     * Número total de citas
     * @type {number}
     * @memberof TotalesCitas
     */
    'totales'?: number;
}
/**
 * Objeto con los totales de la formación de comunidad científica
 * @export
 * @interface TotalesFormacionComunidad
 */
export interface TotalesFormacionComunidad {
    /**
     * 
     * @type {TotalesProductoYPrincipal}
     * @memberof TotalesFormacionComunidad
     */
    'trabajosTitulacion'?: TotalesProductoYPrincipal;
    /**
     * 
     * @type {TotalesProductoYPrincipal}
     * @memberof TotalesFormacionComunidad
     */
    'docencia'?: TotalesProductoYPrincipal;
    /**
     * 
     * @type {TotalesProductoYPrincipal}
     * @memberof TotalesFormacionComunidad
     */
    'desarrolloInstitucional'?: TotalesProductoYPrincipal;
    /**
     * 
     * @type {TotalesProductoYPrincipal}
     * @memberof TotalesFormacionComunidad
     */
    'total'?: TotalesProductoYPrincipal;
}
/**
 * Objeto con los totales de la producción científica para la solicitud
 * @export
 * @interface TotalesProduccionCientifica
 */
export interface TotalesProduccionCientifica {
    /**
     * 
     * @type {TotalesProductoYPrincipal}
     * @memberof TotalesProduccionCientifica
     */
    'articulosCientifica'?: TotalesProductoYPrincipal;
    /**
     * 
     * @type {TotalesProductoYPrincipal}
     * @memberof TotalesProduccionCientifica
     */
    'capitulosCientifica'?: TotalesProductoYPrincipal;
    /**
     * 
     * @type {TotalesProductoYPrincipal}
     * @memberof TotalesProduccionCientifica
     */
    'librosCientifica'?: TotalesProductoYPrincipal;
    /**
     * 
     * @type {TotalesProductoYPrincipal}
     * @memberof TotalesProduccionCientifica
     */
    'total'?: TotalesProductoYPrincipal;
}
/**
 * Objeto con los totales de la producción tecnológica para la solicitud
 * @export
 * @interface TotalesProduccionTecnologica
 */
export interface TotalesProduccionTecnologica {
    /**
     * 
     * @type {TotalesProductoYPrincipal}
     * @memberof TotalesProduccionTecnologica
     */
    'desarrolloTecnologicoInnovacion'?: TotalesProductoYPrincipal;
    /**
     * 
     * @type {TotalesProductoYPrincipal}
     * @memberof TotalesProduccionTecnologica
     */
    'propiedadIntelectual'?: TotalesProductoYPrincipal;
    /**
     * 
     * @type {TotalesProductoYPrincipal}
     * @memberof TotalesProduccionTecnologica
     */
    'transferenciaTecnologica'?: TotalesProductoYPrincipal;
    /**
     * 
     * @type {TotalesProductoYPrincipal}
     * @memberof TotalesProduccionTecnologica
     */
    'total'?: TotalesProductoYPrincipal;
}
/**
 * Objeto genérico utilizado para las propiedades de totales de producto y totales de principal para la solicitud
 * @export
 * @interface TotalesProductoYPrincipal
 */
export interface TotalesProductoYPrincipal {
    /**
     * Número total de productos
     * @type {number}
     * @memberof TotalesProductoYPrincipal
     */
    'totalProducto'?: number;
    /**
     * Número total de productos principales
     * @type {number}
     * @memberof TotalesProductoYPrincipal
     */
    'totalProductoPrincipal'?: number;
}
/**
 * Descripción de un trabajo de divulgación
 * @export
 * @interface TrabajoDivulgacion
 */
export interface TrabajoDivulgacion {
    /**
     * Identificador
     * @type {string}
     * @memberof TrabajoDivulgacion
     */
    'id'?: string;
    /**
     * Id de referencia del origen MIIC
     * @type {string}
     * @memberof TrabajoDivulgacion
     */
    'migracionId'?: string;
    /**
     * Título del trabajo
     * @type {string}
     * @memberof TrabajoDivulgacion
     */
    'tituloTrabajo': string;
    /**
     * Tipo de participación
     * @type {BaseCatalogo}
     * @memberof TrabajoDivulgacion
     */
    'tipoParticipacion': BaseCatalogo;
    /**
     * Tipo de evento
     * @type {BaseCatalogo}
     * @memberof TrabajoDivulgacion
     */
    'tipoEvento': BaseCatalogo;
    /**
     * Nombre de institución
     * @type {string}
     * @memberof TrabajoDivulgacion
     */
    'nombreInstitucion': string;
    /**
     * Público a quien va dirigido
     * @type {BaseCatalogo}
     * @memberof TrabajoDivulgacion
     */
    'dirigidoA': BaseCatalogo;
    /**
     * Fecha del suceso
     * @type {string}
     * @memberof TrabajoDivulgacion
     */
    'fecha': string;
    /**
     * Tipo de divulgación
     * @type {BaseCatalogo}
     * @memberof TrabajoDivulgacion
     */
    'tipoDivulgacion': BaseCatalogo;
    /**
     * Tipo de medio de divulgación
     * @type {BaseCatalogo}
     * @memberof TrabajoDivulgacion
     */
    'tipoMedio': BaseCatalogo;
    /**
     * Notas periodísticas
     * @type {string}
     * @memberof TrabajoDivulgacion
     */
    'notasPeriodisticas'?: string;
    /**
     * Indicador de si es un producto principal
     * @type {boolean}
     * @memberof TrabajoDivulgacion
     */
    'productoPrincipal'?: boolean;
    /**
     * Productos obtenidos
     * @type {Array<BaseCatalogo>}
     * @memberof TrabajoDivulgacion
     */
    'productoObtenido': Array<BaseCatalogo>;
}

/**
 * AvisosApi - axios parameter creator
 * @export
 */
export const AvisosApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtiene la información del último aviso o carta de acuerdo al tipo indicado
         * @param {TipoAviso} tipo Tipo de aviso
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUltimoAviso: async (tipo: TipoAviso, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tipo' is not null or undefined
            assertParamExists('getUltimoAviso', 'tipo', tipo)
            const localVarPath = `/avisos/ultimo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (tipo !== undefined) {
                localVarQueryParameter['tipo'] = tipo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Almacena información de un aviso o carta
         * @param {Aviso} aviso 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAviso: async (aviso: Aviso, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aviso' is not null or undefined
            assertParamExists('saveAviso', 'aviso', aviso)
            const localVarPath = `/avisos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aviso, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AvisosApi - functional programming interface
 * @export
 */
export const AvisosApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AvisosApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtiene la información del último aviso o carta de acuerdo al tipo indicado
         * @param {TipoAviso} tipo Tipo de aviso
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUltimoAviso(tipo: TipoAviso, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Aviso>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUltimoAviso(tipo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Almacena información de un aviso o carta
         * @param {Aviso} aviso 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveAviso(aviso: Aviso, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveAviso(aviso, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AvisosApi - factory interface
 * @export
 */
export const AvisosApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AvisosApiFp(configuration)
    return {
        /**
         * Obtiene la información del último aviso o carta de acuerdo al tipo indicado
         * @param {TipoAviso} tipo Tipo de aviso
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUltimoAviso(tipo: TipoAviso, options?: any): AxiosPromise<Aviso> {
            return localVarFp.getUltimoAviso(tipo, options).then((request) => request(axios, basePath));
        },
        /**
         * Almacena información de un aviso o carta
         * @param {Aviso} aviso 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAviso(aviso: Aviso, options?: any): AxiosPromise<void> {
            return localVarFp.saveAviso(aviso, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AvisosApi - object-oriented interface
 * @export
 * @class AvisosApi
 * @extends {BaseAPI}
 */
export class AvisosApi extends BaseAPI {
    /**
     * Obtiene la información del último aviso o carta de acuerdo al tipo indicado
     * @param {TipoAviso} tipo Tipo de aviso
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AvisosApi
     */
    public getUltimoAviso(tipo: TipoAviso, options?: AxiosRequestConfig) {
        return AvisosApiFp(this.configuration).getUltimoAviso(tipo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Almacena información de un aviso o carta
     * @param {Aviso} aviso 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AvisosApi
     */
    public saveAviso(aviso: Aviso, options?: AxiosRequestConfig) {
        return AvisosApiFp(this.configuration).saveAviso(aviso, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BecasApi - axios parameter creator
 * @export
 */
export const BecasApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Envío de solicitud becas
         * @param {string} id id de solicitud
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enviarSolicitudBecas: async (id: string, solicitud: Solicitud, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('enviarSolicitudBecas', 'id', id)
            // verify required parameter 'solicitud' is not null or undefined
            assertParamExists('enviarSolicitudBecas', 'solicitud', solicitud)
            const localVarPath = `/solicitudes-becas/nacionales/{id}/enviar`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(solicitud, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Descarga carta ISSSTE de un becario cuando tiene una solicitud de becas nacionales y se encuentra ASIGNADA o FORMALIZADA
         * @param {string} id id de la solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentoExportCartaISSSTE: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDocumentoExportCartaISSSTE', 'id', id)
            const localVarPath = `/solicitudes-becas/nacionales/{id}/carta-issste`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Exportar pdf de solicitud de becas
         * @param {string} id id de la solicitud de becas
         * @param {string} urlPu Url del documento de PU codificada en base 64
         * @param {string} tipo tipo documento de la solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReporteSolicitudBecas: async (id: string, urlPu: string, tipo: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getReporteSolicitudBecas', 'id', id)
            // verify required parameter 'urlPu' is not null or undefined
            assertParamExists('getReporteSolicitudBecas', 'urlPu', urlPu)
            // verify required parameter 'tipo' is not null or undefined
            assertParamExists('getReporteSolicitudBecas', 'tipo', tipo)
            const localVarPath = `/solicitudes-becas/nacionales/{id}/pdf`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (urlPu !== undefined) {
                localVarQueryParameter['urlPu'] = urlPu;
            }

            if (tipo !== undefined) {
                localVarQueryParameter['tipo'] = tipo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Almacena información de una solicitud de becas
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSolicitudBecas: async (solicitud: Solicitud, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'solicitud' is not null or undefined
            assertParamExists('saveSolicitudBecas', 'solicitud', solicitud)
            const localVarPath = `/solicitudes-becas/nacionales`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(solicitud, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actualiza la participación de programa SNP de una solicitud obtenida de aperion/snp
         * @param {string} id id de la solicitud
         * @param {string} fechaActualizacionPrograma Fecha de última actualización del programa SNP-apeiron en que se consultó (dato generado al consultar programa)
         * @param {ParticipacionBrief} participacionBrief 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateParticipacionPrograma: async (id: string, fechaActualizacionPrograma: string, participacionBrief: ParticipacionBrief, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateParticipacionPrograma', 'id', id)
            // verify required parameter 'fechaActualizacionPrograma' is not null or undefined
            assertParamExists('updateParticipacionPrograma', 'fechaActualizacionPrograma', fechaActualizacionPrograma)
            // verify required parameter 'participacionBrief' is not null or undefined
            assertParamExists('updateParticipacionPrograma', 'participacionBrief', participacionBrief)
            const localVarPath = `/solicitudes-becas/nacionales/{id}/programa`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (fechaActualizacionPrograma !== undefined) {
                localVarQueryParameter['fechaActualizacionPrograma'] = (fechaActualizacionPrograma as any instanceof Date) ?
                    (fechaActualizacionPrograma as any).toISOString() :
                    fechaActualizacionPrograma;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(participacionBrief, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actualiza la solicitud de becas
         * @param {string} id id de la solicitud
         * @param {'PARCIAL' | 'FINAL'} tipoActualizacion tipo de actualizacion, si es PARCIAL sólo se actualiza en perfil, si es FINAL se envía actualizacion a aperion y ejecutan reglas finales
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSolicitudBecas: async (id: string, tipoActualizacion: 'PARCIAL' | 'FINAL', solicitud: Solicitud, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSolicitudBecas', 'id', id)
            // verify required parameter 'tipoActualizacion' is not null or undefined
            assertParamExists('updateSolicitudBecas', 'tipoActualizacion', tipoActualizacion)
            // verify required parameter 'solicitud' is not null or undefined
            assertParamExists('updateSolicitudBecas', 'solicitud', solicitud)
            const localVarPath = `/solicitudes-becas/nacionales/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (tipoActualizacion !== undefined) {
                localVarQueryParameter['tipoActualizacion'] = tipoActualizacion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(solicitud, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Verifica que un usuario pueda participar en la convocatoria de acuerdo a los requisitos mínimos. En caso de no existir errores regresa una lista vacia.
         * @param {string} claveGrado Clave de grado del programa a aplicar
         * @param {string} caracteristicaPrograma Caracteristica del programa a aplicar
         * @param {string} tipoPrograma Tipo del programa a aplicar
         * @param {string} modalidadPrograma Modalidad del programa a aplicar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validarRequisitosBecas: async (claveGrado: string, caracteristicaPrograma: string, tipoPrograma: string, modalidadPrograma: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'claveGrado' is not null or undefined
            assertParamExists('validarRequisitosBecas', 'claveGrado', claveGrado)
            // verify required parameter 'caracteristicaPrograma' is not null or undefined
            assertParamExists('validarRequisitosBecas', 'caracteristicaPrograma', caracteristicaPrograma)
            // verify required parameter 'tipoPrograma' is not null or undefined
            assertParamExists('validarRequisitosBecas', 'tipoPrograma', tipoPrograma)
            // verify required parameter 'modalidadPrograma' is not null or undefined
            assertParamExists('validarRequisitosBecas', 'modalidadPrograma', modalidadPrograma)
            const localVarPath = `/solicitudes-becas/nacionales/valida-requisitos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (claveGrado !== undefined) {
                localVarQueryParameter['claveGrado'] = claveGrado;
            }

            if (caracteristicaPrograma !== undefined) {
                localVarQueryParameter['caracteristicaPrograma'] = caracteristicaPrograma;
            }

            if (tipoPrograma !== undefined) {
                localVarQueryParameter['tipoPrograma'] = tipoPrograma;
            }

            if (modalidadPrograma !== undefined) {
                localVarQueryParameter['modalidadPrograma'] = modalidadPrograma;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BecasApi - functional programming interface
 * @export
 */
export const BecasApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BecasApiAxiosParamCreator(configuration)
    return {
        /**
         * Envío de solicitud becas
         * @param {string} id id de solicitud
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enviarSolicitudBecas(id: string, solicitud: Solicitud, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enviarSolicitudBecas(id, solicitud, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Descarga carta ISSSTE de un becario cuando tiene una solicitud de becas nacionales y se encuentra ASIGNADA o FORMALIZADA
         * @param {string} id id de la solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentoExportCartaISSSTE(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentoExport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentoExportCartaISSSTE(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Exportar pdf de solicitud de becas
         * @param {string} id id de la solicitud de becas
         * @param {string} urlPu Url del documento de PU codificada en base 64
         * @param {string} tipo tipo documento de la solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReporteSolicitudBecas(id: string, urlPu: string, tipo: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentoExport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReporteSolicitudBecas(id, urlPu, tipo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Almacena información de una solicitud de becas
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveSolicitudBecas(solicitud: Solicitud, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveSolicitudBecas(solicitud, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Actualiza la participación de programa SNP de una solicitud obtenida de aperion/snp
         * @param {string} id id de la solicitud
         * @param {string} fechaActualizacionPrograma Fecha de última actualización del programa SNP-apeiron en que se consultó (dato generado al consultar programa)
         * @param {ParticipacionBrief} participacionBrief 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateParticipacionPrograma(id: string, fechaActualizacionPrograma: string, participacionBrief: ParticipacionBrief, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateParticipacionPrograma(id, fechaActualizacionPrograma, participacionBrief, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Actualiza la solicitud de becas
         * @param {string} id id de la solicitud
         * @param {'PARCIAL' | 'FINAL'} tipoActualizacion tipo de actualizacion, si es PARCIAL sólo se actualiza en perfil, si es FINAL se envía actualizacion a aperion y ejecutan reglas finales
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSolicitudBecas(id: string, tipoActualizacion: 'PARCIAL' | 'FINAL', solicitud: Solicitud, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSolicitudBecas(id, tipoActualizacion, solicitud, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Verifica que un usuario pueda participar en la convocatoria de acuerdo a los requisitos mínimos. En caso de no existir errores regresa una lista vacia.
         * @param {string} claveGrado Clave de grado del programa a aplicar
         * @param {string} caracteristicaPrograma Caracteristica del programa a aplicar
         * @param {string} tipoPrograma Tipo del programa a aplicar
         * @param {string} modalidadPrograma Modalidad del programa a aplicar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validarRequisitosBecas(claveGrado: string, caracteristicaPrograma: string, tipoPrograma: string, modalidadPrograma: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ErrorValidacion>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validarRequisitosBecas(claveGrado, caracteristicaPrograma, tipoPrograma, modalidadPrograma, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BecasApi - factory interface
 * @export
 */
export const BecasApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BecasApiFp(configuration)
    return {
        /**
         * Envío de solicitud becas
         * @param {string} id id de solicitud
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enviarSolicitudBecas(id: string, solicitud: Solicitud, options?: any): AxiosPromise<void> {
            return localVarFp.enviarSolicitudBecas(id, solicitud, options).then((request) => request(axios, basePath));
        },
        /**
         * Descarga carta ISSSTE de un becario cuando tiene una solicitud de becas nacionales y se encuentra ASIGNADA o FORMALIZADA
         * @param {string} id id de la solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentoExportCartaISSSTE(id: string, options?: any): AxiosPromise<DocumentoExport> {
            return localVarFp.getDocumentoExportCartaISSSTE(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Exportar pdf de solicitud de becas
         * @param {string} id id de la solicitud de becas
         * @param {string} urlPu Url del documento de PU codificada en base 64
         * @param {string} tipo tipo documento de la solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReporteSolicitudBecas(id: string, urlPu: string, tipo: string, options?: any): AxiosPromise<DocumentoExport> {
            return localVarFp.getReporteSolicitudBecas(id, urlPu, tipo, options).then((request) => request(axios, basePath));
        },
        /**
         * Almacena información de una solicitud de becas
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSolicitudBecas(solicitud: Solicitud, options?: any): AxiosPromise<void> {
            return localVarFp.saveSolicitudBecas(solicitud, options).then((request) => request(axios, basePath));
        },
        /**
         * Actualiza la participación de programa SNP de una solicitud obtenida de aperion/snp
         * @param {string} id id de la solicitud
         * @param {string} fechaActualizacionPrograma Fecha de última actualización del programa SNP-apeiron en que se consultó (dato generado al consultar programa)
         * @param {ParticipacionBrief} participacionBrief 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateParticipacionPrograma(id: string, fechaActualizacionPrograma: string, participacionBrief: ParticipacionBrief, options?: any): AxiosPromise<void> {
            return localVarFp.updateParticipacionPrograma(id, fechaActualizacionPrograma, participacionBrief, options).then((request) => request(axios, basePath));
        },
        /**
         * Actualiza la solicitud de becas
         * @param {string} id id de la solicitud
         * @param {'PARCIAL' | 'FINAL'} tipoActualizacion tipo de actualizacion, si es PARCIAL sólo se actualiza en perfil, si es FINAL se envía actualizacion a aperion y ejecutan reglas finales
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSolicitudBecas(id: string, tipoActualizacion: 'PARCIAL' | 'FINAL', solicitud: Solicitud, options?: any): AxiosPromise<void> {
            return localVarFp.updateSolicitudBecas(id, tipoActualizacion, solicitud, options).then((request) => request(axios, basePath));
        },
        /**
         * Verifica que un usuario pueda participar en la convocatoria de acuerdo a los requisitos mínimos. En caso de no existir errores regresa una lista vacia.
         * @param {string} claveGrado Clave de grado del programa a aplicar
         * @param {string} caracteristicaPrograma Caracteristica del programa a aplicar
         * @param {string} tipoPrograma Tipo del programa a aplicar
         * @param {string} modalidadPrograma Modalidad del programa a aplicar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validarRequisitosBecas(claveGrado: string, caracteristicaPrograma: string, tipoPrograma: string, modalidadPrograma: string, options?: any): AxiosPromise<Array<ErrorValidacion>> {
            return localVarFp.validarRequisitosBecas(claveGrado, caracteristicaPrograma, tipoPrograma, modalidadPrograma, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BecasApi - object-oriented interface
 * @export
 * @class BecasApi
 * @extends {BaseAPI}
 */
export class BecasApi extends BaseAPI {
    /**
     * Envío de solicitud becas
     * @param {string} id id de solicitud
     * @param {Solicitud} solicitud 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BecasApi
     */
    public enviarSolicitudBecas(id: string, solicitud: Solicitud, options?: AxiosRequestConfig) {
        return BecasApiFp(this.configuration).enviarSolicitudBecas(id, solicitud, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Descarga carta ISSSTE de un becario cuando tiene una solicitud de becas nacionales y se encuentra ASIGNADA o FORMALIZADA
     * @param {string} id id de la solicitud
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BecasApi
     */
    public getDocumentoExportCartaISSSTE(id: string, options?: AxiosRequestConfig) {
        return BecasApiFp(this.configuration).getDocumentoExportCartaISSSTE(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Exportar pdf de solicitud de becas
     * @param {string} id id de la solicitud de becas
     * @param {string} urlPu Url del documento de PU codificada en base 64
     * @param {string} tipo tipo documento de la solicitud
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BecasApi
     */
    public getReporteSolicitudBecas(id: string, urlPu: string, tipo: string, options?: AxiosRequestConfig) {
        return BecasApiFp(this.configuration).getReporteSolicitudBecas(id, urlPu, tipo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Almacena información de una solicitud de becas
     * @param {Solicitud} solicitud 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BecasApi
     */
    public saveSolicitudBecas(solicitud: Solicitud, options?: AxiosRequestConfig) {
        return BecasApiFp(this.configuration).saveSolicitudBecas(solicitud, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actualiza la participación de programa SNP de una solicitud obtenida de aperion/snp
     * @param {string} id id de la solicitud
     * @param {string} fechaActualizacionPrograma Fecha de última actualización del programa SNP-apeiron en que se consultó (dato generado al consultar programa)
     * @param {ParticipacionBrief} participacionBrief 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BecasApi
     */
    public updateParticipacionPrograma(id: string, fechaActualizacionPrograma: string, participacionBrief: ParticipacionBrief, options?: AxiosRequestConfig) {
        return BecasApiFp(this.configuration).updateParticipacionPrograma(id, fechaActualizacionPrograma, participacionBrief, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actualiza la solicitud de becas
     * @param {string} id id de la solicitud
     * @param {'PARCIAL' | 'FINAL'} tipoActualizacion tipo de actualizacion, si es PARCIAL sólo se actualiza en perfil, si es FINAL se envía actualizacion a aperion y ejecutan reglas finales
     * @param {Solicitud} solicitud 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BecasApi
     */
    public updateSolicitudBecas(id: string, tipoActualizacion: 'PARCIAL' | 'FINAL', solicitud: Solicitud, options?: AxiosRequestConfig) {
        return BecasApiFp(this.configuration).updateSolicitudBecas(id, tipoActualizacion, solicitud, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Verifica que un usuario pueda participar en la convocatoria de acuerdo a los requisitos mínimos. En caso de no existir errores regresa una lista vacia.
     * @param {string} claveGrado Clave de grado del programa a aplicar
     * @param {string} caracteristicaPrograma Caracteristica del programa a aplicar
     * @param {string} tipoPrograma Tipo del programa a aplicar
     * @param {string} modalidadPrograma Modalidad del programa a aplicar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BecasApi
     */
    public validarRequisitosBecas(claveGrado: string, caracteristicaPrograma: string, tipoPrograma: string, modalidadPrograma: string, options?: AxiosRequestConfig) {
        return BecasApiFp(this.configuration).validarRequisitosBecas(claveGrado, caracteristicaPrograma, tipoPrograma, modalidadPrograma, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BecasExtranjeroApi - axios parameter creator
 * @export
 */
export const BecasExtranjeroApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Exportar pdf de solicitud de becas extranjero
         * @param {TipoBecaExtranjero} tipoBecaExtranjero Tipo de Beca Extranjera
         * @param {string} id id de la solicitud de becas
         * @param {boolean} esEnviada Bandera para saber si una solicitud esta enviada para la marca de agua
         * @param {string} [password] Contraseña para firma de solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReporteSolicitudBecasExtranjero: async (tipoBecaExtranjero: TipoBecaExtranjero, id: string, esEnviada: boolean, password?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tipoBecaExtranjero' is not null or undefined
            assertParamExists('getReporteSolicitudBecasExtranjero', 'tipoBecaExtranjero', tipoBecaExtranjero)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getReporteSolicitudBecasExtranjero', 'id', id)
            // verify required parameter 'esEnviada' is not null or undefined
            assertParamExists('getReporteSolicitudBecasExtranjero', 'esEnviada', esEnviada)
            const localVarPath = `/solicitudes-becas-extranjero/{tipoBecaExtranjero}/{id}/pdf`
                .replace(`{${"tipoBecaExtranjero"}}`, encodeURIComponent(String(tipoBecaExtranjero)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (esEnviada !== undefined) {
                localVarQueryParameter['esEnviada'] = esEnviada;
            }

            if (password !== undefined) {
                localVarQueryParameter['password'] = password;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Almacena información de una solicitud de becas extranjero
         * @param {TipoBecaExtranjero} tipoBecaExtranjero Tipo de Beca Extranjera
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSolicitudBecasExtranjero: async (tipoBecaExtranjero: TipoBecaExtranjero, solicitud: Solicitud, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tipoBecaExtranjero' is not null or undefined
            assertParamExists('saveSolicitudBecasExtranjero', 'tipoBecaExtranjero', tipoBecaExtranjero)
            // verify required parameter 'solicitud' is not null or undefined
            assertParamExists('saveSolicitudBecasExtranjero', 'solicitud', solicitud)
            const localVarPath = `/solicitudes-becas-extranjero/{tipoBecaExtranjero}`
                .replace(`{${"tipoBecaExtranjero"}}`, encodeURIComponent(String(tipoBecaExtranjero)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(solicitud, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actualiza la información de los rubros en la solicitud EN_CAPTURA
         * @param {TipoBecaExtranjero} tipoBecaExtranjero Tipo de Beca Extranjera
         * @param {string} id id de solicitud
         * @param {Array<Rubro>} [rubro] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRubros: async (tipoBecaExtranjero: TipoBecaExtranjero, id: string, rubro?: Array<Rubro>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tipoBecaExtranjero' is not null or undefined
            assertParamExists('updateRubros', 'tipoBecaExtranjero', tipoBecaExtranjero)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateRubros', 'id', id)
            const localVarPath = `/solicitudes-becas-extranjero/{tipoBecaExtranjero}/{id}/rubros`
                .replace(`{${"tipoBecaExtranjero"}}`, encodeURIComponent(String(tipoBecaExtranjero)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rubro, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actualiza la solicitud de becas extranjero
         * @param {TipoBecaExtranjero} tipoBecaExtranjero Tipo de Beca Extranjera
         * @param {string} id id de la solicitud
         * @param {'PARCIAL' | 'FINAL'} tipoActualizacion tipo de actualizacion, si es PARCIAL sólo se actualiza en perfil, si es FINAL se envía actualizacion a aperion y ejecutan reglas finales
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSolicitudBecasExtranjero: async (tipoBecaExtranjero: TipoBecaExtranjero, id: string, tipoActualizacion: 'PARCIAL' | 'FINAL', solicitud: Solicitud, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tipoBecaExtranjero' is not null or undefined
            assertParamExists('updateSolicitudBecasExtranjero', 'tipoBecaExtranjero', tipoBecaExtranjero)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSolicitudBecasExtranjero', 'id', id)
            // verify required parameter 'tipoActualizacion' is not null or undefined
            assertParamExists('updateSolicitudBecasExtranjero', 'tipoActualizacion', tipoActualizacion)
            // verify required parameter 'solicitud' is not null or undefined
            assertParamExists('updateSolicitudBecasExtranjero', 'solicitud', solicitud)
            const localVarPath = `/solicitudes-becas-extranjero/{tipoBecaExtranjero}/{id}`
                .replace(`{${"tipoBecaExtranjero"}}`, encodeURIComponent(String(tipoBecaExtranjero)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (tipoActualizacion !== undefined) {
                localVarQueryParameter['tipoActualizacion'] = tipoActualizacion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(solicitud, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Verifica que un usuario pueda participar en la convocatoria de acuerdo a los requisitos mínimos. En caso de no existir errores regresa una lista vacia.
         * @param {TipoBecaExtranjero} tipoBecaExtranjero Tipo de Beca Extranjera
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validarRequisitosBecasExtranjero: async (tipoBecaExtranjero: TipoBecaExtranjero, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tipoBecaExtranjero' is not null or undefined
            assertParamExists('validarRequisitosBecasExtranjero', 'tipoBecaExtranjero', tipoBecaExtranjero)
            const localVarPath = `/solicitudes-becas-extranjero/{tipoBecaExtranjero}/valida-requisitos`
                .replace(`{${"tipoBecaExtranjero"}}`, encodeURIComponent(String(tipoBecaExtranjero)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BecasExtranjeroApi - functional programming interface
 * @export
 */
export const BecasExtranjeroApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BecasExtranjeroApiAxiosParamCreator(configuration)
    return {
        /**
         * Exportar pdf de solicitud de becas extranjero
         * @param {TipoBecaExtranjero} tipoBecaExtranjero Tipo de Beca Extranjera
         * @param {string} id id de la solicitud de becas
         * @param {boolean} esEnviada Bandera para saber si una solicitud esta enviada para la marca de agua
         * @param {string} [password] Contraseña para firma de solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReporteSolicitudBecasExtranjero(tipoBecaExtranjero: TipoBecaExtranjero, id: string, esEnviada: boolean, password?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentoExport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReporteSolicitudBecasExtranjero(tipoBecaExtranjero, id, esEnviada, password, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Almacena información de una solicitud de becas extranjero
         * @param {TipoBecaExtranjero} tipoBecaExtranjero Tipo de Beca Extranjera
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveSolicitudBecasExtranjero(tipoBecaExtranjero: TipoBecaExtranjero, solicitud: Solicitud, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveSolicitudBecasExtranjero(tipoBecaExtranjero, solicitud, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Actualiza la información de los rubros en la solicitud EN_CAPTURA
         * @param {TipoBecaExtranjero} tipoBecaExtranjero Tipo de Beca Extranjera
         * @param {string} id id de solicitud
         * @param {Array<Rubro>} [rubro] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRubros(tipoBecaExtranjero: TipoBecaExtranjero, id: string, rubro?: Array<Rubro>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Rubro>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRubros(tipoBecaExtranjero, id, rubro, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Actualiza la solicitud de becas extranjero
         * @param {TipoBecaExtranjero} tipoBecaExtranjero Tipo de Beca Extranjera
         * @param {string} id id de la solicitud
         * @param {'PARCIAL' | 'FINAL'} tipoActualizacion tipo de actualizacion, si es PARCIAL sólo se actualiza en perfil, si es FINAL se envía actualizacion a aperion y ejecutan reglas finales
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSolicitudBecasExtranjero(tipoBecaExtranjero: TipoBecaExtranjero, id: string, tipoActualizacion: 'PARCIAL' | 'FINAL', solicitud: Solicitud, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSolicitudBecasExtranjero(tipoBecaExtranjero, id, tipoActualizacion, solicitud, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Verifica que un usuario pueda participar en la convocatoria de acuerdo a los requisitos mínimos. En caso de no existir errores regresa una lista vacia.
         * @param {TipoBecaExtranjero} tipoBecaExtranjero Tipo de Beca Extranjera
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validarRequisitosBecasExtranjero(tipoBecaExtranjero: TipoBecaExtranjero, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ErrorValidacion>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validarRequisitosBecasExtranjero(tipoBecaExtranjero, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BecasExtranjeroApi - factory interface
 * @export
 */
export const BecasExtranjeroApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BecasExtranjeroApiFp(configuration)
    return {
        /**
         * Exportar pdf de solicitud de becas extranjero
         * @param {TipoBecaExtranjero} tipoBecaExtranjero Tipo de Beca Extranjera
         * @param {string} id id de la solicitud de becas
         * @param {boolean} esEnviada Bandera para saber si una solicitud esta enviada para la marca de agua
         * @param {string} [password] Contraseña para firma de solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReporteSolicitudBecasExtranjero(tipoBecaExtranjero: TipoBecaExtranjero, id: string, esEnviada: boolean, password?: string, options?: any): AxiosPromise<DocumentoExport> {
            return localVarFp.getReporteSolicitudBecasExtranjero(tipoBecaExtranjero, id, esEnviada, password, options).then((request) => request(axios, basePath));
        },
        /**
         * Almacena información de una solicitud de becas extranjero
         * @param {TipoBecaExtranjero} tipoBecaExtranjero Tipo de Beca Extranjera
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSolicitudBecasExtranjero(tipoBecaExtranjero: TipoBecaExtranjero, solicitud: Solicitud, options?: any): AxiosPromise<void> {
            return localVarFp.saveSolicitudBecasExtranjero(tipoBecaExtranjero, solicitud, options).then((request) => request(axios, basePath));
        },
        /**
         * Actualiza la información de los rubros en la solicitud EN_CAPTURA
         * @param {TipoBecaExtranjero} tipoBecaExtranjero Tipo de Beca Extranjera
         * @param {string} id id de solicitud
         * @param {Array<Rubro>} [rubro] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRubros(tipoBecaExtranjero: TipoBecaExtranjero, id: string, rubro?: Array<Rubro>, options?: any): AxiosPromise<Array<Rubro>> {
            return localVarFp.updateRubros(tipoBecaExtranjero, id, rubro, options).then((request) => request(axios, basePath));
        },
        /**
         * Actualiza la solicitud de becas extranjero
         * @param {TipoBecaExtranjero} tipoBecaExtranjero Tipo de Beca Extranjera
         * @param {string} id id de la solicitud
         * @param {'PARCIAL' | 'FINAL'} tipoActualizacion tipo de actualizacion, si es PARCIAL sólo se actualiza en perfil, si es FINAL se envía actualizacion a aperion y ejecutan reglas finales
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSolicitudBecasExtranjero(tipoBecaExtranjero: TipoBecaExtranjero, id: string, tipoActualizacion: 'PARCIAL' | 'FINAL', solicitud: Solicitud, options?: any): AxiosPromise<void> {
            return localVarFp.updateSolicitudBecasExtranjero(tipoBecaExtranjero, id, tipoActualizacion, solicitud, options).then((request) => request(axios, basePath));
        },
        /**
         * Verifica que un usuario pueda participar en la convocatoria de acuerdo a los requisitos mínimos. En caso de no existir errores regresa una lista vacia.
         * @param {TipoBecaExtranjero} tipoBecaExtranjero Tipo de Beca Extranjera
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validarRequisitosBecasExtranjero(tipoBecaExtranjero: TipoBecaExtranjero, options?: any): AxiosPromise<Array<ErrorValidacion>> {
            return localVarFp.validarRequisitosBecasExtranjero(tipoBecaExtranjero, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BecasExtranjeroApi - object-oriented interface
 * @export
 * @class BecasExtranjeroApi
 * @extends {BaseAPI}
 */
export class BecasExtranjeroApi extends BaseAPI {
    /**
     * Exportar pdf de solicitud de becas extranjero
     * @param {TipoBecaExtranjero} tipoBecaExtranjero Tipo de Beca Extranjera
     * @param {string} id id de la solicitud de becas
     * @param {boolean} esEnviada Bandera para saber si una solicitud esta enviada para la marca de agua
     * @param {string} [password] Contraseña para firma de solicitud
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BecasExtranjeroApi
     */
    public getReporteSolicitudBecasExtranjero(tipoBecaExtranjero: TipoBecaExtranjero, id: string, esEnviada: boolean, password?: string, options?: AxiosRequestConfig) {
        return BecasExtranjeroApiFp(this.configuration).getReporteSolicitudBecasExtranjero(tipoBecaExtranjero, id, esEnviada, password, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Almacena información de una solicitud de becas extranjero
     * @param {TipoBecaExtranjero} tipoBecaExtranjero Tipo de Beca Extranjera
     * @param {Solicitud} solicitud 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BecasExtranjeroApi
     */
    public saveSolicitudBecasExtranjero(tipoBecaExtranjero: TipoBecaExtranjero, solicitud: Solicitud, options?: AxiosRequestConfig) {
        return BecasExtranjeroApiFp(this.configuration).saveSolicitudBecasExtranjero(tipoBecaExtranjero, solicitud, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actualiza la información de los rubros en la solicitud EN_CAPTURA
     * @param {TipoBecaExtranjero} tipoBecaExtranjero Tipo de Beca Extranjera
     * @param {string} id id de solicitud
     * @param {Array<Rubro>} [rubro] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BecasExtranjeroApi
     */
    public updateRubros(tipoBecaExtranjero: TipoBecaExtranjero, id: string, rubro?: Array<Rubro>, options?: AxiosRequestConfig) {
        return BecasExtranjeroApiFp(this.configuration).updateRubros(tipoBecaExtranjero, id, rubro, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actualiza la solicitud de becas extranjero
     * @param {TipoBecaExtranjero} tipoBecaExtranjero Tipo de Beca Extranjera
     * @param {string} id id de la solicitud
     * @param {'PARCIAL' | 'FINAL'} tipoActualizacion tipo de actualizacion, si es PARCIAL sólo se actualiza en perfil, si es FINAL se envía actualizacion a aperion y ejecutan reglas finales
     * @param {Solicitud} solicitud 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BecasExtranjeroApi
     */
    public updateSolicitudBecasExtranjero(tipoBecaExtranjero: TipoBecaExtranjero, id: string, tipoActualizacion: 'PARCIAL' | 'FINAL', solicitud: Solicitud, options?: AxiosRequestConfig) {
        return BecasExtranjeroApiFp(this.configuration).updateSolicitudBecasExtranjero(tipoBecaExtranjero, id, tipoActualizacion, solicitud, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Verifica que un usuario pueda participar en la convocatoria de acuerdo a los requisitos mínimos. En caso de no existir errores regresa una lista vacia.
     * @param {TipoBecaExtranjero} tipoBecaExtranjero Tipo de Beca Extranjera
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BecasExtranjeroApi
     */
    public validarRequisitosBecasExtranjero(tipoBecaExtranjero: TipoBecaExtranjero, options?: AxiosRequestConfig) {
        return BecasExtranjeroApiFp(this.configuration).validarRequisitosBecasExtranjero(tipoBecaExtranjero, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ColaboracionesApi - axios parameter creator
 * @export
 */
export const ColaboracionesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Elimina un Colaboración del usuario
         * @param {string} id id de Colaboración
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteColaboracionById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteColaboracionById', 'id', id)
            const localVarPath = `/colaboraciones/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene la información de las colaboraciones con Ecosistemas Nacionales Informáticos (ENI), Gestor de Mapas (Gema) , Sistema de Diseño y Accesibilidad para la Investigación (Sisdai)
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getColaboraciones: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/colaboraciones`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Almacena la colaboración en los proyectos  Ecosistemas Nacionales Informáticos (ENI), Gestor de Mapas (Gema) , Sistema de Diseño y Accesibilidad para la Investigación (Sisdai)
         * @param {Colaboracion} colaboracion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guardarColaboracion: async (colaboracion: Colaboracion, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'colaboracion' is not null or undefined
            assertParamExists('guardarColaboracion', 'colaboracion', colaboracion)
            const localVarPath = `/colaboraciones`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(colaboracion, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ColaboracionesApi - functional programming interface
 * @export
 */
export const ColaboracionesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ColaboracionesApiAxiosParamCreator(configuration)
    return {
        /**
         * Elimina un Colaboración del usuario
         * @param {string} id id de Colaboración
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteColaboracionById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteColaboracionById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene la información de las colaboraciones con Ecosistemas Nacionales Informáticos (ENI), Gestor de Mapas (Gema) , Sistema de Diseño y Accesibilidad para la Investigación (Sisdai)
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getColaboraciones(page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Colaboracion>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getColaboraciones(page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Almacena la colaboración en los proyectos  Ecosistemas Nacionales Informáticos (ENI), Gestor de Mapas (Gema) , Sistema de Diseño y Accesibilidad para la Investigación (Sisdai)
         * @param {Colaboracion} colaboracion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async guardarColaboracion(colaboracion: Colaboracion, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Colaboracion>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.guardarColaboracion(colaboracion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ColaboracionesApi - factory interface
 * @export
 */
export const ColaboracionesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ColaboracionesApiFp(configuration)
    return {
        /**
         * Elimina un Colaboración del usuario
         * @param {string} id id de Colaboración
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteColaboracionById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteColaboracionById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene la información de las colaboraciones con Ecosistemas Nacionales Informáticos (ENI), Gestor de Mapas (Gema) , Sistema de Diseño y Accesibilidad para la Investigación (Sisdai)
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getColaboraciones(page?: number, size?: number, options?: any): AxiosPromise<Array<Colaboracion>> {
            return localVarFp.getColaboraciones(page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * Almacena la colaboración en los proyectos  Ecosistemas Nacionales Informáticos (ENI), Gestor de Mapas (Gema) , Sistema de Diseño y Accesibilidad para la Investigación (Sisdai)
         * @param {Colaboracion} colaboracion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guardarColaboracion(colaboracion: Colaboracion, options?: any): AxiosPromise<Array<Colaboracion>> {
            return localVarFp.guardarColaboracion(colaboracion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ColaboracionesApi - object-oriented interface
 * @export
 * @class ColaboracionesApi
 * @extends {BaseAPI}
 */
export class ColaboracionesApi extends BaseAPI {
    /**
     * Elimina un Colaboración del usuario
     * @param {string} id id de Colaboración
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ColaboracionesApi
     */
    public deleteColaboracionById(id: string, options?: AxiosRequestConfig) {
        return ColaboracionesApiFp(this.configuration).deleteColaboracionById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene la información de las colaboraciones con Ecosistemas Nacionales Informáticos (ENI), Gestor de Mapas (Gema) , Sistema de Diseño y Accesibilidad para la Investigación (Sisdai)
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ColaboracionesApi
     */
    public getColaboraciones(page?: number, size?: number, options?: AxiosRequestConfig) {
        return ColaboracionesApiFp(this.configuration).getColaboraciones(page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Almacena la colaboración en los proyectos  Ecosistemas Nacionales Informáticos (ENI), Gestor de Mapas (Gema) , Sistema de Diseño y Accesibilidad para la Investigación (Sisdai)
     * @param {Colaboracion} colaboracion 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ColaboracionesApi
     */
    public guardarColaboracion(colaboracion: Colaboracion, options?: AxiosRequestConfig) {
        return ColaboracionesApiFp(this.configuration).guardarColaboracion(colaboracion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CorreoApi - axios parameter creator
 * @export
 */
export const CorreoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Permite a los usuarios enviar un correo electrónico con uno o más archivos adjuntos.
         * @param {CorreoAyudaRequest} correoAyudaRequest Datos necesarios para enviar el correo.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enviarCorreoConAdjunto: async (correoAyudaRequest: CorreoAyudaRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'correoAyudaRequest' is not null or undefined
            assertParamExists('enviarCorreoConAdjunto', 'correoAyudaRequest', correoAyudaRequest)
            const localVarPath = `/soporte/correo-ayuda`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(correoAyudaRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CorreoApi - functional programming interface
 * @export
 */
export const CorreoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CorreoApiAxiosParamCreator(configuration)
    return {
        /**
         * Permite a los usuarios enviar un correo electrónico con uno o más archivos adjuntos.
         * @param {CorreoAyudaRequest} correoAyudaRequest Datos necesarios para enviar el correo.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enviarCorreoConAdjunto(correoAyudaRequest: CorreoAyudaRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enviarCorreoConAdjunto(correoAyudaRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CorreoApi - factory interface
 * @export
 */
export const CorreoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CorreoApiFp(configuration)
    return {
        /**
         * Permite a los usuarios enviar un correo electrónico con uno o más archivos adjuntos.
         * @param {CorreoAyudaRequest} correoAyudaRequest Datos necesarios para enviar el correo.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enviarCorreoConAdjunto(correoAyudaRequest: CorreoAyudaRequest, options?: any): AxiosPromise<void> {
            return localVarFp.enviarCorreoConAdjunto(correoAyudaRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CorreoApi - object-oriented interface
 * @export
 * @class CorreoApi
 * @extends {BaseAPI}
 */
export class CorreoApi extends BaseAPI {
    /**
     * Permite a los usuarios enviar un correo electrónico con uno o más archivos adjuntos.
     * @param {CorreoAyudaRequest} correoAyudaRequest Datos necesarios para enviar el correo.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorreoApi
     */
    public enviarCorreoConAdjunto(correoAyudaRequest: CorreoAyudaRequest, options?: AxiosRequestConfig) {
        return CorreoApiFp(this.configuration).enviarCorreoConAdjunto(correoAyudaRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CvuApi - axios parameter creator
 * @export
 */
export const CvuApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtiene los perfiles de usuario dado el parámetro de filtro, ya sea por id de la institución, nombre o ambos
         * @param {Filtro} [filtro] filtro para realizar consultas
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPerfiles: async (filtro?: Filtro, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/perfil/datos-generales`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (filtro !== undefined) {
                localVarQueryParameter['filtro'] = filtro;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene datos breves de perfiles de los cvus indicados
         * @param {Array<string>} cvus cvus de los usuarios
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBulkPerfiles: async (cvus: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cvus' is not null or undefined
            assertParamExists('getBulkPerfiles', 'cvus', cvus)
            const localVarPath = `/perfil/bulk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (cvus) {
                localVarQueryParameter['cvus'] = cvus;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene el empleo principal de usuario dado un cvu
         * @param {string} cvu cvu del usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmpleoPrincipal: async (cvu: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cvu' is not null or undefined
            assertParamExists('getEmpleoPrincipal', 'cvu', cvu)
            const localVarPath = `/perfil/{cvu}/trayectoria-profesional/principal`
                .replace(`{${"cvu"}}`, encodeURIComponent(String(cvu)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene un pdf en base64 del perfil dado un cvu o login
         * @param {string} [cvu] cvu del usuario
         * @param {string} [login] correo de login del usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPdfPerfil: async (cvu?: string, login?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/perfil/pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (cvu !== undefined) {
                localVarQueryParameter['cvu'] = cvu;
            }

            if (login !== undefined) {
                localVarQueryParameter['login'] = login;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene el perfil de usuario dado alguno de los parámetros cvu o login
         * @param {string} [cvu] cvu del usuario
         * @param {string} [login] correo de login del usuario
         * @param {boolean} [completo] Indica si obtendrá información completa de perfil
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerfil: async (cvu?: string, login?: string, completo?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/perfil`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (cvu !== undefined) {
                localVarQueryParameter['cvu'] = cvu;
            }

            if (login !== undefined) {
                localVarQueryParameter['login'] = login;
            }

            if (completo !== undefined) {
                localVarQueryParameter['completo'] = completo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene el perfil de usuario dado un cvu
         * @param {string} cvu cvu del usuario
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getPerfilByCvu: async (cvu: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cvu' is not null or undefined
            assertParamExists('getPerfilByCvu', 'cvu', cvu)
            const localVarPath = `/perfil/{cvu}`
                .replace(`{${"cvu"}}`, encodeURIComponent(String(cvu)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CvuApi - functional programming interface
 * @export
 */
export const CvuApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CvuApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtiene los perfiles de usuario dado el parámetro de filtro, ya sea por id de la institución, nombre o ambos
         * @param {Filtro} [filtro] filtro para realizar consultas
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllPerfiles(filtro?: Filtro, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Perfil>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllPerfiles(filtro, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene datos breves de perfiles de los cvus indicados
         * @param {Array<string>} cvus cvus de los usuarios
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBulkPerfiles(cvus: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PerfilBrief>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBulkPerfiles(cvus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene el empleo principal de usuario dado un cvu
         * @param {string} cvu cvu del usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmpleoPrincipal(cvu: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Empleo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmpleoPrincipal(cvu, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene un pdf en base64 del perfil dado un cvu o login
         * @param {string} [cvu] cvu del usuario
         * @param {string} [login] correo de login del usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPdfPerfil(cvu?: string, login?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentoExport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPdfPerfil(cvu, login, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene el perfil de usuario dado alguno de los parámetros cvu o login
         * @param {string} [cvu] cvu del usuario
         * @param {string} [login] correo de login del usuario
         * @param {boolean} [completo] Indica si obtendrá información completa de perfil
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPerfil(cvu?: string, login?: string, completo?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Perfil>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPerfil(cvu, login, completo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene el perfil de usuario dado un cvu
         * @param {string} cvu cvu del usuario
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getPerfilByCvu(cvu: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Perfil>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPerfilByCvu(cvu, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CvuApi - factory interface
 * @export
 */
export const CvuApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CvuApiFp(configuration)
    return {
        /**
         * Obtiene los perfiles de usuario dado el parámetro de filtro, ya sea por id de la institución, nombre o ambos
         * @param {Filtro} [filtro] filtro para realizar consultas
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPerfiles(filtro?: Filtro, page?: number, size?: number, options?: any): AxiosPromise<Array<Perfil>> {
            return localVarFp.getAllPerfiles(filtro, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene datos breves de perfiles de los cvus indicados
         * @param {Array<string>} cvus cvus de los usuarios
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBulkPerfiles(cvus: Array<string>, options?: any): AxiosPromise<Array<PerfilBrief>> {
            return localVarFp.getBulkPerfiles(cvus, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene el empleo principal de usuario dado un cvu
         * @param {string} cvu cvu del usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmpleoPrincipal(cvu: string, options?: any): AxiosPromise<Empleo> {
            return localVarFp.getEmpleoPrincipal(cvu, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene un pdf en base64 del perfil dado un cvu o login
         * @param {string} [cvu] cvu del usuario
         * @param {string} [login] correo de login del usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPdfPerfil(cvu?: string, login?: string, options?: any): AxiosPromise<DocumentoExport> {
            return localVarFp.getPdfPerfil(cvu, login, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene el perfil de usuario dado alguno de los parámetros cvu o login
         * @param {string} [cvu] cvu del usuario
         * @param {string} [login] correo de login del usuario
         * @param {boolean} [completo] Indica si obtendrá información completa de perfil
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerfil(cvu?: string, login?: string, completo?: boolean, options?: any): AxiosPromise<Perfil> {
            return localVarFp.getPerfil(cvu, login, completo, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene el perfil de usuario dado un cvu
         * @param {string} cvu cvu del usuario
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getPerfilByCvu(cvu: string, options?: any): AxiosPromise<Perfil> {
            return localVarFp.getPerfilByCvu(cvu, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CvuApi - object-oriented interface
 * @export
 * @class CvuApi
 * @extends {BaseAPI}
 */
export class CvuApi extends BaseAPI {
    /**
     * Obtiene los perfiles de usuario dado el parámetro de filtro, ya sea por id de la institución, nombre o ambos
     * @param {Filtro} [filtro] filtro para realizar consultas
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CvuApi
     */
    public getAllPerfiles(filtro?: Filtro, page?: number, size?: number, options?: AxiosRequestConfig) {
        return CvuApiFp(this.configuration).getAllPerfiles(filtro, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene datos breves de perfiles de los cvus indicados
     * @param {Array<string>} cvus cvus de los usuarios
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CvuApi
     */
    public getBulkPerfiles(cvus: Array<string>, options?: AxiosRequestConfig) {
        return CvuApiFp(this.configuration).getBulkPerfiles(cvus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene el empleo principal de usuario dado un cvu
     * @param {string} cvu cvu del usuario
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CvuApi
     */
    public getEmpleoPrincipal(cvu: string, options?: AxiosRequestConfig) {
        return CvuApiFp(this.configuration).getEmpleoPrincipal(cvu, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene un pdf en base64 del perfil dado un cvu o login
     * @param {string} [cvu] cvu del usuario
     * @param {string} [login] correo de login del usuario
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CvuApi
     */
    public getPdfPerfil(cvu?: string, login?: string, options?: AxiosRequestConfig) {
        return CvuApiFp(this.configuration).getPdfPerfil(cvu, login, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene el perfil de usuario dado alguno de los parámetros cvu o login
     * @param {string} [cvu] cvu del usuario
     * @param {string} [login] correo de login del usuario
     * @param {boolean} [completo] Indica si obtendrá información completa de perfil
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CvuApi
     */
    public getPerfil(cvu?: string, login?: string, completo?: boolean, options?: AxiosRequestConfig) {
        return CvuApiFp(this.configuration).getPerfil(cvu, login, completo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene el perfil de usuario dado un cvu
     * @param {string} cvu cvu del usuario
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof CvuApi
     */
    public getPerfilByCvu(cvu: string, options?: AxiosRequestConfig) {
        return CvuApiFp(this.configuration).getPerfilByCvu(cvu, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DatosGeneralesApi - axios parameter creator
 * @export
 */
export const DatosGeneralesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtiene la información general de perfil de usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMiPerfil: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mi-perfil`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene la información de perfil de usuario
         * @param {number} id id del usuario
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getMiPerfilById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMiPerfilById', 'id', id)
            const localVarPath = `/mi-perfil/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Exportar pdf perfil único
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportePerfil: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mi-perfil/pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Almacena información general de perfil de usuario
         * @param {MiPerfil} miPerfil 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveMiPerfil: async (miPerfil: MiPerfil, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'miPerfil' is not null or undefined
            assertParamExists('saveMiPerfil', 'miPerfil', miPerfil)
            const localVarPath = `/mi-perfil`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(miPerfil, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actualiza la información de contactos de emergencia en la seccion general de perfil de usuario
         * @param {Array<ContactoEmergencias>} [contactoEmergencias] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContactosEmergencia: async (contactoEmergencias?: Array<ContactoEmergencias>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mi-perfil/contactos-emergencia`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactoEmergencias, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actualiza la información de los dependientes económicos en la seccion general del usuario de perfil
         * @param {Array<DependienteEconomico>} [dependienteEconomico] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDependientesEconomicos: async (dependienteEconomico?: Array<DependienteEconomico>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mi-perfil/dependientes-economicos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dependienteEconomico, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actualiza la información de medios de contacto en la sección general de perfil de usuario
         * @param {Array<Contacto>} [contacto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMediosContacto: async (contacto?: Array<Contacto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mi-perfil/medios-contacto`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contacto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actualiza la información general de perfil de usuario
         * @param {number} id id del usuario
         * @param {MiPerfil} miPerfil 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMiPerfilById: async (id: number, miPerfil: MiPerfil, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateMiPerfilById', 'id', id)
            // verify required parameter 'miPerfil' is not null or undefined
            assertParamExists('updateMiPerfilById', 'miPerfil', miPerfil)
            const localVarPath = `/mi-perfil/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(miPerfil, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Verifica que exista un perfil o crea uno nuevo de un usuario recien registrado
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validaPerfil: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mi-perfil/valida`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DatosGeneralesApi - functional programming interface
 * @export
 */
export const DatosGeneralesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DatosGeneralesApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtiene la información general de perfil de usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMiPerfil(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MiPerfil>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMiPerfil(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene la información de perfil de usuario
         * @param {number} id id del usuario
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getMiPerfilById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MiPerfil>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMiPerfilById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Exportar pdf perfil único
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportePerfil(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentoExport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportePerfil(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Almacena información general de perfil de usuario
         * @param {MiPerfil} miPerfil 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveMiPerfil(miPerfil: MiPerfil, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MiPerfil>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveMiPerfil(miPerfil, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Actualiza la información de contactos de emergencia en la seccion general de perfil de usuario
         * @param {Array<ContactoEmergencias>} [contactoEmergencias] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateContactosEmergencia(contactoEmergencias?: Array<ContactoEmergencias>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContactoEmergencias>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateContactosEmergencia(contactoEmergencias, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Actualiza la información de los dependientes económicos en la seccion general del usuario de perfil
         * @param {Array<DependienteEconomico>} [dependienteEconomico] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDependientesEconomicos(dependienteEconomico?: Array<DependienteEconomico>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DependienteEconomico>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDependientesEconomicos(dependienteEconomico, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Actualiza la información de medios de contacto en la sección general de perfil de usuario
         * @param {Array<Contacto>} [contacto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMediosContacto(contacto?: Array<Contacto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Contacto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMediosContacto(contacto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Actualiza la información general de perfil de usuario
         * @param {number} id id del usuario
         * @param {MiPerfil} miPerfil 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMiPerfilById(id: number, miPerfil: MiPerfil, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MiPerfil>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMiPerfilById(id, miPerfil, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Verifica que exista un perfil o crea uno nuevo de un usuario recien registrado
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validaPerfil(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MiPerfil>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validaPerfil(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DatosGeneralesApi - factory interface
 * @export
 */
export const DatosGeneralesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DatosGeneralesApiFp(configuration)
    return {
        /**
         * Obtiene la información general de perfil de usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMiPerfil(options?: any): AxiosPromise<MiPerfil> {
            return localVarFp.getMiPerfil(options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene la información de perfil de usuario
         * @param {number} id id del usuario
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getMiPerfilById(id: number, options?: any): AxiosPromise<MiPerfil> {
            return localVarFp.getMiPerfilById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Exportar pdf perfil único
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportePerfil(options?: any): AxiosPromise<DocumentoExport> {
            return localVarFp.getReportePerfil(options).then((request) => request(axios, basePath));
        },
        /**
         * Almacena información general de perfil de usuario
         * @param {MiPerfil} miPerfil 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveMiPerfil(miPerfil: MiPerfil, options?: any): AxiosPromise<MiPerfil> {
            return localVarFp.saveMiPerfil(miPerfil, options).then((request) => request(axios, basePath));
        },
        /**
         * Actualiza la información de contactos de emergencia en la seccion general de perfil de usuario
         * @param {Array<ContactoEmergencias>} [contactoEmergencias] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContactosEmergencia(contactoEmergencias?: Array<ContactoEmergencias>, options?: any): AxiosPromise<Array<ContactoEmergencias>> {
            return localVarFp.updateContactosEmergencia(contactoEmergencias, options).then((request) => request(axios, basePath));
        },
        /**
         * Actualiza la información de los dependientes económicos en la seccion general del usuario de perfil
         * @param {Array<DependienteEconomico>} [dependienteEconomico] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDependientesEconomicos(dependienteEconomico?: Array<DependienteEconomico>, options?: any): AxiosPromise<Array<DependienteEconomico>> {
            return localVarFp.updateDependientesEconomicos(dependienteEconomico, options).then((request) => request(axios, basePath));
        },
        /**
         * Actualiza la información de medios de contacto en la sección general de perfil de usuario
         * @param {Array<Contacto>} [contacto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMediosContacto(contacto?: Array<Contacto>, options?: any): AxiosPromise<Array<Contacto>> {
            return localVarFp.updateMediosContacto(contacto, options).then((request) => request(axios, basePath));
        },
        /**
         * Actualiza la información general de perfil de usuario
         * @param {number} id id del usuario
         * @param {MiPerfil} miPerfil 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMiPerfilById(id: number, miPerfil: MiPerfil, options?: any): AxiosPromise<MiPerfil> {
            return localVarFp.updateMiPerfilById(id, miPerfil, options).then((request) => request(axios, basePath));
        },
        /**
         * Verifica que exista un perfil o crea uno nuevo de un usuario recien registrado
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validaPerfil(options?: any): AxiosPromise<MiPerfil> {
            return localVarFp.validaPerfil(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DatosGeneralesApi - object-oriented interface
 * @export
 * @class DatosGeneralesApi
 * @extends {BaseAPI}
 */
export class DatosGeneralesApi extends BaseAPI {
    /**
     * Obtiene la información general de perfil de usuario
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatosGeneralesApi
     */
    public getMiPerfil(options?: AxiosRequestConfig) {
        return DatosGeneralesApiFp(this.configuration).getMiPerfil(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene la información de perfil de usuario
     * @param {number} id id del usuario
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DatosGeneralesApi
     */
    public getMiPerfilById(id: number, options?: AxiosRequestConfig) {
        return DatosGeneralesApiFp(this.configuration).getMiPerfilById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Exportar pdf perfil único
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatosGeneralesApi
     */
    public getReportePerfil(options?: AxiosRequestConfig) {
        return DatosGeneralesApiFp(this.configuration).getReportePerfil(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Almacena información general de perfil de usuario
     * @param {MiPerfil} miPerfil 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatosGeneralesApi
     */
    public saveMiPerfil(miPerfil: MiPerfil, options?: AxiosRequestConfig) {
        return DatosGeneralesApiFp(this.configuration).saveMiPerfil(miPerfil, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actualiza la información de contactos de emergencia en la seccion general de perfil de usuario
     * @param {Array<ContactoEmergencias>} [contactoEmergencias] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatosGeneralesApi
     */
    public updateContactosEmergencia(contactoEmergencias?: Array<ContactoEmergencias>, options?: AxiosRequestConfig) {
        return DatosGeneralesApiFp(this.configuration).updateContactosEmergencia(contactoEmergencias, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actualiza la información de los dependientes económicos en la seccion general del usuario de perfil
     * @param {Array<DependienteEconomico>} [dependienteEconomico] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatosGeneralesApi
     */
    public updateDependientesEconomicos(dependienteEconomico?: Array<DependienteEconomico>, options?: AxiosRequestConfig) {
        return DatosGeneralesApiFp(this.configuration).updateDependientesEconomicos(dependienteEconomico, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actualiza la información de medios de contacto en la sección general de perfil de usuario
     * @param {Array<Contacto>} [contacto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatosGeneralesApi
     */
    public updateMediosContacto(contacto?: Array<Contacto>, options?: AxiosRequestConfig) {
        return DatosGeneralesApiFp(this.configuration).updateMediosContacto(contacto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actualiza la información general de perfil de usuario
     * @param {number} id id del usuario
     * @param {MiPerfil} miPerfil 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatosGeneralesApi
     */
    public updateMiPerfilById(id: number, miPerfil: MiPerfil, options?: AxiosRequestConfig) {
        return DatosGeneralesApiFp(this.configuration).updateMiPerfilById(id, miPerfil, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Verifica que exista un perfil o crea uno nuevo de un usuario recien registrado
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DatosGeneralesApi
     */
    public validaPerfil(options?: AxiosRequestConfig) {
        return DatosGeneralesApiFp(this.configuration).validaPerfil(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DesarrollosInstitucionalesApi - axios parameter creator
 * @export
 */
export const DesarrollosInstitucionalesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtiene la información de Desarrollo Institucional de usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDesarrolloInstitucional: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/desarrollos-institucionales`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Almacena información de Desarrollo Institucional de usuario
         * @param {DesarrolloInstitucional} desarrolloInstitucional 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDesarrolloInstitucional: async (desarrolloInstitucional: DesarrolloInstitucional, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'desarrolloInstitucional' is not null or undefined
            assertParamExists('saveDesarrolloInstitucional', 'desarrolloInstitucional', desarrolloInstitucional)
            const localVarPath = `/desarrollos-institucionales`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(desarrolloInstitucional, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actualiza la información de Desarrollo Institucional
         * @param {number} id id del usuario
         * @param {DesarrolloInstitucional} desarrolloInstitucional 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDesarrolloInstitucionalById: async (id: number, desarrolloInstitucional: DesarrolloInstitucional, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateDesarrolloInstitucionalById', 'id', id)
            // verify required parameter 'desarrolloInstitucional' is not null or undefined
            assertParamExists('updateDesarrolloInstitucionalById', 'desarrolloInstitucional', desarrolloInstitucional)
            const localVarPath = `/desarrollos-institucionales/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(desarrolloInstitucional, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DesarrollosInstitucionalesApi - functional programming interface
 * @export
 */
export const DesarrollosInstitucionalesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DesarrollosInstitucionalesApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtiene la información de Desarrollo Institucional de usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDesarrolloInstitucional(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DesarrolloInstitucional>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDesarrolloInstitucional(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Almacena información de Desarrollo Institucional de usuario
         * @param {DesarrolloInstitucional} desarrolloInstitucional 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveDesarrolloInstitucional(desarrolloInstitucional: DesarrolloInstitucional, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DesarrolloInstitucional>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveDesarrolloInstitucional(desarrolloInstitucional, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Actualiza la información de Desarrollo Institucional
         * @param {number} id id del usuario
         * @param {DesarrolloInstitucional} desarrolloInstitucional 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDesarrolloInstitucionalById(id: number, desarrolloInstitucional: DesarrolloInstitucional, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DesarrolloInstitucional>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDesarrolloInstitucionalById(id, desarrolloInstitucional, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DesarrollosInstitucionalesApi - factory interface
 * @export
 */
export const DesarrollosInstitucionalesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DesarrollosInstitucionalesApiFp(configuration)
    return {
        /**
         * Obtiene la información de Desarrollo Institucional de usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDesarrolloInstitucional(options?: any): AxiosPromise<DesarrolloInstitucional> {
            return localVarFp.getDesarrolloInstitucional(options).then((request) => request(axios, basePath));
        },
        /**
         * Almacena información de Desarrollo Institucional de usuario
         * @param {DesarrolloInstitucional} desarrolloInstitucional 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDesarrolloInstitucional(desarrolloInstitucional: DesarrolloInstitucional, options?: any): AxiosPromise<DesarrolloInstitucional> {
            return localVarFp.saveDesarrolloInstitucional(desarrolloInstitucional, options).then((request) => request(axios, basePath));
        },
        /**
         * Actualiza la información de Desarrollo Institucional
         * @param {number} id id del usuario
         * @param {DesarrolloInstitucional} desarrolloInstitucional 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDesarrolloInstitucionalById(id: number, desarrolloInstitucional: DesarrolloInstitucional, options?: any): AxiosPromise<DesarrolloInstitucional> {
            return localVarFp.updateDesarrolloInstitucionalById(id, desarrolloInstitucional, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DesarrollosInstitucionalesApi - object-oriented interface
 * @export
 * @class DesarrollosInstitucionalesApi
 * @extends {BaseAPI}
 */
export class DesarrollosInstitucionalesApi extends BaseAPI {
    /**
     * Obtiene la información de Desarrollo Institucional de usuario
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DesarrollosInstitucionalesApi
     */
    public getDesarrolloInstitucional(options?: AxiosRequestConfig) {
        return DesarrollosInstitucionalesApiFp(this.configuration).getDesarrolloInstitucional(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Almacena información de Desarrollo Institucional de usuario
     * @param {DesarrolloInstitucional} desarrolloInstitucional 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DesarrollosInstitucionalesApi
     */
    public saveDesarrolloInstitucional(desarrolloInstitucional: DesarrolloInstitucional, options?: AxiosRequestConfig) {
        return DesarrollosInstitucionalesApiFp(this.configuration).saveDesarrolloInstitucional(desarrolloInstitucional, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actualiza la información de Desarrollo Institucional
     * @param {number} id id del usuario
     * @param {DesarrolloInstitucional} desarrolloInstitucional 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DesarrollosInstitucionalesApi
     */
    public updateDesarrolloInstitucionalById(id: number, desarrolloInstitucional: DesarrolloInstitucional, options?: AxiosRequestConfig) {
        return DesarrollosInstitucionalesApiFp(this.configuration).updateDesarrolloInstitucionalById(id, desarrolloInstitucional, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EducacionApi - axios parameter creator
 * @export
 */
export const EducacionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Elimina un logro de usuario
         * @param {string} id id de logro
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLogrosById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteLogrosById', 'id', id)
            const localVarPath = `/logros/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Elimina un grado académico de un usuario
         * @param {string} id id de grado académico
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTrayectoriaAcademicaById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTrayectoriaAcademicaById', 'id', id)
            const localVarPath = `/trayectoria-academica/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene la información de formación continua de usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCursos: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cursos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene la información de formación continua de un usuario
         * @param {number} id id del usuario
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getCursosById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCursosById', 'id', id)
            const localVarPath = `/cursos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene la información de idiomas de usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdiomas: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/idiomas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene la información de idiomas
         * @param {number} id id del usuario
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getIdiomasById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getIdiomasById', 'id', id)
            const localVarPath = `/idiomas/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene la información de logros de usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogros: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/logros`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene la información de trayectoria académica (grados) de un usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrayectoriaAcademica: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trayectoria-academica`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Almacena información de formación continua de usuario
         * @param {Cursos} cursos 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCursos: async (cursos: Cursos, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cursos' is not null or undefined
            assertParamExists('saveCursos', 'cursos', cursos)
            const localVarPath = `/cursos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cursos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Almacena información de idiomas de usuario
         * @param {Idiomas} idiomas 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveIdiomas: async (idiomas: Idiomas, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idiomas' is not null or undefined
            assertParamExists('saveIdiomas', 'idiomas', idiomas)
            const localVarPath = `/idiomas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(idiomas, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Almacena un logro de usuario
         * @param {Logro} logro 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveLogros: async (logro: Logro, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'logro' is not null or undefined
            assertParamExists('saveLogros', 'logro', logro)
            const localVarPath = `/logros`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logro, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Almacena un grado académico de un usuario
         * @param {GradoAcademico} gradoAcademico 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTrayectoriaAcademica: async (gradoAcademico: GradoAcademico, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gradoAcademico' is not null or undefined
            assertParamExists('saveTrayectoriaAcademica', 'gradoAcademico', gradoAcademico)
            const localVarPath = `/trayectoria-academica`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gradoAcademico, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actualiza la información de formación continua de usuario
         * @param {string} id id del usuario
         * @param {Cursos} cursos 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCursosById: async (id: string, cursos: Cursos, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCursosById', 'id', id)
            // verify required parameter 'cursos' is not null or undefined
            assertParamExists('updateCursosById', 'cursos', cursos)
            const localVarPath = `/cursos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cursos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actualiza la información de idiomas de usuario
         * @param {number} id id del usuario
         * @param {Idiomas} idiomas 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIdiomasById: async (id: number, idiomas: Idiomas, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateIdiomasById', 'id', id)
            // verify required parameter 'idiomas' is not null or undefined
            assertParamExists('updateIdiomasById', 'idiomas', idiomas)
            const localVarPath = `/idiomas/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(idiomas, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actualiza un logro de usuario
         * @param {string} id id de logro
         * @param {Logro} logro 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLogrosById: async (id: string, logro: Logro, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateLogrosById', 'id', id)
            // verify required parameter 'logro' is not null or undefined
            assertParamExists('updateLogrosById', 'logro', logro)
            const localVarPath = `/logros/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logro, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actualiza un grado académico de un usuario
         * @param {string} id id de grado académico
         * @param {GradoAcademico} gradoAcademico 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTrayectoriaAcademicaById: async (id: string, gradoAcademico: GradoAcademico, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTrayectoriaAcademicaById', 'id', id)
            // verify required parameter 'gradoAcademico' is not null or undefined
            assertParamExists('updateTrayectoriaAcademicaById', 'gradoAcademico', gradoAcademico)
            const localVarPath = `/trayectoria-academica/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gradoAcademico, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EducacionApi - functional programming interface
 * @export
 */
export const EducacionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EducacionApiAxiosParamCreator(configuration)
    return {
        /**
         * Elimina un logro de usuario
         * @param {string} id id de logro
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLogrosById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLogrosById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Elimina un grado académico de un usuario
         * @param {string} id id de grado académico
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTrayectoriaAcademicaById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTrayectoriaAcademicaById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene la información de formación continua de usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCursos(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Cursos>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCursos(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene la información de formación continua de un usuario
         * @param {number} id id del usuario
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getCursosById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Cursos>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCursosById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene la información de idiomas de usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIdiomas(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Idiomas>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIdiomas(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene la información de idiomas
         * @param {number} id id del usuario
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getIdiomasById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Idiomas>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIdiomasById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene la información de logros de usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLogros(page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Logro>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLogros(page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene la información de trayectoria académica (grados) de un usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTrayectoriaAcademica(page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GradoAcademico>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTrayectoriaAcademica(page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Almacena información de formación continua de usuario
         * @param {Cursos} cursos 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveCursos(cursos: Cursos, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Cursos>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveCursos(cursos, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Almacena información de idiomas de usuario
         * @param {Idiomas} idiomas 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveIdiomas(idiomas: Idiomas, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Idiomas>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveIdiomas(idiomas, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Almacena un logro de usuario
         * @param {Logro} logro 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveLogros(logro: Logro, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveLogros(logro, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Almacena un grado académico de un usuario
         * @param {GradoAcademico} gradoAcademico 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveTrayectoriaAcademica(gradoAcademico: GradoAcademico, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveTrayectoriaAcademica(gradoAcademico, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Actualiza la información de formación continua de usuario
         * @param {string} id id del usuario
         * @param {Cursos} cursos 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCursosById(id: string, cursos: Cursos, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Cursos>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCursosById(id, cursos, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Actualiza la información de idiomas de usuario
         * @param {number} id id del usuario
         * @param {Idiomas} idiomas 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateIdiomasById(id: number, idiomas: Idiomas, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Idiomas>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateIdiomasById(id, idiomas, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Actualiza un logro de usuario
         * @param {string} id id de logro
         * @param {Logro} logro 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLogrosById(id: string, logro: Logro, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLogrosById(id, logro, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Actualiza un grado académico de un usuario
         * @param {string} id id de grado académico
         * @param {GradoAcademico} gradoAcademico 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTrayectoriaAcademicaById(id: string, gradoAcademico: GradoAcademico, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTrayectoriaAcademicaById(id, gradoAcademico, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EducacionApi - factory interface
 * @export
 */
export const EducacionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EducacionApiFp(configuration)
    return {
        /**
         * Elimina un logro de usuario
         * @param {string} id id de logro
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLogrosById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteLogrosById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Elimina un grado académico de un usuario
         * @param {string} id id de grado académico
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTrayectoriaAcademicaById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTrayectoriaAcademicaById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene la información de formación continua de usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCursos(options?: any): AxiosPromise<Cursos> {
            return localVarFp.getCursos(options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene la información de formación continua de un usuario
         * @param {number} id id del usuario
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getCursosById(id: number, options?: any): AxiosPromise<Cursos> {
            return localVarFp.getCursosById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene la información de idiomas de usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdiomas(options?: any): AxiosPromise<Idiomas> {
            return localVarFp.getIdiomas(options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene la información de idiomas
         * @param {number} id id del usuario
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getIdiomasById(id: number, options?: any): AxiosPromise<Idiomas> {
            return localVarFp.getIdiomasById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene la información de logros de usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogros(page?: number, size?: number, options?: any): AxiosPromise<Array<Logro>> {
            return localVarFp.getLogros(page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene la información de trayectoria académica (grados) de un usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrayectoriaAcademica(page?: number, size?: number, options?: any): AxiosPromise<Array<GradoAcademico>> {
            return localVarFp.getTrayectoriaAcademica(page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * Almacena información de formación continua de usuario
         * @param {Cursos} cursos 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCursos(cursos: Cursos, options?: any): AxiosPromise<Cursos> {
            return localVarFp.saveCursos(cursos, options).then((request) => request(axios, basePath));
        },
        /**
         * Almacena información de idiomas de usuario
         * @param {Idiomas} idiomas 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveIdiomas(idiomas: Idiomas, options?: any): AxiosPromise<Idiomas> {
            return localVarFp.saveIdiomas(idiomas, options).then((request) => request(axios, basePath));
        },
        /**
         * Almacena un logro de usuario
         * @param {Logro} logro 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveLogros(logro: Logro, options?: any): AxiosPromise<void> {
            return localVarFp.saveLogros(logro, options).then((request) => request(axios, basePath));
        },
        /**
         * Almacena un grado académico de un usuario
         * @param {GradoAcademico} gradoAcademico 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTrayectoriaAcademica(gradoAcademico: GradoAcademico, options?: any): AxiosPromise<void> {
            return localVarFp.saveTrayectoriaAcademica(gradoAcademico, options).then((request) => request(axios, basePath));
        },
        /**
         * Actualiza la información de formación continua de usuario
         * @param {string} id id del usuario
         * @param {Cursos} cursos 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCursosById(id: string, cursos: Cursos, options?: any): AxiosPromise<Cursos> {
            return localVarFp.updateCursosById(id, cursos, options).then((request) => request(axios, basePath));
        },
        /**
         * Actualiza la información de idiomas de usuario
         * @param {number} id id del usuario
         * @param {Idiomas} idiomas 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIdiomasById(id: number, idiomas: Idiomas, options?: any): AxiosPromise<Idiomas> {
            return localVarFp.updateIdiomasById(id, idiomas, options).then((request) => request(axios, basePath));
        },
        /**
         * Actualiza un logro de usuario
         * @param {string} id id de logro
         * @param {Logro} logro 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLogrosById(id: string, logro: Logro, options?: any): AxiosPromise<void> {
            return localVarFp.updateLogrosById(id, logro, options).then((request) => request(axios, basePath));
        },
        /**
         * Actualiza un grado académico de un usuario
         * @param {string} id id de grado académico
         * @param {GradoAcademico} gradoAcademico 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTrayectoriaAcademicaById(id: string, gradoAcademico: GradoAcademico, options?: any): AxiosPromise<void> {
            return localVarFp.updateTrayectoriaAcademicaById(id, gradoAcademico, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EducacionApi - object-oriented interface
 * @export
 * @class EducacionApi
 * @extends {BaseAPI}
 */
export class EducacionApi extends BaseAPI {
    /**
     * Elimina un logro de usuario
     * @param {string} id id de logro
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducacionApi
     */
    public deleteLogrosById(id: string, options?: AxiosRequestConfig) {
        return EducacionApiFp(this.configuration).deleteLogrosById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Elimina un grado académico de un usuario
     * @param {string} id id de grado académico
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducacionApi
     */
    public deleteTrayectoriaAcademicaById(id: string, options?: AxiosRequestConfig) {
        return EducacionApiFp(this.configuration).deleteTrayectoriaAcademicaById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene la información de formación continua de usuario
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducacionApi
     */
    public getCursos(options?: AxiosRequestConfig) {
        return EducacionApiFp(this.configuration).getCursos(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene la información de formación continua de un usuario
     * @param {number} id id del usuario
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof EducacionApi
     */
    public getCursosById(id: number, options?: AxiosRequestConfig) {
        return EducacionApiFp(this.configuration).getCursosById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene la información de idiomas de usuario
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducacionApi
     */
    public getIdiomas(options?: AxiosRequestConfig) {
        return EducacionApiFp(this.configuration).getIdiomas(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene la información de idiomas
     * @param {number} id id del usuario
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof EducacionApi
     */
    public getIdiomasById(id: number, options?: AxiosRequestConfig) {
        return EducacionApiFp(this.configuration).getIdiomasById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene la información de logros de usuario
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducacionApi
     */
    public getLogros(page?: number, size?: number, options?: AxiosRequestConfig) {
        return EducacionApiFp(this.configuration).getLogros(page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene la información de trayectoria académica (grados) de un usuario
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducacionApi
     */
    public getTrayectoriaAcademica(page?: number, size?: number, options?: AxiosRequestConfig) {
        return EducacionApiFp(this.configuration).getTrayectoriaAcademica(page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Almacena información de formación continua de usuario
     * @param {Cursos} cursos 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducacionApi
     */
    public saveCursos(cursos: Cursos, options?: AxiosRequestConfig) {
        return EducacionApiFp(this.configuration).saveCursos(cursos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Almacena información de idiomas de usuario
     * @param {Idiomas} idiomas 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducacionApi
     */
    public saveIdiomas(idiomas: Idiomas, options?: AxiosRequestConfig) {
        return EducacionApiFp(this.configuration).saveIdiomas(idiomas, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Almacena un logro de usuario
     * @param {Logro} logro 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducacionApi
     */
    public saveLogros(logro: Logro, options?: AxiosRequestConfig) {
        return EducacionApiFp(this.configuration).saveLogros(logro, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Almacena un grado académico de un usuario
     * @param {GradoAcademico} gradoAcademico 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducacionApi
     */
    public saveTrayectoriaAcademica(gradoAcademico: GradoAcademico, options?: AxiosRequestConfig) {
        return EducacionApiFp(this.configuration).saveTrayectoriaAcademica(gradoAcademico, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actualiza la información de formación continua de usuario
     * @param {string} id id del usuario
     * @param {Cursos} cursos 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducacionApi
     */
    public updateCursosById(id: string, cursos: Cursos, options?: AxiosRequestConfig) {
        return EducacionApiFp(this.configuration).updateCursosById(id, cursos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actualiza la información de idiomas de usuario
     * @param {number} id id del usuario
     * @param {Idiomas} idiomas 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducacionApi
     */
    public updateIdiomasById(id: number, idiomas: Idiomas, options?: AxiosRequestConfig) {
        return EducacionApiFp(this.configuration).updateIdiomasById(id, idiomas, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actualiza un logro de usuario
     * @param {string} id id de logro
     * @param {Logro} logro 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducacionApi
     */
    public updateLogrosById(id: string, logro: Logro, options?: AxiosRequestConfig) {
        return EducacionApiFp(this.configuration).updateLogrosById(id, logro, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actualiza un grado académico de un usuario
     * @param {string} id id de grado académico
     * @param {GradoAcademico} gradoAcademico 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducacionApi
     */
    public updateTrayectoriaAcademicaById(id: string, gradoAcademico: GradoAcademico, options?: AxiosRequestConfig) {
        return EducacionApiFp(this.configuration).updateTrayectoriaAcademicaById(id, gradoAcademico, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ExperienciaProfesionalApi - axios parameter creator
 * @export
 */
export const ExperienciaProfesionalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Elimina un congreso de usuario
         * @param {string} id id de congreso
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCongresosById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCongresosById', 'id', id)
            const localVarPath = `/congresos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Elimina un curso impartido de usuario
         * @param {string} id id de curso impartido
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCursosImpartidosById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCursosImpartidosById', 'id', id)
            const localVarPath = `/cursos-impartidos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Elimina un diplomado de usuario
         * @param {string} id id de diplomado
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDiplomadosById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteDiplomadosById', 'id', id)
            const localVarPath = `/diplomados/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Elimina un trabajo de divulgación de usuario
         * @param {string} id id de trabajo de divulgación
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDivulgacionById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteDivulgacionById', 'id', id)
            const localVarPath = `/divulgacion/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Elimina una estancia de usuario
         * @param {string} id id de estancia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEstanciasById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteEstanciasById', 'id', id)
            const localVarPath = `/estancias/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Elimina una evaluación otorgada de usuario
         * @param {string} id id de evaluación
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEvaluacionesOtorgadasById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteEvaluacionesOtorgadasById', 'id', id)
            const localVarPath = `/evaluaciones-otorgadas/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Elimina una tesis dirigida de usuario
         * @param {string} id id de tesis
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTesisById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTesisById', 'id', id)
            const localVarPath = `/tesis/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Elimina un empleo de usuario
         * @param {string} id id de empleo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTrayectoriaProfesionalById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTrayectoriaProfesionalById', 'id', id)
            const localVarPath = `/trayectoria-profesional/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene la información de Congresos de usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCongresos: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/congresos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene el documento de las participaciones en congresos
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCongresosDocumento: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/congresos-documento`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene el documento probatorio de los cursos
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCursoDocumento: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cursos-documento`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene la información de cursos impartidos de usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCursosImpartidos: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cursos-impartidos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene la información de Diplomados de usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDiplomados: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/diplomados`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene el documento probatorio de los diplomados
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDiplomadosDocumento: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/diplomados-documento`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene la información de Divulgacion de usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDivulgacion: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/divulgacion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene el documento de las divulgaciones
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDivulgacionDocumento: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/divulgacion-documento`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene la información del empleo actual de un usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmpleoActual: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trayectoria-profesional/empleo-actual`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene el empleo principal de usuario dado un cvu
         * @param {string} cvu cvu del usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmpleoPrincipal: async (cvu: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cvu' is not null or undefined
            assertParamExists('getEmpleoPrincipal', 'cvu', cvu)
            const localVarPath = `/perfil/{cvu}/trayectoria-profesional/principal`
                .replace(`{${"cvu"}}`, encodeURIComponent(String(cvu)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene la información de estancias de usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEstancias: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/estancias`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene la información de evaluaciones otorgadas de usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvaluacionesOtorgadas: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/evaluaciones-otorgadas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene la información de Tesis dirigidas de usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTesis: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tesis`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene el documento de las tesis
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTesisDocumento: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tesis-documento`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene la información de trayectoria profesional (empleos) de usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrayectoriaProfesional: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trayectoria-profesional`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Almacena un documento de congresos de usuario
         * @param {DocumentoProbatorio} documentoProbatorio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCongresoDocumento: async (documentoProbatorio: DocumentoProbatorio, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentoProbatorio' is not null or undefined
            assertParamExists('saveCongresoDocumento', 'documentoProbatorio', documentoProbatorio)
            const localVarPath = `/congresos-documento`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentoProbatorio, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Almacena un congreso de usuario
         * @param {Congreso} congreso 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCongresos: async (congreso: Congreso, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'congreso' is not null or undefined
            assertParamExists('saveCongresos', 'congreso', congreso)
            const localVarPath = `/congresos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(congreso, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Almacena un documento de los cursos de un usuario
         * @param {DocumentoProbatorio} documentoProbatorio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCursoDocumento: async (documentoProbatorio: DocumentoProbatorio, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentoProbatorio' is not null or undefined
            assertParamExists('saveCursoDocumento', 'documentoProbatorio', documentoProbatorio)
            const localVarPath = `/cursos-documento`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentoProbatorio, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Almacena un curso impartido de usuario
         * @param {CursoImpartido} cursoImpartido 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCursosImpartidos: async (cursoImpartido: CursoImpartido, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cursoImpartido' is not null or undefined
            assertParamExists('saveCursosImpartidos', 'cursoImpartido', cursoImpartido)
            const localVarPath = `/cursos-impartidos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cursoImpartido, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Almacena un diplomado de usuario
         * @param {Diplomado} diplomado 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDiplomados: async (diplomado: Diplomado, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'diplomado' is not null or undefined
            assertParamExists('saveDiplomados', 'diplomado', diplomado)
            const localVarPath = `/diplomados`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(diplomado, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Almacena un documento de los diplomados de un usuario
         * @param {DocumentoProbatorio} documentoProbatorio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDiplomadosDocumento: async (documentoProbatorio: DocumentoProbatorio, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentoProbatorio' is not null or undefined
            assertParamExists('saveDiplomadosDocumento', 'documentoProbatorio', documentoProbatorio)
            const localVarPath = `/diplomados-documento`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentoProbatorio, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Almacena un trabajo de divulgacion de usuario
         * @param {TrabajoDivulgacion} trabajoDivulgacion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDivulgacion: async (trabajoDivulgacion: TrabajoDivulgacion, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'trabajoDivulgacion' is not null or undefined
            assertParamExists('saveDivulgacion', 'trabajoDivulgacion', trabajoDivulgacion)
            const localVarPath = `/divulgacion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trabajoDivulgacion, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Almacena un documento de divulgaciones de usuario
         * @param {DocumentoProbatorio} documentoProbatorio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDivulgacionDocumento: async (documentoProbatorio: DocumentoProbatorio, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentoProbatorio' is not null or undefined
            assertParamExists('saveDivulgacionDocumento', 'documentoProbatorio', documentoProbatorio)
            const localVarPath = `/divulgacion-documento`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentoProbatorio, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Almacena una estancia de usuario
         * @param {Estancia} estancia 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveEstancias: async (estancia: Estancia, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'estancia' is not null or undefined
            assertParamExists('saveEstancias', 'estancia', estancia)
            const localVarPath = `/estancias`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(estancia, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Almacena una evaluación otorgada de usuario
         * @param {Evaluacion} evaluacion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveEvaluacionesOtorgadas: async (evaluacion: Evaluacion, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'evaluacion' is not null or undefined
            assertParamExists('saveEvaluacionesOtorgadas', 'evaluacion', evaluacion)
            const localVarPath = `/evaluaciones-otorgadas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(evaluacion, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Almacena una tesis dirigida de usuario
         * @param {TesisDirigida} tesisDirigida 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTesis: async (tesisDirigida: TesisDirigida, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tesisDirigida' is not null or undefined
            assertParamExists('saveTesis', 'tesisDirigida', tesisDirigida)
            const localVarPath = `/tesis`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tesisDirigida, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Almacena un documento tesis dirigida de usuario
         * @param {DocumentoProbatorio} documentoProbatorio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTesisDocumento: async (documentoProbatorio: DocumentoProbatorio, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentoProbatorio' is not null or undefined
            assertParamExists('saveTesisDocumento', 'documentoProbatorio', documentoProbatorio)
            const localVarPath = `/tesis-documento`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentoProbatorio, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Almacena un empleo de usuario
         * @param {Empleo} empleo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTrayectoriaProfesional: async (empleo: Empleo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'empleo' is not null or undefined
            assertParamExists('saveTrayectoriaProfesional', 'empleo', empleo)
            const localVarPath = `/trayectoria-profesional`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(empleo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actualiza un congreso de usuario
         * @param {string} id id de congreso
         * @param {Congreso} congreso 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCongresosById: async (id: string, congreso: Congreso, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCongresosById', 'id', id)
            // verify required parameter 'congreso' is not null or undefined
            assertParamExists('updateCongresosById', 'congreso', congreso)
            const localVarPath = `/congresos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(congreso, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actualiza un curso impartido de usuario
         * @param {string} id id de curso impartido
         * @param {CursoImpartido} cursoImpartido 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCursosImpartidosById: async (id: string, cursoImpartido: CursoImpartido, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCursosImpartidosById', 'id', id)
            // verify required parameter 'cursoImpartido' is not null or undefined
            assertParamExists('updateCursosImpartidosById', 'cursoImpartido', cursoImpartido)
            const localVarPath = `/cursos-impartidos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cursoImpartido, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actualiza un diplomado de usuario
         * @param {string} id id de diplomado
         * @param {Diplomado} diplomado 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDiplomadosById: async (id: string, diplomado: Diplomado, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateDiplomadosById', 'id', id)
            // verify required parameter 'diplomado' is not null or undefined
            assertParamExists('updateDiplomadosById', 'diplomado', diplomado)
            const localVarPath = `/diplomados/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(diplomado, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actualiza un trabajo de divulgación de usuario
         * @param {string} id id de trabajo de divulgación
         * @param {TrabajoDivulgacion} trabajoDivulgacion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDivulgacionById: async (id: string, trabajoDivulgacion: TrabajoDivulgacion, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateDivulgacionById', 'id', id)
            // verify required parameter 'trabajoDivulgacion' is not null or undefined
            assertParamExists('updateDivulgacionById', 'trabajoDivulgacion', trabajoDivulgacion)
            const localVarPath = `/divulgacion/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trabajoDivulgacion, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actualiza una estancia de usuario
         * @param {string} id id de estancia
         * @param {Estancia} estancia 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEstanciasById: async (id: string, estancia: Estancia, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateEstanciasById', 'id', id)
            // verify required parameter 'estancia' is not null or undefined
            assertParamExists('updateEstanciasById', 'estancia', estancia)
            const localVarPath = `/estancias/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(estancia, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actualiza una evaluación otorgada de usuario
         * @param {string} id id de evaluación
         * @param {Evaluacion} evaluacion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEvaluacionesOtorgadasById: async (id: string, evaluacion: Evaluacion, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateEvaluacionesOtorgadasById', 'id', id)
            // verify required parameter 'evaluacion' is not null or undefined
            assertParamExists('updateEvaluacionesOtorgadasById', 'evaluacion', evaluacion)
            const localVarPath = `/evaluaciones-otorgadas/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(evaluacion, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actualiza una tesis dirigida de usuario
         * @param {string} id id de tesis
         * @param {TesisDirigida} tesisDirigida 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTesisById: async (id: string, tesisDirigida: TesisDirigida, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTesisById', 'id', id)
            // verify required parameter 'tesisDirigida' is not null or undefined
            assertParamExists('updateTesisById', 'tesisDirigida', tesisDirigida)
            const localVarPath = `/tesis/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tesisDirigida, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actualiza un empleo de usuario
         * @param {string} id id de empleo
         * @param {Empleo} empleo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTrayectoriaProfesionalById: async (id: string, empleo: Empleo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTrayectoriaProfesionalById', 'id', id)
            // verify required parameter 'empleo' is not null or undefined
            assertParamExists('updateTrayectoriaProfesionalById', 'empleo', empleo)
            const localVarPath = `/trayectoria-profesional/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(empleo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExperienciaProfesionalApi - functional programming interface
 * @export
 */
export const ExperienciaProfesionalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExperienciaProfesionalApiAxiosParamCreator(configuration)
    return {
        /**
         * Elimina un congreso de usuario
         * @param {string} id id de congreso
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCongresosById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCongresosById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Elimina un curso impartido de usuario
         * @param {string} id id de curso impartido
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCursosImpartidosById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCursosImpartidosById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Elimina un diplomado de usuario
         * @param {string} id id de diplomado
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDiplomadosById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDiplomadosById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Elimina un trabajo de divulgación de usuario
         * @param {string} id id de trabajo de divulgación
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDivulgacionById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDivulgacionById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Elimina una estancia de usuario
         * @param {string} id id de estancia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEstanciasById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEstanciasById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Elimina una evaluación otorgada de usuario
         * @param {string} id id de evaluación
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEvaluacionesOtorgadasById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEvaluacionesOtorgadasById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Elimina una tesis dirigida de usuario
         * @param {string} id id de tesis
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTesisById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTesisById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Elimina un empleo de usuario
         * @param {string} id id de empleo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTrayectoriaProfesionalById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTrayectoriaProfesionalById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene la información de Congresos de usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCongresos(page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Congreso>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCongresos(page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene el documento de las participaciones en congresos
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCongresosDocumento(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentoProbatorio>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCongresosDocumento(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene el documento probatorio de los cursos
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCursoDocumento(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentoProbatorio>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCursoDocumento(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene la información de cursos impartidos de usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCursosImpartidos(page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CursoImpartido>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCursosImpartidos(page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene la información de Diplomados de usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDiplomados(page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Diplomado>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDiplomados(page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene el documento probatorio de los diplomados
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDiplomadosDocumento(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentoProbatorio>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDiplomadosDocumento(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene la información de Divulgacion de usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDivulgacion(page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TrabajoDivulgacion>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDivulgacion(page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene el documento de las divulgaciones
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDivulgacionDocumento(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentoProbatorio>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDivulgacionDocumento(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene la información del empleo actual de un usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmpleoActual(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Empleo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmpleoActual(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene el empleo principal de usuario dado un cvu
         * @param {string} cvu cvu del usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmpleoPrincipal(cvu: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Empleo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmpleoPrincipal(cvu, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene la información de estancias de usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEstancias(page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Estancia>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEstancias(page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene la información de evaluaciones otorgadas de usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEvaluacionesOtorgadas(page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Evaluacion>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEvaluacionesOtorgadas(page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene la información de Tesis dirigidas de usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTesis(page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TesisDirigida>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTesis(page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene el documento de las tesis
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTesisDocumento(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentoProbatorio>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTesisDocumento(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene la información de trayectoria profesional (empleos) de usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTrayectoriaProfesional(page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Empleo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTrayectoriaProfesional(page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Almacena un documento de congresos de usuario
         * @param {DocumentoProbatorio} documentoProbatorio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveCongresoDocumento(documentoProbatorio: DocumentoProbatorio, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentoProbatorio>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveCongresoDocumento(documentoProbatorio, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Almacena un congreso de usuario
         * @param {Congreso} congreso 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveCongresos(congreso: Congreso, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveCongresos(congreso, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Almacena un documento de los cursos de un usuario
         * @param {DocumentoProbatorio} documentoProbatorio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveCursoDocumento(documentoProbatorio: DocumentoProbatorio, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentoProbatorio>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveCursoDocumento(documentoProbatorio, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Almacena un curso impartido de usuario
         * @param {CursoImpartido} cursoImpartido 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveCursosImpartidos(cursoImpartido: CursoImpartido, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveCursosImpartidos(cursoImpartido, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Almacena un diplomado de usuario
         * @param {Diplomado} diplomado 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveDiplomados(diplomado: Diplomado, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveDiplomados(diplomado, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Almacena un documento de los diplomados de un usuario
         * @param {DocumentoProbatorio} documentoProbatorio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveDiplomadosDocumento(documentoProbatorio: DocumentoProbatorio, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentoProbatorio>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveDiplomadosDocumento(documentoProbatorio, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Almacena un trabajo de divulgacion de usuario
         * @param {TrabajoDivulgacion} trabajoDivulgacion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveDivulgacion(trabajoDivulgacion: TrabajoDivulgacion, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveDivulgacion(trabajoDivulgacion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Almacena un documento de divulgaciones de usuario
         * @param {DocumentoProbatorio} documentoProbatorio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveDivulgacionDocumento(documentoProbatorio: DocumentoProbatorio, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentoProbatorio>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveDivulgacionDocumento(documentoProbatorio, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Almacena una estancia de usuario
         * @param {Estancia} estancia 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveEstancias(estancia: Estancia, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveEstancias(estancia, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Almacena una evaluación otorgada de usuario
         * @param {Evaluacion} evaluacion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveEvaluacionesOtorgadas(evaluacion: Evaluacion, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveEvaluacionesOtorgadas(evaluacion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Almacena una tesis dirigida de usuario
         * @param {TesisDirigida} tesisDirigida 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveTesis(tesisDirigida: TesisDirigida, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveTesis(tesisDirigida, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Almacena un documento tesis dirigida de usuario
         * @param {DocumentoProbatorio} documentoProbatorio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveTesisDocumento(documentoProbatorio: DocumentoProbatorio, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentoProbatorio>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveTesisDocumento(documentoProbatorio, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Almacena un empleo de usuario
         * @param {Empleo} empleo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveTrayectoriaProfesional(empleo: Empleo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveTrayectoriaProfesional(empleo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Actualiza un congreso de usuario
         * @param {string} id id de congreso
         * @param {Congreso} congreso 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCongresosById(id: string, congreso: Congreso, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCongresosById(id, congreso, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Actualiza un curso impartido de usuario
         * @param {string} id id de curso impartido
         * @param {CursoImpartido} cursoImpartido 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCursosImpartidosById(id: string, cursoImpartido: CursoImpartido, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCursosImpartidosById(id, cursoImpartido, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Actualiza un diplomado de usuario
         * @param {string} id id de diplomado
         * @param {Diplomado} diplomado 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDiplomadosById(id: string, diplomado: Diplomado, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDiplomadosById(id, diplomado, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Actualiza un trabajo de divulgación de usuario
         * @param {string} id id de trabajo de divulgación
         * @param {TrabajoDivulgacion} trabajoDivulgacion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDivulgacionById(id: string, trabajoDivulgacion: TrabajoDivulgacion, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDivulgacionById(id, trabajoDivulgacion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Actualiza una estancia de usuario
         * @param {string} id id de estancia
         * @param {Estancia} estancia 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEstanciasById(id: string, estancia: Estancia, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEstanciasById(id, estancia, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Actualiza una evaluación otorgada de usuario
         * @param {string} id id de evaluación
         * @param {Evaluacion} evaluacion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEvaluacionesOtorgadasById(id: string, evaluacion: Evaluacion, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEvaluacionesOtorgadasById(id, evaluacion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Actualiza una tesis dirigida de usuario
         * @param {string} id id de tesis
         * @param {TesisDirigida} tesisDirigida 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTesisById(id: string, tesisDirigida: TesisDirigida, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTesisById(id, tesisDirigida, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Actualiza un empleo de usuario
         * @param {string} id id de empleo
         * @param {Empleo} empleo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTrayectoriaProfesionalById(id: string, empleo: Empleo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTrayectoriaProfesionalById(id, empleo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExperienciaProfesionalApi - factory interface
 * @export
 */
export const ExperienciaProfesionalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExperienciaProfesionalApiFp(configuration)
    return {
        /**
         * Elimina un congreso de usuario
         * @param {string} id id de congreso
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCongresosById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCongresosById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Elimina un curso impartido de usuario
         * @param {string} id id de curso impartido
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCursosImpartidosById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCursosImpartidosById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Elimina un diplomado de usuario
         * @param {string} id id de diplomado
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDiplomadosById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDiplomadosById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Elimina un trabajo de divulgación de usuario
         * @param {string} id id de trabajo de divulgación
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDivulgacionById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDivulgacionById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Elimina una estancia de usuario
         * @param {string} id id de estancia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEstanciasById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteEstanciasById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Elimina una evaluación otorgada de usuario
         * @param {string} id id de evaluación
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEvaluacionesOtorgadasById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteEvaluacionesOtorgadasById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Elimina una tesis dirigida de usuario
         * @param {string} id id de tesis
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTesisById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTesisById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Elimina un empleo de usuario
         * @param {string} id id de empleo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTrayectoriaProfesionalById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTrayectoriaProfesionalById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene la información de Congresos de usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCongresos(page?: number, size?: number, options?: any): AxiosPromise<Array<Congreso>> {
            return localVarFp.getCongresos(page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene el documento de las participaciones en congresos
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCongresosDocumento(options?: any): AxiosPromise<DocumentoProbatorio> {
            return localVarFp.getCongresosDocumento(options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene el documento probatorio de los cursos
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCursoDocumento(options?: any): AxiosPromise<DocumentoProbatorio> {
            return localVarFp.getCursoDocumento(options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene la información de cursos impartidos de usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCursosImpartidos(page?: number, size?: number, options?: any): AxiosPromise<Array<CursoImpartido>> {
            return localVarFp.getCursosImpartidos(page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene la información de Diplomados de usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDiplomados(page?: number, size?: number, options?: any): AxiosPromise<Array<Diplomado>> {
            return localVarFp.getDiplomados(page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene el documento probatorio de los diplomados
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDiplomadosDocumento(options?: any): AxiosPromise<DocumentoProbatorio> {
            return localVarFp.getDiplomadosDocumento(options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene la información de Divulgacion de usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDivulgacion(page?: number, size?: number, options?: any): AxiosPromise<Array<TrabajoDivulgacion>> {
            return localVarFp.getDivulgacion(page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene el documento de las divulgaciones
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDivulgacionDocumento(options?: any): AxiosPromise<DocumentoProbatorio> {
            return localVarFp.getDivulgacionDocumento(options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene la información del empleo actual de un usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmpleoActual(options?: any): AxiosPromise<Array<Empleo>> {
            return localVarFp.getEmpleoActual(options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene el empleo principal de usuario dado un cvu
         * @param {string} cvu cvu del usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmpleoPrincipal(cvu: string, options?: any): AxiosPromise<Empleo> {
            return localVarFp.getEmpleoPrincipal(cvu, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene la información de estancias de usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEstancias(page?: number, size?: number, options?: any): AxiosPromise<Array<Estancia>> {
            return localVarFp.getEstancias(page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene la información de evaluaciones otorgadas de usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvaluacionesOtorgadas(page?: number, size?: number, options?: any): AxiosPromise<Array<Evaluacion>> {
            return localVarFp.getEvaluacionesOtorgadas(page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene la información de Tesis dirigidas de usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTesis(page?: number, size?: number, options?: any): AxiosPromise<Array<TesisDirigida>> {
            return localVarFp.getTesis(page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene el documento de las tesis
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTesisDocumento(options?: any): AxiosPromise<DocumentoProbatorio> {
            return localVarFp.getTesisDocumento(options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene la información de trayectoria profesional (empleos) de usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrayectoriaProfesional(page?: number, size?: number, options?: any): AxiosPromise<Array<Empleo>> {
            return localVarFp.getTrayectoriaProfesional(page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * Almacena un documento de congresos de usuario
         * @param {DocumentoProbatorio} documentoProbatorio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCongresoDocumento(documentoProbatorio: DocumentoProbatorio, options?: any): AxiosPromise<DocumentoProbatorio> {
            return localVarFp.saveCongresoDocumento(documentoProbatorio, options).then((request) => request(axios, basePath));
        },
        /**
         * Almacena un congreso de usuario
         * @param {Congreso} congreso 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCongresos(congreso: Congreso, options?: any): AxiosPromise<void> {
            return localVarFp.saveCongresos(congreso, options).then((request) => request(axios, basePath));
        },
        /**
         * Almacena un documento de los cursos de un usuario
         * @param {DocumentoProbatorio} documentoProbatorio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCursoDocumento(documentoProbatorio: DocumentoProbatorio, options?: any): AxiosPromise<DocumentoProbatorio> {
            return localVarFp.saveCursoDocumento(documentoProbatorio, options).then((request) => request(axios, basePath));
        },
        /**
         * Almacena un curso impartido de usuario
         * @param {CursoImpartido} cursoImpartido 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCursosImpartidos(cursoImpartido: CursoImpartido, options?: any): AxiosPromise<void> {
            return localVarFp.saveCursosImpartidos(cursoImpartido, options).then((request) => request(axios, basePath));
        },
        /**
         * Almacena un diplomado de usuario
         * @param {Diplomado} diplomado 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDiplomados(diplomado: Diplomado, options?: any): AxiosPromise<void> {
            return localVarFp.saveDiplomados(diplomado, options).then((request) => request(axios, basePath));
        },
        /**
         * Almacena un documento de los diplomados de un usuario
         * @param {DocumentoProbatorio} documentoProbatorio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDiplomadosDocumento(documentoProbatorio: DocumentoProbatorio, options?: any): AxiosPromise<DocumentoProbatorio> {
            return localVarFp.saveDiplomadosDocumento(documentoProbatorio, options).then((request) => request(axios, basePath));
        },
        /**
         * Almacena un trabajo de divulgacion de usuario
         * @param {TrabajoDivulgacion} trabajoDivulgacion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDivulgacion(trabajoDivulgacion: TrabajoDivulgacion, options?: any): AxiosPromise<void> {
            return localVarFp.saveDivulgacion(trabajoDivulgacion, options).then((request) => request(axios, basePath));
        },
        /**
         * Almacena un documento de divulgaciones de usuario
         * @param {DocumentoProbatorio} documentoProbatorio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDivulgacionDocumento(documentoProbatorio: DocumentoProbatorio, options?: any): AxiosPromise<DocumentoProbatorio> {
            return localVarFp.saveDivulgacionDocumento(documentoProbatorio, options).then((request) => request(axios, basePath));
        },
        /**
         * Almacena una estancia de usuario
         * @param {Estancia} estancia 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveEstancias(estancia: Estancia, options?: any): AxiosPromise<void> {
            return localVarFp.saveEstancias(estancia, options).then((request) => request(axios, basePath));
        },
        /**
         * Almacena una evaluación otorgada de usuario
         * @param {Evaluacion} evaluacion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveEvaluacionesOtorgadas(evaluacion: Evaluacion, options?: any): AxiosPromise<void> {
            return localVarFp.saveEvaluacionesOtorgadas(evaluacion, options).then((request) => request(axios, basePath));
        },
        /**
         * Almacena una tesis dirigida de usuario
         * @param {TesisDirigida} tesisDirigida 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTesis(tesisDirigida: TesisDirigida, options?: any): AxiosPromise<void> {
            return localVarFp.saveTesis(tesisDirigida, options).then((request) => request(axios, basePath));
        },
        /**
         * Almacena un documento tesis dirigida de usuario
         * @param {DocumentoProbatorio} documentoProbatorio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTesisDocumento(documentoProbatorio: DocumentoProbatorio, options?: any): AxiosPromise<DocumentoProbatorio> {
            return localVarFp.saveTesisDocumento(documentoProbatorio, options).then((request) => request(axios, basePath));
        },
        /**
         * Almacena un empleo de usuario
         * @param {Empleo} empleo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTrayectoriaProfesional(empleo: Empleo, options?: any): AxiosPromise<void> {
            return localVarFp.saveTrayectoriaProfesional(empleo, options).then((request) => request(axios, basePath));
        },
        /**
         * Actualiza un congreso de usuario
         * @param {string} id id de congreso
         * @param {Congreso} congreso 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCongresosById(id: string, congreso: Congreso, options?: any): AxiosPromise<void> {
            return localVarFp.updateCongresosById(id, congreso, options).then((request) => request(axios, basePath));
        },
        /**
         * Actualiza un curso impartido de usuario
         * @param {string} id id de curso impartido
         * @param {CursoImpartido} cursoImpartido 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCursosImpartidosById(id: string, cursoImpartido: CursoImpartido, options?: any): AxiosPromise<void> {
            return localVarFp.updateCursosImpartidosById(id, cursoImpartido, options).then((request) => request(axios, basePath));
        },
        /**
         * Actualiza un diplomado de usuario
         * @param {string} id id de diplomado
         * @param {Diplomado} diplomado 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDiplomadosById(id: string, diplomado: Diplomado, options?: any): AxiosPromise<void> {
            return localVarFp.updateDiplomadosById(id, diplomado, options).then((request) => request(axios, basePath));
        },
        /**
         * Actualiza un trabajo de divulgación de usuario
         * @param {string} id id de trabajo de divulgación
         * @param {TrabajoDivulgacion} trabajoDivulgacion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDivulgacionById(id: string, trabajoDivulgacion: TrabajoDivulgacion, options?: any): AxiosPromise<void> {
            return localVarFp.updateDivulgacionById(id, trabajoDivulgacion, options).then((request) => request(axios, basePath));
        },
        /**
         * Actualiza una estancia de usuario
         * @param {string} id id de estancia
         * @param {Estancia} estancia 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEstanciasById(id: string, estancia: Estancia, options?: any): AxiosPromise<void> {
            return localVarFp.updateEstanciasById(id, estancia, options).then((request) => request(axios, basePath));
        },
        /**
         * Actualiza una evaluación otorgada de usuario
         * @param {string} id id de evaluación
         * @param {Evaluacion} evaluacion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEvaluacionesOtorgadasById(id: string, evaluacion: Evaluacion, options?: any): AxiosPromise<void> {
            return localVarFp.updateEvaluacionesOtorgadasById(id, evaluacion, options).then((request) => request(axios, basePath));
        },
        /**
         * Actualiza una tesis dirigida de usuario
         * @param {string} id id de tesis
         * @param {TesisDirigida} tesisDirigida 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTesisById(id: string, tesisDirigida: TesisDirigida, options?: any): AxiosPromise<void> {
            return localVarFp.updateTesisById(id, tesisDirigida, options).then((request) => request(axios, basePath));
        },
        /**
         * Actualiza un empleo de usuario
         * @param {string} id id de empleo
         * @param {Empleo} empleo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTrayectoriaProfesionalById(id: string, empleo: Empleo, options?: any): AxiosPromise<void> {
            return localVarFp.updateTrayectoriaProfesionalById(id, empleo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExperienciaProfesionalApi - object-oriented interface
 * @export
 * @class ExperienciaProfesionalApi
 * @extends {BaseAPI}
 */
export class ExperienciaProfesionalApi extends BaseAPI {
    /**
     * Elimina un congreso de usuario
     * @param {string} id id de congreso
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperienciaProfesionalApi
     */
    public deleteCongresosById(id: string, options?: AxiosRequestConfig) {
        return ExperienciaProfesionalApiFp(this.configuration).deleteCongresosById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Elimina un curso impartido de usuario
     * @param {string} id id de curso impartido
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperienciaProfesionalApi
     */
    public deleteCursosImpartidosById(id: string, options?: AxiosRequestConfig) {
        return ExperienciaProfesionalApiFp(this.configuration).deleteCursosImpartidosById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Elimina un diplomado de usuario
     * @param {string} id id de diplomado
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperienciaProfesionalApi
     */
    public deleteDiplomadosById(id: string, options?: AxiosRequestConfig) {
        return ExperienciaProfesionalApiFp(this.configuration).deleteDiplomadosById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Elimina un trabajo de divulgación de usuario
     * @param {string} id id de trabajo de divulgación
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperienciaProfesionalApi
     */
    public deleteDivulgacionById(id: string, options?: AxiosRequestConfig) {
        return ExperienciaProfesionalApiFp(this.configuration).deleteDivulgacionById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Elimina una estancia de usuario
     * @param {string} id id de estancia
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperienciaProfesionalApi
     */
    public deleteEstanciasById(id: string, options?: AxiosRequestConfig) {
        return ExperienciaProfesionalApiFp(this.configuration).deleteEstanciasById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Elimina una evaluación otorgada de usuario
     * @param {string} id id de evaluación
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperienciaProfesionalApi
     */
    public deleteEvaluacionesOtorgadasById(id: string, options?: AxiosRequestConfig) {
        return ExperienciaProfesionalApiFp(this.configuration).deleteEvaluacionesOtorgadasById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Elimina una tesis dirigida de usuario
     * @param {string} id id de tesis
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperienciaProfesionalApi
     */
    public deleteTesisById(id: string, options?: AxiosRequestConfig) {
        return ExperienciaProfesionalApiFp(this.configuration).deleteTesisById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Elimina un empleo de usuario
     * @param {string} id id de empleo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperienciaProfesionalApi
     */
    public deleteTrayectoriaProfesionalById(id: string, options?: AxiosRequestConfig) {
        return ExperienciaProfesionalApiFp(this.configuration).deleteTrayectoriaProfesionalById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene la información de Congresos de usuario
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperienciaProfesionalApi
     */
    public getCongresos(page?: number, size?: number, options?: AxiosRequestConfig) {
        return ExperienciaProfesionalApiFp(this.configuration).getCongresos(page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene el documento de las participaciones en congresos
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperienciaProfesionalApi
     */
    public getCongresosDocumento(options?: AxiosRequestConfig) {
        return ExperienciaProfesionalApiFp(this.configuration).getCongresosDocumento(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene el documento probatorio de los cursos
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperienciaProfesionalApi
     */
    public getCursoDocumento(options?: AxiosRequestConfig) {
        return ExperienciaProfesionalApiFp(this.configuration).getCursoDocumento(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene la información de cursos impartidos de usuario
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperienciaProfesionalApi
     */
    public getCursosImpartidos(page?: number, size?: number, options?: AxiosRequestConfig) {
        return ExperienciaProfesionalApiFp(this.configuration).getCursosImpartidos(page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene la información de Diplomados de usuario
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperienciaProfesionalApi
     */
    public getDiplomados(page?: number, size?: number, options?: AxiosRequestConfig) {
        return ExperienciaProfesionalApiFp(this.configuration).getDiplomados(page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene el documento probatorio de los diplomados
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperienciaProfesionalApi
     */
    public getDiplomadosDocumento(options?: AxiosRequestConfig) {
        return ExperienciaProfesionalApiFp(this.configuration).getDiplomadosDocumento(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene la información de Divulgacion de usuario
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperienciaProfesionalApi
     */
    public getDivulgacion(page?: number, size?: number, options?: AxiosRequestConfig) {
        return ExperienciaProfesionalApiFp(this.configuration).getDivulgacion(page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene el documento de las divulgaciones
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperienciaProfesionalApi
     */
    public getDivulgacionDocumento(options?: AxiosRequestConfig) {
        return ExperienciaProfesionalApiFp(this.configuration).getDivulgacionDocumento(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene la información del empleo actual de un usuario
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperienciaProfesionalApi
     */
    public getEmpleoActual(options?: AxiosRequestConfig) {
        return ExperienciaProfesionalApiFp(this.configuration).getEmpleoActual(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene el empleo principal de usuario dado un cvu
     * @param {string} cvu cvu del usuario
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperienciaProfesionalApi
     */
    public getEmpleoPrincipal(cvu: string, options?: AxiosRequestConfig) {
        return ExperienciaProfesionalApiFp(this.configuration).getEmpleoPrincipal(cvu, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene la información de estancias de usuario
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperienciaProfesionalApi
     */
    public getEstancias(page?: number, size?: number, options?: AxiosRequestConfig) {
        return ExperienciaProfesionalApiFp(this.configuration).getEstancias(page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene la información de evaluaciones otorgadas de usuario
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperienciaProfesionalApi
     */
    public getEvaluacionesOtorgadas(page?: number, size?: number, options?: AxiosRequestConfig) {
        return ExperienciaProfesionalApiFp(this.configuration).getEvaluacionesOtorgadas(page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene la información de Tesis dirigidas de usuario
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperienciaProfesionalApi
     */
    public getTesis(page?: number, size?: number, options?: AxiosRequestConfig) {
        return ExperienciaProfesionalApiFp(this.configuration).getTesis(page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene el documento de las tesis
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperienciaProfesionalApi
     */
    public getTesisDocumento(options?: AxiosRequestConfig) {
        return ExperienciaProfesionalApiFp(this.configuration).getTesisDocumento(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene la información de trayectoria profesional (empleos) de usuario
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperienciaProfesionalApi
     */
    public getTrayectoriaProfesional(page?: number, size?: number, options?: AxiosRequestConfig) {
        return ExperienciaProfesionalApiFp(this.configuration).getTrayectoriaProfesional(page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Almacena un documento de congresos de usuario
     * @param {DocumentoProbatorio} documentoProbatorio 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperienciaProfesionalApi
     */
    public saveCongresoDocumento(documentoProbatorio: DocumentoProbatorio, options?: AxiosRequestConfig) {
        return ExperienciaProfesionalApiFp(this.configuration).saveCongresoDocumento(documentoProbatorio, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Almacena un congreso de usuario
     * @param {Congreso} congreso 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperienciaProfesionalApi
     */
    public saveCongresos(congreso: Congreso, options?: AxiosRequestConfig) {
        return ExperienciaProfesionalApiFp(this.configuration).saveCongresos(congreso, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Almacena un documento de los cursos de un usuario
     * @param {DocumentoProbatorio} documentoProbatorio 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperienciaProfesionalApi
     */
    public saveCursoDocumento(documentoProbatorio: DocumentoProbatorio, options?: AxiosRequestConfig) {
        return ExperienciaProfesionalApiFp(this.configuration).saveCursoDocumento(documentoProbatorio, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Almacena un curso impartido de usuario
     * @param {CursoImpartido} cursoImpartido 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperienciaProfesionalApi
     */
    public saveCursosImpartidos(cursoImpartido: CursoImpartido, options?: AxiosRequestConfig) {
        return ExperienciaProfesionalApiFp(this.configuration).saveCursosImpartidos(cursoImpartido, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Almacena un diplomado de usuario
     * @param {Diplomado} diplomado 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperienciaProfesionalApi
     */
    public saveDiplomados(diplomado: Diplomado, options?: AxiosRequestConfig) {
        return ExperienciaProfesionalApiFp(this.configuration).saveDiplomados(diplomado, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Almacena un documento de los diplomados de un usuario
     * @param {DocumentoProbatorio} documentoProbatorio 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperienciaProfesionalApi
     */
    public saveDiplomadosDocumento(documentoProbatorio: DocumentoProbatorio, options?: AxiosRequestConfig) {
        return ExperienciaProfesionalApiFp(this.configuration).saveDiplomadosDocumento(documentoProbatorio, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Almacena un trabajo de divulgacion de usuario
     * @param {TrabajoDivulgacion} trabajoDivulgacion 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperienciaProfesionalApi
     */
    public saveDivulgacion(trabajoDivulgacion: TrabajoDivulgacion, options?: AxiosRequestConfig) {
        return ExperienciaProfesionalApiFp(this.configuration).saveDivulgacion(trabajoDivulgacion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Almacena un documento de divulgaciones de usuario
     * @param {DocumentoProbatorio} documentoProbatorio 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperienciaProfesionalApi
     */
    public saveDivulgacionDocumento(documentoProbatorio: DocumentoProbatorio, options?: AxiosRequestConfig) {
        return ExperienciaProfesionalApiFp(this.configuration).saveDivulgacionDocumento(documentoProbatorio, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Almacena una estancia de usuario
     * @param {Estancia} estancia 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperienciaProfesionalApi
     */
    public saveEstancias(estancia: Estancia, options?: AxiosRequestConfig) {
        return ExperienciaProfesionalApiFp(this.configuration).saveEstancias(estancia, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Almacena una evaluación otorgada de usuario
     * @param {Evaluacion} evaluacion 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperienciaProfesionalApi
     */
    public saveEvaluacionesOtorgadas(evaluacion: Evaluacion, options?: AxiosRequestConfig) {
        return ExperienciaProfesionalApiFp(this.configuration).saveEvaluacionesOtorgadas(evaluacion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Almacena una tesis dirigida de usuario
     * @param {TesisDirigida} tesisDirigida 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperienciaProfesionalApi
     */
    public saveTesis(tesisDirigida: TesisDirigida, options?: AxiosRequestConfig) {
        return ExperienciaProfesionalApiFp(this.configuration).saveTesis(tesisDirigida, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Almacena un documento tesis dirigida de usuario
     * @param {DocumentoProbatorio} documentoProbatorio 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperienciaProfesionalApi
     */
    public saveTesisDocumento(documentoProbatorio: DocumentoProbatorio, options?: AxiosRequestConfig) {
        return ExperienciaProfesionalApiFp(this.configuration).saveTesisDocumento(documentoProbatorio, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Almacena un empleo de usuario
     * @param {Empleo} empleo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperienciaProfesionalApi
     */
    public saveTrayectoriaProfesional(empleo: Empleo, options?: AxiosRequestConfig) {
        return ExperienciaProfesionalApiFp(this.configuration).saveTrayectoriaProfesional(empleo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actualiza un congreso de usuario
     * @param {string} id id de congreso
     * @param {Congreso} congreso 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperienciaProfesionalApi
     */
    public updateCongresosById(id: string, congreso: Congreso, options?: AxiosRequestConfig) {
        return ExperienciaProfesionalApiFp(this.configuration).updateCongresosById(id, congreso, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actualiza un curso impartido de usuario
     * @param {string} id id de curso impartido
     * @param {CursoImpartido} cursoImpartido 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperienciaProfesionalApi
     */
    public updateCursosImpartidosById(id: string, cursoImpartido: CursoImpartido, options?: AxiosRequestConfig) {
        return ExperienciaProfesionalApiFp(this.configuration).updateCursosImpartidosById(id, cursoImpartido, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actualiza un diplomado de usuario
     * @param {string} id id de diplomado
     * @param {Diplomado} diplomado 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperienciaProfesionalApi
     */
    public updateDiplomadosById(id: string, diplomado: Diplomado, options?: AxiosRequestConfig) {
        return ExperienciaProfesionalApiFp(this.configuration).updateDiplomadosById(id, diplomado, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actualiza un trabajo de divulgación de usuario
     * @param {string} id id de trabajo de divulgación
     * @param {TrabajoDivulgacion} trabajoDivulgacion 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperienciaProfesionalApi
     */
    public updateDivulgacionById(id: string, trabajoDivulgacion: TrabajoDivulgacion, options?: AxiosRequestConfig) {
        return ExperienciaProfesionalApiFp(this.configuration).updateDivulgacionById(id, trabajoDivulgacion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actualiza una estancia de usuario
     * @param {string} id id de estancia
     * @param {Estancia} estancia 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperienciaProfesionalApi
     */
    public updateEstanciasById(id: string, estancia: Estancia, options?: AxiosRequestConfig) {
        return ExperienciaProfesionalApiFp(this.configuration).updateEstanciasById(id, estancia, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actualiza una evaluación otorgada de usuario
     * @param {string} id id de evaluación
     * @param {Evaluacion} evaluacion 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperienciaProfesionalApi
     */
    public updateEvaluacionesOtorgadasById(id: string, evaluacion: Evaluacion, options?: AxiosRequestConfig) {
        return ExperienciaProfesionalApiFp(this.configuration).updateEvaluacionesOtorgadasById(id, evaluacion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actualiza una tesis dirigida de usuario
     * @param {string} id id de tesis
     * @param {TesisDirigida} tesisDirigida 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperienciaProfesionalApi
     */
    public updateTesisById(id: string, tesisDirigida: TesisDirigida, options?: AxiosRequestConfig) {
        return ExperienciaProfesionalApiFp(this.configuration).updateTesisById(id, tesisDirigida, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actualiza un empleo de usuario
     * @param {string} id id de empleo
     * @param {Empleo} empleo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExperienciaProfesionalApi
     */
    public updateTrayectoriaProfesionalById(id: string, empleo: Empleo, options?: AxiosRequestConfig) {
        return ExperienciaProfesionalApiFp(this.configuration).updateTrayectoriaProfesionalById(id, empleo, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IngresopermanenciaApi - axios parameter creator
 * @export
 */
export const IngresopermanenciaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Cancela una solicitud
         * @param {string} id id de la solicitud
         * @param {SolicitudCancelada} solicitudCancelada Información de la cancelacion de la solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelaSolicitud: async (id: string, solicitudCancelada: SolicitudCancelada, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cancelaSolicitud', 'id', id)
            // verify required parameter 'solicitudCancelada' is not null or undefined
            assertParamExists('cancelaSolicitud', 'solicitudCancelada', solicitudCancelada)
            const localVarPath = `/solicitud-ingreso-permanencia/{id}/cancelacion`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(solicitudCancelada, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Genera todo el proceso para el envio de la solicitud
         * @param {string} id id de la solicitud
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enviarSolicitud: async (id: string, solicitud: Solicitud, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('enviarSolicitud', 'id', id)
            // verify required parameter 'solicitud' is not null or undefined
            assertParamExists('enviarSolicitud', 'solicitud', solicitud)
            const localVarPath = `/solicitud-ingreso-permanencia/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(solicitud, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene información de una solicitud cancelada
         * @param {string} id id de la solicitud cancelada
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSolicitudCancelada: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSolicitudCancelada', 'id', id)
            const localVarPath = `/solicitud-ingreso-permanencia/{id}/cancelacion`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IngresopermanenciaApi - functional programming interface
 * @export
 */
export const IngresopermanenciaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IngresopermanenciaApiAxiosParamCreator(configuration)
    return {
        /**
         * Cancela una solicitud
         * @param {string} id id de la solicitud
         * @param {SolicitudCancelada} solicitudCancelada Información de la cancelacion de la solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelaSolicitud(id: string, solicitudCancelada: SolicitudCancelada, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelaSolicitud(id, solicitudCancelada, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Genera todo el proceso para el envio de la solicitud
         * @param {string} id id de la solicitud
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enviarSolicitud(id: string, solicitud: Solicitud, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enviarSolicitud(id, solicitud, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene información de una solicitud cancelada
         * @param {string} id id de la solicitud cancelada
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSolicitudCancelada(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SolicitudCancelada>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSolicitudCancelada(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IngresopermanenciaApi - factory interface
 * @export
 */
export const IngresopermanenciaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IngresopermanenciaApiFp(configuration)
    return {
        /**
         * Cancela una solicitud
         * @param {string} id id de la solicitud
         * @param {SolicitudCancelada} solicitudCancelada Información de la cancelacion de la solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelaSolicitud(id: string, solicitudCancelada: SolicitudCancelada, options?: any): AxiosPromise<void> {
            return localVarFp.cancelaSolicitud(id, solicitudCancelada, options).then((request) => request(axios, basePath));
        },
        /**
         * Genera todo el proceso para el envio de la solicitud
         * @param {string} id id de la solicitud
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enviarSolicitud(id: string, solicitud: Solicitud, options?: any): AxiosPromise<void> {
            return localVarFp.enviarSolicitud(id, solicitud, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene información de una solicitud cancelada
         * @param {string} id id de la solicitud cancelada
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSolicitudCancelada(id: string, options?: any): AxiosPromise<SolicitudCancelada> {
            return localVarFp.getSolicitudCancelada(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IngresopermanenciaApi - object-oriented interface
 * @export
 * @class IngresopermanenciaApi
 * @extends {BaseAPI}
 */
export class IngresopermanenciaApi extends BaseAPI {
    /**
     * Cancela una solicitud
     * @param {string} id id de la solicitud
     * @param {SolicitudCancelada} solicitudCancelada Información de la cancelacion de la solicitud
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngresopermanenciaApi
     */
    public cancelaSolicitud(id: string, solicitudCancelada: SolicitudCancelada, options?: AxiosRequestConfig) {
        return IngresopermanenciaApiFp(this.configuration).cancelaSolicitud(id, solicitudCancelada, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Genera todo el proceso para el envio de la solicitud
     * @param {string} id id de la solicitud
     * @param {Solicitud} solicitud 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngresopermanenciaApi
     */
    public enviarSolicitud(id: string, solicitud: Solicitud, options?: AxiosRequestConfig) {
        return IngresopermanenciaApiFp(this.configuration).enviarSolicitud(id, solicitud, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene información de una solicitud cancelada
     * @param {string} id id de la solicitud cancelada
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngresopermanenciaApi
     */
    public getSolicitudCancelada(id: string, options?: AxiosRequestConfig) {
        return IngresopermanenciaApiFp(this.configuration).getSolicitudCancelada(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * JobApi - axios parameter creator
 * @export
 */
export const JobApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Recupera el estado del job de jobrunr
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getjobStatus: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getjobStatus', 'id', id)
            const localVarPath = `/perfil-job/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JobApi - functional programming interface
 * @export
 */
export const JobApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = JobApiAxiosParamCreator(configuration)
    return {
        /**
         * Recupera el estado del job de jobrunr
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getjobStatus(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getjobStatus(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * JobApi - factory interface
 * @export
 */
export const JobApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = JobApiFp(configuration)
    return {
        /**
         * Recupera el estado del job de jobrunr
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getjobStatus(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.getjobStatus(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * JobApi - object-oriented interface
 * @export
 * @class JobApi
 * @extends {BaseAPI}
 */
export class JobApi extends BaseAPI {
    /**
     * Recupera el estado del job de jobrunr
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobApi
     */
    public getjobStatus(id: string, options?: AxiosRequestConfig) {
        return JobApiFp(this.configuration).getjobStatus(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ParticipacionesApi - axios parameter creator
 * @export
 */
export const ParticipacionesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtiene las participaciones dado un cvu
         * @param {string} [participanteCvu] cvu del participante
         * @param {boolean} [includePrograma] bandera que indica si se debe o no inclur la información de un programa
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParticipacionesByCvu: async (participanteCvu?: string, includePrograma?: boolean, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/programas/participaciones`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (participanteCvu !== undefined) {
                localVarQueryParameter['participanteCvu'] = participanteCvu;
            }

            if (includePrograma !== undefined) {
                localVarQueryParameter['includePrograma'] = includePrograma;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ParticipacionesApi - functional programming interface
 * @export
 */
export const ParticipacionesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ParticipacionesApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtiene las participaciones dado un cvu
         * @param {string} [participanteCvu] cvu del participante
         * @param {boolean} [includePrograma] bandera que indica si se debe o no inclur la información de un programa
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParticipacionesByCvu(participanteCvu?: string, includePrograma?: boolean, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ParticipacionBrief>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParticipacionesByCvu(participanteCvu, includePrograma, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ParticipacionesApi - factory interface
 * @export
 */
export const ParticipacionesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ParticipacionesApiFp(configuration)
    return {
        /**
         * Obtiene las participaciones dado un cvu
         * @param {string} [participanteCvu] cvu del participante
         * @param {boolean} [includePrograma] bandera que indica si se debe o no inclur la información de un programa
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParticipacionesByCvu(participanteCvu?: string, includePrograma?: boolean, page?: number, size?: number, options?: any): AxiosPromise<Array<ParticipacionBrief>> {
            return localVarFp.getParticipacionesByCvu(participanteCvu, includePrograma, page, size, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ParticipacionesApi - object-oriented interface
 * @export
 * @class ParticipacionesApi
 * @extends {BaseAPI}
 */
export class ParticipacionesApi extends BaseAPI {
    /**
     * Obtiene las participaciones dado un cvu
     * @param {string} [participanteCvu] cvu del participante
     * @param {boolean} [includePrograma] bandera que indica si se debe o no inclur la información de un programa
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParticipacionesApi
     */
    public getParticipacionesByCvu(participanteCvu?: string, includePrograma?: boolean, page?: number, size?: number, options?: AxiosRequestConfig) {
        return ParticipacionesApiFp(this.configuration).getParticipacionesByCvu(participanteCvu, includePrograma, page, size, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PerfilApi - axios parameter creator
 * @export
 */
export const PerfilApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Elimina un Colaboración del usuario
         * @param {string} id id de Colaboración
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteColaboracionById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteColaboracionById', 'id', id)
            const localVarPath = `/colaboraciones/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Elimina un congreso de usuario
         * @param {string} id id de congreso
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCongresosById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCongresosById', 'id', id)
            const localVarPath = `/congresos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Elimina un curso impartido de usuario
         * @param {string} id id de curso impartido
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCursosImpartidosById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteCursosImpartidosById', 'id', id)
            const localVarPath = `/cursos-impartidos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Elimina un diplomado de usuario
         * @param {string} id id de diplomado
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDiplomadosById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteDiplomadosById', 'id', id)
            const localVarPath = `/diplomados/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Elimina un trabajo de divulgación de usuario
         * @param {string} id id de trabajo de divulgación
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDivulgacionById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteDivulgacionById', 'id', id)
            const localVarPath = `/divulgacion/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Elimina una estancia de usuario
         * @param {string} id id de estancia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEstanciasById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteEstanciasById', 'id', id)
            const localVarPath = `/estancias/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Elimina una evaluación otorgada de usuario
         * @param {string} id id de evaluación
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEvaluacionesOtorgadasById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteEvaluacionesOtorgadasById', 'id', id)
            const localVarPath = `/evaluaciones-otorgadas/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Elimina un logro de usuario
         * @param {string} id id de logro
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLogrosById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteLogrosById', 'id', id)
            const localVarPath = `/logros/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Elimina una tesis dirigida de usuario
         * @param {string} id id de tesis
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTesisById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTesisById', 'id', id)
            const localVarPath = `/tesis/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Elimina un grado académico de un usuario
         * @param {string} id id de grado académico
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTrayectoriaAcademicaById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTrayectoriaAcademicaById', 'id', id)
            const localVarPath = `/trayectoria-academica/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Elimina un empleo de usuario
         * @param {string} id id de empleo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTrayectoriaProfesionalById: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTrayectoriaProfesionalById', 'id', id)
            const localVarPath = `/trayectoria-profesional/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene los perfiles de usuario dado el parámetro de filtro, ya sea por id de la institución, nombre o ambos
         * @param {Filtro} [filtro] filtro para realizar consultas
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPerfiles: async (filtro?: Filtro, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/perfil/datos-generales`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (filtro !== undefined) {
                localVarQueryParameter['filtro'] = filtro;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene datos breves de perfiles de los cvus indicados
         * @param {Array<string>} cvus cvus de los usuarios
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBulkPerfiles: async (cvus: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cvus' is not null or undefined
            assertParamExists('getBulkPerfiles', 'cvus', cvus)
            const localVarPath = `/perfil/bulk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (cvus) {
                localVarQueryParameter['cvus'] = cvus;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene la información de las colaboraciones con Ecosistemas Nacionales Informáticos (ENI), Gestor de Mapas (Gema) , Sistema de Diseño y Accesibilidad para la Investigación (Sisdai)
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getColaboraciones: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/colaboraciones`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene la información de Congresos de usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCongresos: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/congresos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene el documento de las participaciones en congresos
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCongresosDocumento: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/congresos-documento`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene el documento probatorio de los cursos
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCursoDocumento: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cursos-documento`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene la información de formación continua de usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCursos: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cursos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene la información de formación continua de un usuario
         * @param {number} id id del usuario
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getCursosById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCursosById', 'id', id)
            const localVarPath = `/cursos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene la información de cursos impartidos de usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCursosImpartidos: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cursos-impartidos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene la información de Desarrollo Institucional de usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDesarrolloInstitucional: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/desarrollos-institucionales`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene la información de Diplomados de usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDiplomados: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/diplomados`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene el documento probatorio de los diplomados
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDiplomadosDocumento: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/diplomados-documento`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene la información de Divulgacion de usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDivulgacion: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/divulgacion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene el documento de las divulgaciones
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDivulgacionDocumento: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/divulgacion-documento`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene la información del empleo actual de un usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmpleoActual: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trayectoria-profesional/empleo-actual`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene el empleo principal de usuario dado un cvu
         * @param {string} cvu cvu del usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmpleoPrincipal: async (cvu: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cvu' is not null or undefined
            assertParamExists('getEmpleoPrincipal', 'cvu', cvu)
            const localVarPath = `/perfil/{cvu}/trayectoria-profesional/principal`
                .replace(`{${"cvu"}}`, encodeURIComponent(String(cvu)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene la información de estancias de usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEstancias: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/estancias`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene la información de evaluaciones otorgadas de usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvaluacionesOtorgadas: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/evaluaciones-otorgadas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Cancela una solicitud de un usuario
         * @param {string} id id de la solicitud a cancelar
         * @param {SolicitudCancelada} solicitudCancelada Información de la cancelacion de la solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExportarReporteSolicitudCancelar: async (id: string, solicitudCancelada: SolicitudCancelada, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getExportarReporteSolicitudCancelar', 'id', id)
            // verify required parameter 'solicitudCancelada' is not null or undefined
            assertParamExists('getExportarReporteSolicitudCancelar', 'solicitudCancelada', solicitudCancelada)
            const localVarPath = `/exportar-reporte-solicitud/{id}/cancelar`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(solicitudCancelada, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene el cuestionario de variables sociodemográficas de un usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGrupos: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/grupos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene el cuestionario de variables sociodemográficas de un usuario
         * @param {number} id id del usuario
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getGruposById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getGruposById', 'id', id)
            const localVarPath = `/grupos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene la información de idiomas de usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdiomas: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/idiomas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene la información de idiomas
         * @param {number} id id del usuario
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getIdiomasById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getIdiomasById', 'id', id)
            const localVarPath = `/idiomas/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene la información de logros de usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogros: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/logros`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene la información general de perfil de usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMiPerfil: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mi-perfil`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene la información de perfil de usuario
         * @param {number} id id del usuario
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getMiPerfilById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMiPerfilById', 'id', id)
            const localVarPath = `/mi-perfil/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene un pdf en base64 del perfil dado un cvu o login
         * @param {string} [cvu] cvu del usuario
         * @param {string} [login] correo de login del usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPdfPerfil: async (cvu?: string, login?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/perfil/pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (cvu !== undefined) {
                localVarQueryParameter['cvu'] = cvu;
            }

            if (login !== undefined) {
                localVarQueryParameter['login'] = login;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene el perfil de usuario dado alguno de los parámetros cvu o login
         * @param {string} [cvu] cvu del usuario
         * @param {string} [login] correo de login del usuario
         * @param {boolean} [completo] Indica si obtendrá información completa de perfil
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerfil: async (cvu?: string, login?: string, completo?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/perfil`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (cvu !== undefined) {
                localVarQueryParameter['cvu'] = cvu;
            }

            if (login !== undefined) {
                localVarQueryParameter['login'] = login;
            }

            if (completo !== undefined) {
                localVarQueryParameter['completo'] = completo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene el perfil de usuario dado un cvu
         * @param {string} cvu cvu del usuario
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getPerfilByCvu: async (cvu: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cvu' is not null or undefined
            assertParamExists('getPerfilByCvu', 'cvu', cvu)
            const localVarPath = `/perfil/{cvu}`
                .replace(`{${"cvu"}}`, encodeURIComponent(String(cvu)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Exportar pdf perfil único
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportePerfil: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mi-perfil/pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Exportar pdf de solicitud
         * @param {string} id id de la solicitud
         * @param {string} tipo tipo documento de la solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReporteSolicitud: async (id: string, tipo: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getReporteSolicitud', 'id', id)
            // verify required parameter 'tipo' is not null or undefined
            assertParamExists('getReporteSolicitud', 'tipo', tipo)
            const localVarPath = `/solicitudes-sni/ingreso-permanencia/{id}/{tipo}/pdf`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"tipo"}}`, encodeURIComponent(String(tipo)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene la solicitud de un usuario
         * @param {string} id id de la solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSolicitud: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSolicitud', 'id', id)
            const localVarPath = `/solicitudes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene la información de las solicitudes del usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSolicitudes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/solicitudes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene la información de Tesis dirigidas de usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTesis: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tesis`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene el documento de las tesis
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTesisDocumento: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tesis-documento`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene el tipo de solicitud al que puede aplicar un usuario sni
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTipoSolicitudActual: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/solicitudes/tipo-solicitud-actual`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene los totales de productos principales de congreso y divulgacion
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalCongresoDivulgacion: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/total-productos/congreso-divulgacion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene los totales de productos generales y principales
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalProductos: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/total-productos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene los totales de productos principales de tesis, cursos, y diplomados
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalTesisCursosDiplomados: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/total-productos/tesis-cursos-diplomados`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene la información de trayectoria académica (grados) de un usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrayectoriaAcademica: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trayectoria-academica`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene la información de trayectoria profesional (empleos) de usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrayectoriaProfesional: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trayectoria-profesional`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene la información del último aviso o carta de acuerdo al tipo indicado
         * @param {TipoAviso} tipo Tipo de aviso
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUltimoAviso: async (tipo: TipoAviso, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tipo' is not null or undefined
            assertParamExists('getUltimoAviso', 'tipo', tipo)
            const localVarPath = `/avisos/ultimo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (tipo !== undefined) {
                localVarQueryParameter['tipo'] = tipo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Recupera el estado del job de jobrunr
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getjobStatus: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getjobStatus', 'id', id)
            const localVarPath = `/perfil-job/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Almacena la colaboración en los proyectos  Ecosistemas Nacionales Informáticos (ENI), Gestor de Mapas (Gema) , Sistema de Diseño y Accesibilidad para la Investigación (Sisdai)
         * @param {Colaboracion} colaboracion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guardarColaboracion: async (colaboracion: Colaboracion, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'colaboracion' is not null or undefined
            assertParamExists('guardarColaboracion', 'colaboracion', colaboracion)
            const localVarPath = `/colaboraciones`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(colaboracion, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Almacena información de un aviso o carta
         * @param {Aviso} aviso 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAviso: async (aviso: Aviso, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aviso' is not null or undefined
            assertParamExists('saveAviso', 'aviso', aviso)
            const localVarPath = `/avisos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aviso, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Almacena un documento de congresos de usuario
         * @param {DocumentoProbatorio} documentoProbatorio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCongresoDocumento: async (documentoProbatorio: DocumentoProbatorio, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentoProbatorio' is not null or undefined
            assertParamExists('saveCongresoDocumento', 'documentoProbatorio', documentoProbatorio)
            const localVarPath = `/congresos-documento`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentoProbatorio, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Almacena un congreso de usuario
         * @param {Congreso} congreso 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCongresos: async (congreso: Congreso, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'congreso' is not null or undefined
            assertParamExists('saveCongresos', 'congreso', congreso)
            const localVarPath = `/congresos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(congreso, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Almacena un documento de los cursos de un usuario
         * @param {DocumentoProbatorio} documentoProbatorio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCursoDocumento: async (documentoProbatorio: DocumentoProbatorio, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentoProbatorio' is not null or undefined
            assertParamExists('saveCursoDocumento', 'documentoProbatorio', documentoProbatorio)
            const localVarPath = `/cursos-documento`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentoProbatorio, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Almacena información de formación continua de usuario
         * @param {Cursos} cursos 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCursos: async (cursos: Cursos, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cursos' is not null or undefined
            assertParamExists('saveCursos', 'cursos', cursos)
            const localVarPath = `/cursos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cursos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Almacena un curso impartido de usuario
         * @param {CursoImpartido} cursoImpartido 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCursosImpartidos: async (cursoImpartido: CursoImpartido, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cursoImpartido' is not null or undefined
            assertParamExists('saveCursosImpartidos', 'cursoImpartido', cursoImpartido)
            const localVarPath = `/cursos-impartidos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cursoImpartido, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Almacena información de Desarrollo Institucional de usuario
         * @param {DesarrolloInstitucional} desarrolloInstitucional 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDesarrolloInstitucional: async (desarrolloInstitucional: DesarrolloInstitucional, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'desarrolloInstitucional' is not null or undefined
            assertParamExists('saveDesarrolloInstitucional', 'desarrolloInstitucional', desarrolloInstitucional)
            const localVarPath = `/desarrollos-institucionales`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(desarrolloInstitucional, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Almacena un diplomado de usuario
         * @param {Diplomado} diplomado 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDiplomados: async (diplomado: Diplomado, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'diplomado' is not null or undefined
            assertParamExists('saveDiplomados', 'diplomado', diplomado)
            const localVarPath = `/diplomados`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(diplomado, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Almacena un documento de los diplomados de un usuario
         * @param {DocumentoProbatorio} documentoProbatorio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDiplomadosDocumento: async (documentoProbatorio: DocumentoProbatorio, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentoProbatorio' is not null or undefined
            assertParamExists('saveDiplomadosDocumento', 'documentoProbatorio', documentoProbatorio)
            const localVarPath = `/diplomados-documento`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentoProbatorio, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Almacena un trabajo de divulgacion de usuario
         * @param {TrabajoDivulgacion} trabajoDivulgacion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDivulgacion: async (trabajoDivulgacion: TrabajoDivulgacion, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'trabajoDivulgacion' is not null or undefined
            assertParamExists('saveDivulgacion', 'trabajoDivulgacion', trabajoDivulgacion)
            const localVarPath = `/divulgacion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trabajoDivulgacion, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Almacena un documento de divulgaciones de usuario
         * @param {DocumentoProbatorio} documentoProbatorio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDivulgacionDocumento: async (documentoProbatorio: DocumentoProbatorio, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentoProbatorio' is not null or undefined
            assertParamExists('saveDivulgacionDocumento', 'documentoProbatorio', documentoProbatorio)
            const localVarPath = `/divulgacion-documento`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentoProbatorio, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Almacena una estancia de usuario
         * @param {Estancia} estancia 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveEstancias: async (estancia: Estancia, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'estancia' is not null or undefined
            assertParamExists('saveEstancias', 'estancia', estancia)
            const localVarPath = `/estancias`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(estancia, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Almacena una evaluación otorgada de usuario
         * @param {Evaluacion} evaluacion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveEvaluacionesOtorgadas: async (evaluacion: Evaluacion, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'evaluacion' is not null or undefined
            assertParamExists('saveEvaluacionesOtorgadas', 'evaluacion', evaluacion)
            const localVarPath = `/evaluaciones-otorgadas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(evaluacion, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Almacena el cuestionario de variables sociodemográficas de un usuario
         * @param {Grupos} grupos 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveGrupos: async (grupos: Grupos, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'grupos' is not null or undefined
            assertParamExists('saveGrupos', 'grupos', grupos)
            const localVarPath = `/grupos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(grupos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Almacena información de idiomas de usuario
         * @param {Idiomas} idiomas 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveIdiomas: async (idiomas: Idiomas, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idiomas' is not null or undefined
            assertParamExists('saveIdiomas', 'idiomas', idiomas)
            const localVarPath = `/idiomas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(idiomas, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Almacena un logro de usuario
         * @param {Logro} logro 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveLogros: async (logro: Logro, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'logro' is not null or undefined
            assertParamExists('saveLogros', 'logro', logro)
            const localVarPath = `/logros`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logro, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Almacena información general de perfil de usuario
         * @param {MiPerfil} miPerfil 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveMiPerfil: async (miPerfil: MiPerfil, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'miPerfil' is not null or undefined
            assertParamExists('saveMiPerfil', 'miPerfil', miPerfil)
            const localVarPath = `/mi-perfil`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(miPerfil, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Almacena información de una solicitud
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSolicitud: async (solicitud: Solicitud, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'solicitud' is not null or undefined
            assertParamExists('saveSolicitud', 'solicitud', solicitud)
            const localVarPath = `/solicitudes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(solicitud, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Almacena una tesis dirigida de usuario
         * @param {TesisDirigida} tesisDirigida 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTesis: async (tesisDirigida: TesisDirigida, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tesisDirigida' is not null or undefined
            assertParamExists('saveTesis', 'tesisDirigida', tesisDirigida)
            const localVarPath = `/tesis`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tesisDirigida, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Almacena un documento tesis dirigida de usuario
         * @param {DocumentoProbatorio} documentoProbatorio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTesisDocumento: async (documentoProbatorio: DocumentoProbatorio, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentoProbatorio' is not null or undefined
            assertParamExists('saveTesisDocumento', 'documentoProbatorio', documentoProbatorio)
            const localVarPath = `/tesis-documento`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentoProbatorio, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Almacena un grado académico de un usuario
         * @param {GradoAcademico} gradoAcademico 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTrayectoriaAcademica: async (gradoAcademico: GradoAcademico, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gradoAcademico' is not null or undefined
            assertParamExists('saveTrayectoriaAcademica', 'gradoAcademico', gradoAcademico)
            const localVarPath = `/trayectoria-academica`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gradoAcademico, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Almacena un empleo de usuario
         * @param {Empleo} empleo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTrayectoriaProfesional: async (empleo: Empleo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'empleo' is not null or undefined
            assertParamExists('saveTrayectoriaProfesional', 'empleo', empleo)
            const localVarPath = `/trayectoria-profesional`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(empleo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actualiza un congreso de usuario
         * @param {string} id id de congreso
         * @param {Congreso} congreso 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCongresosById: async (id: string, congreso: Congreso, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCongresosById', 'id', id)
            // verify required parameter 'congreso' is not null or undefined
            assertParamExists('updateCongresosById', 'congreso', congreso)
            const localVarPath = `/congresos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(congreso, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actualiza la información de contactos de emergencia en la seccion general de perfil de usuario
         * @param {Array<ContactoEmergencias>} [contactoEmergencias] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContactosEmergencia: async (contactoEmergencias?: Array<ContactoEmergencias>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mi-perfil/contactos-emergencia`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactoEmergencias, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actualiza la información de formación continua de usuario
         * @param {string} id id del usuario
         * @param {Cursos} cursos 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCursosById: async (id: string, cursos: Cursos, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCursosById', 'id', id)
            // verify required parameter 'cursos' is not null or undefined
            assertParamExists('updateCursosById', 'cursos', cursos)
            const localVarPath = `/cursos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cursos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actualiza un curso impartido de usuario
         * @param {string} id id de curso impartido
         * @param {CursoImpartido} cursoImpartido 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCursosImpartidosById: async (id: string, cursoImpartido: CursoImpartido, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCursosImpartidosById', 'id', id)
            // verify required parameter 'cursoImpartido' is not null or undefined
            assertParamExists('updateCursosImpartidosById', 'cursoImpartido', cursoImpartido)
            const localVarPath = `/cursos-impartidos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cursoImpartido, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actualiza la información de los dependientes económicos en la seccion general del usuario de perfil
         * @param {Array<DependienteEconomico>} [dependienteEconomico] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDependientesEconomicos: async (dependienteEconomico?: Array<DependienteEconomico>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mi-perfil/dependientes-economicos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dependienteEconomico, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actualiza la información de Desarrollo Institucional
         * @param {number} id id del usuario
         * @param {DesarrolloInstitucional} desarrolloInstitucional 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDesarrolloInstitucionalById: async (id: number, desarrolloInstitucional: DesarrolloInstitucional, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateDesarrolloInstitucionalById', 'id', id)
            // verify required parameter 'desarrolloInstitucional' is not null or undefined
            assertParamExists('updateDesarrolloInstitucionalById', 'desarrolloInstitucional', desarrolloInstitucional)
            const localVarPath = `/desarrollos-institucionales/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(desarrolloInstitucional, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actualiza un diplomado de usuario
         * @param {string} id id de diplomado
         * @param {Diplomado} diplomado 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDiplomadosById: async (id: string, diplomado: Diplomado, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateDiplomadosById', 'id', id)
            // verify required parameter 'diplomado' is not null or undefined
            assertParamExists('updateDiplomadosById', 'diplomado', diplomado)
            const localVarPath = `/diplomados/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(diplomado, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actualiza un trabajo de divulgación de usuario
         * @param {string} id id de trabajo de divulgación
         * @param {TrabajoDivulgacion} trabajoDivulgacion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDivulgacionById: async (id: string, trabajoDivulgacion: TrabajoDivulgacion, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateDivulgacionById', 'id', id)
            // verify required parameter 'trabajoDivulgacion' is not null or undefined
            assertParamExists('updateDivulgacionById', 'trabajoDivulgacion', trabajoDivulgacion)
            const localVarPath = `/divulgacion/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(trabajoDivulgacion, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actualiza una estancia de usuario
         * @param {string} id id de estancia
         * @param {Estancia} estancia 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEstanciasById: async (id: string, estancia: Estancia, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateEstanciasById', 'id', id)
            // verify required parameter 'estancia' is not null or undefined
            assertParamExists('updateEstanciasById', 'estancia', estancia)
            const localVarPath = `/estancias/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(estancia, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actualiza una evaluación otorgada de usuario
         * @param {string} id id de evaluación
         * @param {Evaluacion} evaluacion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEvaluacionesOtorgadasById: async (id: string, evaluacion: Evaluacion, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateEvaluacionesOtorgadasById', 'id', id)
            // verify required parameter 'evaluacion' is not null or undefined
            assertParamExists('updateEvaluacionesOtorgadasById', 'evaluacion', evaluacion)
            const localVarPath = `/evaluaciones-otorgadas/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(evaluacion, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actualiza el cuestionario de variables sociodemográficas de un usuario
         * @param {number} id id del usuario
         * @param {Grupos} grupos 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGruposById: async (id: number, grupos: Grupos, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateGruposById', 'id', id)
            // verify required parameter 'grupos' is not null or undefined
            assertParamExists('updateGruposById', 'grupos', grupos)
            const localVarPath = `/grupos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(grupos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actualiza la información de idiomas de usuario
         * @param {number} id id del usuario
         * @param {Idiomas} idiomas 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIdiomasById: async (id: number, idiomas: Idiomas, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateIdiomasById', 'id', id)
            // verify required parameter 'idiomas' is not null or undefined
            assertParamExists('updateIdiomasById', 'idiomas', idiomas)
            const localVarPath = `/idiomas/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(idiomas, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actualiza un logro de usuario
         * @param {string} id id de logro
         * @param {Logro} logro 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLogrosById: async (id: string, logro: Logro, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateLogrosById', 'id', id)
            // verify required parameter 'logro' is not null or undefined
            assertParamExists('updateLogrosById', 'logro', logro)
            const localVarPath = `/logros/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logro, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actualiza la información de medios de contacto en la sección general de perfil de usuario
         * @param {Array<Contacto>} [contacto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMediosContacto: async (contacto?: Array<Contacto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mi-perfil/medios-contacto`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contacto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actualiza la información general de perfil de usuario
         * @param {number} id id del usuario
         * @param {MiPerfil} miPerfil 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMiPerfilById: async (id: number, miPerfil: MiPerfil, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateMiPerfilById', 'id', id)
            // verify required parameter 'miPerfil' is not null or undefined
            assertParamExists('updateMiPerfilById', 'miPerfil', miPerfil)
            const localVarPath = `/mi-perfil/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(miPerfil, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actualiza la solicitud
         * @param {string} id id de la solicitud
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSolicitud: async (id: string, solicitud: Solicitud, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSolicitud', 'id', id)
            // verify required parameter 'solicitud' is not null or undefined
            assertParamExists('updateSolicitud', 'solicitud', solicitud)
            const localVarPath = `/solicitudes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(solicitud, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actualiza una tesis dirigida de usuario
         * @param {string} id id de tesis
         * @param {TesisDirigida} tesisDirigida 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTesisById: async (id: string, tesisDirigida: TesisDirigida, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTesisById', 'id', id)
            // verify required parameter 'tesisDirigida' is not null or undefined
            assertParamExists('updateTesisById', 'tesisDirigida', tesisDirigida)
            const localVarPath = `/tesis/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tesisDirigida, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actualiza un grado académico de un usuario
         * @param {string} id id de grado académico
         * @param {GradoAcademico} gradoAcademico 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTrayectoriaAcademicaById: async (id: string, gradoAcademico: GradoAcademico, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTrayectoriaAcademicaById', 'id', id)
            // verify required parameter 'gradoAcademico' is not null or undefined
            assertParamExists('updateTrayectoriaAcademicaById', 'gradoAcademico', gradoAcademico)
            const localVarPath = `/trayectoria-academica/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gradoAcademico, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actualiza un empleo de usuario
         * @param {string} id id de empleo
         * @param {Empleo} empleo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTrayectoriaProfesionalById: async (id: string, empleo: Empleo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTrayectoriaProfesionalById', 'id', id)
            // verify required parameter 'empleo' is not null or undefined
            assertParamExists('updateTrayectoriaProfesionalById', 'empleo', empleo)
            const localVarPath = `/trayectoria-profesional/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(empleo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Verifica que exista un perfil o crea uno nuevo de un usuario recien registrado
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validaPerfil: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mi-perfil/valida`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PerfilApi - functional programming interface
 * @export
 */
export const PerfilApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PerfilApiAxiosParamCreator(configuration)
    return {
        /**
         * Elimina un Colaboración del usuario
         * @param {string} id id de Colaboración
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteColaboracionById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteColaboracionById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Elimina un congreso de usuario
         * @param {string} id id de congreso
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCongresosById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCongresosById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Elimina un curso impartido de usuario
         * @param {string} id id de curso impartido
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCursosImpartidosById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCursosImpartidosById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Elimina un diplomado de usuario
         * @param {string} id id de diplomado
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDiplomadosById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDiplomadosById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Elimina un trabajo de divulgación de usuario
         * @param {string} id id de trabajo de divulgación
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDivulgacionById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDivulgacionById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Elimina una estancia de usuario
         * @param {string} id id de estancia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEstanciasById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEstanciasById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Elimina una evaluación otorgada de usuario
         * @param {string} id id de evaluación
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEvaluacionesOtorgadasById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEvaluacionesOtorgadasById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Elimina un logro de usuario
         * @param {string} id id de logro
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLogrosById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLogrosById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Elimina una tesis dirigida de usuario
         * @param {string} id id de tesis
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTesisById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTesisById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Elimina un grado académico de un usuario
         * @param {string} id id de grado académico
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTrayectoriaAcademicaById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTrayectoriaAcademicaById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Elimina un empleo de usuario
         * @param {string} id id de empleo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTrayectoriaProfesionalById(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTrayectoriaProfesionalById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene los perfiles de usuario dado el parámetro de filtro, ya sea por id de la institución, nombre o ambos
         * @param {Filtro} [filtro] filtro para realizar consultas
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllPerfiles(filtro?: Filtro, page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Perfil>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllPerfiles(filtro, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene datos breves de perfiles de los cvus indicados
         * @param {Array<string>} cvus cvus de los usuarios
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBulkPerfiles(cvus: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PerfilBrief>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBulkPerfiles(cvus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene la información de las colaboraciones con Ecosistemas Nacionales Informáticos (ENI), Gestor de Mapas (Gema) , Sistema de Diseño y Accesibilidad para la Investigación (Sisdai)
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getColaboraciones(page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Colaboracion>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getColaboraciones(page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene la información de Congresos de usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCongresos(page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Congreso>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCongresos(page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene el documento de las participaciones en congresos
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCongresosDocumento(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentoProbatorio>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCongresosDocumento(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene el documento probatorio de los cursos
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCursoDocumento(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentoProbatorio>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCursoDocumento(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene la información de formación continua de usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCursos(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Cursos>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCursos(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene la información de formación continua de un usuario
         * @param {number} id id del usuario
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getCursosById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Cursos>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCursosById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene la información de cursos impartidos de usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCursosImpartidos(page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CursoImpartido>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCursosImpartidos(page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene la información de Desarrollo Institucional de usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDesarrolloInstitucional(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DesarrolloInstitucional>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDesarrolloInstitucional(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene la información de Diplomados de usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDiplomados(page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Diplomado>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDiplomados(page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene el documento probatorio de los diplomados
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDiplomadosDocumento(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentoProbatorio>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDiplomadosDocumento(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene la información de Divulgacion de usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDivulgacion(page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TrabajoDivulgacion>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDivulgacion(page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene el documento de las divulgaciones
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDivulgacionDocumento(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentoProbatorio>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDivulgacionDocumento(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene la información del empleo actual de un usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmpleoActual(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Empleo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmpleoActual(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene el empleo principal de usuario dado un cvu
         * @param {string} cvu cvu del usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmpleoPrincipal(cvu: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Empleo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmpleoPrincipal(cvu, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene la información de estancias de usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEstancias(page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Estancia>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEstancias(page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene la información de evaluaciones otorgadas de usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEvaluacionesOtorgadas(page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Evaluacion>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEvaluacionesOtorgadas(page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Cancela una solicitud de un usuario
         * @param {string} id id de la solicitud a cancelar
         * @param {SolicitudCancelada} solicitudCancelada Información de la cancelacion de la solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExportarReporteSolicitudCancelar(id: string, solicitudCancelada: SolicitudCancelada, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentoExport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExportarReporteSolicitudCancelar(id, solicitudCancelada, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene el cuestionario de variables sociodemográficas de un usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGrupos(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Grupos>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGrupos(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene el cuestionario de variables sociodemográficas de un usuario
         * @param {number} id id del usuario
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getGruposById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Grupos>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGruposById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene la información de idiomas de usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIdiomas(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Idiomas>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIdiomas(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene la información de idiomas
         * @param {number} id id del usuario
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getIdiomasById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Idiomas>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIdiomasById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene la información de logros de usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLogros(page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Logro>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLogros(page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene la información general de perfil de usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMiPerfil(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MiPerfil>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMiPerfil(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene la información de perfil de usuario
         * @param {number} id id del usuario
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getMiPerfilById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MiPerfil>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMiPerfilById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene un pdf en base64 del perfil dado un cvu o login
         * @param {string} [cvu] cvu del usuario
         * @param {string} [login] correo de login del usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPdfPerfil(cvu?: string, login?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentoExport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPdfPerfil(cvu, login, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene el perfil de usuario dado alguno de los parámetros cvu o login
         * @param {string} [cvu] cvu del usuario
         * @param {string} [login] correo de login del usuario
         * @param {boolean} [completo] Indica si obtendrá información completa de perfil
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPerfil(cvu?: string, login?: string, completo?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Perfil>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPerfil(cvu, login, completo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene el perfil de usuario dado un cvu
         * @param {string} cvu cvu del usuario
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getPerfilByCvu(cvu: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Perfil>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPerfilByCvu(cvu, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Exportar pdf perfil único
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportePerfil(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentoExport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportePerfil(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Exportar pdf de solicitud
         * @param {string} id id de la solicitud
         * @param {string} tipo tipo documento de la solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReporteSolicitud(id: string, tipo: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentoExport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReporteSolicitud(id, tipo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene la solicitud de un usuario
         * @param {string} id id de la solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSolicitud(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Solicitud>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSolicitud(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene la información de las solicitudes del usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSolicitudes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Solicitud>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSolicitudes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene la información de Tesis dirigidas de usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTesis(page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TesisDirigida>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTesis(page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene el documento de las tesis
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTesisDocumento(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentoProbatorio>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTesisDocumento(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene el tipo de solicitud al que puede aplicar un usuario sni
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTipoSolicitudActual(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseCatalogo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTipoSolicitudActual(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene los totales de productos principales de congreso y divulgacion
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTotalCongresoDivulgacion(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTotalCongresoDivulgacion(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene los totales de productos generales y principales
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTotalProductos(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TotalProductos>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTotalProductos(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene los totales de productos principales de tesis, cursos, y diplomados
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTotalTesisCursosDiplomados(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTotalTesisCursosDiplomados(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene la información de trayectoria académica (grados) de un usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTrayectoriaAcademica(page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GradoAcademico>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTrayectoriaAcademica(page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene la información de trayectoria profesional (empleos) de usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTrayectoriaProfesional(page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Empleo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTrayectoriaProfesional(page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene la información del último aviso o carta de acuerdo al tipo indicado
         * @param {TipoAviso} tipo Tipo de aviso
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUltimoAviso(tipo: TipoAviso, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Aviso>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUltimoAviso(tipo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Recupera el estado del job de jobrunr
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getjobStatus(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getjobStatus(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Almacena la colaboración en los proyectos  Ecosistemas Nacionales Informáticos (ENI), Gestor de Mapas (Gema) , Sistema de Diseño y Accesibilidad para la Investigación (Sisdai)
         * @param {Colaboracion} colaboracion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async guardarColaboracion(colaboracion: Colaboracion, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Colaboracion>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.guardarColaboracion(colaboracion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Almacena información de un aviso o carta
         * @param {Aviso} aviso 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveAviso(aviso: Aviso, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveAviso(aviso, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Almacena un documento de congresos de usuario
         * @param {DocumentoProbatorio} documentoProbatorio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveCongresoDocumento(documentoProbatorio: DocumentoProbatorio, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentoProbatorio>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveCongresoDocumento(documentoProbatorio, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Almacena un congreso de usuario
         * @param {Congreso} congreso 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveCongresos(congreso: Congreso, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveCongresos(congreso, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Almacena un documento de los cursos de un usuario
         * @param {DocumentoProbatorio} documentoProbatorio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveCursoDocumento(documentoProbatorio: DocumentoProbatorio, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentoProbatorio>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveCursoDocumento(documentoProbatorio, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Almacena información de formación continua de usuario
         * @param {Cursos} cursos 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveCursos(cursos: Cursos, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Cursos>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveCursos(cursos, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Almacena un curso impartido de usuario
         * @param {CursoImpartido} cursoImpartido 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveCursosImpartidos(cursoImpartido: CursoImpartido, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveCursosImpartidos(cursoImpartido, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Almacena información de Desarrollo Institucional de usuario
         * @param {DesarrolloInstitucional} desarrolloInstitucional 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveDesarrolloInstitucional(desarrolloInstitucional: DesarrolloInstitucional, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DesarrolloInstitucional>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveDesarrolloInstitucional(desarrolloInstitucional, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Almacena un diplomado de usuario
         * @param {Diplomado} diplomado 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveDiplomados(diplomado: Diplomado, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveDiplomados(diplomado, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Almacena un documento de los diplomados de un usuario
         * @param {DocumentoProbatorio} documentoProbatorio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveDiplomadosDocumento(documentoProbatorio: DocumentoProbatorio, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentoProbatorio>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveDiplomadosDocumento(documentoProbatorio, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Almacena un trabajo de divulgacion de usuario
         * @param {TrabajoDivulgacion} trabajoDivulgacion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveDivulgacion(trabajoDivulgacion: TrabajoDivulgacion, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveDivulgacion(trabajoDivulgacion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Almacena un documento de divulgaciones de usuario
         * @param {DocumentoProbatorio} documentoProbatorio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveDivulgacionDocumento(documentoProbatorio: DocumentoProbatorio, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentoProbatorio>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveDivulgacionDocumento(documentoProbatorio, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Almacena una estancia de usuario
         * @param {Estancia} estancia 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveEstancias(estancia: Estancia, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveEstancias(estancia, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Almacena una evaluación otorgada de usuario
         * @param {Evaluacion} evaluacion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveEvaluacionesOtorgadas(evaluacion: Evaluacion, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveEvaluacionesOtorgadas(evaluacion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Almacena el cuestionario de variables sociodemográficas de un usuario
         * @param {Grupos} grupos 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveGrupos(grupos: Grupos, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Grupos>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveGrupos(grupos, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Almacena información de idiomas de usuario
         * @param {Idiomas} idiomas 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveIdiomas(idiomas: Idiomas, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Idiomas>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveIdiomas(idiomas, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Almacena un logro de usuario
         * @param {Logro} logro 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveLogros(logro: Logro, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveLogros(logro, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Almacena información general de perfil de usuario
         * @param {MiPerfil} miPerfil 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveMiPerfil(miPerfil: MiPerfil, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MiPerfil>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveMiPerfil(miPerfil, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Almacena información de una solicitud
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveSolicitud(solicitud: Solicitud, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveSolicitud(solicitud, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Almacena una tesis dirigida de usuario
         * @param {TesisDirigida} tesisDirigida 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveTesis(tesisDirigida: TesisDirigida, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveTesis(tesisDirigida, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Almacena un documento tesis dirigida de usuario
         * @param {DocumentoProbatorio} documentoProbatorio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveTesisDocumento(documentoProbatorio: DocumentoProbatorio, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentoProbatorio>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveTesisDocumento(documentoProbatorio, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Almacena un grado académico de un usuario
         * @param {GradoAcademico} gradoAcademico 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveTrayectoriaAcademica(gradoAcademico: GradoAcademico, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveTrayectoriaAcademica(gradoAcademico, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Almacena un empleo de usuario
         * @param {Empleo} empleo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveTrayectoriaProfesional(empleo: Empleo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveTrayectoriaProfesional(empleo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Actualiza un congreso de usuario
         * @param {string} id id de congreso
         * @param {Congreso} congreso 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCongresosById(id: string, congreso: Congreso, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCongresosById(id, congreso, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Actualiza la información de contactos de emergencia en la seccion general de perfil de usuario
         * @param {Array<ContactoEmergencias>} [contactoEmergencias] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateContactosEmergencia(contactoEmergencias?: Array<ContactoEmergencias>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContactoEmergencias>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateContactosEmergencia(contactoEmergencias, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Actualiza la información de formación continua de usuario
         * @param {string} id id del usuario
         * @param {Cursos} cursos 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCursosById(id: string, cursos: Cursos, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Cursos>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCursosById(id, cursos, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Actualiza un curso impartido de usuario
         * @param {string} id id de curso impartido
         * @param {CursoImpartido} cursoImpartido 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCursosImpartidosById(id: string, cursoImpartido: CursoImpartido, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCursosImpartidosById(id, cursoImpartido, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Actualiza la información de los dependientes económicos en la seccion general del usuario de perfil
         * @param {Array<DependienteEconomico>} [dependienteEconomico] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDependientesEconomicos(dependienteEconomico?: Array<DependienteEconomico>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DependienteEconomico>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDependientesEconomicos(dependienteEconomico, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Actualiza la información de Desarrollo Institucional
         * @param {number} id id del usuario
         * @param {DesarrolloInstitucional} desarrolloInstitucional 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDesarrolloInstitucionalById(id: number, desarrolloInstitucional: DesarrolloInstitucional, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DesarrolloInstitucional>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDesarrolloInstitucionalById(id, desarrolloInstitucional, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Actualiza un diplomado de usuario
         * @param {string} id id de diplomado
         * @param {Diplomado} diplomado 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDiplomadosById(id: string, diplomado: Diplomado, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDiplomadosById(id, diplomado, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Actualiza un trabajo de divulgación de usuario
         * @param {string} id id de trabajo de divulgación
         * @param {TrabajoDivulgacion} trabajoDivulgacion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDivulgacionById(id: string, trabajoDivulgacion: TrabajoDivulgacion, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDivulgacionById(id, trabajoDivulgacion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Actualiza una estancia de usuario
         * @param {string} id id de estancia
         * @param {Estancia} estancia 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEstanciasById(id: string, estancia: Estancia, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEstanciasById(id, estancia, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Actualiza una evaluación otorgada de usuario
         * @param {string} id id de evaluación
         * @param {Evaluacion} evaluacion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEvaluacionesOtorgadasById(id: string, evaluacion: Evaluacion, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEvaluacionesOtorgadasById(id, evaluacion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Actualiza el cuestionario de variables sociodemográficas de un usuario
         * @param {number} id id del usuario
         * @param {Grupos} grupos 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGruposById(id: number, grupos: Grupos, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Grupos>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGruposById(id, grupos, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Actualiza la información de idiomas de usuario
         * @param {number} id id del usuario
         * @param {Idiomas} idiomas 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateIdiomasById(id: number, idiomas: Idiomas, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Idiomas>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateIdiomasById(id, idiomas, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Actualiza un logro de usuario
         * @param {string} id id de logro
         * @param {Logro} logro 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLogrosById(id: string, logro: Logro, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLogrosById(id, logro, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Actualiza la información de medios de contacto en la sección general de perfil de usuario
         * @param {Array<Contacto>} [contacto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMediosContacto(contacto?: Array<Contacto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Contacto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMediosContacto(contacto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Actualiza la información general de perfil de usuario
         * @param {number} id id del usuario
         * @param {MiPerfil} miPerfil 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMiPerfilById(id: number, miPerfil: MiPerfil, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MiPerfil>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMiPerfilById(id, miPerfil, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Actualiza la solicitud
         * @param {string} id id de la solicitud
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSolicitud(id: string, solicitud: Solicitud, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSolicitud(id, solicitud, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Actualiza una tesis dirigida de usuario
         * @param {string} id id de tesis
         * @param {TesisDirigida} tesisDirigida 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTesisById(id: string, tesisDirigida: TesisDirigida, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTesisById(id, tesisDirigida, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Actualiza un grado académico de un usuario
         * @param {string} id id de grado académico
         * @param {GradoAcademico} gradoAcademico 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTrayectoriaAcademicaById(id: string, gradoAcademico: GradoAcademico, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTrayectoriaAcademicaById(id, gradoAcademico, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Actualiza un empleo de usuario
         * @param {string} id id de empleo
         * @param {Empleo} empleo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTrayectoriaProfesionalById(id: string, empleo: Empleo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTrayectoriaProfesionalById(id, empleo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Verifica que exista un perfil o crea uno nuevo de un usuario recien registrado
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validaPerfil(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MiPerfil>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validaPerfil(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PerfilApi - factory interface
 * @export
 */
export const PerfilApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PerfilApiFp(configuration)
    return {
        /**
         * Elimina un Colaboración del usuario
         * @param {string} id id de Colaboración
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteColaboracionById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteColaboracionById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Elimina un congreso de usuario
         * @param {string} id id de congreso
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCongresosById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCongresosById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Elimina un curso impartido de usuario
         * @param {string} id id de curso impartido
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCursosImpartidosById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCursosImpartidosById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Elimina un diplomado de usuario
         * @param {string} id id de diplomado
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDiplomadosById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDiplomadosById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Elimina un trabajo de divulgación de usuario
         * @param {string} id id de trabajo de divulgación
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDivulgacionById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDivulgacionById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Elimina una estancia de usuario
         * @param {string} id id de estancia
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEstanciasById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteEstanciasById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Elimina una evaluación otorgada de usuario
         * @param {string} id id de evaluación
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEvaluacionesOtorgadasById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteEvaluacionesOtorgadasById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Elimina un logro de usuario
         * @param {string} id id de logro
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLogrosById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteLogrosById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Elimina una tesis dirigida de usuario
         * @param {string} id id de tesis
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTesisById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTesisById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Elimina un grado académico de un usuario
         * @param {string} id id de grado académico
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTrayectoriaAcademicaById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTrayectoriaAcademicaById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Elimina un empleo de usuario
         * @param {string} id id de empleo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTrayectoriaProfesionalById(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTrayectoriaProfesionalById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene los perfiles de usuario dado el parámetro de filtro, ya sea por id de la institución, nombre o ambos
         * @param {Filtro} [filtro] filtro para realizar consultas
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPerfiles(filtro?: Filtro, page?: number, size?: number, options?: any): AxiosPromise<Array<Perfil>> {
            return localVarFp.getAllPerfiles(filtro, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene datos breves de perfiles de los cvus indicados
         * @param {Array<string>} cvus cvus de los usuarios
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBulkPerfiles(cvus: Array<string>, options?: any): AxiosPromise<Array<PerfilBrief>> {
            return localVarFp.getBulkPerfiles(cvus, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene la información de las colaboraciones con Ecosistemas Nacionales Informáticos (ENI), Gestor de Mapas (Gema) , Sistema de Diseño y Accesibilidad para la Investigación (Sisdai)
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getColaboraciones(page?: number, size?: number, options?: any): AxiosPromise<Array<Colaboracion>> {
            return localVarFp.getColaboraciones(page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene la información de Congresos de usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCongresos(page?: number, size?: number, options?: any): AxiosPromise<Array<Congreso>> {
            return localVarFp.getCongresos(page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene el documento de las participaciones en congresos
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCongresosDocumento(options?: any): AxiosPromise<DocumentoProbatorio> {
            return localVarFp.getCongresosDocumento(options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene el documento probatorio de los cursos
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCursoDocumento(options?: any): AxiosPromise<DocumentoProbatorio> {
            return localVarFp.getCursoDocumento(options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene la información de formación continua de usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCursos(options?: any): AxiosPromise<Cursos> {
            return localVarFp.getCursos(options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene la información de formación continua de un usuario
         * @param {number} id id del usuario
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getCursosById(id: number, options?: any): AxiosPromise<Cursos> {
            return localVarFp.getCursosById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene la información de cursos impartidos de usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCursosImpartidos(page?: number, size?: number, options?: any): AxiosPromise<Array<CursoImpartido>> {
            return localVarFp.getCursosImpartidos(page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene la información de Desarrollo Institucional de usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDesarrolloInstitucional(options?: any): AxiosPromise<DesarrolloInstitucional> {
            return localVarFp.getDesarrolloInstitucional(options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene la información de Diplomados de usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDiplomados(page?: number, size?: number, options?: any): AxiosPromise<Array<Diplomado>> {
            return localVarFp.getDiplomados(page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene el documento probatorio de los diplomados
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDiplomadosDocumento(options?: any): AxiosPromise<DocumentoProbatorio> {
            return localVarFp.getDiplomadosDocumento(options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene la información de Divulgacion de usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDivulgacion(page?: number, size?: number, options?: any): AxiosPromise<Array<TrabajoDivulgacion>> {
            return localVarFp.getDivulgacion(page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene el documento de las divulgaciones
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDivulgacionDocumento(options?: any): AxiosPromise<DocumentoProbatorio> {
            return localVarFp.getDivulgacionDocumento(options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene la información del empleo actual de un usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmpleoActual(options?: any): AxiosPromise<Array<Empleo>> {
            return localVarFp.getEmpleoActual(options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene el empleo principal de usuario dado un cvu
         * @param {string} cvu cvu del usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmpleoPrincipal(cvu: string, options?: any): AxiosPromise<Empleo> {
            return localVarFp.getEmpleoPrincipal(cvu, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene la información de estancias de usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEstancias(page?: number, size?: number, options?: any): AxiosPromise<Array<Estancia>> {
            return localVarFp.getEstancias(page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene la información de evaluaciones otorgadas de usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvaluacionesOtorgadas(page?: number, size?: number, options?: any): AxiosPromise<Array<Evaluacion>> {
            return localVarFp.getEvaluacionesOtorgadas(page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * Cancela una solicitud de un usuario
         * @param {string} id id de la solicitud a cancelar
         * @param {SolicitudCancelada} solicitudCancelada Información de la cancelacion de la solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExportarReporteSolicitudCancelar(id: string, solicitudCancelada: SolicitudCancelada, options?: any): AxiosPromise<DocumentoExport> {
            return localVarFp.getExportarReporteSolicitudCancelar(id, solicitudCancelada, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene el cuestionario de variables sociodemográficas de un usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGrupos(options?: any): AxiosPromise<Grupos> {
            return localVarFp.getGrupos(options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene el cuestionario de variables sociodemográficas de un usuario
         * @param {number} id id del usuario
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getGruposById(id: number, options?: any): AxiosPromise<Grupos> {
            return localVarFp.getGruposById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene la información de idiomas de usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIdiomas(options?: any): AxiosPromise<Idiomas> {
            return localVarFp.getIdiomas(options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene la información de idiomas
         * @param {number} id id del usuario
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getIdiomasById(id: number, options?: any): AxiosPromise<Idiomas> {
            return localVarFp.getIdiomasById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene la información de logros de usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogros(page?: number, size?: number, options?: any): AxiosPromise<Array<Logro>> {
            return localVarFp.getLogros(page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene la información general de perfil de usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMiPerfil(options?: any): AxiosPromise<MiPerfil> {
            return localVarFp.getMiPerfil(options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene la información de perfil de usuario
         * @param {number} id id del usuario
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getMiPerfilById(id: number, options?: any): AxiosPromise<MiPerfil> {
            return localVarFp.getMiPerfilById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene un pdf en base64 del perfil dado un cvu o login
         * @param {string} [cvu] cvu del usuario
         * @param {string} [login] correo de login del usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPdfPerfil(cvu?: string, login?: string, options?: any): AxiosPromise<DocumentoExport> {
            return localVarFp.getPdfPerfil(cvu, login, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene el perfil de usuario dado alguno de los parámetros cvu o login
         * @param {string} [cvu] cvu del usuario
         * @param {string} [login] correo de login del usuario
         * @param {boolean} [completo] Indica si obtendrá información completa de perfil
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerfil(cvu?: string, login?: string, completo?: boolean, options?: any): AxiosPromise<Perfil> {
            return localVarFp.getPerfil(cvu, login, completo, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene el perfil de usuario dado un cvu
         * @param {string} cvu cvu del usuario
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getPerfilByCvu(cvu: string, options?: any): AxiosPromise<Perfil> {
            return localVarFp.getPerfilByCvu(cvu, options).then((request) => request(axios, basePath));
        },
        /**
         * Exportar pdf perfil único
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportePerfil(options?: any): AxiosPromise<DocumentoExport> {
            return localVarFp.getReportePerfil(options).then((request) => request(axios, basePath));
        },
        /**
         * Exportar pdf de solicitud
         * @param {string} id id de la solicitud
         * @param {string} tipo tipo documento de la solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReporteSolicitud(id: string, tipo: string, options?: any): AxiosPromise<DocumentoExport> {
            return localVarFp.getReporteSolicitud(id, tipo, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene la solicitud de un usuario
         * @param {string} id id de la solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSolicitud(id: string, options?: any): AxiosPromise<Solicitud> {
            return localVarFp.getSolicitud(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene la información de las solicitudes del usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSolicitudes(options?: any): AxiosPromise<Array<Solicitud>> {
            return localVarFp.getSolicitudes(options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene la información de Tesis dirigidas de usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTesis(page?: number, size?: number, options?: any): AxiosPromise<Array<TesisDirigida>> {
            return localVarFp.getTesis(page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene el documento de las tesis
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTesisDocumento(options?: any): AxiosPromise<DocumentoProbatorio> {
            return localVarFp.getTesisDocumento(options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene el tipo de solicitud al que puede aplicar un usuario sni
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTipoSolicitudActual(options?: any): AxiosPromise<BaseCatalogo> {
            return localVarFp.getTipoSolicitudActual(options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene los totales de productos principales de congreso y divulgacion
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalCongresoDivulgacion(options?: any): AxiosPromise<number> {
            return localVarFp.getTotalCongresoDivulgacion(options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene los totales de productos generales y principales
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalProductos(options?: any): AxiosPromise<TotalProductos> {
            return localVarFp.getTotalProductos(options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene los totales de productos principales de tesis, cursos, y diplomados
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalTesisCursosDiplomados(options?: any): AxiosPromise<number> {
            return localVarFp.getTotalTesisCursosDiplomados(options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene la información de trayectoria académica (grados) de un usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrayectoriaAcademica(page?: number, size?: number, options?: any): AxiosPromise<Array<GradoAcademico>> {
            return localVarFp.getTrayectoriaAcademica(page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene la información de trayectoria profesional (empleos) de usuario
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrayectoriaProfesional(page?: number, size?: number, options?: any): AxiosPromise<Array<Empleo>> {
            return localVarFp.getTrayectoriaProfesional(page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene la información del último aviso o carta de acuerdo al tipo indicado
         * @param {TipoAviso} tipo Tipo de aviso
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUltimoAviso(tipo: TipoAviso, options?: any): AxiosPromise<Aviso> {
            return localVarFp.getUltimoAviso(tipo, options).then((request) => request(axios, basePath));
        },
        /**
         * Recupera el estado del job de jobrunr
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getjobStatus(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.getjobStatus(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Almacena la colaboración en los proyectos  Ecosistemas Nacionales Informáticos (ENI), Gestor de Mapas (Gema) , Sistema de Diseño y Accesibilidad para la Investigación (Sisdai)
         * @param {Colaboracion} colaboracion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guardarColaboracion(colaboracion: Colaboracion, options?: any): AxiosPromise<Array<Colaboracion>> {
            return localVarFp.guardarColaboracion(colaboracion, options).then((request) => request(axios, basePath));
        },
        /**
         * Almacena información de un aviso o carta
         * @param {Aviso} aviso 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAviso(aviso: Aviso, options?: any): AxiosPromise<void> {
            return localVarFp.saveAviso(aviso, options).then((request) => request(axios, basePath));
        },
        /**
         * Almacena un documento de congresos de usuario
         * @param {DocumentoProbatorio} documentoProbatorio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCongresoDocumento(documentoProbatorio: DocumentoProbatorio, options?: any): AxiosPromise<DocumentoProbatorio> {
            return localVarFp.saveCongresoDocumento(documentoProbatorio, options).then((request) => request(axios, basePath));
        },
        /**
         * Almacena un congreso de usuario
         * @param {Congreso} congreso 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCongresos(congreso: Congreso, options?: any): AxiosPromise<void> {
            return localVarFp.saveCongresos(congreso, options).then((request) => request(axios, basePath));
        },
        /**
         * Almacena un documento de los cursos de un usuario
         * @param {DocumentoProbatorio} documentoProbatorio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCursoDocumento(documentoProbatorio: DocumentoProbatorio, options?: any): AxiosPromise<DocumentoProbatorio> {
            return localVarFp.saveCursoDocumento(documentoProbatorio, options).then((request) => request(axios, basePath));
        },
        /**
         * Almacena información de formación continua de usuario
         * @param {Cursos} cursos 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCursos(cursos: Cursos, options?: any): AxiosPromise<Cursos> {
            return localVarFp.saveCursos(cursos, options).then((request) => request(axios, basePath));
        },
        /**
         * Almacena un curso impartido de usuario
         * @param {CursoImpartido} cursoImpartido 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCursosImpartidos(cursoImpartido: CursoImpartido, options?: any): AxiosPromise<void> {
            return localVarFp.saveCursosImpartidos(cursoImpartido, options).then((request) => request(axios, basePath));
        },
        /**
         * Almacena información de Desarrollo Institucional de usuario
         * @param {DesarrolloInstitucional} desarrolloInstitucional 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDesarrolloInstitucional(desarrolloInstitucional: DesarrolloInstitucional, options?: any): AxiosPromise<DesarrolloInstitucional> {
            return localVarFp.saveDesarrolloInstitucional(desarrolloInstitucional, options).then((request) => request(axios, basePath));
        },
        /**
         * Almacena un diplomado de usuario
         * @param {Diplomado} diplomado 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDiplomados(diplomado: Diplomado, options?: any): AxiosPromise<void> {
            return localVarFp.saveDiplomados(diplomado, options).then((request) => request(axios, basePath));
        },
        /**
         * Almacena un documento de los diplomados de un usuario
         * @param {DocumentoProbatorio} documentoProbatorio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDiplomadosDocumento(documentoProbatorio: DocumentoProbatorio, options?: any): AxiosPromise<DocumentoProbatorio> {
            return localVarFp.saveDiplomadosDocumento(documentoProbatorio, options).then((request) => request(axios, basePath));
        },
        /**
         * Almacena un trabajo de divulgacion de usuario
         * @param {TrabajoDivulgacion} trabajoDivulgacion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDivulgacion(trabajoDivulgacion: TrabajoDivulgacion, options?: any): AxiosPromise<void> {
            return localVarFp.saveDivulgacion(trabajoDivulgacion, options).then((request) => request(axios, basePath));
        },
        /**
         * Almacena un documento de divulgaciones de usuario
         * @param {DocumentoProbatorio} documentoProbatorio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDivulgacionDocumento(documentoProbatorio: DocumentoProbatorio, options?: any): AxiosPromise<DocumentoProbatorio> {
            return localVarFp.saveDivulgacionDocumento(documentoProbatorio, options).then((request) => request(axios, basePath));
        },
        /**
         * Almacena una estancia de usuario
         * @param {Estancia} estancia 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveEstancias(estancia: Estancia, options?: any): AxiosPromise<void> {
            return localVarFp.saveEstancias(estancia, options).then((request) => request(axios, basePath));
        },
        /**
         * Almacena una evaluación otorgada de usuario
         * @param {Evaluacion} evaluacion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveEvaluacionesOtorgadas(evaluacion: Evaluacion, options?: any): AxiosPromise<void> {
            return localVarFp.saveEvaluacionesOtorgadas(evaluacion, options).then((request) => request(axios, basePath));
        },
        /**
         * Almacena el cuestionario de variables sociodemográficas de un usuario
         * @param {Grupos} grupos 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveGrupos(grupos: Grupos, options?: any): AxiosPromise<Grupos> {
            return localVarFp.saveGrupos(grupos, options).then((request) => request(axios, basePath));
        },
        /**
         * Almacena información de idiomas de usuario
         * @param {Idiomas} idiomas 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveIdiomas(idiomas: Idiomas, options?: any): AxiosPromise<Idiomas> {
            return localVarFp.saveIdiomas(idiomas, options).then((request) => request(axios, basePath));
        },
        /**
         * Almacena un logro de usuario
         * @param {Logro} logro 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveLogros(logro: Logro, options?: any): AxiosPromise<void> {
            return localVarFp.saveLogros(logro, options).then((request) => request(axios, basePath));
        },
        /**
         * Almacena información general de perfil de usuario
         * @param {MiPerfil} miPerfil 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveMiPerfil(miPerfil: MiPerfil, options?: any): AxiosPromise<MiPerfil> {
            return localVarFp.saveMiPerfil(miPerfil, options).then((request) => request(axios, basePath));
        },
        /**
         * Almacena información de una solicitud
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSolicitud(solicitud: Solicitud, options?: any): AxiosPromise<void> {
            return localVarFp.saveSolicitud(solicitud, options).then((request) => request(axios, basePath));
        },
        /**
         * Almacena una tesis dirigida de usuario
         * @param {TesisDirigida} tesisDirigida 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTesis(tesisDirigida: TesisDirigida, options?: any): AxiosPromise<void> {
            return localVarFp.saveTesis(tesisDirigida, options).then((request) => request(axios, basePath));
        },
        /**
         * Almacena un documento tesis dirigida de usuario
         * @param {DocumentoProbatorio} documentoProbatorio 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTesisDocumento(documentoProbatorio: DocumentoProbatorio, options?: any): AxiosPromise<DocumentoProbatorio> {
            return localVarFp.saveTesisDocumento(documentoProbatorio, options).then((request) => request(axios, basePath));
        },
        /**
         * Almacena un grado académico de un usuario
         * @param {GradoAcademico} gradoAcademico 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTrayectoriaAcademica(gradoAcademico: GradoAcademico, options?: any): AxiosPromise<void> {
            return localVarFp.saveTrayectoriaAcademica(gradoAcademico, options).then((request) => request(axios, basePath));
        },
        /**
         * Almacena un empleo de usuario
         * @param {Empleo} empleo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTrayectoriaProfesional(empleo: Empleo, options?: any): AxiosPromise<void> {
            return localVarFp.saveTrayectoriaProfesional(empleo, options).then((request) => request(axios, basePath));
        },
        /**
         * Actualiza un congreso de usuario
         * @param {string} id id de congreso
         * @param {Congreso} congreso 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCongresosById(id: string, congreso: Congreso, options?: any): AxiosPromise<void> {
            return localVarFp.updateCongresosById(id, congreso, options).then((request) => request(axios, basePath));
        },
        /**
         * Actualiza la información de contactos de emergencia en la seccion general de perfil de usuario
         * @param {Array<ContactoEmergencias>} [contactoEmergencias] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContactosEmergencia(contactoEmergencias?: Array<ContactoEmergencias>, options?: any): AxiosPromise<Array<ContactoEmergencias>> {
            return localVarFp.updateContactosEmergencia(contactoEmergencias, options).then((request) => request(axios, basePath));
        },
        /**
         * Actualiza la información de formación continua de usuario
         * @param {string} id id del usuario
         * @param {Cursos} cursos 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCursosById(id: string, cursos: Cursos, options?: any): AxiosPromise<Cursos> {
            return localVarFp.updateCursosById(id, cursos, options).then((request) => request(axios, basePath));
        },
        /**
         * Actualiza un curso impartido de usuario
         * @param {string} id id de curso impartido
         * @param {CursoImpartido} cursoImpartido 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCursosImpartidosById(id: string, cursoImpartido: CursoImpartido, options?: any): AxiosPromise<void> {
            return localVarFp.updateCursosImpartidosById(id, cursoImpartido, options).then((request) => request(axios, basePath));
        },
        /**
         * Actualiza la información de los dependientes económicos en la seccion general del usuario de perfil
         * @param {Array<DependienteEconomico>} [dependienteEconomico] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDependientesEconomicos(dependienteEconomico?: Array<DependienteEconomico>, options?: any): AxiosPromise<Array<DependienteEconomico>> {
            return localVarFp.updateDependientesEconomicos(dependienteEconomico, options).then((request) => request(axios, basePath));
        },
        /**
         * Actualiza la información de Desarrollo Institucional
         * @param {number} id id del usuario
         * @param {DesarrolloInstitucional} desarrolloInstitucional 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDesarrolloInstitucionalById(id: number, desarrolloInstitucional: DesarrolloInstitucional, options?: any): AxiosPromise<DesarrolloInstitucional> {
            return localVarFp.updateDesarrolloInstitucionalById(id, desarrolloInstitucional, options).then((request) => request(axios, basePath));
        },
        /**
         * Actualiza un diplomado de usuario
         * @param {string} id id de diplomado
         * @param {Diplomado} diplomado 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDiplomadosById(id: string, diplomado: Diplomado, options?: any): AxiosPromise<void> {
            return localVarFp.updateDiplomadosById(id, diplomado, options).then((request) => request(axios, basePath));
        },
        /**
         * Actualiza un trabajo de divulgación de usuario
         * @param {string} id id de trabajo de divulgación
         * @param {TrabajoDivulgacion} trabajoDivulgacion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDivulgacionById(id: string, trabajoDivulgacion: TrabajoDivulgacion, options?: any): AxiosPromise<void> {
            return localVarFp.updateDivulgacionById(id, trabajoDivulgacion, options).then((request) => request(axios, basePath));
        },
        /**
         * Actualiza una estancia de usuario
         * @param {string} id id de estancia
         * @param {Estancia} estancia 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEstanciasById(id: string, estancia: Estancia, options?: any): AxiosPromise<void> {
            return localVarFp.updateEstanciasById(id, estancia, options).then((request) => request(axios, basePath));
        },
        /**
         * Actualiza una evaluación otorgada de usuario
         * @param {string} id id de evaluación
         * @param {Evaluacion} evaluacion 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEvaluacionesOtorgadasById(id: string, evaluacion: Evaluacion, options?: any): AxiosPromise<void> {
            return localVarFp.updateEvaluacionesOtorgadasById(id, evaluacion, options).then((request) => request(axios, basePath));
        },
        /**
         * Actualiza el cuestionario de variables sociodemográficas de un usuario
         * @param {number} id id del usuario
         * @param {Grupos} grupos 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGruposById(id: number, grupos: Grupos, options?: any): AxiosPromise<Grupos> {
            return localVarFp.updateGruposById(id, grupos, options).then((request) => request(axios, basePath));
        },
        /**
         * Actualiza la información de idiomas de usuario
         * @param {number} id id del usuario
         * @param {Idiomas} idiomas 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateIdiomasById(id: number, idiomas: Idiomas, options?: any): AxiosPromise<Idiomas> {
            return localVarFp.updateIdiomasById(id, idiomas, options).then((request) => request(axios, basePath));
        },
        /**
         * Actualiza un logro de usuario
         * @param {string} id id de logro
         * @param {Logro} logro 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLogrosById(id: string, logro: Logro, options?: any): AxiosPromise<void> {
            return localVarFp.updateLogrosById(id, logro, options).then((request) => request(axios, basePath));
        },
        /**
         * Actualiza la información de medios de contacto en la sección general de perfil de usuario
         * @param {Array<Contacto>} [contacto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMediosContacto(contacto?: Array<Contacto>, options?: any): AxiosPromise<Array<Contacto>> {
            return localVarFp.updateMediosContacto(contacto, options).then((request) => request(axios, basePath));
        },
        /**
         * Actualiza la información general de perfil de usuario
         * @param {number} id id del usuario
         * @param {MiPerfil} miPerfil 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMiPerfilById(id: number, miPerfil: MiPerfil, options?: any): AxiosPromise<MiPerfil> {
            return localVarFp.updateMiPerfilById(id, miPerfil, options).then((request) => request(axios, basePath));
        },
        /**
         * Actualiza la solicitud
         * @param {string} id id de la solicitud
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSolicitud(id: string, solicitud: Solicitud, options?: any): AxiosPromise<void> {
            return localVarFp.updateSolicitud(id, solicitud, options).then((request) => request(axios, basePath));
        },
        /**
         * Actualiza una tesis dirigida de usuario
         * @param {string} id id de tesis
         * @param {TesisDirigida} tesisDirigida 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTesisById(id: string, tesisDirigida: TesisDirigida, options?: any): AxiosPromise<void> {
            return localVarFp.updateTesisById(id, tesisDirigida, options).then((request) => request(axios, basePath));
        },
        /**
         * Actualiza un grado académico de un usuario
         * @param {string} id id de grado académico
         * @param {GradoAcademico} gradoAcademico 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTrayectoriaAcademicaById(id: string, gradoAcademico: GradoAcademico, options?: any): AxiosPromise<void> {
            return localVarFp.updateTrayectoriaAcademicaById(id, gradoAcademico, options).then((request) => request(axios, basePath));
        },
        /**
         * Actualiza un empleo de usuario
         * @param {string} id id de empleo
         * @param {Empleo} empleo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTrayectoriaProfesionalById(id: string, empleo: Empleo, options?: any): AxiosPromise<void> {
            return localVarFp.updateTrayectoriaProfesionalById(id, empleo, options).then((request) => request(axios, basePath));
        },
        /**
         * Verifica que exista un perfil o crea uno nuevo de un usuario recien registrado
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validaPerfil(options?: any): AxiosPromise<MiPerfil> {
            return localVarFp.validaPerfil(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PerfilApi - object-oriented interface
 * @export
 * @class PerfilApi
 * @extends {BaseAPI}
 */
export class PerfilApi extends BaseAPI {
    /**
     * Elimina un Colaboración del usuario
     * @param {string} id id de Colaboración
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public deleteColaboracionById(id: string, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).deleteColaboracionById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Elimina un congreso de usuario
     * @param {string} id id de congreso
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public deleteCongresosById(id: string, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).deleteCongresosById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Elimina un curso impartido de usuario
     * @param {string} id id de curso impartido
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public deleteCursosImpartidosById(id: string, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).deleteCursosImpartidosById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Elimina un diplomado de usuario
     * @param {string} id id de diplomado
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public deleteDiplomadosById(id: string, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).deleteDiplomadosById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Elimina un trabajo de divulgación de usuario
     * @param {string} id id de trabajo de divulgación
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public deleteDivulgacionById(id: string, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).deleteDivulgacionById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Elimina una estancia de usuario
     * @param {string} id id de estancia
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public deleteEstanciasById(id: string, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).deleteEstanciasById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Elimina una evaluación otorgada de usuario
     * @param {string} id id de evaluación
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public deleteEvaluacionesOtorgadasById(id: string, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).deleteEvaluacionesOtorgadasById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Elimina un logro de usuario
     * @param {string} id id de logro
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public deleteLogrosById(id: string, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).deleteLogrosById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Elimina una tesis dirigida de usuario
     * @param {string} id id de tesis
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public deleteTesisById(id: string, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).deleteTesisById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Elimina un grado académico de un usuario
     * @param {string} id id de grado académico
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public deleteTrayectoriaAcademicaById(id: string, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).deleteTrayectoriaAcademicaById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Elimina un empleo de usuario
     * @param {string} id id de empleo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public deleteTrayectoriaProfesionalById(id: string, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).deleteTrayectoriaProfesionalById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene los perfiles de usuario dado el parámetro de filtro, ya sea por id de la institución, nombre o ambos
     * @param {Filtro} [filtro] filtro para realizar consultas
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public getAllPerfiles(filtro?: Filtro, page?: number, size?: number, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).getAllPerfiles(filtro, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene datos breves de perfiles de los cvus indicados
     * @param {Array<string>} cvus cvus de los usuarios
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public getBulkPerfiles(cvus: Array<string>, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).getBulkPerfiles(cvus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene la información de las colaboraciones con Ecosistemas Nacionales Informáticos (ENI), Gestor de Mapas (Gema) , Sistema de Diseño y Accesibilidad para la Investigación (Sisdai)
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public getColaboraciones(page?: number, size?: number, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).getColaboraciones(page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene la información de Congresos de usuario
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public getCongresos(page?: number, size?: number, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).getCongresos(page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene el documento de las participaciones en congresos
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public getCongresosDocumento(options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).getCongresosDocumento(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene el documento probatorio de los cursos
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public getCursoDocumento(options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).getCursoDocumento(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene la información de formación continua de usuario
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public getCursos(options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).getCursos(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene la información de formación continua de un usuario
     * @param {number} id id del usuario
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public getCursosById(id: number, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).getCursosById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene la información de cursos impartidos de usuario
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public getCursosImpartidos(page?: number, size?: number, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).getCursosImpartidos(page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene la información de Desarrollo Institucional de usuario
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public getDesarrolloInstitucional(options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).getDesarrolloInstitucional(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene la información de Diplomados de usuario
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public getDiplomados(page?: number, size?: number, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).getDiplomados(page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene el documento probatorio de los diplomados
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public getDiplomadosDocumento(options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).getDiplomadosDocumento(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene la información de Divulgacion de usuario
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public getDivulgacion(page?: number, size?: number, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).getDivulgacion(page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene el documento de las divulgaciones
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public getDivulgacionDocumento(options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).getDivulgacionDocumento(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene la información del empleo actual de un usuario
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public getEmpleoActual(options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).getEmpleoActual(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene el empleo principal de usuario dado un cvu
     * @param {string} cvu cvu del usuario
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public getEmpleoPrincipal(cvu: string, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).getEmpleoPrincipal(cvu, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene la información de estancias de usuario
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public getEstancias(page?: number, size?: number, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).getEstancias(page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene la información de evaluaciones otorgadas de usuario
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public getEvaluacionesOtorgadas(page?: number, size?: number, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).getEvaluacionesOtorgadas(page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Cancela una solicitud de un usuario
     * @param {string} id id de la solicitud a cancelar
     * @param {SolicitudCancelada} solicitudCancelada Información de la cancelacion de la solicitud
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public getExportarReporteSolicitudCancelar(id: string, solicitudCancelada: SolicitudCancelada, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).getExportarReporteSolicitudCancelar(id, solicitudCancelada, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene el cuestionario de variables sociodemográficas de un usuario
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public getGrupos(options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).getGrupos(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene el cuestionario de variables sociodemográficas de un usuario
     * @param {number} id id del usuario
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public getGruposById(id: number, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).getGruposById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene la información de idiomas de usuario
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public getIdiomas(options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).getIdiomas(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene la información de idiomas
     * @param {number} id id del usuario
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public getIdiomasById(id: number, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).getIdiomasById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene la información de logros de usuario
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public getLogros(page?: number, size?: number, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).getLogros(page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene la información general de perfil de usuario
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public getMiPerfil(options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).getMiPerfil(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene la información de perfil de usuario
     * @param {number} id id del usuario
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public getMiPerfilById(id: number, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).getMiPerfilById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene un pdf en base64 del perfil dado un cvu o login
     * @param {string} [cvu] cvu del usuario
     * @param {string} [login] correo de login del usuario
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public getPdfPerfil(cvu?: string, login?: string, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).getPdfPerfil(cvu, login, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene el perfil de usuario dado alguno de los parámetros cvu o login
     * @param {string} [cvu] cvu del usuario
     * @param {string} [login] correo de login del usuario
     * @param {boolean} [completo] Indica si obtendrá información completa de perfil
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public getPerfil(cvu?: string, login?: string, completo?: boolean, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).getPerfil(cvu, login, completo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene el perfil de usuario dado un cvu
     * @param {string} cvu cvu del usuario
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public getPerfilByCvu(cvu: string, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).getPerfilByCvu(cvu, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Exportar pdf perfil único
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public getReportePerfil(options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).getReportePerfil(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Exportar pdf de solicitud
     * @param {string} id id de la solicitud
     * @param {string} tipo tipo documento de la solicitud
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public getReporteSolicitud(id: string, tipo: string, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).getReporteSolicitud(id, tipo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene la solicitud de un usuario
     * @param {string} id id de la solicitud
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public getSolicitud(id: string, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).getSolicitud(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene la información de las solicitudes del usuario
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public getSolicitudes(options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).getSolicitudes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene la información de Tesis dirigidas de usuario
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public getTesis(page?: number, size?: number, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).getTesis(page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene el documento de las tesis
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public getTesisDocumento(options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).getTesisDocumento(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene el tipo de solicitud al que puede aplicar un usuario sni
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public getTipoSolicitudActual(options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).getTipoSolicitudActual(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene los totales de productos principales de congreso y divulgacion
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public getTotalCongresoDivulgacion(options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).getTotalCongresoDivulgacion(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene los totales de productos generales y principales
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public getTotalProductos(options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).getTotalProductos(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene los totales de productos principales de tesis, cursos, y diplomados
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public getTotalTesisCursosDiplomados(options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).getTotalTesisCursosDiplomados(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene la información de trayectoria académica (grados) de un usuario
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public getTrayectoriaAcademica(page?: number, size?: number, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).getTrayectoriaAcademica(page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene la información de trayectoria profesional (empleos) de usuario
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public getTrayectoriaProfesional(page?: number, size?: number, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).getTrayectoriaProfesional(page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene la información del último aviso o carta de acuerdo al tipo indicado
     * @param {TipoAviso} tipo Tipo de aviso
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public getUltimoAviso(tipo: TipoAviso, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).getUltimoAviso(tipo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Recupera el estado del job de jobrunr
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public getjobStatus(id: string, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).getjobStatus(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Almacena la colaboración en los proyectos  Ecosistemas Nacionales Informáticos (ENI), Gestor de Mapas (Gema) , Sistema de Diseño y Accesibilidad para la Investigación (Sisdai)
     * @param {Colaboracion} colaboracion 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public guardarColaboracion(colaboracion: Colaboracion, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).guardarColaboracion(colaboracion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Almacena información de un aviso o carta
     * @param {Aviso} aviso 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public saveAviso(aviso: Aviso, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).saveAviso(aviso, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Almacena un documento de congresos de usuario
     * @param {DocumentoProbatorio} documentoProbatorio 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public saveCongresoDocumento(documentoProbatorio: DocumentoProbatorio, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).saveCongresoDocumento(documentoProbatorio, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Almacena un congreso de usuario
     * @param {Congreso} congreso 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public saveCongresos(congreso: Congreso, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).saveCongresos(congreso, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Almacena un documento de los cursos de un usuario
     * @param {DocumentoProbatorio} documentoProbatorio 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public saveCursoDocumento(documentoProbatorio: DocumentoProbatorio, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).saveCursoDocumento(documentoProbatorio, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Almacena información de formación continua de usuario
     * @param {Cursos} cursos 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public saveCursos(cursos: Cursos, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).saveCursos(cursos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Almacena un curso impartido de usuario
     * @param {CursoImpartido} cursoImpartido 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public saveCursosImpartidos(cursoImpartido: CursoImpartido, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).saveCursosImpartidos(cursoImpartido, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Almacena información de Desarrollo Institucional de usuario
     * @param {DesarrolloInstitucional} desarrolloInstitucional 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public saveDesarrolloInstitucional(desarrolloInstitucional: DesarrolloInstitucional, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).saveDesarrolloInstitucional(desarrolloInstitucional, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Almacena un diplomado de usuario
     * @param {Diplomado} diplomado 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public saveDiplomados(diplomado: Diplomado, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).saveDiplomados(diplomado, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Almacena un documento de los diplomados de un usuario
     * @param {DocumentoProbatorio} documentoProbatorio 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public saveDiplomadosDocumento(documentoProbatorio: DocumentoProbatorio, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).saveDiplomadosDocumento(documentoProbatorio, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Almacena un trabajo de divulgacion de usuario
     * @param {TrabajoDivulgacion} trabajoDivulgacion 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public saveDivulgacion(trabajoDivulgacion: TrabajoDivulgacion, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).saveDivulgacion(trabajoDivulgacion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Almacena un documento de divulgaciones de usuario
     * @param {DocumentoProbatorio} documentoProbatorio 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public saveDivulgacionDocumento(documentoProbatorio: DocumentoProbatorio, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).saveDivulgacionDocumento(documentoProbatorio, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Almacena una estancia de usuario
     * @param {Estancia} estancia 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public saveEstancias(estancia: Estancia, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).saveEstancias(estancia, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Almacena una evaluación otorgada de usuario
     * @param {Evaluacion} evaluacion 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public saveEvaluacionesOtorgadas(evaluacion: Evaluacion, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).saveEvaluacionesOtorgadas(evaluacion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Almacena el cuestionario de variables sociodemográficas de un usuario
     * @param {Grupos} grupos 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public saveGrupos(grupos: Grupos, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).saveGrupos(grupos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Almacena información de idiomas de usuario
     * @param {Idiomas} idiomas 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public saveIdiomas(idiomas: Idiomas, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).saveIdiomas(idiomas, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Almacena un logro de usuario
     * @param {Logro} logro 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public saveLogros(logro: Logro, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).saveLogros(logro, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Almacena información general de perfil de usuario
     * @param {MiPerfil} miPerfil 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public saveMiPerfil(miPerfil: MiPerfil, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).saveMiPerfil(miPerfil, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Almacena información de una solicitud
     * @param {Solicitud} solicitud 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public saveSolicitud(solicitud: Solicitud, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).saveSolicitud(solicitud, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Almacena una tesis dirigida de usuario
     * @param {TesisDirigida} tesisDirigida 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public saveTesis(tesisDirigida: TesisDirigida, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).saveTesis(tesisDirigida, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Almacena un documento tesis dirigida de usuario
     * @param {DocumentoProbatorio} documentoProbatorio 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public saveTesisDocumento(documentoProbatorio: DocumentoProbatorio, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).saveTesisDocumento(documentoProbatorio, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Almacena un grado académico de un usuario
     * @param {GradoAcademico} gradoAcademico 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public saveTrayectoriaAcademica(gradoAcademico: GradoAcademico, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).saveTrayectoriaAcademica(gradoAcademico, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Almacena un empleo de usuario
     * @param {Empleo} empleo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public saveTrayectoriaProfesional(empleo: Empleo, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).saveTrayectoriaProfesional(empleo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actualiza un congreso de usuario
     * @param {string} id id de congreso
     * @param {Congreso} congreso 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public updateCongresosById(id: string, congreso: Congreso, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).updateCongresosById(id, congreso, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actualiza la información de contactos de emergencia en la seccion general de perfil de usuario
     * @param {Array<ContactoEmergencias>} [contactoEmergencias] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public updateContactosEmergencia(contactoEmergencias?: Array<ContactoEmergencias>, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).updateContactosEmergencia(contactoEmergencias, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actualiza la información de formación continua de usuario
     * @param {string} id id del usuario
     * @param {Cursos} cursos 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public updateCursosById(id: string, cursos: Cursos, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).updateCursosById(id, cursos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actualiza un curso impartido de usuario
     * @param {string} id id de curso impartido
     * @param {CursoImpartido} cursoImpartido 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public updateCursosImpartidosById(id: string, cursoImpartido: CursoImpartido, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).updateCursosImpartidosById(id, cursoImpartido, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actualiza la información de los dependientes económicos en la seccion general del usuario de perfil
     * @param {Array<DependienteEconomico>} [dependienteEconomico] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public updateDependientesEconomicos(dependienteEconomico?: Array<DependienteEconomico>, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).updateDependientesEconomicos(dependienteEconomico, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actualiza la información de Desarrollo Institucional
     * @param {number} id id del usuario
     * @param {DesarrolloInstitucional} desarrolloInstitucional 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public updateDesarrolloInstitucionalById(id: number, desarrolloInstitucional: DesarrolloInstitucional, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).updateDesarrolloInstitucionalById(id, desarrolloInstitucional, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actualiza un diplomado de usuario
     * @param {string} id id de diplomado
     * @param {Diplomado} diplomado 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public updateDiplomadosById(id: string, diplomado: Diplomado, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).updateDiplomadosById(id, diplomado, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actualiza un trabajo de divulgación de usuario
     * @param {string} id id de trabajo de divulgación
     * @param {TrabajoDivulgacion} trabajoDivulgacion 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public updateDivulgacionById(id: string, trabajoDivulgacion: TrabajoDivulgacion, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).updateDivulgacionById(id, trabajoDivulgacion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actualiza una estancia de usuario
     * @param {string} id id de estancia
     * @param {Estancia} estancia 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public updateEstanciasById(id: string, estancia: Estancia, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).updateEstanciasById(id, estancia, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actualiza una evaluación otorgada de usuario
     * @param {string} id id de evaluación
     * @param {Evaluacion} evaluacion 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public updateEvaluacionesOtorgadasById(id: string, evaluacion: Evaluacion, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).updateEvaluacionesOtorgadasById(id, evaluacion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actualiza el cuestionario de variables sociodemográficas de un usuario
     * @param {number} id id del usuario
     * @param {Grupos} grupos 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public updateGruposById(id: number, grupos: Grupos, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).updateGruposById(id, grupos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actualiza la información de idiomas de usuario
     * @param {number} id id del usuario
     * @param {Idiomas} idiomas 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public updateIdiomasById(id: number, idiomas: Idiomas, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).updateIdiomasById(id, idiomas, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actualiza un logro de usuario
     * @param {string} id id de logro
     * @param {Logro} logro 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public updateLogrosById(id: string, logro: Logro, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).updateLogrosById(id, logro, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actualiza la información de medios de contacto en la sección general de perfil de usuario
     * @param {Array<Contacto>} [contacto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public updateMediosContacto(contacto?: Array<Contacto>, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).updateMediosContacto(contacto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actualiza la información general de perfil de usuario
     * @param {number} id id del usuario
     * @param {MiPerfil} miPerfil 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public updateMiPerfilById(id: number, miPerfil: MiPerfil, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).updateMiPerfilById(id, miPerfil, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actualiza la solicitud
     * @param {string} id id de la solicitud
     * @param {Solicitud} solicitud 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public updateSolicitud(id: string, solicitud: Solicitud, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).updateSolicitud(id, solicitud, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actualiza una tesis dirigida de usuario
     * @param {string} id id de tesis
     * @param {TesisDirigida} tesisDirigida 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public updateTesisById(id: string, tesisDirigida: TesisDirigida, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).updateTesisById(id, tesisDirigida, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actualiza un grado académico de un usuario
     * @param {string} id id de grado académico
     * @param {GradoAcademico} gradoAcademico 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public updateTrayectoriaAcademicaById(id: string, gradoAcademico: GradoAcademico, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).updateTrayectoriaAcademicaById(id, gradoAcademico, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actualiza un empleo de usuario
     * @param {string} id id de empleo
     * @param {Empleo} empleo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public updateTrayectoriaProfesionalById(id: string, empleo: Empleo, options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).updateTrayectoriaProfesionalById(id, empleo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Verifica que exista un perfil o crea uno nuevo de un usuario recien registrado
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerfilApi
     */
    public validaPerfil(options?: AxiosRequestConfig) {
        return PerfilApiFp(this.configuration).validaPerfil(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RegistroApi - axios parameter creator
 * @export
 */
export const RegistroApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Verifica que exista un perfil o crea uno nuevo de un usuario recien registrado
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validaPerfil: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mi-perfil/valida`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RegistroApi - functional programming interface
 * @export
 */
export const RegistroApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RegistroApiAxiosParamCreator(configuration)
    return {
        /**
         * Verifica que exista un perfil o crea uno nuevo de un usuario recien registrado
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validaPerfil(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MiPerfil>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validaPerfil(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RegistroApi - factory interface
 * @export
 */
export const RegistroApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RegistroApiFp(configuration)
    return {
        /**
         * Verifica que exista un perfil o crea uno nuevo de un usuario recien registrado
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validaPerfil(options?: any): AxiosPromise<MiPerfil> {
            return localVarFp.validaPerfil(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RegistroApi - object-oriented interface
 * @export
 * @class RegistroApi
 * @extends {BaseAPI}
 */
export class RegistroApi extends BaseAPI {
    /**
     * Verifica que exista un perfil o crea uno nuevo de un usuario recien registrado
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistroApi
     */
    public validaPerfil(options?: AxiosRequestConfig) {
        return RegistroApiFp(this.configuration).validaPerfil(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SniApi - axios parameter creator
 * @export
 */
export const SniApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Envío de solicitud eméritos
         * @param {string} id id de solicitud
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enviarSolicitudEmerito: async (id: string, solicitud: Solicitud, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('enviarSolicitudEmerito', 'id', id)
            // verify required parameter 'solicitud' is not null or undefined
            assertParamExists('enviarSolicitudEmerito', 'solicitud', solicitud)
            const localVarPath = `/solicitudes-sni/emeritos/{id}/enviar`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(solicitud, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Exportar pdf de solicitud de Eméritos
         * @param {string} id id de la solicitud de Eméritos
         * @param {string} urlPu Url del documento de PU codificada en base 64
         * @param {string} tipo tipo documento de la solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReporteSolicitudEmeritos: async (id: string, urlPu: string, tipo: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getReporteSolicitudEmeritos', 'id', id)
            // verify required parameter 'urlPu' is not null or undefined
            assertParamExists('getReporteSolicitudEmeritos', 'urlPu', urlPu)
            // verify required parameter 'tipo' is not null or undefined
            assertParamExists('getReporteSolicitudEmeritos', 'tipo', tipo)
            const localVarPath = `/solicitudes-sni/emeritos/{id}/pdf`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (urlPu !== undefined) {
                localVarQueryParameter['urlPu'] = urlPu;
            }

            if (tipo !== undefined) {
                localVarQueryParameter['tipo'] = tipo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Almacena información de una solicitud eméritos
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSolicitudEmerito: async (solicitud: Solicitud, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'solicitud' is not null or undefined
            assertParamExists('saveSolicitudEmerito', 'solicitud', solicitud)
            const localVarPath = `/solicitudes-sni/emeritos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(solicitud, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actualiza una solicitud eméritos
         * @param {string} id id de solicitud
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSolicitudEmerito: async (id: string, solicitud: Solicitud, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSolicitudEmerito', 'id', id)
            // verify required parameter 'solicitud' is not null or undefined
            assertParamExists('updateSolicitudEmerito', 'solicitud', solicitud)
            const localVarPath = `/solicitudes-sni/emeritos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(solicitud, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Verifica que un usuario pueda participar en la convocatoria de acuerdo a los requisitos mínimos. En caso de no existir errores regresa una lista vacia.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validaRequisitos: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/solicitudes-sni/emeritos/valida-requisitos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SniApi - functional programming interface
 * @export
 */
export const SniApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SniApiAxiosParamCreator(configuration)
    return {
        /**
         * Envío de solicitud eméritos
         * @param {string} id id de solicitud
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enviarSolicitudEmerito(id: string, solicitud: Solicitud, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enviarSolicitudEmerito(id, solicitud, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Exportar pdf de solicitud de Eméritos
         * @param {string} id id de la solicitud de Eméritos
         * @param {string} urlPu Url del documento de PU codificada en base 64
         * @param {string} tipo tipo documento de la solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReporteSolicitudEmeritos(id: string, urlPu: string, tipo: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentoExport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReporteSolicitudEmeritos(id, urlPu, tipo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Almacena información de una solicitud eméritos
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveSolicitudEmerito(solicitud: Solicitud, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveSolicitudEmerito(solicitud, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Actualiza una solicitud eméritos
         * @param {string} id id de solicitud
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSolicitudEmerito(id: string, solicitud: Solicitud, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSolicitudEmerito(id, solicitud, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Verifica que un usuario pueda participar en la convocatoria de acuerdo a los requisitos mínimos. En caso de no existir errores regresa una lista vacia.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validaRequisitos(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ErrorValidacion>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validaRequisitos(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SniApi - factory interface
 * @export
 */
export const SniApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SniApiFp(configuration)
    return {
        /**
         * Envío de solicitud eméritos
         * @param {string} id id de solicitud
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enviarSolicitudEmerito(id: string, solicitud: Solicitud, options?: any): AxiosPromise<void> {
            return localVarFp.enviarSolicitudEmerito(id, solicitud, options).then((request) => request(axios, basePath));
        },
        /**
         * Exportar pdf de solicitud de Eméritos
         * @param {string} id id de la solicitud de Eméritos
         * @param {string} urlPu Url del documento de PU codificada en base 64
         * @param {string} tipo tipo documento de la solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReporteSolicitudEmeritos(id: string, urlPu: string, tipo: string, options?: any): AxiosPromise<DocumentoExport> {
            return localVarFp.getReporteSolicitudEmeritos(id, urlPu, tipo, options).then((request) => request(axios, basePath));
        },
        /**
         * Almacena información de una solicitud eméritos
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSolicitudEmerito(solicitud: Solicitud, options?: any): AxiosPromise<void> {
            return localVarFp.saveSolicitudEmerito(solicitud, options).then((request) => request(axios, basePath));
        },
        /**
         * Actualiza una solicitud eméritos
         * @param {string} id id de solicitud
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSolicitudEmerito(id: string, solicitud: Solicitud, options?: any): AxiosPromise<void> {
            return localVarFp.updateSolicitudEmerito(id, solicitud, options).then((request) => request(axios, basePath));
        },
        /**
         * Verifica que un usuario pueda participar en la convocatoria de acuerdo a los requisitos mínimos. En caso de no existir errores regresa una lista vacia.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validaRequisitos(options?: any): AxiosPromise<Array<ErrorValidacion>> {
            return localVarFp.validaRequisitos(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SniApi - object-oriented interface
 * @export
 * @class SniApi
 * @extends {BaseAPI}
 */
export class SniApi extends BaseAPI {
    /**
     * Envío de solicitud eméritos
     * @param {string} id id de solicitud
     * @param {Solicitud} solicitud 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SniApi
     */
    public enviarSolicitudEmerito(id: string, solicitud: Solicitud, options?: AxiosRequestConfig) {
        return SniApiFp(this.configuration).enviarSolicitudEmerito(id, solicitud, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Exportar pdf de solicitud de Eméritos
     * @param {string} id id de la solicitud de Eméritos
     * @param {string} urlPu Url del documento de PU codificada en base 64
     * @param {string} tipo tipo documento de la solicitud
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SniApi
     */
    public getReporteSolicitudEmeritos(id: string, urlPu: string, tipo: string, options?: AxiosRequestConfig) {
        return SniApiFp(this.configuration).getReporteSolicitudEmeritos(id, urlPu, tipo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Almacena información de una solicitud eméritos
     * @param {Solicitud} solicitud 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SniApi
     */
    public saveSolicitudEmerito(solicitud: Solicitud, options?: AxiosRequestConfig) {
        return SniApiFp(this.configuration).saveSolicitudEmerito(solicitud, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actualiza una solicitud eméritos
     * @param {string} id id de solicitud
     * @param {Solicitud} solicitud 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SniApi
     */
    public updateSolicitudEmerito(id: string, solicitud: Solicitud, options?: AxiosRequestConfig) {
        return SniApiFp(this.configuration).updateSolicitudEmerito(id, solicitud, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Verifica que un usuario pueda participar en la convocatoria de acuerdo a los requisitos mínimos. En caso de no existir errores regresa una lista vacia.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SniApi
     */
    public validaRequisitos(options?: AxiosRequestConfig) {
        return SniApiFp(this.configuration).validaRequisitos(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SolicitudApi - axios parameter creator
 * @export
 */
export const SolicitudApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Cancela una solicitud
         * @param {string} id id de la solicitud
         * @param {SolicitudCancelada} solicitudCancelada Información de la cancelacion de la solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelaSolicitud: async (id: string, solicitudCancelada: SolicitudCancelada, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('cancelaSolicitud', 'id', id)
            // verify required parameter 'solicitudCancelada' is not null or undefined
            assertParamExists('cancelaSolicitud', 'solicitudCancelada', solicitudCancelada)
            const localVarPath = `/solicitud-ingreso-permanencia/{id}/cancelacion`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(solicitudCancelada, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Genera todo el proceso para el envio de la solicitud
         * @param {string} id id de la solicitud
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enviarSolicitud: async (id: string, solicitud: Solicitud, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('enviarSolicitud', 'id', id)
            // verify required parameter 'solicitud' is not null or undefined
            assertParamExists('enviarSolicitud', 'solicitud', solicitud)
            const localVarPath = `/solicitud-ingreso-permanencia/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(solicitud, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Envío de solicitud becas
         * @param {string} id id de solicitud
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enviarSolicitudBecas: async (id: string, solicitud: Solicitud, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('enviarSolicitudBecas', 'id', id)
            // verify required parameter 'solicitud' is not null or undefined
            assertParamExists('enviarSolicitudBecas', 'solicitud', solicitud)
            const localVarPath = `/solicitudes-becas/nacionales/{id}/enviar`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(solicitud, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Envío de solicitud eméritos
         * @param {string} id id de solicitud
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enviarSolicitudEmerito: async (id: string, solicitud: Solicitud, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('enviarSolicitudEmerito', 'id', id)
            // verify required parameter 'solicitud' is not null or undefined
            assertParamExists('enviarSolicitudEmerito', 'solicitud', solicitud)
            const localVarPath = `/solicitudes-sni/emeritos/{id}/enviar`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(solicitud, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Indica si el usuario logeado con cvu es apto para enviar una solicitud dado un id de solución
         * @param {string} idSolucion id de solucion o convocatoria
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        esReenviable: async (idSolucion: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idSolucion' is not null or undefined
            assertParamExists('esReenviable', 'idSolucion', idSolucion)
            const localVarPath = `/solicitudes/whitelist/reenviable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (idSolucion !== undefined) {
                localVarQueryParameter['idSolucion'] = idSolucion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Descarga carta ISSSTE de un becario cuando tiene una solicitud de becas nacionales y se encuentra ASIGNADA o FORMALIZADA
         * @param {string} id id de la solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentoExportCartaISSSTE: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDocumentoExportCartaISSSTE', 'id', id)
            const localVarPath = `/solicitudes-becas/nacionales/{id}/carta-issste`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Cancela una solicitud de un usuario
         * @param {string} id id de la solicitud a cancelar
         * @param {SolicitudCancelada} solicitudCancelada Información de la cancelacion de la solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExportarReporteSolicitudCancelar: async (id: string, solicitudCancelada: SolicitudCancelada, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getExportarReporteSolicitudCancelar', 'id', id)
            // verify required parameter 'solicitudCancelada' is not null or undefined
            assertParamExists('getExportarReporteSolicitudCancelar', 'solicitudCancelada', solicitudCancelada)
            const localVarPath = `/exportar-reporte-solicitud/{id}/cancelar`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(solicitudCancelada, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Exportar pdf de solicitud
         * @param {string} id id de la solicitud
         * @param {string} tipo tipo documento de la solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReporteSolicitud: async (id: string, tipo: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getReporteSolicitud', 'id', id)
            // verify required parameter 'tipo' is not null or undefined
            assertParamExists('getReporteSolicitud', 'tipo', tipo)
            const localVarPath = `/solicitudes-sni/ingreso-permanencia/{id}/{tipo}/pdf`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"tipo"}}`, encodeURIComponent(String(tipo)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Exportar pdf de solicitud de becas
         * @param {string} id id de la solicitud de becas
         * @param {string} urlPu Url del documento de PU codificada en base 64
         * @param {string} tipo tipo documento de la solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReporteSolicitudBecas: async (id: string, urlPu: string, tipo: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getReporteSolicitudBecas', 'id', id)
            // verify required parameter 'urlPu' is not null or undefined
            assertParamExists('getReporteSolicitudBecas', 'urlPu', urlPu)
            // verify required parameter 'tipo' is not null or undefined
            assertParamExists('getReporteSolicitudBecas', 'tipo', tipo)
            const localVarPath = `/solicitudes-becas/nacionales/{id}/pdf`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (urlPu !== undefined) {
                localVarQueryParameter['urlPu'] = urlPu;
            }

            if (tipo !== undefined) {
                localVarQueryParameter['tipo'] = tipo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Exportar pdf de solicitud de becas extranjero
         * @param {TipoBecaExtranjero} tipoBecaExtranjero Tipo de Beca Extranjera
         * @param {string} id id de la solicitud de becas
         * @param {boolean} esEnviada Bandera para saber si una solicitud esta enviada para la marca de agua
         * @param {string} [password] Contraseña para firma de solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReporteSolicitudBecasExtranjero: async (tipoBecaExtranjero: TipoBecaExtranjero, id: string, esEnviada: boolean, password?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tipoBecaExtranjero' is not null or undefined
            assertParamExists('getReporteSolicitudBecasExtranjero', 'tipoBecaExtranjero', tipoBecaExtranjero)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getReporteSolicitudBecasExtranjero', 'id', id)
            // verify required parameter 'esEnviada' is not null or undefined
            assertParamExists('getReporteSolicitudBecasExtranjero', 'esEnviada', esEnviada)
            const localVarPath = `/solicitudes-becas-extranjero/{tipoBecaExtranjero}/{id}/pdf`
                .replace(`{${"tipoBecaExtranjero"}}`, encodeURIComponent(String(tipoBecaExtranjero)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (esEnviada !== undefined) {
                localVarQueryParameter['esEnviada'] = esEnviada;
            }

            if (password !== undefined) {
                localVarQueryParameter['password'] = password;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Genera y obtiene el acuse en base64 del pdf de cancelación de una solicitud
         * @param {TipoSolucion} tipoSolucion Tipo de solución (Convocatoria)
         * @param {string} id id de la solicitud
         * @param {SolicitudCancelada} solicitudCancelada Información de la cancelacion de la solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReporteSolicitudCancelada: async (tipoSolucion: TipoSolucion, id: string, solicitudCancelada: SolicitudCancelada, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tipoSolucion' is not null or undefined
            assertParamExists('getReporteSolicitudCancelada', 'tipoSolucion', tipoSolucion)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getReporteSolicitudCancelada', 'id', id)
            // verify required parameter 'solicitudCancelada' is not null or undefined
            assertParamExists('getReporteSolicitudCancelada', 'solicitudCancelada', solicitudCancelada)
            const localVarPath = `/solicitudes/{tipoSolucion}/{id}/cancelacion/pdf`
                .replace(`{${"tipoSolucion"}}`, encodeURIComponent(String(tipoSolucion)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(solicitudCancelada, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Exportar pdf de solicitud de Eméritos
         * @param {string} id id de la solicitud de Eméritos
         * @param {string} urlPu Url del documento de PU codificada en base 64
         * @param {string} tipo tipo documento de la solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReporteSolicitudEmeritos: async (id: string, urlPu: string, tipo: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getReporteSolicitudEmeritos', 'id', id)
            // verify required parameter 'urlPu' is not null or undefined
            assertParamExists('getReporteSolicitudEmeritos', 'urlPu', urlPu)
            // verify required parameter 'tipo' is not null or undefined
            assertParamExists('getReporteSolicitudEmeritos', 'tipo', tipo)
            const localVarPath = `/solicitudes-sni/emeritos/{id}/pdf`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (urlPu !== undefined) {
                localVarQueryParameter['urlPu'] = urlPu;
            }

            if (tipo !== undefined) {
                localVarQueryParameter['tipo'] = tipo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene la solicitud de un usuario
         * @param {string} id id de la solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSolicitud: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSolicitud', 'id', id)
            const localVarPath = `/solicitudes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene información de una solicitud cancelada
         * @param {string} id id de la solicitud cancelada
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSolicitudCancelada: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSolicitudCancelada', 'id', id)
            const localVarPath = `/solicitud-ingreso-permanencia/{id}/cancelacion`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene la información de las solicitudes del usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSolicitudes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/solicitudes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene el tipo de solicitud al que puede aplicar un usuario sni
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTipoSolicitudActual: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/solicitudes/tipo-solicitud-actual`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene información de una solicitud cancelada
         * @param {string} id id de la solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtieneSolicitudCancelada: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('obtieneSolicitudCancelada', 'id', id)
            const localVarPath = `/solicitudes/{tipoSolucion}/{id}/cancelacion`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Crea un registro en whitelist para un cvu y una convocatoria específica
         * @param {CvuReenviable} cvuReenviable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCvuReenviable: async (cvuReenviable: CvuReenviable, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cvuReenviable' is not null or undefined
            assertParamExists('saveCvuReenviable', 'cvuReenviable', cvuReenviable)
            const localVarPath = `/solicitudes/whitelist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cvuReenviable, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Almacena información de una solicitud
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSolicitud: async (solicitud: Solicitud, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'solicitud' is not null or undefined
            assertParamExists('saveSolicitud', 'solicitud', solicitud)
            const localVarPath = `/solicitudes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(solicitud, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Almacena información de una solicitud de becas
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSolicitudBecas: async (solicitud: Solicitud, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'solicitud' is not null or undefined
            assertParamExists('saveSolicitudBecas', 'solicitud', solicitud)
            const localVarPath = `/solicitudes-becas/nacionales`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(solicitud, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Almacena información de una solicitud de becas extranjero
         * @param {TipoBecaExtranjero} tipoBecaExtranjero Tipo de Beca Extranjera
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSolicitudBecasExtranjero: async (tipoBecaExtranjero: TipoBecaExtranjero, solicitud: Solicitud, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tipoBecaExtranjero' is not null or undefined
            assertParamExists('saveSolicitudBecasExtranjero', 'tipoBecaExtranjero', tipoBecaExtranjero)
            // verify required parameter 'solicitud' is not null or undefined
            assertParamExists('saveSolicitudBecasExtranjero', 'solicitud', solicitud)
            const localVarPath = `/solicitudes-becas-extranjero/{tipoBecaExtranjero}`
                .replace(`{${"tipoBecaExtranjero"}}`, encodeURIComponent(String(tipoBecaExtranjero)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(solicitud, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Cancela una solicitud, guarda un registro con acuse de cancelación (pdf) y envía el cambio de estado a la solicitud apeiron (de acuerdo al proceso definido)
         * @param {TipoSolucion} tipoSolucion Tipo de solución (Convocatoria)
         * @param {string} id id de la solicitud
         * @param {SolicitudCancelada} solicitudCancelada Información de la cancelacion de la solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSolicitudCancelada: async (tipoSolucion: TipoSolucion, id: string, solicitudCancelada: SolicitudCancelada, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tipoSolucion' is not null or undefined
            assertParamExists('saveSolicitudCancelada', 'tipoSolucion', tipoSolucion)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('saveSolicitudCancelada', 'id', id)
            // verify required parameter 'solicitudCancelada' is not null or undefined
            assertParamExists('saveSolicitudCancelada', 'solicitudCancelada', solicitudCancelada)
            const localVarPath = `/solicitudes/{tipoSolucion}/{id}/cancelacion`
                .replace(`{${"tipoSolucion"}}`, encodeURIComponent(String(tipoSolucion)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(solicitudCancelada, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Almacena información de una solicitud eméritos
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSolicitudEmerito: async (solicitud: Solicitud, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'solicitud' is not null or undefined
            assertParamExists('saveSolicitudEmerito', 'solicitud', solicitud)
            const localVarPath = `/solicitudes-sni/emeritos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(solicitud, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actualiza la participación de programa SNP de una solicitud obtenida de aperion/snp
         * @param {string} id id de la solicitud
         * @param {string} fechaActualizacionPrograma Fecha de última actualización del programa SNP-apeiron en que se consultó (dato generado al consultar programa)
         * @param {ParticipacionBrief} participacionBrief 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateParticipacionPrograma: async (id: string, fechaActualizacionPrograma: string, participacionBrief: ParticipacionBrief, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateParticipacionPrograma', 'id', id)
            // verify required parameter 'fechaActualizacionPrograma' is not null or undefined
            assertParamExists('updateParticipacionPrograma', 'fechaActualizacionPrograma', fechaActualizacionPrograma)
            // verify required parameter 'participacionBrief' is not null or undefined
            assertParamExists('updateParticipacionPrograma', 'participacionBrief', participacionBrief)
            const localVarPath = `/solicitudes-becas/nacionales/{id}/programa`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (fechaActualizacionPrograma !== undefined) {
                localVarQueryParameter['fechaActualizacionPrograma'] = (fechaActualizacionPrograma as any instanceof Date) ?
                    (fechaActualizacionPrograma as any).toISOString() :
                    fechaActualizacionPrograma;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(participacionBrief, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actualiza la información de los rubros en la solicitud EN_CAPTURA
         * @param {TipoBecaExtranjero} tipoBecaExtranjero Tipo de Beca Extranjera
         * @param {string} id id de solicitud
         * @param {Array<Rubro>} [rubro] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRubros: async (tipoBecaExtranjero: TipoBecaExtranjero, id: string, rubro?: Array<Rubro>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tipoBecaExtranjero' is not null or undefined
            assertParamExists('updateRubros', 'tipoBecaExtranjero', tipoBecaExtranjero)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateRubros', 'id', id)
            const localVarPath = `/solicitudes-becas-extranjero/{tipoBecaExtranjero}/{id}/rubros`
                .replace(`{${"tipoBecaExtranjero"}}`, encodeURIComponent(String(tipoBecaExtranjero)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rubro, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actualiza la solicitud
         * @param {string} id id de la solicitud
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSolicitud: async (id: string, solicitud: Solicitud, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSolicitud', 'id', id)
            // verify required parameter 'solicitud' is not null or undefined
            assertParamExists('updateSolicitud', 'solicitud', solicitud)
            const localVarPath = `/solicitudes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(solicitud, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actualiza la solicitud de becas
         * @param {string} id id de la solicitud
         * @param {'PARCIAL' | 'FINAL'} tipoActualizacion tipo de actualizacion, si es PARCIAL sólo se actualiza en perfil, si es FINAL se envía actualizacion a aperion y ejecutan reglas finales
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSolicitudBecas: async (id: string, tipoActualizacion: 'PARCIAL' | 'FINAL', solicitud: Solicitud, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSolicitudBecas', 'id', id)
            // verify required parameter 'tipoActualizacion' is not null or undefined
            assertParamExists('updateSolicitudBecas', 'tipoActualizacion', tipoActualizacion)
            // verify required parameter 'solicitud' is not null or undefined
            assertParamExists('updateSolicitudBecas', 'solicitud', solicitud)
            const localVarPath = `/solicitudes-becas/nacionales/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (tipoActualizacion !== undefined) {
                localVarQueryParameter['tipoActualizacion'] = tipoActualizacion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(solicitud, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actualiza la solicitud de becas extranjero
         * @param {TipoBecaExtranjero} tipoBecaExtranjero Tipo de Beca Extranjera
         * @param {string} id id de la solicitud
         * @param {'PARCIAL' | 'FINAL'} tipoActualizacion tipo de actualizacion, si es PARCIAL sólo se actualiza en perfil, si es FINAL se envía actualizacion a aperion y ejecutan reglas finales
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSolicitudBecasExtranjero: async (tipoBecaExtranjero: TipoBecaExtranjero, id: string, tipoActualizacion: 'PARCIAL' | 'FINAL', solicitud: Solicitud, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tipoBecaExtranjero' is not null or undefined
            assertParamExists('updateSolicitudBecasExtranjero', 'tipoBecaExtranjero', tipoBecaExtranjero)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSolicitudBecasExtranjero', 'id', id)
            // verify required parameter 'tipoActualizacion' is not null or undefined
            assertParamExists('updateSolicitudBecasExtranjero', 'tipoActualizacion', tipoActualizacion)
            // verify required parameter 'solicitud' is not null or undefined
            assertParamExists('updateSolicitudBecasExtranjero', 'solicitud', solicitud)
            const localVarPath = `/solicitudes-becas-extranjero/{tipoBecaExtranjero}/{id}`
                .replace(`{${"tipoBecaExtranjero"}}`, encodeURIComponent(String(tipoBecaExtranjero)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (tipoActualizacion !== undefined) {
                localVarQueryParameter['tipoActualizacion'] = tipoActualizacion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(solicitud, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actualiza una solicitud eméritos
         * @param {string} id id de solicitud
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSolicitudEmerito: async (id: string, solicitud: Solicitud, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSolicitudEmerito', 'id', id)
            // verify required parameter 'solicitud' is not null or undefined
            assertParamExists('updateSolicitudEmerito', 'solicitud', solicitud)
            const localVarPath = `/solicitudes-sni/emeritos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(solicitud, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Verifica que un usuario pueda participar en la convocatoria de acuerdo a los requisitos mínimos. En caso de no existir errores regresa una lista vacia.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validaRequisitos: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/solicitudes-sni/emeritos/valida-requisitos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Verifica que un usuario pueda participar en la convocatoria de acuerdo a los requisitos mínimos. En caso de no existir errores regresa una lista vacia.
         * @param {string} claveGrado Clave de grado del programa a aplicar
         * @param {string} caracteristicaPrograma Caracteristica del programa a aplicar
         * @param {string} tipoPrograma Tipo del programa a aplicar
         * @param {string} modalidadPrograma Modalidad del programa a aplicar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validarRequisitosBecas: async (claveGrado: string, caracteristicaPrograma: string, tipoPrograma: string, modalidadPrograma: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'claveGrado' is not null or undefined
            assertParamExists('validarRequisitosBecas', 'claveGrado', claveGrado)
            // verify required parameter 'caracteristicaPrograma' is not null or undefined
            assertParamExists('validarRequisitosBecas', 'caracteristicaPrograma', caracteristicaPrograma)
            // verify required parameter 'tipoPrograma' is not null or undefined
            assertParamExists('validarRequisitosBecas', 'tipoPrograma', tipoPrograma)
            // verify required parameter 'modalidadPrograma' is not null or undefined
            assertParamExists('validarRequisitosBecas', 'modalidadPrograma', modalidadPrograma)
            const localVarPath = `/solicitudes-becas/nacionales/valida-requisitos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (claveGrado !== undefined) {
                localVarQueryParameter['claveGrado'] = claveGrado;
            }

            if (caracteristicaPrograma !== undefined) {
                localVarQueryParameter['caracteristicaPrograma'] = caracteristicaPrograma;
            }

            if (tipoPrograma !== undefined) {
                localVarQueryParameter['tipoPrograma'] = tipoPrograma;
            }

            if (modalidadPrograma !== undefined) {
                localVarQueryParameter['modalidadPrograma'] = modalidadPrograma;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Verifica que un usuario pueda participar en la convocatoria de acuerdo a los requisitos mínimos. En caso de no existir errores regresa una lista vacia.
         * @param {TipoBecaExtranjero} tipoBecaExtranjero Tipo de Beca Extranjera
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validarRequisitosBecasExtranjero: async (tipoBecaExtranjero: TipoBecaExtranjero, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tipoBecaExtranjero' is not null or undefined
            assertParamExists('validarRequisitosBecasExtranjero', 'tipoBecaExtranjero', tipoBecaExtranjero)
            const localVarPath = `/solicitudes-becas-extranjero/{tipoBecaExtranjero}/valida-requisitos`
                .replace(`{${"tipoBecaExtranjero"}}`, encodeURIComponent(String(tipoBecaExtranjero)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SolicitudApi - functional programming interface
 * @export
 */
export const SolicitudApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SolicitudApiAxiosParamCreator(configuration)
    return {
        /**
         * Cancela una solicitud
         * @param {string} id id de la solicitud
         * @param {SolicitudCancelada} solicitudCancelada Información de la cancelacion de la solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelaSolicitud(id: string, solicitudCancelada: SolicitudCancelada, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelaSolicitud(id, solicitudCancelada, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Genera todo el proceso para el envio de la solicitud
         * @param {string} id id de la solicitud
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enviarSolicitud(id: string, solicitud: Solicitud, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enviarSolicitud(id, solicitud, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Envío de solicitud becas
         * @param {string} id id de solicitud
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enviarSolicitudBecas(id: string, solicitud: Solicitud, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enviarSolicitudBecas(id, solicitud, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Envío de solicitud eméritos
         * @param {string} id id de solicitud
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enviarSolicitudEmerito(id: string, solicitud: Solicitud, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enviarSolicitudEmerito(id, solicitud, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Indica si el usuario logeado con cvu es apto para enviar una solicitud dado un id de solución
         * @param {string} idSolucion id de solucion o convocatoria
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async esReenviable(idSolucion: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.esReenviable(idSolucion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Descarga carta ISSSTE de un becario cuando tiene una solicitud de becas nacionales y se encuentra ASIGNADA o FORMALIZADA
         * @param {string} id id de la solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentoExportCartaISSSTE(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentoExport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentoExportCartaISSSTE(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Cancela una solicitud de un usuario
         * @param {string} id id de la solicitud a cancelar
         * @param {SolicitudCancelada} solicitudCancelada Información de la cancelacion de la solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExportarReporteSolicitudCancelar(id: string, solicitudCancelada: SolicitudCancelada, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentoExport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExportarReporteSolicitudCancelar(id, solicitudCancelada, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Exportar pdf de solicitud
         * @param {string} id id de la solicitud
         * @param {string} tipo tipo documento de la solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReporteSolicitud(id: string, tipo: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentoExport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReporteSolicitud(id, tipo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Exportar pdf de solicitud de becas
         * @param {string} id id de la solicitud de becas
         * @param {string} urlPu Url del documento de PU codificada en base 64
         * @param {string} tipo tipo documento de la solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReporteSolicitudBecas(id: string, urlPu: string, tipo: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentoExport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReporteSolicitudBecas(id, urlPu, tipo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Exportar pdf de solicitud de becas extranjero
         * @param {TipoBecaExtranjero} tipoBecaExtranjero Tipo de Beca Extranjera
         * @param {string} id id de la solicitud de becas
         * @param {boolean} esEnviada Bandera para saber si una solicitud esta enviada para la marca de agua
         * @param {string} [password] Contraseña para firma de solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReporteSolicitudBecasExtranjero(tipoBecaExtranjero: TipoBecaExtranjero, id: string, esEnviada: boolean, password?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentoExport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReporteSolicitudBecasExtranjero(tipoBecaExtranjero, id, esEnviada, password, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Genera y obtiene el acuse en base64 del pdf de cancelación de una solicitud
         * @param {TipoSolucion} tipoSolucion Tipo de solución (Convocatoria)
         * @param {string} id id de la solicitud
         * @param {SolicitudCancelada} solicitudCancelada Información de la cancelacion de la solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReporteSolicitudCancelada(tipoSolucion: TipoSolucion, id: string, solicitudCancelada: SolicitudCancelada, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentoExport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReporteSolicitudCancelada(tipoSolucion, id, solicitudCancelada, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Exportar pdf de solicitud de Eméritos
         * @param {string} id id de la solicitud de Eméritos
         * @param {string} urlPu Url del documento de PU codificada en base 64
         * @param {string} tipo tipo documento de la solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReporteSolicitudEmeritos(id: string, urlPu: string, tipo: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentoExport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReporteSolicitudEmeritos(id, urlPu, tipo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene la solicitud de un usuario
         * @param {string} id id de la solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSolicitud(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Solicitud>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSolicitud(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene información de una solicitud cancelada
         * @param {string} id id de la solicitud cancelada
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSolicitudCancelada(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SolicitudCancelada>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSolicitudCancelada(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene la información de las solicitudes del usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSolicitudes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Solicitud>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSolicitudes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene el tipo de solicitud al que puede aplicar un usuario sni
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTipoSolicitudActual(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseCatalogo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTipoSolicitudActual(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene información de una solicitud cancelada
         * @param {string} id id de la solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtieneSolicitudCancelada(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SolicitudCancelada>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtieneSolicitudCancelada(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Crea un registro en whitelist para un cvu y una convocatoria específica
         * @param {CvuReenviable} cvuReenviable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveCvuReenviable(cvuReenviable: CvuReenviable, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveCvuReenviable(cvuReenviable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Almacena información de una solicitud
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveSolicitud(solicitud: Solicitud, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveSolicitud(solicitud, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Almacena información de una solicitud de becas
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveSolicitudBecas(solicitud: Solicitud, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveSolicitudBecas(solicitud, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Almacena información de una solicitud de becas extranjero
         * @param {TipoBecaExtranjero} tipoBecaExtranjero Tipo de Beca Extranjera
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveSolicitudBecasExtranjero(tipoBecaExtranjero: TipoBecaExtranjero, solicitud: Solicitud, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveSolicitudBecasExtranjero(tipoBecaExtranjero, solicitud, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Cancela una solicitud, guarda un registro con acuse de cancelación (pdf) y envía el cambio de estado a la solicitud apeiron (de acuerdo al proceso definido)
         * @param {TipoSolucion} tipoSolucion Tipo de solución (Convocatoria)
         * @param {string} id id de la solicitud
         * @param {SolicitudCancelada} solicitudCancelada Información de la cancelacion de la solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveSolicitudCancelada(tipoSolucion: TipoSolucion, id: string, solicitudCancelada: SolicitudCancelada, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveSolicitudCancelada(tipoSolucion, id, solicitudCancelada, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Almacena información de una solicitud eméritos
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveSolicitudEmerito(solicitud: Solicitud, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveSolicitudEmerito(solicitud, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Actualiza la participación de programa SNP de una solicitud obtenida de aperion/snp
         * @param {string} id id de la solicitud
         * @param {string} fechaActualizacionPrograma Fecha de última actualización del programa SNP-apeiron en que se consultó (dato generado al consultar programa)
         * @param {ParticipacionBrief} participacionBrief 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateParticipacionPrograma(id: string, fechaActualizacionPrograma: string, participacionBrief: ParticipacionBrief, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateParticipacionPrograma(id, fechaActualizacionPrograma, participacionBrief, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Actualiza la información de los rubros en la solicitud EN_CAPTURA
         * @param {TipoBecaExtranjero} tipoBecaExtranjero Tipo de Beca Extranjera
         * @param {string} id id de solicitud
         * @param {Array<Rubro>} [rubro] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRubros(tipoBecaExtranjero: TipoBecaExtranjero, id: string, rubro?: Array<Rubro>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Rubro>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRubros(tipoBecaExtranjero, id, rubro, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Actualiza la solicitud
         * @param {string} id id de la solicitud
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSolicitud(id: string, solicitud: Solicitud, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSolicitud(id, solicitud, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Actualiza la solicitud de becas
         * @param {string} id id de la solicitud
         * @param {'PARCIAL' | 'FINAL'} tipoActualizacion tipo de actualizacion, si es PARCIAL sólo se actualiza en perfil, si es FINAL se envía actualizacion a aperion y ejecutan reglas finales
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSolicitudBecas(id: string, tipoActualizacion: 'PARCIAL' | 'FINAL', solicitud: Solicitud, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSolicitudBecas(id, tipoActualizacion, solicitud, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Actualiza la solicitud de becas extranjero
         * @param {TipoBecaExtranjero} tipoBecaExtranjero Tipo de Beca Extranjera
         * @param {string} id id de la solicitud
         * @param {'PARCIAL' | 'FINAL'} tipoActualizacion tipo de actualizacion, si es PARCIAL sólo se actualiza en perfil, si es FINAL se envía actualizacion a aperion y ejecutan reglas finales
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSolicitudBecasExtranjero(tipoBecaExtranjero: TipoBecaExtranjero, id: string, tipoActualizacion: 'PARCIAL' | 'FINAL', solicitud: Solicitud, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSolicitudBecasExtranjero(tipoBecaExtranjero, id, tipoActualizacion, solicitud, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Actualiza una solicitud eméritos
         * @param {string} id id de solicitud
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSolicitudEmerito(id: string, solicitud: Solicitud, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSolicitudEmerito(id, solicitud, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Verifica que un usuario pueda participar en la convocatoria de acuerdo a los requisitos mínimos. En caso de no existir errores regresa una lista vacia.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validaRequisitos(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ErrorValidacion>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validaRequisitos(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Verifica que un usuario pueda participar en la convocatoria de acuerdo a los requisitos mínimos. En caso de no existir errores regresa una lista vacia.
         * @param {string} claveGrado Clave de grado del programa a aplicar
         * @param {string} caracteristicaPrograma Caracteristica del programa a aplicar
         * @param {string} tipoPrograma Tipo del programa a aplicar
         * @param {string} modalidadPrograma Modalidad del programa a aplicar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validarRequisitosBecas(claveGrado: string, caracteristicaPrograma: string, tipoPrograma: string, modalidadPrograma: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ErrorValidacion>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validarRequisitosBecas(claveGrado, caracteristicaPrograma, tipoPrograma, modalidadPrograma, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Verifica que un usuario pueda participar en la convocatoria de acuerdo a los requisitos mínimos. En caso de no existir errores regresa una lista vacia.
         * @param {TipoBecaExtranjero} tipoBecaExtranjero Tipo de Beca Extranjera
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validarRequisitosBecasExtranjero(tipoBecaExtranjero: TipoBecaExtranjero, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ErrorValidacion>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validarRequisitosBecasExtranjero(tipoBecaExtranjero, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SolicitudApi - factory interface
 * @export
 */
export const SolicitudApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SolicitudApiFp(configuration)
    return {
        /**
         * Cancela una solicitud
         * @param {string} id id de la solicitud
         * @param {SolicitudCancelada} solicitudCancelada Información de la cancelacion de la solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelaSolicitud(id: string, solicitudCancelada: SolicitudCancelada, options?: any): AxiosPromise<void> {
            return localVarFp.cancelaSolicitud(id, solicitudCancelada, options).then((request) => request(axios, basePath));
        },
        /**
         * Genera todo el proceso para el envio de la solicitud
         * @param {string} id id de la solicitud
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enviarSolicitud(id: string, solicitud: Solicitud, options?: any): AxiosPromise<void> {
            return localVarFp.enviarSolicitud(id, solicitud, options).then((request) => request(axios, basePath));
        },
        /**
         * Envío de solicitud becas
         * @param {string} id id de solicitud
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enviarSolicitudBecas(id: string, solicitud: Solicitud, options?: any): AxiosPromise<void> {
            return localVarFp.enviarSolicitudBecas(id, solicitud, options).then((request) => request(axios, basePath));
        },
        /**
         * Envío de solicitud eméritos
         * @param {string} id id de solicitud
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enviarSolicitudEmerito(id: string, solicitud: Solicitud, options?: any): AxiosPromise<void> {
            return localVarFp.enviarSolicitudEmerito(id, solicitud, options).then((request) => request(axios, basePath));
        },
        /**
         * Indica si el usuario logeado con cvu es apto para enviar una solicitud dado un id de solución
         * @param {string} idSolucion id de solucion o convocatoria
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        esReenviable(idSolucion: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.esReenviable(idSolucion, options).then((request) => request(axios, basePath));
        },
        /**
         * Descarga carta ISSSTE de un becario cuando tiene una solicitud de becas nacionales y se encuentra ASIGNADA o FORMALIZADA
         * @param {string} id id de la solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentoExportCartaISSSTE(id: string, options?: any): AxiosPromise<DocumentoExport> {
            return localVarFp.getDocumentoExportCartaISSSTE(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Cancela una solicitud de un usuario
         * @param {string} id id de la solicitud a cancelar
         * @param {SolicitudCancelada} solicitudCancelada Información de la cancelacion de la solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExportarReporteSolicitudCancelar(id: string, solicitudCancelada: SolicitudCancelada, options?: any): AxiosPromise<DocumentoExport> {
            return localVarFp.getExportarReporteSolicitudCancelar(id, solicitudCancelada, options).then((request) => request(axios, basePath));
        },
        /**
         * Exportar pdf de solicitud
         * @param {string} id id de la solicitud
         * @param {string} tipo tipo documento de la solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReporteSolicitud(id: string, tipo: string, options?: any): AxiosPromise<DocumentoExport> {
            return localVarFp.getReporteSolicitud(id, tipo, options).then((request) => request(axios, basePath));
        },
        /**
         * Exportar pdf de solicitud de becas
         * @param {string} id id de la solicitud de becas
         * @param {string} urlPu Url del documento de PU codificada en base 64
         * @param {string} tipo tipo documento de la solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReporteSolicitudBecas(id: string, urlPu: string, tipo: string, options?: any): AxiosPromise<DocumentoExport> {
            return localVarFp.getReporteSolicitudBecas(id, urlPu, tipo, options).then((request) => request(axios, basePath));
        },
        /**
         * Exportar pdf de solicitud de becas extranjero
         * @param {TipoBecaExtranjero} tipoBecaExtranjero Tipo de Beca Extranjera
         * @param {string} id id de la solicitud de becas
         * @param {boolean} esEnviada Bandera para saber si una solicitud esta enviada para la marca de agua
         * @param {string} [password] Contraseña para firma de solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReporteSolicitudBecasExtranjero(tipoBecaExtranjero: TipoBecaExtranjero, id: string, esEnviada: boolean, password?: string, options?: any): AxiosPromise<DocumentoExport> {
            return localVarFp.getReporteSolicitudBecasExtranjero(tipoBecaExtranjero, id, esEnviada, password, options).then((request) => request(axios, basePath));
        },
        /**
         * Genera y obtiene el acuse en base64 del pdf de cancelación de una solicitud
         * @param {TipoSolucion} tipoSolucion Tipo de solución (Convocatoria)
         * @param {string} id id de la solicitud
         * @param {SolicitudCancelada} solicitudCancelada Información de la cancelacion de la solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReporteSolicitudCancelada(tipoSolucion: TipoSolucion, id: string, solicitudCancelada: SolicitudCancelada, options?: any): AxiosPromise<DocumentoExport> {
            return localVarFp.getReporteSolicitudCancelada(tipoSolucion, id, solicitudCancelada, options).then((request) => request(axios, basePath));
        },
        /**
         * Exportar pdf de solicitud de Eméritos
         * @param {string} id id de la solicitud de Eméritos
         * @param {string} urlPu Url del documento de PU codificada en base 64
         * @param {string} tipo tipo documento de la solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReporteSolicitudEmeritos(id: string, urlPu: string, tipo: string, options?: any): AxiosPromise<DocumentoExport> {
            return localVarFp.getReporteSolicitudEmeritos(id, urlPu, tipo, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene la solicitud de un usuario
         * @param {string} id id de la solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSolicitud(id: string, options?: any): AxiosPromise<Solicitud> {
            return localVarFp.getSolicitud(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene información de una solicitud cancelada
         * @param {string} id id de la solicitud cancelada
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSolicitudCancelada(id: string, options?: any): AxiosPromise<SolicitudCancelada> {
            return localVarFp.getSolicitudCancelada(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene la información de las solicitudes del usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSolicitudes(options?: any): AxiosPromise<Array<Solicitud>> {
            return localVarFp.getSolicitudes(options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene el tipo de solicitud al que puede aplicar un usuario sni
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTipoSolicitudActual(options?: any): AxiosPromise<BaseCatalogo> {
            return localVarFp.getTipoSolicitudActual(options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene información de una solicitud cancelada
         * @param {string} id id de la solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtieneSolicitudCancelada(id: string, options?: any): AxiosPromise<SolicitudCancelada> {
            return localVarFp.obtieneSolicitudCancelada(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Crea un registro en whitelist para un cvu y una convocatoria específica
         * @param {CvuReenviable} cvuReenviable 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveCvuReenviable(cvuReenviable: CvuReenviable, options?: any): AxiosPromise<void> {
            return localVarFp.saveCvuReenviable(cvuReenviable, options).then((request) => request(axios, basePath));
        },
        /**
         * Almacena información de una solicitud
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSolicitud(solicitud: Solicitud, options?: any): AxiosPromise<void> {
            return localVarFp.saveSolicitud(solicitud, options).then((request) => request(axios, basePath));
        },
        /**
         * Almacena información de una solicitud de becas
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSolicitudBecas(solicitud: Solicitud, options?: any): AxiosPromise<void> {
            return localVarFp.saveSolicitudBecas(solicitud, options).then((request) => request(axios, basePath));
        },
        /**
         * Almacena información de una solicitud de becas extranjero
         * @param {TipoBecaExtranjero} tipoBecaExtranjero Tipo de Beca Extranjera
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSolicitudBecasExtranjero(tipoBecaExtranjero: TipoBecaExtranjero, solicitud: Solicitud, options?: any): AxiosPromise<void> {
            return localVarFp.saveSolicitudBecasExtranjero(tipoBecaExtranjero, solicitud, options).then((request) => request(axios, basePath));
        },
        /**
         * Cancela una solicitud, guarda un registro con acuse de cancelación (pdf) y envía el cambio de estado a la solicitud apeiron (de acuerdo al proceso definido)
         * @param {TipoSolucion} tipoSolucion Tipo de solución (Convocatoria)
         * @param {string} id id de la solicitud
         * @param {SolicitudCancelada} solicitudCancelada Información de la cancelacion de la solicitud
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSolicitudCancelada(tipoSolucion: TipoSolucion, id: string, solicitudCancelada: SolicitudCancelada, options?: any): AxiosPromise<void> {
            return localVarFp.saveSolicitudCancelada(tipoSolucion, id, solicitudCancelada, options).then((request) => request(axios, basePath));
        },
        /**
         * Almacena información de una solicitud eméritos
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveSolicitudEmerito(solicitud: Solicitud, options?: any): AxiosPromise<void> {
            return localVarFp.saveSolicitudEmerito(solicitud, options).then((request) => request(axios, basePath));
        },
        /**
         * Actualiza la participación de programa SNP de una solicitud obtenida de aperion/snp
         * @param {string} id id de la solicitud
         * @param {string} fechaActualizacionPrograma Fecha de última actualización del programa SNP-apeiron en que se consultó (dato generado al consultar programa)
         * @param {ParticipacionBrief} participacionBrief 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateParticipacionPrograma(id: string, fechaActualizacionPrograma: string, participacionBrief: ParticipacionBrief, options?: any): AxiosPromise<void> {
            return localVarFp.updateParticipacionPrograma(id, fechaActualizacionPrograma, participacionBrief, options).then((request) => request(axios, basePath));
        },
        /**
         * Actualiza la información de los rubros en la solicitud EN_CAPTURA
         * @param {TipoBecaExtranjero} tipoBecaExtranjero Tipo de Beca Extranjera
         * @param {string} id id de solicitud
         * @param {Array<Rubro>} [rubro] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRubros(tipoBecaExtranjero: TipoBecaExtranjero, id: string, rubro?: Array<Rubro>, options?: any): AxiosPromise<Array<Rubro>> {
            return localVarFp.updateRubros(tipoBecaExtranjero, id, rubro, options).then((request) => request(axios, basePath));
        },
        /**
         * Actualiza la solicitud
         * @param {string} id id de la solicitud
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSolicitud(id: string, solicitud: Solicitud, options?: any): AxiosPromise<void> {
            return localVarFp.updateSolicitud(id, solicitud, options).then((request) => request(axios, basePath));
        },
        /**
         * Actualiza la solicitud de becas
         * @param {string} id id de la solicitud
         * @param {'PARCIAL' | 'FINAL'} tipoActualizacion tipo de actualizacion, si es PARCIAL sólo se actualiza en perfil, si es FINAL se envía actualizacion a aperion y ejecutan reglas finales
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSolicitudBecas(id: string, tipoActualizacion: 'PARCIAL' | 'FINAL', solicitud: Solicitud, options?: any): AxiosPromise<void> {
            return localVarFp.updateSolicitudBecas(id, tipoActualizacion, solicitud, options).then((request) => request(axios, basePath));
        },
        /**
         * Actualiza la solicitud de becas extranjero
         * @param {TipoBecaExtranjero} tipoBecaExtranjero Tipo de Beca Extranjera
         * @param {string} id id de la solicitud
         * @param {'PARCIAL' | 'FINAL'} tipoActualizacion tipo de actualizacion, si es PARCIAL sólo se actualiza en perfil, si es FINAL se envía actualizacion a aperion y ejecutan reglas finales
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSolicitudBecasExtranjero(tipoBecaExtranjero: TipoBecaExtranjero, id: string, tipoActualizacion: 'PARCIAL' | 'FINAL', solicitud: Solicitud, options?: any): AxiosPromise<void> {
            return localVarFp.updateSolicitudBecasExtranjero(tipoBecaExtranjero, id, tipoActualizacion, solicitud, options).then((request) => request(axios, basePath));
        },
        /**
         * Actualiza una solicitud eméritos
         * @param {string} id id de solicitud
         * @param {Solicitud} solicitud 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSolicitudEmerito(id: string, solicitud: Solicitud, options?: any): AxiosPromise<void> {
            return localVarFp.updateSolicitudEmerito(id, solicitud, options).then((request) => request(axios, basePath));
        },
        /**
         * Verifica que un usuario pueda participar en la convocatoria de acuerdo a los requisitos mínimos. En caso de no existir errores regresa una lista vacia.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validaRequisitos(options?: any): AxiosPromise<Array<ErrorValidacion>> {
            return localVarFp.validaRequisitos(options).then((request) => request(axios, basePath));
        },
        /**
         * Verifica que un usuario pueda participar en la convocatoria de acuerdo a los requisitos mínimos. En caso de no existir errores regresa una lista vacia.
         * @param {string} claveGrado Clave de grado del programa a aplicar
         * @param {string} caracteristicaPrograma Caracteristica del programa a aplicar
         * @param {string} tipoPrograma Tipo del programa a aplicar
         * @param {string} modalidadPrograma Modalidad del programa a aplicar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validarRequisitosBecas(claveGrado: string, caracteristicaPrograma: string, tipoPrograma: string, modalidadPrograma: string, options?: any): AxiosPromise<Array<ErrorValidacion>> {
            return localVarFp.validarRequisitosBecas(claveGrado, caracteristicaPrograma, tipoPrograma, modalidadPrograma, options).then((request) => request(axios, basePath));
        },
        /**
         * Verifica que un usuario pueda participar en la convocatoria de acuerdo a los requisitos mínimos. En caso de no existir errores regresa una lista vacia.
         * @param {TipoBecaExtranjero} tipoBecaExtranjero Tipo de Beca Extranjera
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validarRequisitosBecasExtranjero(tipoBecaExtranjero: TipoBecaExtranjero, options?: any): AxiosPromise<Array<ErrorValidacion>> {
            return localVarFp.validarRequisitosBecasExtranjero(tipoBecaExtranjero, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SolicitudApi - object-oriented interface
 * @export
 * @class SolicitudApi
 * @extends {BaseAPI}
 */
export class SolicitudApi extends BaseAPI {
    /**
     * Cancela una solicitud
     * @param {string} id id de la solicitud
     * @param {SolicitudCancelada} solicitudCancelada Información de la cancelacion de la solicitud
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolicitudApi
     */
    public cancelaSolicitud(id: string, solicitudCancelada: SolicitudCancelada, options?: AxiosRequestConfig) {
        return SolicitudApiFp(this.configuration).cancelaSolicitud(id, solicitudCancelada, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Genera todo el proceso para el envio de la solicitud
     * @param {string} id id de la solicitud
     * @param {Solicitud} solicitud 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolicitudApi
     */
    public enviarSolicitud(id: string, solicitud: Solicitud, options?: AxiosRequestConfig) {
        return SolicitudApiFp(this.configuration).enviarSolicitud(id, solicitud, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Envío de solicitud becas
     * @param {string} id id de solicitud
     * @param {Solicitud} solicitud 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolicitudApi
     */
    public enviarSolicitudBecas(id: string, solicitud: Solicitud, options?: AxiosRequestConfig) {
        return SolicitudApiFp(this.configuration).enviarSolicitudBecas(id, solicitud, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Envío de solicitud eméritos
     * @param {string} id id de solicitud
     * @param {Solicitud} solicitud 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolicitudApi
     */
    public enviarSolicitudEmerito(id: string, solicitud: Solicitud, options?: AxiosRequestConfig) {
        return SolicitudApiFp(this.configuration).enviarSolicitudEmerito(id, solicitud, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Indica si el usuario logeado con cvu es apto para enviar una solicitud dado un id de solución
     * @param {string} idSolucion id de solucion o convocatoria
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolicitudApi
     */
    public esReenviable(idSolucion: string, options?: AxiosRequestConfig) {
        return SolicitudApiFp(this.configuration).esReenviable(idSolucion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Descarga carta ISSSTE de un becario cuando tiene una solicitud de becas nacionales y se encuentra ASIGNADA o FORMALIZADA
     * @param {string} id id de la solicitud
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolicitudApi
     */
    public getDocumentoExportCartaISSSTE(id: string, options?: AxiosRequestConfig) {
        return SolicitudApiFp(this.configuration).getDocumentoExportCartaISSSTE(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Cancela una solicitud de un usuario
     * @param {string} id id de la solicitud a cancelar
     * @param {SolicitudCancelada} solicitudCancelada Información de la cancelacion de la solicitud
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolicitudApi
     */
    public getExportarReporteSolicitudCancelar(id: string, solicitudCancelada: SolicitudCancelada, options?: AxiosRequestConfig) {
        return SolicitudApiFp(this.configuration).getExportarReporteSolicitudCancelar(id, solicitudCancelada, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Exportar pdf de solicitud
     * @param {string} id id de la solicitud
     * @param {string} tipo tipo documento de la solicitud
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolicitudApi
     */
    public getReporteSolicitud(id: string, tipo: string, options?: AxiosRequestConfig) {
        return SolicitudApiFp(this.configuration).getReporteSolicitud(id, tipo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Exportar pdf de solicitud de becas
     * @param {string} id id de la solicitud de becas
     * @param {string} urlPu Url del documento de PU codificada en base 64
     * @param {string} tipo tipo documento de la solicitud
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolicitudApi
     */
    public getReporteSolicitudBecas(id: string, urlPu: string, tipo: string, options?: AxiosRequestConfig) {
        return SolicitudApiFp(this.configuration).getReporteSolicitudBecas(id, urlPu, tipo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Exportar pdf de solicitud de becas extranjero
     * @param {TipoBecaExtranjero} tipoBecaExtranjero Tipo de Beca Extranjera
     * @param {string} id id de la solicitud de becas
     * @param {boolean} esEnviada Bandera para saber si una solicitud esta enviada para la marca de agua
     * @param {string} [password] Contraseña para firma de solicitud
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolicitudApi
     */
    public getReporteSolicitudBecasExtranjero(tipoBecaExtranjero: TipoBecaExtranjero, id: string, esEnviada: boolean, password?: string, options?: AxiosRequestConfig) {
        return SolicitudApiFp(this.configuration).getReporteSolicitudBecasExtranjero(tipoBecaExtranjero, id, esEnviada, password, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Genera y obtiene el acuse en base64 del pdf de cancelación de una solicitud
     * @param {TipoSolucion} tipoSolucion Tipo de solución (Convocatoria)
     * @param {string} id id de la solicitud
     * @param {SolicitudCancelada} solicitudCancelada Información de la cancelacion de la solicitud
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolicitudApi
     */
    public getReporteSolicitudCancelada(tipoSolucion: TipoSolucion, id: string, solicitudCancelada: SolicitudCancelada, options?: AxiosRequestConfig) {
        return SolicitudApiFp(this.configuration).getReporteSolicitudCancelada(tipoSolucion, id, solicitudCancelada, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Exportar pdf de solicitud de Eméritos
     * @param {string} id id de la solicitud de Eméritos
     * @param {string} urlPu Url del documento de PU codificada en base 64
     * @param {string} tipo tipo documento de la solicitud
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolicitudApi
     */
    public getReporteSolicitudEmeritos(id: string, urlPu: string, tipo: string, options?: AxiosRequestConfig) {
        return SolicitudApiFp(this.configuration).getReporteSolicitudEmeritos(id, urlPu, tipo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene la solicitud de un usuario
     * @param {string} id id de la solicitud
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolicitudApi
     */
    public getSolicitud(id: string, options?: AxiosRequestConfig) {
        return SolicitudApiFp(this.configuration).getSolicitud(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene información de una solicitud cancelada
     * @param {string} id id de la solicitud cancelada
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolicitudApi
     */
    public getSolicitudCancelada(id: string, options?: AxiosRequestConfig) {
        return SolicitudApiFp(this.configuration).getSolicitudCancelada(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene la información de las solicitudes del usuario
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolicitudApi
     */
    public getSolicitudes(options?: AxiosRequestConfig) {
        return SolicitudApiFp(this.configuration).getSolicitudes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene el tipo de solicitud al que puede aplicar un usuario sni
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolicitudApi
     */
    public getTipoSolicitudActual(options?: AxiosRequestConfig) {
        return SolicitudApiFp(this.configuration).getTipoSolicitudActual(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene información de una solicitud cancelada
     * @param {string} id id de la solicitud
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolicitudApi
     */
    public obtieneSolicitudCancelada(id: string, options?: AxiosRequestConfig) {
        return SolicitudApiFp(this.configuration).obtieneSolicitudCancelada(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Crea un registro en whitelist para un cvu y una convocatoria específica
     * @param {CvuReenviable} cvuReenviable 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolicitudApi
     */
    public saveCvuReenviable(cvuReenviable: CvuReenviable, options?: AxiosRequestConfig) {
        return SolicitudApiFp(this.configuration).saveCvuReenviable(cvuReenviable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Almacena información de una solicitud
     * @param {Solicitud} solicitud 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolicitudApi
     */
    public saveSolicitud(solicitud: Solicitud, options?: AxiosRequestConfig) {
        return SolicitudApiFp(this.configuration).saveSolicitud(solicitud, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Almacena información de una solicitud de becas
     * @param {Solicitud} solicitud 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolicitudApi
     */
    public saveSolicitudBecas(solicitud: Solicitud, options?: AxiosRequestConfig) {
        return SolicitudApiFp(this.configuration).saveSolicitudBecas(solicitud, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Almacena información de una solicitud de becas extranjero
     * @param {TipoBecaExtranjero} tipoBecaExtranjero Tipo de Beca Extranjera
     * @param {Solicitud} solicitud 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolicitudApi
     */
    public saveSolicitudBecasExtranjero(tipoBecaExtranjero: TipoBecaExtranjero, solicitud: Solicitud, options?: AxiosRequestConfig) {
        return SolicitudApiFp(this.configuration).saveSolicitudBecasExtranjero(tipoBecaExtranjero, solicitud, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Cancela una solicitud, guarda un registro con acuse de cancelación (pdf) y envía el cambio de estado a la solicitud apeiron (de acuerdo al proceso definido)
     * @param {TipoSolucion} tipoSolucion Tipo de solución (Convocatoria)
     * @param {string} id id de la solicitud
     * @param {SolicitudCancelada} solicitudCancelada Información de la cancelacion de la solicitud
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolicitudApi
     */
    public saveSolicitudCancelada(tipoSolucion: TipoSolucion, id: string, solicitudCancelada: SolicitudCancelada, options?: AxiosRequestConfig) {
        return SolicitudApiFp(this.configuration).saveSolicitudCancelada(tipoSolucion, id, solicitudCancelada, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Almacena información de una solicitud eméritos
     * @param {Solicitud} solicitud 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolicitudApi
     */
    public saveSolicitudEmerito(solicitud: Solicitud, options?: AxiosRequestConfig) {
        return SolicitudApiFp(this.configuration).saveSolicitudEmerito(solicitud, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actualiza la participación de programa SNP de una solicitud obtenida de aperion/snp
     * @param {string} id id de la solicitud
     * @param {string} fechaActualizacionPrograma Fecha de última actualización del programa SNP-apeiron en que se consultó (dato generado al consultar programa)
     * @param {ParticipacionBrief} participacionBrief 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolicitudApi
     */
    public updateParticipacionPrograma(id: string, fechaActualizacionPrograma: string, participacionBrief: ParticipacionBrief, options?: AxiosRequestConfig) {
        return SolicitudApiFp(this.configuration).updateParticipacionPrograma(id, fechaActualizacionPrograma, participacionBrief, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actualiza la información de los rubros en la solicitud EN_CAPTURA
     * @param {TipoBecaExtranjero} tipoBecaExtranjero Tipo de Beca Extranjera
     * @param {string} id id de solicitud
     * @param {Array<Rubro>} [rubro] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolicitudApi
     */
    public updateRubros(tipoBecaExtranjero: TipoBecaExtranjero, id: string, rubro?: Array<Rubro>, options?: AxiosRequestConfig) {
        return SolicitudApiFp(this.configuration).updateRubros(tipoBecaExtranjero, id, rubro, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actualiza la solicitud
     * @param {string} id id de la solicitud
     * @param {Solicitud} solicitud 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolicitudApi
     */
    public updateSolicitud(id: string, solicitud: Solicitud, options?: AxiosRequestConfig) {
        return SolicitudApiFp(this.configuration).updateSolicitud(id, solicitud, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actualiza la solicitud de becas
     * @param {string} id id de la solicitud
     * @param {'PARCIAL' | 'FINAL'} tipoActualizacion tipo de actualizacion, si es PARCIAL sólo se actualiza en perfil, si es FINAL se envía actualizacion a aperion y ejecutan reglas finales
     * @param {Solicitud} solicitud 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolicitudApi
     */
    public updateSolicitudBecas(id: string, tipoActualizacion: 'PARCIAL' | 'FINAL', solicitud: Solicitud, options?: AxiosRequestConfig) {
        return SolicitudApiFp(this.configuration).updateSolicitudBecas(id, tipoActualizacion, solicitud, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actualiza la solicitud de becas extranjero
     * @param {TipoBecaExtranjero} tipoBecaExtranjero Tipo de Beca Extranjera
     * @param {string} id id de la solicitud
     * @param {'PARCIAL' | 'FINAL'} tipoActualizacion tipo de actualizacion, si es PARCIAL sólo se actualiza en perfil, si es FINAL se envía actualizacion a aperion y ejecutan reglas finales
     * @param {Solicitud} solicitud 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolicitudApi
     */
    public updateSolicitudBecasExtranjero(tipoBecaExtranjero: TipoBecaExtranjero, id: string, tipoActualizacion: 'PARCIAL' | 'FINAL', solicitud: Solicitud, options?: AxiosRequestConfig) {
        return SolicitudApiFp(this.configuration).updateSolicitudBecasExtranjero(tipoBecaExtranjero, id, tipoActualizacion, solicitud, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actualiza una solicitud eméritos
     * @param {string} id id de solicitud
     * @param {Solicitud} solicitud 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolicitudApi
     */
    public updateSolicitudEmerito(id: string, solicitud: Solicitud, options?: AxiosRequestConfig) {
        return SolicitudApiFp(this.configuration).updateSolicitudEmerito(id, solicitud, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Verifica que un usuario pueda participar en la convocatoria de acuerdo a los requisitos mínimos. En caso de no existir errores regresa una lista vacia.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolicitudApi
     */
    public validaRequisitos(options?: AxiosRequestConfig) {
        return SolicitudApiFp(this.configuration).validaRequisitos(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Verifica que un usuario pueda participar en la convocatoria de acuerdo a los requisitos mínimos. En caso de no existir errores regresa una lista vacia.
     * @param {string} claveGrado Clave de grado del programa a aplicar
     * @param {string} caracteristicaPrograma Caracteristica del programa a aplicar
     * @param {string} tipoPrograma Tipo del programa a aplicar
     * @param {string} modalidadPrograma Modalidad del programa a aplicar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolicitudApi
     */
    public validarRequisitosBecas(claveGrado: string, caracteristicaPrograma: string, tipoPrograma: string, modalidadPrograma: string, options?: AxiosRequestConfig) {
        return SolicitudApiFp(this.configuration).validarRequisitosBecas(claveGrado, caracteristicaPrograma, tipoPrograma, modalidadPrograma, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Verifica que un usuario pueda participar en la convocatoria de acuerdo a los requisitos mínimos. En caso de no existir errores regresa una lista vacia.
     * @param {TipoBecaExtranjero} tipoBecaExtranjero Tipo de Beca Extranjera
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolicitudApi
     */
    public validarRequisitosBecasExtranjero(tipoBecaExtranjero: TipoBecaExtranjero, options?: AxiosRequestConfig) {
        return SolicitudApiFp(this.configuration).validarRequisitosBecasExtranjero(tipoBecaExtranjero, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SolucionesApi - axios parameter creator
 * @export
 */
export const SolucionesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtiene las soluciones activas (publicadas). Función temporal alterna para consumo de apeiron desde msperfil
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getSolucionesActivas: async (page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/soluciones`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SolucionesApi - functional programming interface
 * @export
 */
export const SolucionesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SolucionesApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtiene las soluciones activas (publicadas). Función temporal alterna para consumo de apeiron desde msperfil
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getSolucionesActivas(page?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Solucion>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSolucionesActivas(page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SolucionesApi - factory interface
 * @export
 */
export const SolucionesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SolucionesApiFp(configuration)
    return {
        /**
         * Obtiene las soluciones activas (publicadas). Función temporal alterna para consumo de apeiron desde msperfil
         * @param {number} [page] Page number
         * @param {number} [size] Page size
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getSolucionesActivas(page?: number, size?: number, options?: any): AxiosPromise<Array<Solucion>> {
            return localVarFp.getSolucionesActivas(page, size, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SolucionesApi - object-oriented interface
 * @export
 * @class SolucionesApi
 * @extends {BaseAPI}
 */
export class SolucionesApi extends BaseAPI {
    /**
     * Obtiene las soluciones activas (publicadas). Función temporal alterna para consumo de apeiron desde msperfil
     * @param {number} [page] Page number
     * @param {number} [size] Page size
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof SolucionesApi
     */
    public getSolucionesActivas(page?: number, size?: number, options?: AxiosRequestConfig) {
        return SolucionesApiFp(this.configuration).getSolucionesActivas(page, size, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SoporteApi - axios parameter creator
 * @export
 */
export const SoporteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Permite a los usuarios enviar un correo electrónico con uno o más archivos adjuntos.
         * @param {CorreoAyudaRequest} correoAyudaRequest Datos necesarios para enviar el correo.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enviarCorreoConAdjunto: async (correoAyudaRequest: CorreoAyudaRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'correoAyudaRequest' is not null or undefined
            assertParamExists('enviarCorreoConAdjunto', 'correoAyudaRequest', correoAyudaRequest)
            const localVarPath = `/soporte/correo-ayuda`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(correoAyudaRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SoporteApi - functional programming interface
 * @export
 */
export const SoporteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SoporteApiAxiosParamCreator(configuration)
    return {
        /**
         * Permite a los usuarios enviar un correo electrónico con uno o más archivos adjuntos.
         * @param {CorreoAyudaRequest} correoAyudaRequest Datos necesarios para enviar el correo.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enviarCorreoConAdjunto(correoAyudaRequest: CorreoAyudaRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enviarCorreoConAdjunto(correoAyudaRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SoporteApi - factory interface
 * @export
 */
export const SoporteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SoporteApiFp(configuration)
    return {
        /**
         * Permite a los usuarios enviar un correo electrónico con uno o más archivos adjuntos.
         * @param {CorreoAyudaRequest} correoAyudaRequest Datos necesarios para enviar el correo.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enviarCorreoConAdjunto(correoAyudaRequest: CorreoAyudaRequest, options?: any): AxiosPromise<void> {
            return localVarFp.enviarCorreoConAdjunto(correoAyudaRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SoporteApi - object-oriented interface
 * @export
 * @class SoporteApi
 * @extends {BaseAPI}
 */
export class SoporteApi extends BaseAPI {
    /**
     * Permite a los usuarios enviar un correo electrónico con uno o más archivos adjuntos.
     * @param {CorreoAyudaRequest} correoAyudaRequest Datos necesarios para enviar el correo.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SoporteApi
     */
    public enviarCorreoConAdjunto(correoAyudaRequest: CorreoAyudaRequest, options?: AxiosRequestConfig) {
        return SoporteApiFp(this.configuration).enviarCorreoConAdjunto(correoAyudaRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TotalProductosApi - axios parameter creator
 * @export
 */
export const TotalProductosApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtiene los totales de productos principales de congreso y divulgacion
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalCongresoDivulgacion: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/total-productos/congreso-divulgacion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene los totales de productos generales y principales
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalProductos: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/total-productos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene los totales de productos principales de tesis, cursos, y diplomados
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalTesisCursosDiplomados: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/total-productos/tesis-cursos-diplomados`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TotalProductosApi - functional programming interface
 * @export
 */
export const TotalProductosApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TotalProductosApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtiene los totales de productos principales de congreso y divulgacion
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTotalCongresoDivulgacion(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTotalCongresoDivulgacion(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene los totales de productos generales y principales
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTotalProductos(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TotalProductos>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTotalProductos(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene los totales de productos principales de tesis, cursos, y diplomados
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTotalTesisCursosDiplomados(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTotalTesisCursosDiplomados(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TotalProductosApi - factory interface
 * @export
 */
export const TotalProductosApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TotalProductosApiFp(configuration)
    return {
        /**
         * Obtiene los totales de productos principales de congreso y divulgacion
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalCongresoDivulgacion(options?: any): AxiosPromise<number> {
            return localVarFp.getTotalCongresoDivulgacion(options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene los totales de productos generales y principales
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalProductos(options?: any): AxiosPromise<TotalProductos> {
            return localVarFp.getTotalProductos(options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene los totales de productos principales de tesis, cursos, y diplomados
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalTesisCursosDiplomados(options?: any): AxiosPromise<number> {
            return localVarFp.getTotalTesisCursosDiplomados(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TotalProductosApi - object-oriented interface
 * @export
 * @class TotalProductosApi
 * @extends {BaseAPI}
 */
export class TotalProductosApi extends BaseAPI {
    /**
     * Obtiene los totales de productos principales de congreso y divulgacion
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TotalProductosApi
     */
    public getTotalCongresoDivulgacion(options?: AxiosRequestConfig) {
        return TotalProductosApiFp(this.configuration).getTotalCongresoDivulgacion(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene los totales de productos generales y principales
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TotalProductosApi
     */
    public getTotalProductos(options?: AxiosRequestConfig) {
        return TotalProductosApiFp(this.configuration).getTotalProductos(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene los totales de productos principales de tesis, cursos, y diplomados
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TotalProductosApi
     */
    public getTotalTesisCursosDiplomados(options?: AxiosRequestConfig) {
        return TotalProductosApiFp(this.configuration).getTotalTesisCursosDiplomados(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VariablesSociodemograficasApi - axios parameter creator
 * @export
 */
export const VariablesSociodemograficasApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtiene el cuestionario de variables sociodemográficas de un usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGrupos: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/grupos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene el cuestionario de variables sociodemográficas de un usuario
         * @param {number} id id del usuario
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getGruposById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getGruposById', 'id', id)
            const localVarPath = `/grupos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Almacena el cuestionario de variables sociodemográficas de un usuario
         * @param {Grupos} grupos 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveGrupos: async (grupos: Grupos, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'grupos' is not null or undefined
            assertParamExists('saveGrupos', 'grupos', grupos)
            const localVarPath = `/grupos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(grupos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actualiza el cuestionario de variables sociodemográficas de un usuario
         * @param {number} id id del usuario
         * @param {Grupos} grupos 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGruposById: async (id: number, grupos: Grupos, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateGruposById', 'id', id)
            // verify required parameter 'grupos' is not null or undefined
            assertParamExists('updateGruposById', 'grupos', grupos)
            const localVarPath = `/grupos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", ["jhipster", "email", "profile"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(grupos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VariablesSociodemograficasApi - functional programming interface
 * @export
 */
export const VariablesSociodemograficasApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VariablesSociodemograficasApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtiene el cuestionario de variables sociodemográficas de un usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGrupos(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Grupos>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGrupos(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene el cuestionario de variables sociodemográficas de un usuario
         * @param {number} id id del usuario
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getGruposById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Grupos>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGruposById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Almacena el cuestionario de variables sociodemográficas de un usuario
         * @param {Grupos} grupos 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveGrupos(grupos: Grupos, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Grupos>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveGrupos(grupos, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Actualiza el cuestionario de variables sociodemográficas de un usuario
         * @param {number} id id del usuario
         * @param {Grupos} grupos 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGruposById(id: number, grupos: Grupos, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Grupos>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGruposById(id, grupos, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VariablesSociodemograficasApi - factory interface
 * @export
 */
export const VariablesSociodemograficasApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VariablesSociodemograficasApiFp(configuration)
    return {
        /**
         * Obtiene el cuestionario de variables sociodemográficas de un usuario
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGrupos(options?: any): AxiosPromise<Grupos> {
            return localVarFp.getGrupos(options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene el cuestionario de variables sociodemográficas de un usuario
         * @param {number} id id del usuario
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getGruposById(id: number, options?: any): AxiosPromise<Grupos> {
            return localVarFp.getGruposById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Almacena el cuestionario de variables sociodemográficas de un usuario
         * @param {Grupos} grupos 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveGrupos(grupos: Grupos, options?: any): AxiosPromise<Grupos> {
            return localVarFp.saveGrupos(grupos, options).then((request) => request(axios, basePath));
        },
        /**
         * Actualiza el cuestionario de variables sociodemográficas de un usuario
         * @param {number} id id del usuario
         * @param {Grupos} grupos 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGruposById(id: number, grupos: Grupos, options?: any): AxiosPromise<Grupos> {
            return localVarFp.updateGruposById(id, grupos, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VariablesSociodemograficasApi - object-oriented interface
 * @export
 * @class VariablesSociodemograficasApi
 * @extends {BaseAPI}
 */
export class VariablesSociodemograficasApi extends BaseAPI {
    /**
     * Obtiene el cuestionario de variables sociodemográficas de un usuario
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VariablesSociodemograficasApi
     */
    public getGrupos(options?: AxiosRequestConfig) {
        return VariablesSociodemograficasApiFp(this.configuration).getGrupos(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene el cuestionario de variables sociodemográficas de un usuario
     * @param {number} id id del usuario
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof VariablesSociodemograficasApi
     */
    public getGruposById(id: number, options?: AxiosRequestConfig) {
        return VariablesSociodemograficasApiFp(this.configuration).getGruposById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Almacena el cuestionario de variables sociodemográficas de un usuario
     * @param {Grupos} grupos 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VariablesSociodemograficasApi
     */
    public saveGrupos(grupos: Grupos, options?: AxiosRequestConfig) {
        return VariablesSociodemograficasApiFp(this.configuration).saveGrupos(grupos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actualiza el cuestionario de variables sociodemográficas de un usuario
     * @param {number} id id del usuario
     * @param {Grupos} grupos 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VariablesSociodemograficasApi
     */
    public updateGruposById(id: number, grupos: Grupos, options?: AxiosRequestConfig) {
        return VariablesSociodemograficasApiFp(this.configuration).updateGruposById(id, grupos, options).then((request) => request(this.axios, this.basePath));
    }
}


