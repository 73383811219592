var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-navbar",
    { attrs: { toggleable: "xl", type: "dark", sticky: "" } },
    [
      _c(
        "b-navbar-brand",
        { attrs: { href: "https://conahcyt.mx/", target: "_blank" } },
        [
          _c("img", {
            staticClass: "conahcyt",
            attrs: {
              src: require("../../../content/images/logo_conahcyt_con_sintagma_azul_completo.svg"),
              alt: "Conahcyt",
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "logo-rizoma" }, [
        _c("img", {
          attrs: {
            src: require("../../../content/images/logo-rizoma-blanco.svg"),
            alt: "Logo Rizoma",
          },
        }),
      ]),
      _vm._v(" "),
      _c("b-navbar-toggle", { attrs: { target: "nav-collapse" } }),
      _vm._v(" "),
      _c("b-collapse", { attrs: { id: "nav-collapse", "is-nav": "" } }, [
        _c(
          "div",
          { staticClass: "nav-collapse-2" },
          [
            _c(
              "b-navbar-nav",
              [
                _c(
                  "b-nav-item",
                  { attrs: { href: "https://rizoma.conahcyt.mx/" } },
                  [
                    _c(
                      "span",
                      {
                        domProps: {
                          textContent: _vm._s(_vm.$t("global.menu.home")),
                        },
                      },
                      [_vm._v("Inicio")]
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-navbar-nav",
              { staticClass: "navbar-right" },
              [
                _vm.authenticated
                  ? _c(
                      "b-nav-item",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            return _vm.reportarIncidencia()
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "icon-correo" }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("reportar-incidencia.menu")
                              ),
                            },
                          },
                          [_vm._v("Reportar Incidencia")]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.authenticated
                  ? _c(
                      "b-nav-item",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            return _vm.consultarDocumentoPU()
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "icon-descargar" }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("perfil-unico.title-descarga")
                              ),
                            },
                          },
                          [_vm._v("Descargar Mi Perfil Único")]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "b-nav-item-dropdown",
                  {
                    staticClass: "pointer",
                    class: {
                      "router-link-active": _vm.subIsActive("/account"),
                    },
                    attrs: {
                      right: "",
                      id: "account-menu",
                      "active-class": "active",
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "navbar-dropdown-menu",
                        attrs: { slot: "button-content" },
                        slot: "button-content",
                      },
                      [
                        _c("span", { staticClass: "icon-usuario" }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "no-bold",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("global.menu.account.main")
                              ),
                            },
                          },
                          [_vm._v(" Account ")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.authenticated
                      ? _c(
                          "b-dropdown-item",
                          {
                            attrs: {
                              "data-cy": "logout",
                              id: "logout",
                              "active-class": "active",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.logout()
                              },
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: "sign-out-alt" },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("global.menu.account.logout")
                                  ),
                                },
                              },
                              [_vm._v("Sign out")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.authenticated
                      ? _c(
                          "b-dropdown-item",
                          {
                            attrs: {
                              "data-cy": "login",
                              id: "login",
                              "active-class": "active",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.openLogin()
                              },
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: "sign-in-alt" },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("global.menu.account.login")
                                  ),
                                },
                              },
                              [_vm._v("Sign in")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-xl-pu",
            title: "Documento de Perfil Único",
            size: "lg",
          },
        },
        [
          _c(
            "object",
            {
              attrs: {
                data: _vm.documentoBase64,
                width: "500",
                height: "700",
                type: "application/pdf",
              },
            },
            [
              _c(
                "p",
                {
                  staticClass: "text-secondary",
                  domProps: {
                    innerHTML: _vm._s(_vm.$t("perfil-unico.alerta-reporte")),
                  },
                },
                [
                  _vm._v(
                    "\n        Su navegador no admite archivos PDF incrustados "
                  ),
                  _c("a", { attrs: { href: _vm.documentoBase64 } }, [
                    _vm._v("Download the PDF"),
                  ]),
                  _vm._v(" instead.\n      "),
                ]
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-ayuda",
            title: _vm.$t("global.menu.ayuda"),
            size: "lg",
            centered: "",
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "outline-primary" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(" Cerrar ")]
                  ),
                ]
              },
            },
          ]),
        },
        [_c("contacto-asistencia")],
        1
      ),
      _vm._v(" "),
      _c("modal-incidencia", { attrs: { id: "modalIncidencia" } }),
      _vm._v(" "),
      _c("div", { attrs: { id: "pdf-preview" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }