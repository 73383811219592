var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "form-group" },
      [
        _c(
          "label",
          { staticClass: "form-control-label", attrs: { for: _vm.id } },
          [_vm._v(_vm._s(_vm.label))]
        ),
        _vm._v(" "),
        _c("alerta", { ref: "alerta", attrs: { id: "alertContacto" } }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "flex w-full h-screen items-center justify-center text-center",
          },
          [
            _c(
              "div",
              {
                staticClass: "p-12 border-file",
                class: { "border-file-active": _vm.filelist.length > 0 },
                on: {
                  dragover: _vm.dragover,
                  dragleave: _vm.dragleave,
                  drop: _vm.drop,
                },
              },
              [
                this.filelist.length === 0
                  ? _c("div", [
                      _c("input", {
                        ref: "inputFile",
                        staticClass:
                          "w-px h-px opacity-0 overflow-hidden absolute",
                        attrs: {
                          type: "file",
                          id: "file_" + _vm.id,
                          accept: _vm.tiposMime,
                        },
                        on: { change: _vm.onChange },
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "block cursor-pointer",
                          attrs: { for: "file_" + _vm.id },
                        },
                        [
                          _c("div", [
                            _c(
                              "p",
                              {
                                domProps: {
                                  textContent: _vm._s(_vm.$t("image.drag")),
                                },
                              },
                              [_vm._v("Arrastre y suelte su archivo")]
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "btn btn-outline-primary",
                                attrs: { "v-text": _vm.$t("image.select") },
                              },
                              [
                                _vm._v(
                                  "\n                Seleccionar archivo\n                "
                                ),
                                _c("span", { staticClass: "icon-cargar" }),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ])
                  : _c(
                      "div",
                      { staticClass: "text-sm p-1" },
                      [
                        _c(
                          "b-container",
                          {
                            staticClass:
                              "bv-example-row bv-example-row-flex-cols",
                          },
                          [
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "left-felx",
                                    attrs: { col: "", lg: "5" },
                                  },
                                  [
                                    _vm.type == "imagen"
                                      ? _c(
                                          "span",
                                          [
                                            _c("b-avatar", {
                                              attrs: {
                                                src: _vm.url,
                                                size: "4rem",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _c("span", { staticClass: "icon-PDF" }),
                                    _vm._v(" "),
                                    _vm.filelist[0].size
                                      ? _c("p", { staticClass: "bold-600" }, [
                                          _vm._v(
                                            _vm._s(
                                              (
                                                _vm.filelist[0].size /
                                                Math.pow(1024, 2)
                                              ).toFixed(3)
                                            ) + " MB"
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("input", {
                                      ref: "inputFile",
                                      staticClass:
                                        "w-px h-px opacity-0 overflow-hidden absolute",
                                      staticStyle: { display: "none" },
                                      attrs: {
                                        type: "file",
                                        id: "file_" + _vm.id,
                                        accept: _vm.tiposMime,
                                      },
                                      on: { change: _vm.onChange },
                                    }),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "right-felx",
                                    attrs: { col: "", lg: "7" },
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-outline-primary",
                                        on: { click: _vm.cambiar },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            attrs: {
                                              "v-text": _vm.$t("image.change"),
                                            },
                                          },
                                          [_vm._v("Cambiar")]
                                        ),
                                        _vm._v(" "),
                                        _c("span", {
                                          staticClass: "icon-actualizar",
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "ml-2 btn btn-outline-primary",
                                        attrs: {
                                          type: "button",
                                          title: "Eliminar",
                                        },
                                        on: { click: _vm.remove },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            attrs: {
                                              "v-text": _vm.$t("image.delete"),
                                            },
                                          },
                                          [_vm._v("Eliminar")]
                                        ),
                                        _vm._v(" "),
                                        _c("span", {
                                          staticClass: "icon-eliminar",
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _vm.description
          ? _c("small", { staticClass: "form-text helper-text" }, [
              _vm._v(_vm._s(_vm.description)),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("b-form-invalid-feedback", { attrs: { state: !_vm.estado } }, [
          _vm.tipoFormatoInvalido
            ? _c("span", { attrs: { v: "" } }, [
                _c(
                  "span",
                  { attrs: { "v-text": _vm.$t("image.valid.size") } },
                  [
                    _vm._v(
                      "La imagen debe ser en formato " +
                        _vm._s(this.tiposMime) +
                        "."
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.pesoInvalido
            ? _c("span", { attrs: { v: "" } }, [
                _c(
                  "span",
                  { attrs: { "v-text": _vm.$t("image.valid.size") } },
                  [
                    _vm._v(
                      "La imagen debe ser máximo de " +
                        _vm._s(this.pesoMaximo / 1024) +
                        " MB."
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.nombreInvalido
            ? _c("span", { attrs: { v: "" } }, [
                _c(
                  "span",
                  { attrs: { "v-text": _vm.$t("image.valid.size") } },
                  [
                    _vm._v(
                      "\n          El nombre de la imagen únicamente puede contener letras, números ó (.,!,#,@,$,%,^,&,*,+,=,_,-,(,),[,],{,},|,<,>,~).\n        "
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }