var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c("b-col", { attrs: { sm: "12", lg: "12" } }, [
        _c("div", { staticClass: "border-custom" }),
      ]),
      _vm._v(" "),
      _c("b-col", { attrs: { sm: "12", lg: "12" } }, [
        _c(
          "h3",
          {
            staticClass: "m-h3",
            attrs: { "v-text": _vm.$t("domicilio.title") },
          },
          [_vm._v("Domicilio")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { sm: "12", lg: "12" } },
        [
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { sm: "12", lg: "4" } }, [
                _c(
                  "label",
                  {
                    attrs: {
                      "v-text": _vm.$t(
                        "domicilio.fields.nacionalExtranjero.label"
                      ),
                    },
                  },
                  [_vm._v("¿Dónde se encuentra su domicilio?")]
                ),
              ]),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { sm: "12", lg: "4" } },
                [
                  _c("b-form-radio-group", {
                    attrs: {
                      options: _vm.catNacionalExtranjero,
                      disabled: _vm.showCP,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.changeTipoUbicacion($event)
                      },
                    },
                    model: {
                      value: _vm.$v.domicilio.tipoUbicacion.$model,
                      callback: function ($$v) {
                        _vm.$set(_vm.$v.domicilio.tipoUbicacion, "$model", $$v)
                      },
                      expression: "$v.domicilio.tipoUbicacion.$model",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        { attrs: { sm: "12", lg: "12" } },
        [_c("alerta", { ref: "alertaCodigoPostal" })],
        1
      ),
      _vm._v(" "),
      _vm.$v.domicilio.tipoUbicacion.$model == "NACIONAL" ||
      _vm.$v.domicilio.tipoUbicacion.$model == null
        ? _c(
            "b-col",
            { attrs: { id: "nacional-id", sm: "12", lg: "12" } },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { sm: "12", lg: "12" } },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { sm: "12", lg: "6", xl: "4" } },
                            [
                              _c("input-text", {
                                attrs: {
                                  id: "id-codigoPostal",
                                  label: _vm.$t(
                                    "domicilio.fields.codigoPostal.label"
                                  ),
                                  readonly: false,
                                  required: true,
                                  valid: _vm.$v.domicilio.codigoPostal.$dirty
                                    ? !_vm.$v.domicilio.codigoPostal.$error
                                    : null,
                                  validationsCommons: {
                                    requiredValue:
                                      !_vm.$v.domicilio.codigoPostal.required,
                                    requiredMessage: _vm.$t(
                                      "domicilio.fields.codigoPostal.validations.required"
                                    ),
                                    minValue:
                                      !_vm.$v.domicilio.codigoPostal.minLength,
                                    minMessage: _vm.$t(
                                      "domicilio.fields.codigoPostal.validations.minMessage",
                                      { min: "5" }
                                    ),
                                    maxValue:
                                      !_vm.$v.domicilio.codigoPostal.maxLength,
                                    maxMessage: _vm.$t(
                                      "domicilio.fields.codigoPostal.validations.maxMessage",
                                      { max: "5" }
                                    ),
                                  },
                                },
                                on: { keypress: _vm.numbersOnly },
                                model: {
                                  value: _vm.$v.domicilio.codigoPostal.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.domicilio.codigoPostal,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "$v.domicilio.codigoPostal.$model",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            { attrs: { col: "", sm: "12", lg: "6", xl: "2" } },
                            [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c("span", { staticClass: "label-hidden" }),
                                  _vm._v(" "),
                                  _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        variant: "primary",
                                        block: "",
                                        disabled:
                                          _vm.$v.domicilio.codigoPostal
                                            .$invalid,
                                      },
                                      on: { click: _vm.getCP },
                                    },
                                    [
                                      _c("span", [_vm._v("Buscar")]),
                                      _vm._v(" "),
                                      _c("span", {
                                        staticClass: "icon-buscar ml-2",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { sm: "12", lg: "6" } },
                    [
                      _c("input-select-one", {
                        attrs: {
                          id: "estado-id",
                          label: _vm.$t("domicilio.fields.estado.label"),
                          options: _vm.catEstado,
                          readonly: true,
                          required: true,
                          valid: _vm.$v.domicilio.estado.$dirty
                            ? !_vm.$v.domicilio.estado.$error
                            : null,
                          validationsCommons: {
                            requiredValue: !_vm.$v.domicilio.estado.required,
                            requiredMessage: _vm.$t(
                              "domicilio.fields.estado.validations.required"
                            ),
                          },
                        },
                        on: {
                          change: function ($event) {
                            return _vm.getCatMunicipio($event)
                          },
                        },
                        model: {
                          value: _vm.$v.domicilio.estado.$model,
                          callback: function ($$v) {
                            _vm.$set(_vm.$v.domicilio.estado, "$model", $$v)
                          },
                          expression: "$v.domicilio.estado.$model",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { sm: "12", lg: "6" } },
                    [
                      _c("input-select-one", {
                        attrs: {
                          id: "municipio-alcaldia-id",
                          label: _vm.$t("domicilio.fields.municipio.label"),
                          options: _vm.catMunicipio,
                          readonly: true,
                          required: true,
                          valid: _vm.$v.domicilio.municipio.$dirty
                            ? !_vm.$v.domicilio.municipio.$error
                            : null,
                          validationsCommons: {
                            requiredValue: !_vm.$v.domicilio.municipio.required,
                            requiredMessage: _vm.$t(
                              "domicilio.fields.municipio.validations.required"
                            ),
                          },
                        },
                        model: {
                          value: _vm.$v.domicilio.municipio.$model,
                          callback: function ($$v) {
                            _vm.$set(_vm.$v.domicilio.municipio, "$model", $$v)
                          },
                          expression: "$v.domicilio.municipio.$model",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { sm: "12", lg: "6" } },
                    [
                      _c("input-select-one", {
                        attrs: {
                          id: "localidad-id",
                          label: _vm.$t("domicilio.fields.localidad.label"),
                          options: _vm.catLocalidad,
                          readonly: false,
                          required: true,
                          valid: _vm.$v.domicilio.localidad.$dirty
                            ? !_vm.$v.domicilio.localidad.$error
                            : null,
                          validationsCommons: {
                            requiredValue: !_vm.$v.domicilio.localidad.required,
                            requiredMessage: _vm.$t(
                              "domicilio.fields.localidad.validations.required"
                            ),
                          },
                        },
                        on: { change: _vm.limpiaAsentamiento },
                        model: {
                          value: _vm.$v.domicilio.localidad.$model,
                          callback: function ($$v) {
                            _vm.$set(_vm.$v.domicilio.localidad, "$model", $$v)
                          },
                          expression: "$v.domicilio.localidad.$model",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { sm: "12", lg: "6" } },
                    [
                      _c("input-select-one", {
                        attrs: {
                          id: "asentamiento-id",
                          label: _vm.$t("domicilio.fields.asentamiento.label"),
                          options: _vm.catAsentamiento,
                          readonly: false,
                          required: false,
                          valid: _vm.$v.domicilio.asentamiento.$dirty
                            ? !_vm.$v.domicilio.asentamiento.$error
                            : null,
                          validationsCommons: {
                            requiredValue:
                              !_vm.$v.domicilio.asentamiento.required,
                            requiredMessage: _vm.$t(
                              "domicilio.fields.asentamiento.validations.required"
                            ),
                          },
                        },
                        on: {
                          change: function ($event) {
                            return _vm.changueTipoAsentamiento($event)
                          },
                        },
                        model: {
                          value: _vm.$v.domicilio.asentamiento.$model,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.$v.domicilio.asentamiento,
                              "$model",
                              $$v
                            )
                          },
                          expression: "$v.domicilio.asentamiento.$model",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { sm: "12", lg: "6" } },
                    [
                      _c("input-select-one", {
                        attrs: {
                          id: "tipoAsentamiento-asentamiento-id",
                          label: _vm.$t(
                            "domicilio.fields.tipoAsentamiento.label"
                          ),
                          options: _vm.catTipoAsentamiento,
                          readonly: true,
                          required: false,
                          valid: _vm.$v.domicilio.tipoAsentamiento.$dirty
                            ? !_vm.$v.domicilio.tipoAsentamiento.$error
                            : null,
                          validationsCommons: {
                            requiredValue:
                              !_vm.$v.domicilio.tipoAsentamiento.required,
                            requiredMessage: _vm.$t(
                              "domicilio.fields.tipoAsentamiento.validations.required"
                            ),
                          },
                        },
                        model: {
                          value: _vm.$v.domicilio.tipoAsentamiento.$model,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.$v.domicilio.tipoAsentamiento,
                              "$model",
                              $$v
                            )
                          },
                          expression: "$v.domicilio.tipoAsentamiento.$model",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("b-overlay", {
                    attrs: {
                      show: _vm.showCP,
                      "no-wrap": "",
                      "spinner-type": "grow",
                      "spinner-variant": "primary",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "b-col",
            { attrs: { id: "extranjero-id", sm: "12", lg: "12" } },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { sm: "12", lg: "12" } },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { sm: "12", lg: "8" } },
                            [
                              _c("input-select-one", {
                                attrs: {
                                  id: "pais-id",
                                  options: _vm.catPais,
                                  label: _vm.$t(
                                    "domicilio.fields.paisExt.label"
                                  ),
                                  readonly: false,
                                  required: true,
                                  valid: _vm.$v.domicilio.pais.$dirty
                                    ? !_vm.$v.domicilio.pais.$error
                                    : null,
                                  validationsCommons: {
                                    requiredValue:
                                      !_vm.$v.domicilio.pais.required,
                                    requiredMessage: _vm.$t(
                                      "domicilio.fields.paisExt.validations.required"
                                    ),
                                  },
                                },
                                model: {
                                  value: _vm.$v.domicilio.pais.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.domicilio.pais,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression: "$v.domicilio.pais.$model",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            { attrs: { col: "", sm: "12", lg: "4" } },
                            [
                              _c("input-text", {
                                attrs: {
                                  id: "extranjero-codigo-postal-id",
                                  label: _vm.$t(
                                    "domicilio.fields.codigoPostalExt.label"
                                  ),
                                  readonly: false,
                                  required: true,
                                  valid: _vm.$v.domicilio.cpExtranjero.$dirty
                                    ? !_vm.$v.domicilio.cpExtranjero.$error
                                    : null,
                                  validationsCommons: {
                                    requiredValue:
                                      !_vm.$v.domicilio.cpExtranjero.required,
                                    requiredMessage: _vm.$t(
                                      "domicilio.fields.codigoPostalExt.validations.required"
                                    ),
                                  },
                                },
                                model: {
                                  value: _vm.$v.domicilio.cpExtranjero.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.domicilio.cpExtranjero,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "$v.domicilio.cpExtranjero.$model",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            { attrs: { sm: "12", lg: "4" } },
                            [
                              _c("input-text", {
                                attrs: {
                                  id: "extranjero-estado-id",
                                  label: _vm.$t(
                                    "domicilio.fields.estadoExt.label"
                                  ),
                                  readonly: false,
                                  required: true,
                                  valid: _vm.$v.domicilio.estadoExtranjero
                                    .$dirty
                                    ? !_vm.$v.domicilio.estadoExtranjero.$error
                                    : null,
                                  validationsCommons: {
                                    requiredValue:
                                      !_vm.$v.domicilio.estadoExtranjero
                                        .required,
                                    requiredMessage: _vm.$t(
                                      "domicilio.fields.estadoExt.validations.required"
                                    ),
                                  },
                                },
                                model: {
                                  value:
                                    _vm.$v.domicilio.estadoExtranjero.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.domicilio.estadoExtranjero,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "$v.domicilio.estadoExtranjero.$model",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            { attrs: { sm: "12", lg: "4" } },
                            [
                              _c("input-text", {
                                attrs: {
                                  id: "extranjero-ciudad-id",
                                  label: _vm.$t(
                                    "domicilio.fields.ciudadExt.label"
                                  ),
                                  readonly: false,
                                  required: true,
                                  valid: _vm.$v.domicilio.ciudadExtranjero
                                    .$dirty
                                    ? !_vm.$v.domicilio.ciudadExtranjero.$error
                                    : null,
                                  validationsCommons: {
                                    requiredValue:
                                      !_vm.$v.domicilio.ciudadExtranjero
                                        .required,
                                    requiredMessage: _vm.$t(
                                      "domicilio.fields.ciudadExt.validations.required"
                                    ),
                                  },
                                },
                                model: {
                                  value:
                                    _vm.$v.domicilio.ciudadExtranjero.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.domicilio.ciudadExtranjero,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "$v.domicilio.ciudadExtranjero.$model",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            { attrs: { sm: "12", lg: "4" } },
                            [
                              _c("input-text", {
                                attrs: {
                                  id: "extranjero-condado-id",
                                  label: _vm.$t(
                                    "domicilio.fields.condadoExt.label"
                                  ),
                                  readonly: false,
                                  required: true,
                                  valid: _vm.$v.domicilio.condadoExtranjero
                                    .$dirty
                                    ? !_vm.$v.domicilio.condadoExtranjero.$error
                                    : null,
                                  validationsCommons: {
                                    requiredValue:
                                      !_vm.$v.domicilio.condadoExtranjero
                                        .required,
                                    requiredMessage: _vm.$t(
                                      "domicilio.fields.condadoExt.validations.required"
                                    ),
                                  },
                                },
                                model: {
                                  value:
                                    _vm.$v.domicilio.condadoExtranjero.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.domicilio.condadoExtranjero,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "$v.domicilio.condadoExtranjero.$model",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
      _vm._v(" "),
      _c("b-col", { attrs: { sm: "12", lg: "12" } }, [
        _c("div", { staticClass: "border-custom-dotted" }),
      ]),
      _vm._v(" "),
      _vm.$v.domicilio.tipoUbicacion.$model == "NACIONAL" ||
      _vm.$v.domicilio.tipoUbicacion.$model == null
        ? _c(
            "b-col",
            { attrs: { id: "nacional-2-id", sm: "12", lg: "12" } },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { sm: "12", lg: "12" } },
                    [
                      _c("b-alert", {
                        attrs: { show: "", variant: "primary" },
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("domicilio.alerts.domicilio")
                          ),
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { staticClass: "mt-3", attrs: { sm: "12", lg: "12" } },
                    [
                      _c(
                        "b-row",
                        [
                          _c("b-col", { attrs: { sm: "12", lg: "4" } }, [
                            _c(
                              "label",
                              {
                                attrs: {
                                  "v-text": _vm.$t("domicilio.tipoDomicilio"),
                                },
                              },
                              [_vm._v("¿Su domicilio es de tipo?")]
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            { attrs: { sm: "12", lg: "4" } },
                            [
                              _c("b-form-radio-group", {
                                attrs: { options: _vm.catTipoDomicilio },
                                on: {
                                  change: function ($event) {
                                    return _vm.changeTipoDomicilio()
                                  },
                                },
                                model: {
                                  value: _vm.$v.domicilio.tipoDomicilio.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.domicilio.tipoDomicilio,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "$v.domicilio.tipoDomicilio.$model",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("b-col", { attrs: { sm: "12", lg: "12" } }, [
                    _c("div", { staticClass: "border-custom-dotted" }),
                  ]),
                  _vm._v(" "),
                  _c("b-col", { attrs: { sm: "12", lg: "12" } }, [
                    _c(
                      "h3",
                      {
                        staticClass: "m-h3",
                        attrs: { "v-text": _vm.$t("domicilio.vialidad") },
                      },
                      [_vm._v("Vialidad")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { sm: "12", lg: "12" } },
                    [
                      _c(
                        "b-row",
                        [
                          _vm.domicilio.tipoDomicilio == "RURAL"
                            ? _c(
                                "b-col",
                                { attrs: { sm: "12", lg: "4" } },
                                [
                                  _c("input-select-one", {
                                    attrs: {
                                      id: "tipo-vialidad-rural-id",
                                      label: _vm.$t(
                                        "domicilio.fields.tipoVialidad.label"
                                      ),
                                      options: _vm.catTipoVialidadC,
                                      readonly: false,
                                      required: true,
                                      valid: _vm.$v.domicilio.tipoVialidadRural
                                        .$dirty
                                        ? !_vm.$v.domicilio.tipoVialidadRural
                                            .$error
                                        : null,
                                      validationsCommons: {
                                        requiredValue:
                                          !_vm.$v.domicilio.tipoVialidadRural
                                            .required,
                                        requiredMessage: _vm.$t(
                                          "domicilio.fields.tipoVialidad.validations.required"
                                        ),
                                      },
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changueTipoVialidad($event)
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.$v.domicilio.tipoVialidadRural
                                          .$model,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.$v.domicilio.tipoVialidadRural,
                                          "$model",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "$v.domicilio.tipoVialidadRural.$model",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _c(
                                "b-col",
                                { attrs: { sm: "12", lg: "4" } },
                                [
                                  _c("input-select-one", {
                                    attrs: {
                                      id: "tipo-vialidad-urbano-id",
                                      label: _vm.$t(
                                        "domicilio.fields.tipoVialidad.label"
                                      ),
                                      options: _vm.catTipoVialidadC,
                                      readonly: false,
                                      required: true,
                                      valid: _vm.$v.domicilio.tipoVialidadUrbano
                                        .$dirty
                                        ? !_vm.$v.domicilio.tipoVialidadUrbano
                                            .$error
                                        : null,
                                      validationsCommons: {
                                        requiredValue:
                                          !_vm.$v.domicilio.tipoVialidadUrbano
                                            .required,
                                        requiredMessage: _vm.$t(
                                          "domicilio.fields.tipoVialidad.validations.required"
                                        ),
                                      },
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changueTipoVialidad($event)
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.$v.domicilio.tipoVialidadUrbano
                                          .$model,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.$v.domicilio.tipoVialidadUrbano,
                                          "$model",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "$v.domicilio.tipoVialidadUrbano.$model",
                                    },
                                  }),
                                ],
                                1
                              ),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            { attrs: { sm: "12", lg: "8" } },
                            [
                              _c("input-text", {
                                attrs: {
                                  id: "nombre-vialidad-id",
                                  label: _vm.$t(
                                    "domicilio.fields.nombreVialidad.label"
                                  ),
                                  readonly: false,
                                  required: true,
                                  valid: _vm.$v.domicilio.nombreVialidad.$dirty
                                    ? !_vm.$v.domicilio.nombreVialidad.$error
                                    : null,
                                  validationsCommons: {
                                    requiredValue:
                                      !_vm.$v.domicilio.nombreVialidad.required,
                                    requiredMessage: _vm.$t(
                                      "domicilio.fields.nombreVialidad.validations.required"
                                    ),
                                    maxValue:
                                      !_vm.$v.domicilio.nombreVialidad
                                        .maxLength,
                                    maxMessage: _vm.$t(
                                      "domicilio.fields.nombreVialidad.validations.maxMessage",
                                      { max: "100" }
                                    ),
                                  },
                                },
                                model: {
                                  value: _vm.$v.domicilio.nombreVialidad.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.domicilio.nombreVialidad,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "$v.domicilio.nombreVialidad.$model",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { sm: "12", lg: "12" } },
                    [
                      _vm.$v.domicilio.tipoDomicilio.$model == "RURAL"
                        ? _c(
                            "b-row",
                            [
                              _vm.$v.domicilio.tipoVialidadRural.$model ==
                                null ||
                              _vm.$v.domicilio.tipoVialidadRural.id.$model ==
                                "01"
                                ? _c(
                                    "b-col",
                                    { attrs: { sm: "12", lg: "4" } },
                                    [
                                      _c("input-select-one", {
                                        attrs: {
                                          id: "administracion-id",
                                          label: _vm.$t(
                                            "domicilio.fields.administracion.label"
                                          ),
                                          options: _vm.catAdministracion,
                                          readonly: false,
                                          required: _vm.banderaRuralCarretera,
                                          valid: _vm.$v.domicilio.administracion
                                            .$dirty
                                            ? !_vm.$v.domicilio.administracion
                                                .$error
                                            : null,
                                          validationsCommons: {
                                            requiredValue:
                                              !_vm.$v.domicilio.administracion
                                                .required,
                                            requiredMessage: _vm.$t(
                                              "domicilio.fields.administracion.validations.required"
                                            ),
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.$v.domicilio.administracion
                                              .$model,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.$v.domicilio.administracion,
                                              "$model",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "$v.domicilio.administracion.$model",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.$v.domicilio.tipoVialidadRural.$model ==
                                null ||
                              _vm.$v.domicilio.tipoVialidadRural.id.$model ==
                                "01"
                                ? _c(
                                    "b-col",
                                    { attrs: { sm: "12", lg: "4" } },
                                    [
                                      _c("input-select-radio", {
                                        attrs: {
                                          id: "derecho-transito-id",
                                          label: _vm.$t(
                                            "domicilio.fields.derechoTransito.label"
                                          ),
                                          required: _vm.banderaRuralCarretera,
                                          options: _vm.catDerechoTransito,
                                          valid: _vm.$v.domicilio
                                            .derechoTransito.$dirty
                                            ? !_vm.$v.domicilio.derechoTransito
                                                .$error
                                            : null,
                                          validationsCommons: {
                                            requiredValue:
                                              !_vm.$v.domicilio.derechoTransito
                                                .required,
                                            requiredMessage: _vm.$t(
                                              "domicilio.fields.derechoTransito.validations.required"
                                            ),
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.$v.domicilio.derechoTransito
                                              .$model,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.$v.domicilio.derechoTransito,
                                              "$model",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "$v.domicilio.derechoTransito.$model",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.$v.domicilio.tipoVialidadRural.$model ==
                                null ||
                              _vm.$v.domicilio.tipoVialidadRural.id.$model ==
                                "01"
                                ? _c(
                                    "b-col",
                                    { attrs: { sm: "12", lg: "4" } },
                                    [
                                      _c("input-text", {
                                        attrs: {
                                          id: "codigo-id",
                                          label: _vm.$t(
                                            "domicilio.fields.codigo.label"
                                          ),
                                          readonly: false,
                                          valid: _vm.$v.domicilio.codigo.$dirty
                                            ? !_vm.$v.domicilio.codigo.$error
                                            : null,
                                          validationsCommons: {
                                            requiredValue:
                                              !_vm.$v.domicilio.codigo.required,
                                            requiredMessage: _vm.$t(
                                              "domicilio.fields.codigo.validations.required"
                                            ),
                                          },
                                        },
                                        model: {
                                          value: _vm.$v.domicilio.codigo.$model,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.$v.domicilio.codigo,
                                              "$model",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "$v.domicilio.codigo.$model",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "b-col",
                                { attrs: { sm: "12", lg: "4" } },
                                [
                                  _c("input-text", {
                                    attrs: {
                                      id: "origen-tramo-id",
                                      label: _vm.$t(
                                        "domicilio.fields.origenTramo.label"
                                      ),
                                      readonly: false,
                                      valid: _vm.$v.domicilio.origenTramo.$dirty
                                        ? !_vm.$v.domicilio.origenTramo.$error
                                        : null,
                                      validationsCommons: {
                                        requiredValue:
                                          !_vm.$v.domicilio.origenTramo
                                            .required,
                                        requiredMessage: _vm.$t(
                                          "domicilio.fields.origenTramo.validations.required"
                                        ),
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.$v.domicilio.origenTramo.$model,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.$v.domicilio.origenTramo,
                                          "$model",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "$v.domicilio.origenTramo.$model",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-col",
                                { attrs: { sm: "12", lg: "4" } },
                                [
                                  _c("input-text", {
                                    attrs: {
                                      id: "destino-tramo-id",
                                      label: _vm.$t(
                                        "domicilio.fields.destinoTramo.label"
                                      ),
                                      readonly: false,
                                      valid: _vm.$v.domicilio.destinoTramo
                                        .$dirty
                                        ? !_vm.$v.domicilio.destinoTramo.$error
                                        : null,
                                      validationsCommons: {
                                        requiredValue:
                                          !_vm.$v.domicilio.destinoTramo
                                            .required,
                                        requiredMessage: _vm.$t(
                                          "domicilio.fields.destinoTramo.validations.required"
                                        ),
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.$v.domicilio.destinoTramo.$model,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.$v.domicilio.destinoTramo,
                                          "$model",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "$v.domicilio.destinoTramo.$model",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.$v.domicilio.tipoVialidadRural.id.$model !==
                              "01"
                                ? _c(
                                    "b-col",
                                    { attrs: { sm: "12", lg: "4" } },
                                    [
                                      _c("input-select-radio", {
                                        attrs: {
                                          id: "margen-id",
                                          label: _vm.$t(
                                            "domicilio.fields.margen.label"
                                          ),
                                          options: _vm.catMargen,
                                          validationsCommons: {
                                            requiredValue:
                                              !_vm.$v.domicilio.margen.required,
                                            requiredMessage: _vm.$t(
                                              "domicilio.fields.margen.validations.required"
                                            ),
                                          },
                                        },
                                        model: {
                                          value: _vm.$v.domicilio.margen.$model,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.$v.domicilio.margen,
                                              "$model",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "$v.domicilio.margen.$model",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "b-col",
                                { attrs: { sm: "12", lg: "4" } },
                                [
                                  _c("input-text", {
                                    attrs: {
                                      id: "cadenamiento-id",
                                      label: _vm.$t(
                                        "domicilio.fields.cadenamiento.label"
                                      ),
                                      readonly: false,
                                      valid: _vm.$v.domicilio.cadenamiento
                                        .$dirty
                                        ? !_vm.$v.domicilio.cadenamiento.$error
                                        : null,
                                      validationsCommons: {
                                        requiredValue:
                                          !_vm.$v.domicilio.cadenamiento
                                            .required,
                                        requiredMessage: _vm.$t(
                                          "domicilio.fields.cadenamiento.validations.required"
                                        ),
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.$v.domicilio.cadenamiento.$model,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.$v.domicilio.cadenamiento,
                                          "$model",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "$v.domicilio.cadenamiento.$model",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("b-col", { attrs: { sm: "12", lg: "12" } }, [
                    _c("h4", { staticClass: "mt-3" }, [
                      _vm._v("Número exterior"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { sm: "12", lg: "12" } },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { sm: "12", lg: "4" } },
                            [
                              _c("input-text", {
                                attrs: {
                                  id: "exterior-numerico-id",
                                  label: _vm.$t(
                                    "domicilio.fields.exteriorNumerico.label"
                                  ),
                                  readonly: false,
                                  valid: _vm.$v.domicilio.exteriorNumerico
                                    .$dirty
                                    ? !_vm.$v.domicilio.exteriorNumerico.$error
                                    : null,
                                  validationsCommons: {
                                    requiredValue:
                                      !_vm.$v.domicilio.exteriorNumerico
                                        .required,
                                    requiredMessage: _vm.$t(
                                      "domicilio.fields.exteriorNumerico.validations.required"
                                    ),
                                    maxValue:
                                      !_vm.$v.domicilio.exteriorNumerico
                                        .maxLength,
                                    maxMessage: _vm.$t(
                                      "domicilio.fields.exteriorNumerico.validations.maxMessage",
                                      { max: "5" }
                                    ),
                                  },
                                },
                                on: { keypress: _vm.numbersOnly },
                                model: {
                                  value:
                                    _vm.$v.domicilio.exteriorNumerico.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.domicilio.exteriorNumerico,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "$v.domicilio.exteriorNumerico.$model",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            { attrs: { sm: "12", lg: "4" } },
                            [
                              _c("input-text", {
                                attrs: {
                                  id: "exterior-anterior-id",
                                  label: _vm.$t(
                                    "domicilio.fields.exteriorAnterior.label"
                                  ),
                                  readonly: false,
                                  valid: _vm.$v.domicilio.exteriorAnterior
                                    .$dirty
                                    ? !_vm.$v.domicilio.exteriorAnterior.$error
                                    : null,
                                  validationsCommons: {
                                    requiredValue:
                                      !_vm.$v.domicilio.exteriorAnterior
                                        .required,
                                    requiredMessage: _vm.$t(
                                      "domicilio.fields.exteriorAnterior.validations.required"
                                    ),
                                    maxValue:
                                      !_vm.$v.domicilio.exteriorAnterior
                                        .maxLength,
                                    maxMessage: _vm.$t(
                                      "domicilio.fields.exteriorAnterior.validations.maxMessage",
                                      { max: "5" }
                                    ),
                                  },
                                },
                                on: { keypress: _vm.numbersOnly },
                                model: {
                                  value:
                                    _vm.$v.domicilio.exteriorAnterior.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.domicilio.exteriorAnterior,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "$v.domicilio.exteriorAnterior.$model",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            { attrs: { sm: "12", lg: "4" } },
                            [
                              _c("input-text", {
                                attrs: {
                                  id: "exterior-alfanumerico-id",
                                  label: _vm.$t(
                                    "domicilio.fields.exteriorAlfanumerico.label"
                                  ),
                                  readonly: false,
                                  valid: _vm.$v.domicilio.exteriorAlfanumerico
                                    .$dirty
                                    ? !_vm.$v.domicilio.exteriorAlfanumerico
                                        .$error
                                    : null,
                                  validationsCommons: {
                                    requiredValue:
                                      !_vm.$v.domicilio.exteriorAlfanumerico
                                        .required,
                                    requiredMessage: _vm.$t(
                                      "domicilio.fields.exteriorAlfanumerico.validations.required"
                                    ),
                                    maxValue:
                                      !_vm.$v.domicilio.exteriorAlfanumerico
                                        .maxLength,
                                    maxMessage: _vm.$t(
                                      "domicilio.fields.exteriorAlfanumerico.validations.maxMessage",
                                      { max: "35" }
                                    ),
                                  },
                                },
                                model: {
                                  value:
                                    _vm.$v.domicilio.exteriorAlfanumerico
                                      .$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.domicilio.exteriorAlfanumerico,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "$v.domicilio.exteriorAlfanumerico.$model",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("b-col", { attrs: { sm: "12", lg: "12" } }, [
                    _c("h4", { staticClass: "mt-3" }, [
                      _vm._v("Número interior"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { sm: "12", lg: "12" } },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { sm: "12", lg: "4" } },
                            [
                              _c("input-text", {
                                attrs: {
                                  id: "interior-numerico-id",
                                  label: _vm.$t(
                                    "domicilio.fields.interiorNumerico.label"
                                  ),
                                  readonly: false,
                                  valid: _vm.$v.domicilio.interiorNumerico
                                    .$dirty
                                    ? !_vm.$v.domicilio.interiorNumerico.$error
                                    : null,
                                  validationsCommons: {
                                    requiredValue:
                                      !_vm.$v.domicilio.interiorNumerico
                                        .required,
                                    requiredMessage: _vm.$t(
                                      "domicilio.fields.interiorNumerico.validations.required"
                                    ),
                                    maxValue:
                                      !_vm.$v.domicilio.interiorNumerico
                                        .maxLength,
                                    maxMessage: _vm.$t(
                                      "domicilio.fields.interiorNumerico.validations.maxMessage",
                                      { max: "5" }
                                    ),
                                  },
                                },
                                on: { keypress: _vm.numbersOnly },
                                model: {
                                  value:
                                    _vm.$v.domicilio.interiorNumerico.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.domicilio.interiorNumerico,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "$v.domicilio.interiorNumerico.$model",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            { attrs: { sm: "12", lg: "4" } },
                            [
                              _c("input-text", {
                                attrs: {
                                  id: "interior-alfanumerico-id",
                                  label: _vm.$t(
                                    "domicilio.fields.interiorAlfanumerico.label"
                                  ),
                                  readonly: false,
                                  valid: _vm.$v.domicilio.interiorAlfanumerico
                                    .$dirty
                                    ? !_vm.$v.domicilio.interiorAlfanumerico
                                        .$error
                                    : null,
                                  validationsCommons: {
                                    requiredValue:
                                      !_vm.$v.domicilio.interiorAlfanumerico
                                        .required,
                                    requiredMessage: _vm.$t(
                                      "domicilio.fields.interiorAlfanumerico.validations.required"
                                    ),
                                    maxValue:
                                      !_vm.$v.domicilio.interiorAlfanumerico
                                        .maxLength,
                                    maxMessage: _vm.$t(
                                      "domicilio.fields.interiorAlfanumerico.validations.maxMessage",
                                      { max: "35" }
                                    ),
                                  },
                                },
                                model: {
                                  value:
                                    _vm.$v.domicilio.interiorAlfanumerico
                                      .$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.domicilio.interiorAlfanumerico,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "$v.domicilio.interiorAlfanumerico.$model",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { staticClass: "mt-3", attrs: { sm: "12", lg: "12" } },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { sm: "12", lg: "5" } },
                            [
                              _c("input-boolean", {
                                attrs: {
                                  id: "id-requiere-detalle-dom",
                                  label: _vm.$t(
                                    "domicilio.fields.requiereDetalle.label"
                                  ),
                                },
                                on: { change: _vm.changeDetalle },
                                model: {
                                  value:
                                    _vm.$v.domicilio.requiereDetalle.$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.domicilio.requiereDetalle,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "$v.domicilio.requiereDetalle.$model",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.$v.domicilio.requiereDetalle.$model == true
                        ? _c(
                            "b-row",
                            [
                              _c("b-col", { attrs: { sm: "12", lg: "12" } }, [
                                _c("div", { staticClass: "border-custom" }),
                              ]),
                              _vm._v(" "),
                              _c("b-col", { attrs: { sm: "12", lg: "12" } }, [
                                _c(
                                  "h3",
                                  {
                                    staticClass: "m-h3",
                                    attrs: {
                                      "v-text": _vm.$t("domicilio.referencias"),
                                    },
                                  },
                                  [_vm._v("Referencias")]
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "b-col",
                                { attrs: { sm: "12", lg: "12" } },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { sm: "12", lg: "4" } },
                                        [
                                          _c("input-select-one", {
                                            attrs: {
                                              id: "tipoReferencia01-id",
                                              label: _vm.$t(
                                                "domicilio.fields.tipoReferencia.label"
                                              ),
                                              options: _vm.catTipoVialidad,
                                              readonly: false,
                                              valid: _vm.$v.domicilio
                                                .tipoReferencia01.$dirty
                                                ? !_vm.$v.domicilio
                                                    .tipoReferencia01.$error
                                                : null,
                                              validationsCommons: {
                                                requiredValue:
                                                  !_vm.$v.domicilio
                                                    .tipoReferencia01.required,
                                                requiredMessage: _vm.$t(
                                                  "domicilio.fields.tipoReferencia.validations.required"
                                                ),
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.$v.domicilio
                                                  .tipoReferencia01.$model,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.$v.domicilio
                                                    .tipoReferencia01,
                                                  "$model",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "$v.domicilio.tipoReferencia01.$model",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-col",
                                        { attrs: { sm: "12", lg: "8" } },
                                        [
                                          _c("input-text", {
                                            attrs: {
                                              id: "nombreReferencia01-id",
                                              label: _vm.$t(
                                                "domicilio.fields.nombreReferencia.label"
                                              ),
                                              readonly: false,
                                              valid: _vm.$v.domicilio
                                                .nombreReferencia01.$dirty
                                                ? !_vm.$v.domicilio
                                                    .nombreReferencia01.$error
                                                : null,
                                              validationsCommons: {
                                                requiredValue:
                                                  !_vm.$v.domicilio
                                                    .nombreReferencia01
                                                    .required,
                                                requiredMessage: _vm.$t(
                                                  "domicilio.fields.nombreReferencia.validations.required"
                                                ),
                                                maxValue:
                                                  !_vm.$v.domicilio
                                                    .nombreReferencia01
                                                    .maxLength,
                                                maxMessage: _vm.$t(
                                                  "domicilio.fields.nombreReferencia.validations.maxMessage",
                                                  { max: "100" }
                                                ),
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.$v.domicilio
                                                  .nombreReferencia01.$model,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.$v.domicilio
                                                    .nombreReferencia01,
                                                  "$model",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "$v.domicilio.nombreReferencia01.$model",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { sm: "12", lg: "4" } },
                                        [
                                          _c("input-select-one", {
                                            attrs: {
                                              id: "tipoReferencia02-id",
                                              label: _vm.$t(
                                                "domicilio.fields.tipoReferencia.label"
                                              ),
                                              options: _vm.catTipoVialidad,
                                              readonly: false,
                                              valid: _vm.$v.domicilio
                                                .tipoReferencia02.$dirty
                                                ? !_vm.$v.domicilio
                                                    .tipoReferencia02.$error
                                                : null,
                                              validationsCommons: {
                                                requiredValue:
                                                  !_vm.$v.domicilio
                                                    .tipoReferencia02.required,
                                                requiredMessage: _vm.$t(
                                                  "domicilio.fields.tipoReferencia.validations.required"
                                                ),
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.$v.domicilio
                                                  .tipoReferencia02.$model,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.$v.domicilio
                                                    .tipoReferencia02,
                                                  "$model",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "$v.domicilio.tipoReferencia02.$model",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-col",
                                        { attrs: { sm: "12", lg: "8" } },
                                        [
                                          _c("input-text", {
                                            attrs: {
                                              id: "nombreReferencia02-id",
                                              label: _vm.$t(
                                                "domicilio.fields.nombreReferencia.label"
                                              ),
                                              readonly: false,
                                              valid: _vm.$v.domicilio
                                                .nombreReferencia02.$dirty
                                                ? !_vm.$v.domicilio
                                                    .nombreReferencia02.$error
                                                : null,
                                              validationsCommons: {
                                                requiredValue:
                                                  !_vm.$v.domicilio
                                                    .nombreReferencia02
                                                    .required,
                                                requiredMessage: _vm.$t(
                                                  "domicilio.fields.nombreReferencia.validations.required"
                                                ),
                                                maxValue:
                                                  !_vm.$v.domicilio
                                                    .nombreReferencia02
                                                    .maxLength,
                                                maxMessage: _vm.$t(
                                                  "domicilio.fields.nombreReferencia.validations.maxMessage",
                                                  { max: "100" }
                                                ),
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.$v.domicilio
                                                  .nombreReferencia02.$model,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.$v.domicilio
                                                    .nombreReferencia02,
                                                  "$model",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "$v.domicilio.nombreReferencia02.$model",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { sm: "12", lg: "4" } },
                                        [
                                          _c("input-select-one", {
                                            attrs: {
                                              id: "tipoReferencia03-id",
                                              label: _vm.$t(
                                                "domicilio.fields.tipoReferencia.label"
                                              ),
                                              options: _vm.catTipoVialidad,
                                              readonly: false,
                                              valid: _vm.$v.domicilio
                                                .tipoReferencia03.$dirty
                                                ? !_vm.$v.domicilio
                                                    .tipoReferencia03.$error
                                                : null,
                                              validationsCommons: {
                                                requiredValue:
                                                  !_vm.$v.domicilio
                                                    .tipoReferencia03.required,
                                                requiredMessage: _vm.$t(
                                                  "domicilio.fields.tipoReferencia.validations.required"
                                                ),
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.$v.domicilio
                                                  .tipoReferencia03.$model,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.$v.domicilio
                                                    .tipoReferencia03,
                                                  "$model",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "$v.domicilio.tipoReferencia03.$model",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-col",
                                        { attrs: { sm: "12", lg: "8" } },
                                        [
                                          _c("input-text", {
                                            attrs: {
                                              id: "nombreReferencia03-id",
                                              label: _vm.$t(
                                                "domicilio.fields.nombreReferencia.label"
                                              ),
                                              readonly: false,
                                              valid: _vm.$v.domicilio
                                                .nombreReferencia03.$dirty
                                                ? !_vm.$v.domicilio
                                                    .nombreReferencia03.$error
                                                : null,
                                              validationsCommons: {
                                                requiredValue:
                                                  !_vm.$v.domicilio
                                                    .nombreReferencia03
                                                    .required,
                                                requiredMessage: _vm.$t(
                                                  "domicilio.fields.nombreReferencia.validations.required"
                                                ),
                                                maxValue:
                                                  !_vm.$v.domicilio
                                                    .nombreReferencia03
                                                    .maxLength,
                                                maxMessage: _vm.$t(
                                                  "domicilio.fields.nombreReferencia.validations.maxMessage",
                                                  { max: "100" }
                                                ),
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.$v.domicilio
                                                  .nombreReferencia03.$model,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.$v.domicilio
                                                    .nombreReferencia03,
                                                  "$model",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "$v.domicilio.nombreReferencia03.$model",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-col",
                                { attrs: { sm: "12", lg: "12" } },
                                [
                                  _c("input-text-area", {
                                    attrs: {
                                      id: "referencias-id",
                                      label: _vm.$t(
                                        "domicilio.fields.descripcion.label"
                                      ),
                                      readonly: false,
                                      valid: _vm.$v.domicilio.descripcion.$dirty
                                        ? !_vm.$v.domicilio.descripcion.$error
                                        : null,
                                      maxCaracteres: 500,
                                    },
                                    model: {
                                      value:
                                        _vm.$v.domicilio.descripcion.$model,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.$v.domicilio.descripcion,
                                          "$model",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "$v.domicilio.descripcion.$model",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "b-col",
            { attrs: { id: "extranjero-2-id", sm: "12", lg: "12" } },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { sm: "12", lg: "8" } },
                    [
                      _c("input-text", {
                        attrs: {
                          id: "calleExtranjero-id",
                          label: _vm.$t(
                            "domicilio.fields.calleExtranjero.label"
                          ),
                          readonly: false,
                          required: true,
                          valid: _vm.$v.domicilio.calleExtranjero.$dirty
                            ? !_vm.$v.domicilio.calleExtranjero.$error
                            : null,
                          validationsCommons: {
                            requiredValue:
                              !_vm.$v.domicilio.calleExtranjero.required,
                            requiredMessage: _vm.$t(
                              "domicilio.fields.calleExtranjero.validations.required"
                            ),
                          },
                        },
                        model: {
                          value: _vm.$v.domicilio.calleExtranjero.$model,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.$v.domicilio.calleExtranjero,
                              "$model",
                              $$v
                            )
                          },
                          expression: "$v.domicilio.calleExtranjero.$model",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { sm: "12", lg: "4" } },
                    [
                      _c("input-text", {
                        attrs: {
                          id: "numero_extranjero-id",
                          label: _vm.$t(
                            "domicilio.fields.numeroExtranjero.label"
                          ),
                          readonly: false,
                          required: true,
                          valid: _vm.$v.domicilio.numeroExtranjero.$dirty
                            ? !_vm.$v.domicilio.numeroExtranjero.$error
                            : null,
                          validationsCommons: {
                            requiredValue:
                              !_vm.$v.domicilio.numeroExtranjero.required,
                            requiredMessage: _vm.$t(
                              "domicilio.fields.numeroExtranjero.validations.required"
                            ),
                          },
                        },
                        model: {
                          value: _vm.$v.domicilio.numeroExtranjero.$model,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.$v.domicilio.numeroExtranjero,
                              "$model",
                              $$v
                            )
                          },
                          expression: "$v.domicilio.numeroExtranjero.$model",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { sm: "12", lg: "12" } },
                    [
                      _c("input-text-area", {
                        attrs: {
                          id: "referencias-id",
                          label: _vm.$t("domicilio.fields.descripcion.label"),
                          readonly: false,
                          required: true,
                          valid: _vm.$v.domicilio.descripcion.$dirty
                            ? !_vm.$v.domicilio.descripcion.$error
                            : null,
                          maxCaracteres: 500,
                          validationsCommons: {
                            requiredValue:
                              !_vm.$v.domicilio.descripcion.required,
                            requiredMessage: _vm.$t(
                              "domicilio.fields.descripcion.validations.required"
                            ),
                            minValue: !_vm.$v.domicilio.descripcion.minLength,
                            minMessage: _vm.$t(
                              "domicilio.fields.descripcion.validations.minMessage",
                              { min: "1" }
                            ),
                            maxValue: !_vm.$v.domicilio.descripcion.maxLength,
                            maxMessage: _vm.$t(
                              "domicilio.fields.descripcion.validations.maxMessage",
                              { max: "500" }
                            ),
                          },
                        },
                        model: {
                          value: _vm.$v.domicilio.descripcion.$model,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.$v.domicilio.descripcion,
                              "$model",
                              $$v
                            )
                          },
                          expression: "$v.domicilio.descripcion.$model",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }