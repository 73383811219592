var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-dropdown-item",
        { attrs: { to: "/cuenta/cambio-email", "active-class": "active" } },
        [
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("cuenta-usuario-menu.sub-menu.email")),
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/cuenta/cambio-password", "active-class": "active" } },
        [
          _c("span", {
            domProps: {
              textContent: _vm._s(
                _vm.$t("cuenta-usuario-menu.sub-menu.password")
              ),
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          attrs: { "active-class": "active" },
          on: { click: _vm.openPersonasMorales },
        },
        [
          _c("span", {
            domProps: {
              textContent: _vm._s(
                _vm.$t("cuenta-usuario-menu.sub-menu.personas-morales")
              ),
            },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }