import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';
import PropertyCommons from '../commons/property.model';

import { mixins } from 'vue-class-component';
@Component
export default class InputTextAreaComponent extends mixins(PropertyCommons) {
  @Prop({ required: true })
  public value!: string;

  @Prop({ default: 0 })
  maxCaracteres: number;

  get text(): string {
    return this.value;
  }

  set text(newValue: string) {
    this.$emit('input', newValue);
  }

  cambio(e) {
    this.$emit('change', e);
  }
}
