var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-dropdown-item",
        {
          attrs: {
            to: "/trayectoria-profesional/empleo",
            "active-class": "active",
          },
        },
        [_c("span", [_vm._v("Empleo actual")])]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          directives: [
            {
              name: "b-toggle",
              rawName: "v-b-toggle:collapseProduccion",
              arg: "collapseProduccion",
            },
          ],
          attrs: {
            id: "produccion",
            "active-class": "active",
            "data-cy": "entity",
          },
        },
        [
          _c("span", [_vm._v("Producción")]),
          _vm._v(" "),
          _c("span", { staticClass: "ml-auto when-items" }, [
            _c(
              "span",
              { staticClass: "when-open" },
              [
                _c("b-icon", {
                  attrs: { icon: "chevron-compact-down", "font-scale": "1" },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "when-closed" },
              [
                _c("b-icon", {
                  attrs: { icon: "chevron-compact-right", "font-scale": "1" },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("b-collapse", { attrs: { id: "collapseProduccion" } }, [
        _c(
          "ul",
          { staticClass: "list-unstyled subitems-2" },
          [
            _c(
              "b-dropdown-item",
              {
                directives: [
                  {
                    name: "b-toggle",
                    rawName: "v-b-toggle:collapseProduccionCientifica",
                    arg: "collapseProduccionCientifica",
                  },
                ],
                attrs: { id: "cientifica-menu", "active-class": "active" },
              },
              [
                _c("span", [_vm._v("Humanista y científica")]),
                _vm._v(" "),
                _c("span", { staticClass: "ml-auto when-items" }, [
                  _c(
                    "span",
                    { staticClass: "when-open" },
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: "chevron-compact-down",
                          "font-scale": "1",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "when-closed" },
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: "chevron-compact-right",
                          "font-scale": "1",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "b-collapse",
              { attrs: { id: "collapseProduccionCientifica" } },
              [
                _c(
                  "ul",
                  { staticClass: "list-unstyled subitems-3" },
                  [
                    _c(
                      "b-dropdown-item",
                      {
                        attrs: {
                          to: "/trayectoria-profesional/produccion/cientifica-humanistica/articulos",
                          "active-class": "active",
                        },
                      },
                      [_c("span", [_vm._v("Artículos")])]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-dropdown-item",
                      {
                        attrs: {
                          to: "/trayectoria-profesional/produccion/cientifica-humanistica/libros",
                          "active-class": "active",
                        },
                      },
                      [_c("span", [_vm._v("Libros")])]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-dropdown-item",
                      {
                        attrs: {
                          to: "/trayectoria-profesional/produccion/cientifica-humanistica/capitulos",
                          "active-class": "active",
                        },
                      },
                      [_c("span", [_vm._v("Capítulos")])]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "b-dropdown-item",
              {
                directives: [
                  {
                    name: "b-toggle",
                    rawName: "v-b-toggle:collapseTecnologica",
                    arg: "collapseTecnologica",
                  },
                ],
                attrs: { id: "tecnologica-menu", "active-class": "active" },
              },
              [
                _c("span", [_vm._v("Tecnológica")]),
                _vm._v(" "),
                _c("span", { staticClass: "ml-auto when-items" }, [
                  _c(
                    "span",
                    { staticClass: "when-open" },
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: "chevron-compact-down",
                          "font-scale": "1",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "when-closed" },
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: "chevron-compact-right",
                          "font-scale": "1",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("b-collapse", { attrs: { id: "collapseTecnologica" } }, [
              _c(
                "ul",
                { staticClass: "list-unstyled subitems-3" },
                [
                  _c(
                    "b-dropdown-item",
                    {
                      attrs: {
                        to: "/aportaciones/desarrollos-tecnologicos-innovaciones",
                        "active-class": "active",
                      },
                    },
                    [
                      _c("span", [
                        _vm._v("Desarrollo Tecnológico e Innovación"),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-dropdown-item",
                    {
                      attrs: {
                        to: "/aportaciones/propiedades-intelectuales",
                        "active-class": "active",
                      },
                    },
                    [_c("span", [_vm._v("Propiedad Intelectual")])]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-dropdown-item",
                    {
                      attrs: {
                        to: "/aportaciones/transferencias-tecnologicas",
                        "active-class": "active",
                      },
                    },
                    [_c("span", [_vm._v("Transferencia Tecnológica")])]
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          attrs: {
            to: "/trayectoria-profesional/evaluaciones",
            "active-class": "active",
          },
        },
        [_c("span", [_vm._v("Evaluaciones realizadas")])]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          attrs: {
            to: "/trayectoria-profesional/estancias-investigacion",
            "active-class": "active",
          },
        },
        [_c("span", [_vm._v("Estancias de investigación")])]
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          directives: [
            {
              name: "b-toggle",
              rawName: "v-b-toggle:collapseFormacionComunidad",
              arg: "collapseFormacionComunidad",
            },
          ],
          attrs: {
            id: "formacion-comunidad",
            "active-class": "active",
            "data-cy": "entity",
          },
        },
        [
          _c("span", [_vm._v("Formación de comunidad HCTI")]),
          _vm._v(" "),
          _c("span", { staticClass: "ml-auto when-items" }, [
            _c(
              "span",
              { staticClass: "when-open" },
              [
                _c("b-icon", {
                  attrs: { icon: "chevron-compact-down", "font-scale": "1" },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "when-closed" },
              [
                _c("b-icon", {
                  attrs: { icon: "chevron-compact-right", "font-scale": "1" },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("b-collapse", { attrs: { id: "collapseFormacionComunidad" } }, [
        _c(
          "ul",
          { staticClass: "list-unstyled subitems-2" },
          [
            _c(
              "b-dropdown-item",
              {
                directives: [
                  {
                    name: "b-toggle",
                    rawName: "v-b-toggle:collapseDocencia",
                    arg: "collapseDocencia",
                  },
                ],
                attrs: { id: "docencia-menu", "active-class": "active" },
              },
              [
                _c("span", [_vm._v("Docencia")]),
                _vm._v(" "),
                _c("span", { staticClass: "ml-auto when-items" }, [
                  _c(
                    "span",
                    { staticClass: "when-open" },
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: "chevron-compact-down",
                          "font-scale": "1",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "when-closed" },
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: "chevron-compact-right",
                          "font-scale": "1",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("b-collapse", { attrs: { id: "collapseDocencia" } }, [
              _c(
                "ul",
                { staticClass: "list-unstyled subitems-3" },
                [
                  _c(
                    "b-dropdown-item",
                    {
                      attrs: {
                        to: "/trayectoria-profesional/formacion-comunidad/docencia/cursos-impartidos",
                        "active-class": "active",
                      },
                    },
                    [_c("span", [_vm._v("Cursos impartidos")])]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-dropdown-item",
                    {
                      attrs: {
                        to: "/trayectoria-profesional/formacion-comunidad/docencia/diplomados-impartidos",
                        "active-class": "active",
                      },
                    },
                    [_c("span", [_vm._v("Diplomados impartidos")])]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "b-dropdown-item",
              {
                attrs: {
                  to: "/trayectoria-profesional/formacion-comunidad/trabajos-titulacion",
                  "active-class": "active",
                },
              },
              [_c("span", [_vm._v("Trabajos de titulación")])]
            ),
            _vm._v(" "),
            _c(
              "b-dropdown-item",
              {
                attrs: {
                  to: "/educacion/desarrollo-institucional",
                  "active-class": "active",
                },
              },
              [_c("span", [_vm._v("Desarrollo institucional")])]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          directives: [
            {
              name: "b-toggle",
              rawName: "v-b-toggle:collapseAccesoUniversal",
              arg: "collapseAccesoUniversal",
            },
          ],
          staticClass: "pointer",
          attrs: {
            id: "acceso-universal",
            "active-class": "active",
            "data-cy": "entity",
          },
        },
        [
          _c("span", [_vm._v("Acceso universal al conocimiento")]),
          _vm._v(" "),
          _c("span", { staticClass: "ml-auto when-items" }, [
            _c(
              "span",
              { staticClass: "when-open" },
              [
                _c("b-icon", {
                  attrs: { icon: "chevron-compact-down", "font-scale": "1" },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "when-closed" },
              [
                _c("b-icon", {
                  attrs: { icon: "chevron-compact-right", "font-scale": "1" },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("b-collapse", { attrs: { id: "collapseAccesoUniversal" } }, [
        _c(
          "ul",
          { staticClass: "list-unstyled subitems-2" },
          [
            _c(
              "b-dropdown-item",
              {
                directives: [
                  {
                    name: "b-toggle",
                    rawName: "v-b-toggle:collapseDifusion",
                    arg: "collapseDifusion",
                  },
                ],
                attrs: { id: "difusion-menu", "active-class": "active" },
              },
              [
                _c("span", [_vm._v("Difusión")]),
                _vm._v(" "),
                _c("span", { staticClass: "ml-auto when-items" }, [
                  _c(
                    "span",
                    { staticClass: "when-open" },
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: "chevron-compact-down",
                          "font-scale": "1",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "when-closed" },
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: "chevron-compact-right",
                          "font-scale": "1",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("b-collapse", { attrs: { id: "collapseDifusion" } }, [
              _c(
                "ul",
                { staticClass: "list-unstyled subitems-3" },
                [
                  _c(
                    "b-dropdown-item",
                    {
                      attrs: {
                        to: "/trayectoria-profesional/acceso-universal-conocimiento/difusion/articulos",
                        "active-class": "active",
                      },
                    },
                    [_c("span", [_vm._v("Artículos")])]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-dropdown-item",
                    {
                      attrs: {
                        to: "/trayectoria-profesional/acceso-universal-conocimiento/difusion/libros",
                        "active-class": "active",
                      },
                    },
                    [_c("span", [_vm._v("Libros")])]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-dropdown-item",
                    {
                      attrs: {
                        to: "/trayectoria-profesional/acceso-universal-conocimiento/difusion/capitulos",
                        "active-class": "active",
                      },
                    },
                    [_c("span", [_vm._v("Capítulos")])]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "b-dropdown-item",
              {
                attrs: {
                  to: "/trayectoria-profesional/acceso-universal/divulgacion",
                  "active-class": "active",
                },
              },
              [_c("span", [_vm._v("Divulgación")])]
            ),
            _vm._v(" "),
            _c(
              "b-dropdown-item",
              {
                attrs: {
                  to: "/trayectoria-profesional/acceso-universal/participacion-congresos",
                  "active-class": "active",
                },
              },
              [_c("span", [_vm._v("Participación en congresos")])]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        {
          attrs: {
            to: "/trayectoria-profesional/eni-gema-sisdai",
            "active-class": "active",
          },
        },
        [_c("span", [_vm._v("ENI-Gema-Sisdai")])]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }