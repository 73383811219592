var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.mostrarListado,
              expression: "mostrarListado",
            },
          ],
          class: { fixed: _vm.isFixed },
        },
        [
          _c(
            "b-progress",
            {
              staticClass: "paginado-custom",
              attrs: {
                max: _vm.totalRegistros,
                height: "2rem",
                variant: "info",
              },
            },
            [
              _c(
                "b-progress-bar",
                { attrs: { value: _vm.registrosListados } },
                [
                  _c("span", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t("cards.listado-registros", {
                          registrosListados: _vm.registrosListados,
                          totalRegistros: _vm.totalRegistros,
                        })
                      ),
                    },
                  }),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.title
        ? _c("h3", { staticClass: "m-h3" }, [_vm._v(_vm._s(_vm.title))])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.items, function (item, i) {
        return _c(
          "div",
          { key: item.id, staticClass: "mt-4" },
          [
            _c(
              "b-card",
              {
                staticClass: "mb-4 custom-card",
                attrs: {
                  id: item.id,
                  "body-border-variant": item.completo
                    ? "success"
                    : item.completo == false
                    ? "danger"
                    : "",
                },
                on: {
                  mouseover: function ($event) {
                    return _vm.removeClassActice(item.id)
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.mostrarListado,
                        expression: "mostrarListado",
                      },
                    ],
                    staticClass: "registro-completo",
                  },
                  [
                    _c(
                      "span",
                      {
                        domProps: {
                          innerHTML: _vm._s(_vm.$t("cards.registro-completo")),
                        },
                      },
                      [_vm._v("completo")]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.mostrarListado,
                        expression: "mostrarListado",
                      },
                    ],
                    staticClass: "registro-incompleto",
                  },
                  [
                    _c(
                      "span",
                      {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("cards.registro-incompleto")
                          ),
                        },
                      },
                      [_vm._v("incompleto")]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-badge",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.mostrarListado,
                            expression: "mostrarListado",
                          },
                        ],
                        staticClass: "position-absolute custom-listado",
                        attrs: {
                          variant: item.completo
                            ? "success"
                            : item.completo == false
                            ? "danger"
                            : "",
                        },
                      },
                      [_vm._v(_vm._s(i + 1))]
                    ),
                    _vm._v(" "),
                    _c("ul", { staticClass: "felx-2" }, [
                      _c(
                        "div",
                        { staticClass: "left-felx" },
                        [
                          _c("b-card-text", [
                            item.title
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "font-weight-bolder custom-title",
                                    attrs: { "data-tooltip": item.title },
                                  },
                                  [_vm._v(_vm._s(item.title))]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.description
                              ? _c("div", {
                                  staticClass: "description",
                                  domProps: {
                                    innerHTML: _vm._s(item.description),
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            item.footer && item.completo == null
                              ? _c("div", {
                                  staticClass: "helper-text",
                                  domProps: { innerHTML: _vm._s(item.footer) },
                                })
                              : _vm._e(),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "right-felx card-btn" },
                        [
                          _vm._l(item.badge, function (b) {
                            return _c(
                              "span",
                              { key: b.id, staticClass: "rt-1" },
                              [
                                b.badge
                                  ? _c(
                                      "b-badge",
                                      {
                                        attrs: {
                                          pill: "",
                                          variant: b.primary
                                            ? "primary"
                                            : b.success
                                            ? "success"
                                            : "",
                                        },
                                      },
                                      [_vm._v(_vm._s(b.badge) + " ")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          }),
                          _vm._v(" "),
                          (_vm.verPrincipal &&
                            item.completo &&
                            item.documento != null &&
                            item.documento.uri != null) ||
                          (_vm.verPrincipal &&
                            item.documentoGlobal != null &&
                            item.completo)
                            ? _c(
                                "div",
                                { staticClass: "productoPrincipal" },
                                [
                                  _c("input-boolean", {
                                    staticClass: "custom-input-boolean",
                                    attrs: {
                                      id: item.id + "a",
                                      label: _vm.$t(
                                        "solicitud.producto-destacado"
                                      ),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.mandaPrincipal(i)
                                      },
                                    },
                                    model: {
                                      value: item.productoPrincipal,
                                      callback: function ($$v) {
                                        _vm.$set(item, "productoPrincipal", $$v)
                                      },
                                      expression: "item.productoPrincipal",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "span",
                            [
                              (item.seccionConDocumento === undefined &&
                                _vm.verDocumentoUpload &&
                                item.documento &&
                                item.documento.uri) ||
                              (item.seccionConDocumento === "empleo" &&
                                item.esPrincipal &&
                                _vm.verDocumentoUpload &&
                                item.documento &&
                                item.documento.uri)
                                ? _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        variant: "outline-primary",
                                        href: item.documento.uri,
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _c("span", { staticClass: "icon-PDF" }),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.$t("entity.action.see")
                                            ),
                                          },
                                        },
                                        [_vm._v("Ver")]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              (item.seccionConDocumento === undefined &&
                                _vm.verDocumentoUpload &&
                                item.documento &&
                                item.documento.uri) ||
                              (item.seccionConDocumento === "empleo" &&
                                item.esPrincipal &&
                                _vm.verDocumentoUpload &&
                                item.documento &&
                                item.documento.uri)
                                ? _c(
                                    "b-button",
                                    {
                                      attrs: { variant: "outline-primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.editDocumento(i)
                                        },
                                      },
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "icon-actualizar",
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.$t("entity.action.attach")
                                            ),
                                          },
                                        },
                                        [_vm._v("Adjuntar")]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              (item.seccionConDocumento === undefined &&
                                _vm.verDocumentoUpload &&
                                item.documento &&
                                item.documento.uri) ||
                              (item.seccionConDocumento === "empleo" &&
                                item.esPrincipal &&
                                _vm.verDocumentoUpload &&
                                item.documento &&
                                item.documento.uri)
                                ? _c(
                                    "b-button",
                                    {
                                      attrs: { variant: "outline-primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.eliminarDocumento(i)
                                        },
                                      },
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "icon-eliminar",
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.$t("entity.action.delete-pdf")
                                            ),
                                          },
                                        },
                                        [_vm._v("Eliminar PDF")]
                                      ),
                                      _vm._v(" "),
                                      _c("label"),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              (item.seccionConDocumento === "empleo" &&
                                item.esPrincipal &&
                                !(item.documento && item.documento.uri)) ||
                              (item.seccionConDocumento === undefined &&
                                !(item.documento && item.documento.uri))
                                ? _c(
                                    "b-button",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !item.hidden,
                                          expression: "!item.hidden",
                                        },
                                      ],
                                      attrs: {
                                        disabled: !item.completo,
                                        variant: "outline-primary",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.editDocumento(i)
                                        },
                                      },
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "icon-cargar",
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        {
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t(
                                                "document.documento-probatorio"
                                              )
                                            ),
                                          },
                                        },
                                        [_vm._v("documento")]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("c-action-buttons", {
                                attrs: {
                                  params: { paramId: item[_vm.trackedBy] },
                                  editView: _vm.editView,
                                  detailsView: _vm.detailsView,
                                  disabledBtn: _vm.disabledBtnAcctions,
                                },
                                on: {
                                  delete: function ($event) {
                                    return _vm.prepareRemove(i)
                                  },
                                  edit: function ($event) {
                                    return _vm.edit(i)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                item.badgeEjes
                  ? _c("b-row", { staticClass: "border-custom pt-3" }, [
                      _c(
                        "div",
                        { staticClass: "large-labels" },
                        _vm._l(item.badgeEjes, function (b) {
                          return _c("div", { key: b.id, staticClass: "ll-1" }, [
                            _c("span", [
                              _vm._v(" " + _vm._s(b.badgeEjes) + " "),
                            ]),
                          ])
                        }),
                        0
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        )
      }),
      _vm._v(" "),
      _c("c-delete-modal", {
        attrs: { id: _vm.id + "-removeEntityModal" },
        on: {
          onDelete: function ($event) {
            return _vm.remove()
          },
        },
      }),
      _vm._v(" "),
      _c("c-save-modal", {
        attrs: {
          productoPrincipal: _vm.productoPrincipal,
          totalPrincipal: _vm.totalPrincipal,
          totalReferenciaPrincipal: _vm.totalReferenciaPrincipal,
          id: _vm.id + "-saveEntityModal",
        },
        on: {
          guardar: function ($event) {
            return _vm.actualizaPrincipal()
          },
          regresaValor: function ($event) {
            return _vm.regresaAlValor()
          },
        },
      }),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }