import { Component, Vue } from 'vue-property-decorator';
import * as Constants from '@/constants';

@Component
export default class CuentaUsuarioMenu extends Vue {
  openPersonasMorales() {
    const urlPM = Constants.URL_PROD_RIZOMA_PM;
    window.open(urlPM, '_blank');
  }
}
