var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "visorPdfModal",
            title: "Documento PDF",
            size: "xl",
            centered: "",
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function () {
                return [
                  _c(
                    "b-button",
                    {
                      staticClass: "float-right",
                      attrs: { variant: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.$bvModal.hide("visorPdfModal")
                        },
                      },
                    },
                    [_vm._v(" Ok ")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("div", { staticStyle: { width: "100%" } }, [
            _c("embed", {
              attrs: {
                src: this.contenidoBase64,
                type: "application/pdf",
                width: "100%",
                height: "600",
                frameborder: "1",
              },
            }),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }