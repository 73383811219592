import Component, { mixins } from 'vue-class-component';
import PropertyCommons from '../commons/property.model';
import { maxLength, minLength, requiredIf, required, numeric } from 'vuelidate/lib/validators';
import CatalogoService from '@/shared/catalogo/catalogo.service';
import { Inject, Prop, PropSync, Ref, Watch } from 'vue-property-decorator';
import EntityFactory from '@/shared/entity-commons/entity.factory';
import { TipoUbicacion, TipoDomicilio } from '@/shared/perfil-usuario/api';
import AlertaComponent from '../alerta/alerta.component';

const VALIDATIONS = function () {
  return {
    domicilio: {
      tipoUbicacion: {},
      codigoPostal: {
        required: requiredIf(() => !this.banderaExtranjero),
        minLength: minLength(5),
        maxLength: maxLength(5),
      },
      estado: {
        required: requiredIf(() => !this.banderaExtranjero),
        id: {},
        nombre: {},
      },
      municipio: {
        required: requiredIf(() => !this.banderaExtranjero),
        id: {},
        nombre: {},
      },
      localidad: {
        required: requiredIf(() => !this.banderaExtranjero),
        id: {},
        nombre: {},
      },
      asentamiento: {
        id: {},
        nombre: {},
      },
      tipoAsentamiento: {
        id: {},
        nombre: {},
      },
      compartir: {},
      tipoDomicilio: {},
      tipoVialidadRural: {
        required: requiredIf(() => this.domicilio.tipoDomicilio == TipoDomicilio.RURAL),
        id: {},
        nombre: {},
      },
      tipoVialidadUrbano: {
        required: requiredIf(() => this.domicilio.tipoDomicilio == TipoDomicilio.URBANO),
        id: {},
        nombre: {},
      },
      nombreVialidad: {
        required,
        maxLength: maxLength(100),
      },
      exteriorAnterior: {
        maxLength: maxLength(5),
        numeric,
      },
      exteriorNumerico: {
        maxLength: maxLength(5),
        numeric,
      },
      exteriorAlfanumerico: {
        maxLength: maxLength(35),
      },
      interiorNumerico: {
        maxLength: maxLength(5),
        numeric,
      },
      interiorAlfanumerico: {
        maxLength: maxLength(35),
      },
      requiereDetalle: {},
      tipoReferencia01: {
        id: {},
        nombre: {},
      },
      nombreReferencia01: {
        maxLength: maxLength(100),
      },
      tipoReferencia02: {
        id: {},
        nombre: {},
      },
      nombreReferencia02: {
        maxLength: maxLength(100),
      },
      tipoReferencia03: {
        id: {},
        nombre: {},
      },
      nombreReferencia03: {
        maxLength: maxLength(100),
      },
      descripcion: {
        required: requiredIf(() => this.banderaExtranjero),
      },
      administracion: {
        id: {},
        nombre: {},
        required: requiredIf(() => this.banderaRuralCarretera),
      },
      derechoTransito: {
        id: {},
        nombre: {},
        required: requiredIf(() => this.banderaRuralCarretera),
      },
      codigo: {},
      origenTramo: {},
      destinoTramo: {},
      cadenamiento: {},
      margen: {
        id: {},
        nombre: {},
      },
      pais: {
        id: {},
        nombre: {},
        required: requiredIf(() => this.banderaExtranjero),
      },
      cpExtranjero: {
        required: requiredIf(() => this.banderaExtranjero),
      },
      estadoExtranjero: {
        required: requiredIf(() => this.banderaExtranjero),
      },
      ciudadExtranjero: {
        required: requiredIf(() => this.banderaExtranjero),
      },
      condadoExtranjero: {
        required: requiredIf(() => this.banderaExtranjero),
      },
      calleExtranjero: {
        required: requiredIf(() => this.banderaExtranjero),
      },
      numeroExtranjero: {
        required: requiredIf(() => this.banderaExtranjero),
      },
    },
  };
};

@Component({
  validations: VALIDATIONS,
})
export default class DomicilioComponent extends mixins(PropertyCommons) {
  @Ref()
  alertaCodigoPostal!: AlertaComponent;

  @Inject('catalogoService') private catalogoService: () => CatalogoService;

  @PropSync('value', { required: true, type: Object })
  public domicilio;

  @Prop({ default: null })
  public validC: boolean;

  @Watch('validC', { immediate: true })
  handler(value) {
    if (value) {
      this.$v.domicilio.$touch();
    } else {
      this.$v.domicilio.$reset();
    }
  }

  public showCP = false;

  public catEstado: Array<any> = [];
  public catMunicipio: Array<any> = [];
  public catTipoAsentamiento: Array<any> = [];
  public catTipoVialidadCarretera: Array<any> = [];
  public catTipoVialidadC: Array<any> = [];
  public catTipoVialidad: Array<any> = [];
  public catAsentamiento: Array<any> = [];
  public catLocalidad: Array<any> = [];
  public catPais: Array<any> = [];
  public catAdministracion: Array<any> = [];
  public catCP: Array<any> = [];
  public banderaExtranjero = false;
  public banderaRuralCarretera = false;
  public catTipoDomicilio = EntityFactory.getTipoDomicilioOptions();
  public catDerechoTransito: Array<any> = [];
  public catMargen: Array<any> = [];
  public catNacionalExtranjero = EntityFactory.getNacionalExtranjeroOptions();

  mounted() {
    this.banderaExtranjero = this.domicilio.tipoUbicacion !== TipoUbicacion.NACIONAL;
    this.getCatalogos();
    if (this.domicilio.codigoPostal || this.domicilio.estado?.clave) {
      this.validaTipoUbicacion();
      this.validaDetalleUbicacion();
    }
  }

  //Busqueda de comicilio por CP
  public getCP() {
    this.showCP = true;
    this.alertaCodigoPostal.limpiar();
    this.catalogoService()
      .get('getCatCodigosPostalesFilter', this.domicilio.codigoPostal)
      .then(res => {
        if (res.length >= 1) {
          this.catAsentamiento = res.sort((a, b) => a.text.localeCompare(b.text));
          this.domicilio.estado = this.filtraCatalogoClave(this.catEstado, res[0].value.id_entidad);
          if (this.catAsentamiento.length == 1) {
            this.domicilio.asentamiento = this.catAsentamiento[0].value;
          } else {
            this.domicilio.asentamiento = null;
          }
          this.getCatMunicipio(this.domicilio.estado).then(() => {
            this.domicilio.municipio = this.filtraCatalogoClave(this.catMunicipio, res[0].value.id_municipio);
            this.getCatLocalidad(this.domicilio.estado.id, this.domicilio.municipio.clave).then(() => {
              if (this.catAsentamiento.length == 1) {
                this.domicilio.localidad = this.catLocalidad[0].value;
              } else {
                this.domicilio.localidad = null;
              }
              this.showCP = false;
            });
          });
        } else {
          this.alertaCodigoPostal.mostrar(this.$t('domicilio.alerts.codigo'), 'primary', 20);
          this.domicilio.estado = null;
          this.domicilio.municipio = null;
          this.domicilio.localidad = null;
          this.domicilio.asentamiento = null;
          this.domicilio.tipoAsentamiento = null;
          this.catLocalidad = [];
          this.catAsentamiento = [];
          this.$v.domicilio.$reset();
          this.showCP = false;
        }
      });
  }

  public getCatalogos() {
    this.getCatEstado();
    this.getCatTipoAsentamiento();
    this.getVialidadRural();
    this.getVialidadUrbano();
    this.getPaises();
    this.getDerechoTransitos();
    this.getMargenes();
    this.getAdministraciones();
  }

  /**
   * getA
   */
  public getAsentamientoPorCP(cp) {
    return new Promise((resolve, reject) => {
      this.catalogoService()
        .get('getCatCodigosPostalesFilter', cp)
        .then(res => {
          this.catAsentamiento = res.sort((a, b) => a.text.localeCompare(b.text));
          resolve('ok');
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public getCatEstado() {
    return new Promise((resolve, reject) => {
      this.catalogoService()
        .get('getAreaGeoestadisticaEstatal')
        .then(res => {
          this.catEstado = res;
          resolve('ok');
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public getCatMunicipio(value) {
    return new Promise((resolve, reject) => {
      this.catalogoService()
        .get('getMunicipiosEstado', value.id)
        .then(res => {
          this.catMunicipio = res;
          resolve('ok');
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public getCatTipoAsentamiento() {
    this.catalogoService()
      .get('getTipoAsentamientos')
      .then(res => {
        this.catTipoAsentamiento = res;
      });
  }

  public getCatLocalidad(estado, municipio) {
    return new Promise((resolve, reject) => {
      this.catalogoService()
        .get('getLocalidadesMunicipiosEstados', estado, municipio)
        .then(res => {
          this.catLocalidad = res.sort((a, b) => a.text.localeCompare(b.text));
          resolve('ok');
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public getVialidadRural() {
    this.catalogoService()
      .get('getTerminoGenericos')
      .then(res => {
        this.catTipoVialidadCarretera = res;
      });
  }

  public getVialidadUrbano() {
    this.catalogoService()
      .get('getTipoVialidades')
      .then(res => {
        this.catTipoVialidad = res;
        this.catTipoVialidadC = this.catTipoVialidad;
      });
  }

  public getPaises() {
    this.catalogoService()
      .get('getPaises')
      .then(res => {
        res.forEach(function (item, index, object) {
          if (item.value.id == 'MEX') {
            object.splice(index, 1); //se elimina del catalogo la opción México
          }
        });
        this.catPais = res;
      });
  }

  public getAdministraciones() {
    return new Promise((resolve, reject) => {
      this.catalogoService()
        .get('getAdministraciones')
        .then(res => {
          this.catAdministracion = res;
          resolve('ok');
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public getDerechoTransitos() {
    return new Promise((resolve, reject) => {
      this.catalogoService()
        .get('getDerechoTransitos')
        .then(res => {
          this.catDerechoTransito = res;
          resolve('ok');
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public getMargenes() {
    return new Promise((resolve, reject) => {
      this.catalogoService()
        .get('getMargenes')
        .then(res => {
          this.catMargen = res;
          resolve('ok');
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public getAsentamientos() {
    this.catalogoService()
      .get('getAsentamientosHumanosLocalidadesMunicipiosEstados')
      .then(res => {
        this.catMargen = res.sort((a, b) => a.text.localeCompare(b.text));
      });
  }

  public changueTipoAsentamiento(val) {
    this.domicilio.tipoAsentamiento = this.filtraCatalogoId(this.catTipoAsentamiento, val.id_tipo_asentamiento);
  }

  public changeTipoDomicilio() {
    if (this.domicilio) {
      if (this.domicilio.tipoDomicilio == TipoDomicilio.RURAL) {
        this.$bvModal
          .msgBoxConfirm(this.$t('domicilio.alerts.cambioDomicilio').toString(), {
            title: 'Confirmación',
            size: 'md',
            buttonSize: 'md',
            okVariant: 'primary',
            okTitle: 'Si',
            cancelTitle: 'No',
            cancelVariant: 'outline-primary',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              this.setDomRural();
              this.catTipoVialidadC = this.catTipoVialidadCarretera;
              this.domicilio.tipoVialidadUrbano = null;
              this.domicilio.tipoVialidadRural = null;
            } else {
              this.setDomUrbano();
              this.domicilio.tipoVialidadRural = null;
            }
          });
      } else {
        this.$bvModal
          .msgBoxConfirm(this.$t('domicilio.alerts.cambioDomicilio').toString(), {
            title: 'Confirmación',
            size: 'md',
            buttonSize: 'md',
            okVariant: 'primary',
            okTitle: 'Si',
            cancelTitle: 'No',
            cancelVariant: 'outline-primary',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (!value) {
              this.setDomRural();
              this.catTipoVialidadC = this.catTipoVialidadCarretera;
              this.domicilio.tipoVialidadUrbano = null;
            } else {
              this.setDomUrbano();
              this.catTipoVialidadC = this.catTipoVialidad;
              this.domicilio.tipoVialidadRural = null;
              this.domicilio.tipoVialidadUrbano = null;
              this.domicilio.administracion = null;
              this.domicilio.derechoTransito = null;
              this.domicilio.codigo = null;
              this.domicilio.origenTramo = null;
              this.domicilio.destinoTramo = null;
              this.domicilio.cadenamiento = null;
            }
          });
      }
    }
  }

  /**
   * Ventana confirmación cuando se cambia el tipo de ubicación
   *
   */
  public changeTipoUbicacion(e) {
    this.banderaExtranjero = e !== TipoUbicacion.NACIONAL;
    if (this.banderaExtranjero) {
      this.$bvModal
        .msgBoxConfirm(this.$t('domicilio.modal.localizacion').toString(), {
          title: 'Confirmación',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'primary',
          okTitle: 'Si',
          cancelTitle: 'No',
          cancelVariant: 'outline-primary',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.setUbicacionExtranjero();
            this.cleanUbicacionNacional();
          } else {
            this.setUbicacionNacional();
          }
        });
    } else {
      this.$bvModal
        .msgBoxConfirm(this.$t('domicilio.modal.localizacion').toString(),  {
          title: 'Confirmación',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'primary',
          okTitle: 'Si',
          cancelTitle: 'No',
          cancelVariant: 'outline-primary',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.domicilio.pais = null;
            this.domicilio.cpExtranjero = null;
            this.domicilio.estadoExtranjero = null;
            this.domicilio.ciudadExtranjero = null;
            this.domicilio.condadoExtranjero = null;
            this.domicilio.calleExtranjero = null;
            this.domicilio.numeroExtranjero = null;
            this.domicilio.descripcion = null;
            this.setUbicacionNacional();
          } else {
            this.setUbicacionExtranjero();
          }
        });
    }
  }

  public changeDetalle() {
    if (!this.domicilio.requiereDetalle) {
      this.$bvModal
        .msgBoxConfirm(this.$t('domicilio.modal.referencias').toString(), {
          title: 'Confirmación',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'primary',
          okTitle: 'Si',
          cancelTitle: 'No',
          cancelVariant: 'outline-primary',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          this.domicilio.requiereDetalle = !value;
          if (!this.domicilio.requiereDetalle) {
            this.cleanReferencias();
          }
        });
    }
  }

  public changueTipoVialidad(e) {
    if (e) {
      if (this.domicilio.tipoDomicilio == TipoDomicilio.RURAL) {
        this.banderaRuralCarretera = e.id == '01';
        if (this.banderaRuralCarretera) {
          this.domicilio.margen = null;
          this.getAdministraciones().then(() => {
            if (this.domicilio.administracion)
              this.domicilio.administracion = this.filtraCatalogoId(this.catAdministracion, this.domicilio.administracion.id);

            this.getDerechoTransitos().then(() => {
              if (this.domicilio.derechoTransito)
                this.domicilio.derechoTransito = this.filtraCatalogoId(this.catDerechoTransito, this.domicilio.derechoTransito.id);
            });
          });
        } else {
          this.domicilio.administracion = null;
          this.domicilio.derechoTransito = null;
          this.domicilio.codigo = null;
          this.getMargenes().then(() => {
            if (this.domicilio.margen) this.domicilio.margen = this.filtraCatalogoId(this.catMargen, this.domicilio.margen.id);
          });
        }
      }
    }
  }

  /**
   * validaTipoUbicacion
   */
  public validaTipoUbicacion() {
    if (this.domicilio.cpExtranjero == null || this.domicilio.cpExtranjero == '') {
      this.domicilio.tipoUbicacion = TipoUbicacion.NACIONAL;
      this.validaTipoVialidad();
      this.setCP();
    }
  }

  /**
   * validaTipoVialidad
   */
  public validaTipoVialidad() {
    if (this.domicilio) {
      this.catalogoService()
        .get('getTerminoGenericos')
        .then(res => {
          this.catTipoVialidadCarretera = res;
          if (
            this.domicilio.tipoVialidadUrbano != null &&
            this.domicilio.administracion == null &&
            this.domicilio.tipoVialidadRural == null
          ) {
            this.catTipoVialidadC = this.catTipoVialidad;
            this.setDomUrbano();
          } else {
            this.catTipoVialidadC = this.catTipoVialidadCarretera;
            this.setDomRural();
            this.changueTipoVialidad(this.domicilio.tipoVialidadRural);
          }
        });
    }
  }

  /**
   *setea información de CP desde la consulta
   */
  public setCP() {
    this.showCP = true;
    this.getCatEstado()
      .then(() => {
        this.domicilio.estado = this.filtraCatalogoClave(this.catEstado, this.domicilio.estado.clave);
        this.getCatMunicipio(this.domicilio.estado)
          .then(() => {
            this.domicilio.municipio = this.filtraCatalogoClave(this.catMunicipio, this.domicilio.municipio.clave);
            this.getCatLocalidad(this.domicilio.estado.id, this.domicilio.municipio.clave)
              .then(() => {
                if (this.domicilio.localidad == null || this.domicilio.localidad?.nombre == 'NINGUNO') {
                  this.domicilio.localidad = null;
                } else {
                  this.domicilio.localidad = this.filtraCatalogoClave(this.catLocalidad, this.domicilio.localidad.clave);
                }
                if (this.domicilio.codigoPostal) {
                  this.getAsentamientoPorCP(this.domicilio.codigoPostal)
                    .then(() => {
                      this.domicilio.asentamiento = this.filtraCatalogoId(this.catAsentamiento, this.domicilio.asentamiento.id);
                      this.changueTipoAsentamiento(this.domicilio.asentamiento);
                      this.showCP = false;
                    })
                    .catch(() => {
                      this.domicilio.asentamiento = null;
                      this.showCP = false;
                    });
                } else {
                  this.catAsentamiento = [];
                  this.domicilio.asentamiento = null;
                  this.catTipoAsentamiento = [];
                  this.domicilio.tipoAsentamiento = null;
                  this.showCP = false;
                }
              })
              .catch(() => {
                this.catLocalidad = [];
                this.domicilio.localidad = null;
                this.showCP = false;
              });
          })
          .catch(() => {
            this.catMunicipio = [];
            this.domicilio.municipio = null;
            this.showCP = false;
          });
      })
      .catch(() => {
        this.catEstado = [];
        this.domicilio.estado = null;
        this.showCP = false;
      });
  }

  /**
   * valida Detalle de la Ubicacion
   */
  public validaDetalleUbicacion() {
    if (this.domicilio.requiereDetalle == null) {
      if (this.domicilio.tipoReferencia01?.id == null) {
        this.domicilio.requiereDetalle = false;
      } else {
        this.domicilio.requiereDetalle = true;
      }
    }
  }

  /**
   * setDomRural
   */
  public setDomRural() {
    this.domicilio.tipoDomicilio = TipoDomicilio.RURAL;
  }

  /**
   * setDomUrbano
   */
  public setDomUrbano() {
    this.domicilio.tipoDomicilio = TipoDomicilio.URBANO;
  }

  /**
   * setUbicacionNacional
   */
  public setUbicacionNacional() {
    this.domicilio.tipoUbicacion = TipoUbicacion.NACIONAL;
  }

  /**
   * setUbicacionExtranjero
   */
  public setUbicacionExtranjero() {
    this.domicilio.tipoUbicacion = TipoUbicacion.EXTRANJERA;
  }

  /**
   * cleanReferencias
   */
  public cleanReferencias() {
    this.domicilio.tipoReferencia01 = null;
    this.domicilio.nombreReferencia01 = null;
    this.domicilio.tipoReferencia02 = null;
    this.domicilio.nombreReferencia02 = null;
    this.domicilio.tipoReferencia03 = null;
    this.domicilio.nombreReferencia03 = null;
    this.domicilio.descripcion = null;
  }

  /**
   * cleanUbicacionNacional
   */
  public cleanUbicacionNacional() {
    this.domicilio.codigoPostal = null;
    this.domicilio.estado = null;
    this.domicilio.municipio = null;
    this.domicilio.localidad = null;
    this.domicilio.asentamiento = null;
    this.domicilio.tipoAsentamiento = null;

    this.setDomUrbano();
    this.catTipoVialidadC = this.catTipoVialidad;
    this.domicilio.tipoVialidadRural = null;
    this.domicilio.tipoVialidadUrbano = null;
    this.domicilio.nombreVialidad = null;
    this.domicilio.administracion = null;
    this.domicilio.derechoTransito = null;
    this.domicilio.codigo = null;
    this.domicilio.origenTramo = null;
    this.domicilio.destinoTramo = null;
    this.domicilio.cadenamiento = null;
    this.domicilio.exteriorNumerico = null;
    this.domicilio.exteriorAnterior = null;
    this.domicilio.exteriorAlfanumerico = null;
    this.domicilio.interiorNumerico = null;
    this.domicilio.interiorAlfanumerico = null;
    this.domicilio.requiereDetalle = false;
    this.cleanReferencias();
  }

  public limpiaAsentamiento() {
    this.domicilio.asentamiento = null;
    this.domicilio.tipoAsentamiento = null;
  }

  /**
   * se hace un filtro a los catalogos por id para sacar el modelo y setearlo en el formulario
      esto soluciona el problema de mayusculas y minusculas
   * @param cat catalogo que se va a filtrar
   * @param val valor con el cual se va a filtrar
   * @returns el valor del catalogo
   */
  // filtra catalogo por clave
  public filtraCatalogoClave(cat, val) {
    const filtro = cat.filter(e => e.value.clave == val);
    return filtro.length > 0 ? filtro[0].value : null;
  }

  // filtra catalogo por Id
  public filtraCatalogoId(cat, val) {
    const filtro = cat.filter(e => e.value.id == val);
    return filtro.length > 0 ? filtro[0].value : null;
  }

  //Acepta solo numeros
  public numbersOnly(evt) {
    const charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
      evt.preventDefault();
    } else {
      return true;
    }
  }
}
