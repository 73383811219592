// Errors
export const PROBLEM_BASE_URL = 'https://www.jhipster.tech/problem';
export const EMAIL_ALREADY_USED_TYPE = `${PROBLEM_BASE_URL}/email-already-used`;
export const LOGIN_ALREADY_USED_TYPE = `${PROBLEM_BASE_URL}/login-already-used`;
export const COMMENT_LENGTH = 90;
export const URL_REGEX = /^http[s]?:\/\/(www\.)?[a-zA-Z0-9\-_]+\.[a-zA-Z]{2,}([\/a-zA-Z0-9\-_#?=&]*)?/;
export const ENI = 'ENI';
export const GEMA = 'Gema';
export const SISDAI = 'Sisdai';
export const URL_PROD_RIZOMA_PM = 'https://rizoma-pm.conahcyt.mx';
