var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-area form-group" },
    [
      _c("label", { attrs: { for: _vm.id } }, [
        _vm._v("\n    " + _vm._s(_vm.label) + " "),
        _vm.required ? _c("span", [_vm._v("*")]) : _vm._e(),
        _vm._v(" "),
        _vm.tooltip || _vm.popover
          ? _c(
              "span",
              [
                _c("span", {
                  staticClass: "icon-info",
                  attrs: { id: _vm.id + "tooltip" },
                }),
                _vm._v(" "),
                _vm.tooltip
                  ? _c("b-tooltip", {
                      attrs: {
                        target: _vm.id + "tooltip",
                        title: _vm.tooltip,
                        placement: "right",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.popover
                  ? _c(
                      "b-popover",
                      {
                        attrs: {
                          target: _vm.id + "tooltip",
                          triggers: "hover",
                        },
                      },
                      [
                        _c("div", { staticClass: "p-3" }, [
                          _vm._v(
                            "\n          " + _vm._s(_vm.popover) + "\n        "
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("b-form-textarea", {
        attrs: {
          id: _vm.id,
          placeholder: _vm.placeholder,
          state: _vm.valid,
          rows: "3",
          readonly: _vm.readonly,
        },
        on: {
          change: function ($event) {
            return _vm.cambio($event)
          },
        },
        model: {
          value: _vm.text,
          callback: function ($$v) {
            _vm.text = $$v
          },
          expression: "text",
        },
      }),
      _vm._v(" "),
      _c(
        "b-form-invalid-feedback",
        [
          _c("validations-commons", {
            attrs: {
              requiredValue: _vm.validationsCommons.requiredValue,
              requiredMessage: _vm.validationsCommons.requiredMessage,
              minValue: _vm.validationsCommons.minValue,
              minMessage: _vm.validationsCommons.minMessage,
              maxValue: _vm.validationsCommons.maxValue,
              maxMessage: _vm.validationsCommons.maxMessage,
              regexMessage: _vm.validationsCommons.regexMessage,
              regexValue: _vm.validationsCommons.regexValue,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("contador-caracteres", {
        staticClass: "mb-3",
        attrs: { max: _vm.maxCaracteres },
        model: {
          value: _vm.text,
          callback: function ($$v) {
            _vm.text = $$v
          },
          expression: "text",
        },
      }),
      _vm._v(" "),
      _vm.description
        ? _c("small", { staticClass: "form-text helper-text" }, [
            _vm._v(_vm._s(_vm.description)),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }