import SaveButtonComponent from '@/components/save-button/save-button.vue';
import DualButtonComponent from '@/components/dual-button/dual-button.vue';
import ActionButtonsComponent from '@/components/action-buttons/action-buttons.vue';
import DeleteModalComponent from '@/components/delete-modal/delete-modal.vue';
import SaveModalComponent from '@/components/save-modal/save-modal.vue';

import InputTextComponent from '@/components/input-text/input-text.vue';
import InputTextAreaComponent from '@/components/input-text-area/input-text-area.vue';
import InputSelectOneComponent from '@/components/input-select-one/input-select-one.vue';
import InputSelectAutocompleteComponent from '@/components/input-select-autocomplete/input-select-autocomplete.vue';
import InputSelectRadioComponent from '@/components/input-select-radio/input-select-radio.vue';
import InputImageComponent from '@/components/input-image/input-image.vue';
import InputPdfComponent from '@/components/input-pdf/input-pdf.vue';
import InputSelectManyComponent from '@/components/input-select-many/input-select-many.vue';
import TablePageableComponent from '@/components/table-pageable/table-pageable.vue';
import InputBooleanComponent from '@/components/input-boolean/input-boolean.vue';
import InputDateComponent from '@/components/input-date/input-date.vue';
import InputSelectYearComponent from '@/components/input-select-year/input-select-year.vue';
import CardsListComponent from '@/components/cards-list/cards-list.vue';
import ValidationsCommonsComponent from '@/components/validations-commons/validations-commons.vue';
import ContadorCaracteresComponent from '@/components/contador-caracteres/contador-caracteres.vue';
import ContactoComponent from '@/components/contacto/contacto.vue';
import AcercaDeMenu from '@/components/menus/acerca-de-menu/acerca-de-menu.vue';
import EducacionMenu from '@/components/menus/educacion-menu/educacion-menu.vue';
import AportacionesMenu from '@/components/menus/aportaciones-menu/aportaciones-menu.vue';
import IdentificadorComponent from '@/components/identificador/identificador.vue';
import AreaConocimientoComponent from '@/components/area-conocimiento/area-conocimiento.vue';
import DomicilioComponent from '@/components/domicilio/domicilio.vue';
import AlertaComponent from '@/components/alerta/alerta.vue';
import CompartirRedComponent from '@/components/compartir-red/compartir-red.vue';
import SocialComponent from '@/components/social/social.vue';
import AlerCardComponent from '@/components/alert-card/alert-card.vue';
import vMultiselectListbox from 'vue-multiselect-listbox';
import MultiselectTagsComponent from '@/components/multiselect-tags/multiselect-tags.vue';
import BuquedaInstitucionComponent from '@/components/instituciones/busqueda-institucion.vue';
import SkeletonComponent from '@/components/skeleton/skeleton.vue';
import OcdeComponent from '@/components/ocde/ocde.vue';
import ScianComponent from '@/components/scian/scian.vue';
import AutoresComponent from '@/components/autores/autores.vue';
import CitasComponent from '@/components/citas/citas.vue';
import TrayectoriaMenu from '@/components/menus/trayectoria-menu/trayectoria-menu.vue';
import ApoyosConacytComponent from '@/components/apoyo-conacyt/apoyo-conacyt.vue';
import DatosSolicitudComponent from '@/components/datos-solicitud/datos-solicitud.vue';
import EtapaComponent from '@/components/etapa-desarrollo/etapa-desarrollo.vue';
import ModalDocumentoComponet from '@/components/modal-documento/modal-documento.vue';
import ActoresTecnoComponent from '@/components/autores-tecno/actores-tecno.vue';
import TotalDocumentosComponent from '@/components/total-documentos/total-documentos.vue';
import MiPerfilComponent from '@/entities/msPerfil/acerca-de-mi/mi-perfil/mi-perfil.vue';
import TotalPrincipalComponent from '@/components/total-principal/total-principal.vue';
import SolicitudesComponent from '@/entities/msPerfil/solicitudes/solicitudes.vue';
import ResumenProductosComponent from '@/entities/msPerfil/solicitudes/resumen-productos-evaluar/resumen.vue';
import AvisoPrivacidad from '@/core/avisos-privacidad/aviso-privacidad-2023/aviso-privacidad.vue';
import InputDateTimeComponent from '@/components/input-date-time/input-date-time.vue';
import EscolaridadFamiliarComponent from '@/components/escolaridad-familiar/escolaridad-familiar.vue';
import RubrosComponent from '@/components/rubros/rubros.vue';
import DependientesComponent from '@/components/dependientes/dependientes.vue';
import VisualizadorPdfComponent from '@/components/visualizador-pdf/visualizador-pdf.vue';
import CuentaUsuarioMenu from '@/components/menus/cuenta-usuario-menu/cuenta-usuario-menu.vue';
import ModalIncidenciaComponent from '@/components/modal-incidencia/modal-incidencia.vue';

export function initBaseComponents(vue) {
  vue.component('c-delete-modal', DeleteModalComponent);
  vue.component('c-save-modal', SaveModalComponent);
  vue.component('c-save-button', SaveButtonComponent);
  vue.component('c-dual-button', DualButtonComponent);
  vue.component('c-action-buttons', ActionButtonsComponent);
  vue.component('input-text-area', InputTextAreaComponent);
  vue.component('input-select-one', InputSelectOneComponent);
  vue.component('input-select-autocomplete', InputSelectAutocompleteComponent);
  vue.component('input-select-radio', InputSelectRadioComponent);
  vue.component('input-select-many', InputSelectManyComponent);
  vue.component('table-pageable', TablePageableComponent);
  vue.component('input-text', InputTextComponent);
  vue.component('input-image', InputImageComponent);
  vue.component('input-pdf', InputPdfComponent);
  vue.component('input-date', InputDateComponent);
  vue.component('input-select-year', InputSelectYearComponent);
  vue.component('input-boolean', InputBooleanComponent);
  vue.component('cards-list', CardsListComponent);
  vue.component('validations-commons', ValidationsCommonsComponent);
  vue.component('contacto', ContactoComponent);
  vue.component('contador-caracteres', ContadorCaracteresComponent);
  vue.component('acerca-de-menu', AcercaDeMenu);
  vue.component('educacion-menu', EducacionMenu);
  vue.component('aportaciones-menu', AportacionesMenu);
  vue.component('identificador', IdentificadorComponent);
  vue.component('area-conocimiento', AreaConocimientoComponent);
  vue.component('domicilio', DomicilioComponent);
  vue.component('alerta', AlertaComponent);
  vue.component('compartir', CompartirRedComponent);
  vue.component('social', SocialComponent);
  vue.component('alerta-card', AlerCardComponent);
  vue.component('v-multiselect-listbox', vMultiselectListbox);
  vue.component('multiselect-tags', MultiselectTagsComponent);
  vue.component('busqueda-institucion', BuquedaInstitucionComponent);
  vue.component('skeleton', SkeletonComponent);
  vue.component('ocde', OcdeComponent);
  vue.component('scian', ScianComponent);
  vue.component('autores', AutoresComponent);
  vue.component('citas', CitasComponent);
  vue.component('trayectoria-menu', TrayectoriaMenu);
  vue.component('apoyo-conacyt', ApoyosConacytComponent);
  vue.component('datos-solicitud', DatosSolicitudComponent);
  vue.component('etapa', EtapaComponent);
  vue.component('modal-documento', ModalDocumentoComponet);
  vue.component('actores-tecno', ActoresTecnoComponent);
  vue.component('total-documentos', TotalDocumentosComponent);
  vue.component('mi-perfil', MiPerfilComponent);
  vue.component('total-principal', TotalPrincipalComponent);
  vue.component('solicitudes', SolicitudesComponent);
  vue.component('resumen-productos', ResumenProductosComponent);
  vue.component('aviso-privacidad', AvisoPrivacidad);
  vue.component('input-date-time', InputDateTimeComponent);
  vue.component('escolaridad-familiar', EscolaridadFamiliarComponent);
  vue.component('rubros', RubrosComponent);
  vue.component('dependientes', DependientesComponent);
  vue.component('visualizador-pdf', VisualizadorPdfComponent);
  vue.component('cuenta-usuario-menu', CuentaUsuarioMenu);
  vue.component('modal-incidencia', ModalIncidenciaComponent);
}
