var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        "header-bg-variant": "dark",
        "body-bg-variant": "light",
        "header-text-variant": "white",
        id: _vm.id,
        size: "xl",
        centered: "",
        "no-close-on-backdrop": "",
        "hide-header-close": "",
      },
    },
    [
      _c("span", { attrs: { slot: "modal-title" }, slot: "modal-title" }, [
        _c("span", { attrs: { id: "modal-incidencia.title" } }, [
          _vm._v(_vm._s(_vm.$t("reportar-incidencia.modal.title"))),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "modal-body" },
        [
          _c("input-select-one", {
            attrs: {
              id: "incidencia-id",
              options: _vm.incidentOptions,
              label: _vm.$t("reportar-incidencia.form.incidencia.label"),
              readonly: false,
              required: true,
              valid: _vm.$v.selectedIncident.$dirty
                ? !_vm.$v.selectedIncident.$error
                : null,
              validationsCommons: {
                requiredValue: !_vm.$v.selectedIncident.required,
                requiredMessage: _vm.$t(
                  "reportar-incidencia.form.incidencia.validations.required"
                ),
              },
            },
            model: {
              value: _vm.selectedIncident,
              callback: function ($$v) {
                _vm.selectedIncident = $$v
              },
              expression: "selectedIncident",
            },
          }),
          _vm._v(" "),
          _c("input-text-area", {
            attrs: {
              id: "descripcion-incidencia-id",
              label: _vm.$t("reportar-incidencia.form.descripcion.label"),
              readonly: false,
              required: true,
              maxCaracteres: 256,
              valid: _vm.$v.descripcionIncidencia.$dirty
                ? !_vm.$v.descripcionIncidencia.$error
                : null,
              validationsCommons: {
                requiredValue: !_vm.$v.descripcionIncidencia.required,
                requiredMessage: _vm.$t(
                  "reportar-incidencia.form.descripcion.validations.required"
                ),
                maxValue: !_vm.$v.descripcionIncidencia.maxLength,
                maxMessage: _vm.$t(
                  "reportar-incidencia.form.descripcion.validations.maxMessage",
                  { max: "256" }
                ),
              },
            },
            model: {
              value: _vm.descripcionIncidencia,
              callback: function ($$v) {
                _vm.descripcionIncidencia = $$v
              },
              expression: "descripcionIncidencia",
            },
          }),
          _vm._v(" "),
          _c("p", {
            staticClass: "font-weight-bold",
            domProps: {
              textContent: _vm._s(
                _vm.$t("reportar-incidencia.form.nota.label")
              ),
            },
          }),
          _vm._v(" "),
          _vm.validFiles
            ? _c("b-col", [
                _c("div", { staticClass: "invalid-feedback d-block" }, [
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t(
                          "reportar-incidencia.form.imagenes.validations.required"
                        )
                      ),
                    },
                  }),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "float-right" },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "primary", disabled: _vm.buttonDisabled },
                  on: { click: _vm.openFileInput },
                },
                [
                  _c(
                    "span",
                    {
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t(
                            "reportar-incidencia.form.btnSeleccionaArchivo"
                          )
                        ),
                      },
                    },
                    [_vm._v("Seleccionar Archivo")]
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "icon-mas" }),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.selectedFiles2.length > 0
            ? _c(
                "ul",
                _vm._l(_vm.selectedFiles2, function (fileObject, index) {
                  return _c(
                    "li",
                    { key: index },
                    [
                      _vm._v(
                        "\n        " + _vm._s(fileObject.name) + "\n\n        "
                      ),
                      _c(
                        "b-button-group",
                        { staticClass: "ml-2" },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "outline-primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.showImages(index)
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "icon-ver" }),
                              _vm._v(" Ver "),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "outline-primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.removeFileInput2(index)
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "icon-eliminar" }),
                              _vm._v(" Eliminar "),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _c("input", {
            ref: "fileInput",
            staticStyle: { display: "none" },
            attrs: { type: "file", accept: "image/*" },
            on: { change: _vm.handleFileChange2 },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { attrs: { slot: "modal-footer" }, slot: "modal-footer" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-outline-primary",
            attrs: { type: "button" },
            domProps: { textContent: _vm._s(_vm.$t("entity.action.cancel")) },
            on: {
              click: function ($event) {
                return _vm.closeModal()
              },
            },
          },
          [_vm._v("Cancel")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { type: "button", id: "jhi-confirm-delete-celula" },
            domProps: { textContent: _vm._s(_vm.$t("entity.action.save")) },
            on: {
              click: function ($event) {
                return _vm.enviarIncidencia()
              },
            },
          },
          [_vm._v("\n      Enviar\n    ")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }