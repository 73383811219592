var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-navbar-nav",
    { staticClass: "sidebar" },
    [
      _c(
        "b-navbar-brand",
        {
          staticClass: "logo",
          attrs: { id: "popover-brand", "b-link": "", to: "/" },
        },
        [
          _c("img", {
            attrs: {
              src: require("../../../content/images/rizoma_black.svg"),
              alt: "rizoma",
            },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "navbar-version" }, [
            _vm._v(_vm._s(_vm.version)),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "sidebar_header mb-3 mt-3" },
        [
          _c("b-link", { attrs: { to: "/", exact: "" } }, [
            _vm._v("PERFIL ÚNICO"),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.authenticated
        ? _c(
            "div",
            [
              _c(
                "b-nav-item",
                {
                  staticClass: "pointer",
                  attrs: {
                    id: "convocatoria-menu",
                    to: { name: "Home" },
                    "data-cy": "entity",
                  },
                },
                [
                  _c("span", { staticClass: "icon-solicitud" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "item-text" }, [
                    _vm._v("Solicitud"),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-nav-item",
                {
                  directives: [
                    {
                      name: "b-toggle",
                      rawName: "v-b-toggle:collapseCuentaUsuario",
                      arg: "collapseCuentaUsuario",
                    },
                  ],
                  staticClass: "pointer",
                  attrs: {
                    id: "cuenta-usuario",
                    "active-class": "active",
                    "data-cy": "entity",
                  },
                },
                [
                  _c("b-icon", { attrs: { icon: "tools" } }),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "item-text",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("global.menu.user-profile-menu.user-account")
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "ml-auto when-items" }, [
                    _c(
                      "span",
                      { staticClass: "when-open" },
                      [
                        _c("b-icon", {
                          attrs: { icon: "chevron-down", "font-scale": "1.5" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "when-closed" },
                      [
                        _c("b-icon", {
                          attrs: { icon: "chevron-up", "font-scale": "1.5" },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("b-collapse", { attrs: { id: "collapseCuentaUsuario" } }, [
                _c(
                  "ul",
                  { staticClass: "list-unstyled subitems" },
                  [_c("cuenta-usuario-menu")],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "b-nav-item",
                {
                  directives: [
                    {
                      name: "b-toggle",
                      rawName: "v-b-toggle:collapsePerfil",
                      arg: "collapsePerfil",
                    },
                  ],
                  staticClass: "pointer",
                  attrs: {
                    id: "pefil-menu",
                    "active-class": "active",
                    "data-cy": "entity",
                  },
                },
                [
                  _c("b-icon", { attrs: { icon: "person" } }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "item-text",
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("global.menu.user-profile-menu.user-profile")
                        ),
                      },
                    },
                    [_vm._v("User profile")]
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "ml-auto when-items" }, [
                    _c(
                      "span",
                      { staticClass: "when-open" },
                      [
                        _c("b-icon", {
                          attrs: { icon: "chevron-down", "font-scale": "1.5" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "when-closed" },
                      [
                        _c("b-icon", {
                          attrs: { icon: "chevron-up", "font-scale": "1.5" },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("b-collapse", { attrs: { id: "collapsePerfil" } }, [
                _c(
                  "ul",
                  { staticClass: "list-unstyled subitems" },
                  [_c("acerca-de-menu")],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "b-nav-item",
                {
                  directives: [
                    {
                      name: "b-toggle",
                      rawName: "v-b-toggle:collapseEducacion",
                      arg: "collapseEducacion",
                    },
                  ],
                  staticClass: "pointer",
                  attrs: {
                    id: "educacion-menu",
                    "active-class": "active",
                    "data-cy": "entity",
                  },
                },
                [
                  _c("b-icon", { attrs: { icon: "book" } }),
                  _vm._v(" "),
                  _c("span", { staticClass: "item-text" }, [
                    _vm._v("Educación"),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "ml-auto when-items" }, [
                    _c(
                      "span",
                      { staticClass: "when-open" },
                      [
                        _c("b-icon", {
                          attrs: { icon: "chevron-down", "font-scale": "1.5" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "when-closed" },
                      [
                        _c("b-icon", {
                          attrs: { icon: "chevron-up", "font-scale": "1.5" },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("b-collapse", { attrs: { id: "collapseEducacion" } }, [
                _c(
                  "ul",
                  { staticClass: "list-unstyled subitems" },
                  [_c("educacion-menu")],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "b-nav-item",
                {
                  directives: [
                    {
                      name: "b-toggle",
                      rawName: "v-b-toggle:collapseTrayectoriaProfecional",
                      arg: "collapseTrayectoriaProfecional",
                    },
                  ],
                  staticClass: "pointer",
                  attrs: {
                    id: "trayectoria-profecional",
                    "active-class": "active",
                    "data-cy": "entity",
                  },
                },
                [
                  _c("b-icon", { attrs: { icon: "person-badge" } }),
                  _vm._v(" "),
                  _c("span", { staticClass: "item-text" }, [
                    _vm._v("Trayectoria profesional"),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "ml-auto when-items" }, [
                    _c(
                      "span",
                      { staticClass: "when-open" },
                      [
                        _c("b-icon", {
                          attrs: { icon: "chevron-down", "font-scale": "1.5" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "when-closed" },
                      [
                        _c("b-icon", {
                          attrs: { icon: "chevron-up", "font-scale": "1.5" },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-collapse",
                { attrs: { id: "collapseTrayectoriaProfecional" } },
                [
                  _c(
                    "ul",
                    { staticClass: "list-unstyled subitems" },
                    [_c("trayectoria-menu")],
                    1
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      false
        ? _c(
            "div",
            [
              _c(
                "b-nav-item",
                {
                  directives: [
                    {
                      name: "b-toggle",
                      rawName: "v-b-toggle:collapseEntities",
                      arg: "collapseEntities",
                    },
                  ],
                  staticClass: "pointer",
                  attrs: {
                    id: "entity-menu",
                    "active-class": "active",
                    "data-cy": "entity",
                  },
                },
                [
                  _c("b-icon", { attrs: { icon: "list-ul" } }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "item-text",
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("global.menu.entities.main")
                        ),
                      },
                    },
                    [_vm._v("Entities")]
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "ml-auto when-items" }, [
                    _c(
                      "span",
                      { staticClass: "when-open" },
                      [
                        _c("b-icon", {
                          attrs: { icon: "chevron-down", "font-scale": "1.5" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "when-closed" },
                      [
                        _c("b-icon", {
                          attrs: { icon: "chevron-up", "font-scale": "1.5" },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("b-collapse", { attrs: { id: "collapseEntities" } }, [
                _c(
                  "ul",
                  { staticClass: "list-unstyled subitems" },
                  [_c("entities-menu")],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "b-popover",
                {
                  attrs: {
                    disabled: _vm.popOverDisabled,
                    target: "entity-menu",
                    triggers: "hover",
                    placement: "right",
                    boundaryPadding: "50",
                    boundary: "window",
                  },
                  on: {
                    "update:disabled": function ($event) {
                      _vm.popOverDisabled = $event
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _c(
                              "span",
                              {
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("global.menu.entities.main")
                                  ),
                                },
                              },
                              [_vm._v("Entities")]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    789142711
                  ),
                },
                [
                  _vm._v(" "),
                  _c(
                    "ul",
                    { staticClass: "list-unstyled subitems subitemsPopover" },
                    [_c("entities-menu")],
                    1
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasAnyAuthority("ROLE_ADMIN") && _vm.authenticated
        ? _c(
            "div",
            [
              _c(
                "b-nav-item",
                {
                  directives: [
                    {
                      name: "b-toggle",
                      rawName: "v-b-toggle:collapseAdmin",
                      arg: "collapseAdmin",
                    },
                  ],
                  staticClass: "pointer",
                  class: { "router-link-active": _vm.subIsActive("/admin") },
                  attrs: {
                    id: "admin-menu",
                    "active-class": "active",
                    "data-cy": "adminMenu",
                  },
                },
                [
                  _c("b-icon", { attrs: { icon: "nut" } }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "item-text",
                      domProps: {
                        textContent: _vm._s(_vm.$t("global.menu.admin.main")),
                      },
                    },
                    [_vm._v("Administration")]
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "ml-auto when-items" }, [
                    _c(
                      "span",
                      { staticClass: "when-open" },
                      [
                        _c("b-icon", {
                          attrs: { icon: "chevron-down", "font-scale": "1.5" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "when-closed" },
                      [
                        _c("b-icon", {
                          attrs: { icon: "chevron-up", "font-scale": "1.5" },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("b-collapse", { attrs: { id: "collapseAdmin" } }, [
                _c(
                  "ul",
                  { staticClass: "list-unstyled subitems" },
                  [_c("admin-menu")],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "b-popover",
                {
                  attrs: {
                    disabled: _vm.popOverDisabled,
                    target: "admin-menu",
                    triggers: "hover",
                    placement: "right",
                    boundaryPadding: "50",
                    boundary: "window",
                  },
                  on: {
                    "update:disabled": function ($event) {
                      _vm.popOverDisabled = $event
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _c(
                              "span",
                              {
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("global.menu.admin.main")
                                  ),
                                },
                              },
                              [_vm._v("Administration")]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    1719467062
                  ),
                },
                [
                  _vm._v(" "),
                  _c(
                    "ul",
                    { staticClass: "list-unstyled subitems subitemsPopover" },
                    [_c("admin-menu")],
                    1
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.languages && Object.keys(_vm.languages).length > 1 && false
        ? _c(
            "b-nav-item",
            {
              directives: [
                {
                  name: "b-toggle",
                  rawName: "v-b-toggle:collapseLenguage",
                  arg: "collapseLenguage",
                },
              ],
              attrs: { id: "languagesnavBarDropdown" },
            },
            [
              _c("b-icon", { attrs: { icon: "flag" } }),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "item-text",
                  domProps: {
                    textContent: _vm._s(_vm.$t("global.menu.language")),
                  },
                },
                [_vm._v("Language")]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "ml-auto when-items" }, [
                _c(
                  "span",
                  { staticClass: "when-open" },
                  [
                    _c("b-icon", {
                      attrs: { icon: "chevron-down", "font-scale": "1.5" },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "when-closed" },
                  [
                    _c("b-icon", {
                      attrs: { icon: "chevron-up", "font-scale": "1.5" },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("b-collapse", { attrs: { id: "collapseLenguage" } }, [
        _c(
          "ul",
          { staticClass: "list-unstyled subitems" },
          _vm._l(_vm.languages, function (value, key) {
            return _c(
              "b-dropdown-item",
              {
                key: "lang-" + key,
                class: { active: _vm.isActiveLanguage(key) },
                attrs: { id: "itemsLenguage" },
                on: {
                  click: function ($event) {
                    return _vm.changeLanguage(key)
                  },
                },
              },
              [_vm._v("\n        " + _vm._s(value.name) + "\n      ")]
            )
          }),
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "b-popover",
        {
          attrs: {
            disabled: _vm.popOverDisabled,
            target: "languagesnavBarDropdown",
            triggers: "hover",
            placement: "right",
            boundaryPadding: "50",
            boundary: "window",
          },
          on: {
            "update:disabled": function ($event) {
              _vm.popOverDisabled = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _c(
                    "span",
                    {
                      domProps: {
                        textContent: _vm._s(_vm.$t("global.menu.language")),
                      },
                    },
                    [_vm._v("Language")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "list-unstyled subitems subitemsPopover" },
            _vm._l(_vm.languages, function (value, key) {
              return _c(
                "b-dropdown-item",
                {
                  key: "lang-" + key,
                  class: { active: _vm.isActiveLanguage(key) },
                  attrs: { id: "itemsLenguage" },
                  on: {
                    click: function ($event) {
                      return _vm.changeLanguage(key)
                    },
                  },
                },
                [_vm._v("\n        " + _vm._s(value.name) + "\n      ")]
              )
            }),
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }