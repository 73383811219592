var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { staticClass: "float-right" }, [
    _vm.caracteresRestantes >= 0
      ? _c(
          "small",
          { staticClass: "form-text helper-text" },
          [
            _c("span", { staticClass: "font-italic" }, [
              _vm._v("Caracteres restantes"),
            ]),
            _vm._v(" "),
            _c(
              "b-badge",
              {
                attrs: { pill: "", variant: "secondary" },
                domProps: { textContent: _vm._s(_vm.caracteresRestantes) },
              },
              [_vm._v("NN")]
            ),
          ],
          1
        )
      : _c(
          "small",
          { staticClass: "form-text text-danger" },
          [
            _c("span", { staticClass: "font-italic" }, [
              _vm._v("Ha sobrepasado el número de caracteres por"),
            ]),
            _vm._v(" "),
            _c(
              "b-badge",
              {
                attrs: { pill: "", variant: "danger" },
                domProps: {
                  textContent: _vm._s(Math.abs(_vm.caracteresRestantes)),
                },
              },
              [_vm._v("NN")]
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }