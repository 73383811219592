import Component from 'vue-class-component';
import { Prop, Vue, Inject } from 'vue-property-decorator';
import BModal from 'bootstrap-vue';
import { Route } from 'vue-router';
import { required } from 'vuelidate/lib/validators';
import AlertService from '@/shared/alert/alert.service';
import EntityFactory from '@/shared/entity-commons/entity.factory';
import { SoporteApi, CorreoAyudaRequest } from '@/shared/perfil-usuario';

const VALIDATIONS = function () {
  return {
    selectedIncident: {
      required,
    },
    descripcionIncidencia: {
      required,
    },
  };
};

@Component({
  validations: VALIDATIONS,
})
export default class ModalIncidenciaComponent extends Vue {
  @Inject('soporteApi') private soporteApi: () => SoporteApi;
  @Inject('alertService') private alertService: () => AlertService;
  @Prop({ default: 'removeEntity' })
  id: string;

  public buttonTextLabel = this.$t('reportar-incidencia.form.btnArchivos');
  public buttonDisabled = false;
  public validFiles = false;
  public selectedIncident = '';
  public descripcionIncidencia = '';
  public incidentOptions = EntityFactory.geListaIncidentesOptions();
  public selectedFiles: File[] = [];
  public selectedFiles2: { name: string; file: File }[] = [];

  public closeModal(): void {
    this.selectedIncident = null;
    this.descripcionIncidencia = '';
    this.selectedFiles2 = [];
    this.validFiles = false;
    this.$bvModal.hide(this.id);
  }

  public openFileInput() {
    if (this.selectedFiles.length >= 5 || this.getTotalFileSize() >= 5 * 1024 * 1024) {
      this.buttonDisabled = true;
      this.alertService().showWarning(this, this.$t('reportar-incidencia.messages.warning').toString());
      return;
    }

    this.$nextTick(() => {
      const fileInput = this.$refs.fileInput as HTMLInputElement | undefined;
      if (fileInput) {
        fileInput.click();
      }
    });
  }

  public getTotalFileSize(): number {
    let totalSize = 0;
    for (let i = 0; i < this.selectedFiles.length; i++) {
      totalSize += 1000;
    }
    return totalSize;
  }

  public handleFileChange2(event: Event) {
    this.validFiles = false;
    const target = event.target as HTMLInputElement;
    if (target.files) {
      const files = target.files;
      for (let i = 0; i < files.length; i++) {
        this.selectedFiles2.push({
          name: files[i].name,
          file: files[i] as File,
        });
      }
      this.buttonTextLabel = this.$t('reportar-incidencia.form.btnArchivos').toString();
      if (this.selectedFiles2.length >= 5 || this.getTotalFileSize() >= 5 * 1024 * 1024) {
        this.buttonDisabled = true;
      }
    }
  }

  public showImages(index: number): void {
    const fileObject = this.selectedFiles2[index];

    if (fileObject) {
      const imageUrl = window.URL.createObjectURL(fileObject.file);
      window.open(imageUrl, '_blank');
    }
  }

  public removeFileInput2(index: number) {
    this.selectedFiles2.splice(index, 1);
    if (this.selectedFiles2.length < 5 && this.getTotalFileSize() < 5 * 1024 * 1024) {
      this.buttonDisabled = false;
    }
  }

  private convertFileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = error => reject(error);
    });
  }

  public enviarIncidencia(): void {
    if (!this.$v.selectedIncident.$invalid && !this.$v.descripcionIncidencia.$invalid) {
      const currentRoute: Route | undefined = this.$route;
      const currentRouteName = currentRoute.name || '';
      this.validFiles = !(this.selectedFiles2.length >= 1);

      if (this.validFiles) {
        this.validFiles = true;
        return;
      }

      const imagenesPromises = this.selectedFiles2.map(fileObj => this.convertFileToBase64(fileObj.file));

      Promise.all(imagenesPromises).then(base64Images => {
        const correoAyuda: CorreoAyudaRequest = {
          descripcion: this.descripcionIncidencia,
          incidente: this.selectedIncident,
          routeName: currentRouteName,
          imagenes: base64Images,
        };

        this.soporteApi()
          .enviarCorreoConAdjunto(correoAyuda)
          .then(res => {
            if (res.status === 201) {
              this.alertService().showSuccess(this, this.$t('reportar-incidencia.messages.success').toString());
              this.closeModal();
            }
          })
          .catch(err => {
            this.alertService().showError(this, err.response.data.detail);
          });
      });
    } else {
      this.$v.$touch();
    }
  }
}
