import AlertaComponent from '@/components/alerta/alerta.component';
import { Component, Ref, Vue } from 'vue-property-decorator';

@Component
export default class UtilityMixin extends Vue {
  // mostrar skeleton en formularios
  public loadingForm = false;
  // mostrar skeleton en cards
  public loadingCards = false;
  // mostrar error en cards
  public errorCards = false;

  //Infinite Loading
  public itemsPerPage = 25;
  public page = 1;
  public totalItems = 0;
  public infiniteId = +new Date();
  //Total de documentos cargados
  public totalDocumento = 0;
  public validaTotalDocumento = 30;
  //Total de productos principales
  public totalPrincipal = 0;
  public validaPrincipal = 9;

  /**
   * alerta
   */
  @Ref()
  alerta!: AlertaComponent;

  /**
   * Modal para registros duplicados
   */
  public modalDuplicados() {
    this.$bvModal.msgBoxOk(this.$t('global.messages.alert.duplicated-info').toString(), {
      title: this.$t('global.messages.alert.duplicated-title').toString(),
      size: 'sm',
      buttonSize: 'sm',
      okVariant: 'primary',
      headerClass: 'p-2 border-bottom-0',
      footerClass: 'p-2 border-top-0',
      centered: true,
    });
  }

  /**
   * Sroll top
   */
  public scrollTop() {
    window.scrollTo({
      top: window.scrollX,
      behavior: 'smooth',
    });
  }

  //Scroll al card seleccionado para editar... se agregan y se quitan clases para que se distinga de los demas
  public scrollToCard(idCard) {
    if (idCard) {
      const el = document.querySelector(`[id="${idCard}"]`);
      const classCardBody = document.getElementById(`${idCard}`);
      if (classCardBody) {
        const classCard = classCardBody.querySelector('.card-body');
        if (el != null) {
          el.scrollIntoView({ block: 'center', inline: 'center' });
          setTimeout(() => {
            classCard.classList.add('shadow-lg');
            classCard.classList.add('border-primary');
          }, 500);
          if (idCard != null) {
            setTimeout(() => {
              classCard.classList.remove('shadow-lg');
              classCard.classList.remove('border-primary');
              this.$store.commit('setIdCardNull');
            }, 3500);
          }
        }
      }
    }
  }

  /**
   *Valida objeto areaConocimiento
   *
   */
  public validaAreaConocimiento(ac): boolean {
    if (ac.areaConocimiento.area && ac.areaConocimiento.campo && ac.areaConocimiento.disciplina && ac.areaConocimiento.subdisciplina) {
      return (
        ac.areaConocimiento.area.id &&
        ac.areaConocimiento.campo.id &&
        ac.areaConocimiento.disciplina.id &&
        ac.areaConocimiento.subdisciplina.id
      );
    } else {
      return false;
    }
  }

  /**
   *Valida objeto Institucion
   *
   */
  public validaInstitucion(ac): boolean {
    return ac.institucion.id && ac.institucion.nombre && ac.institucion.tipo && ac.institucion.pais;
  }

  /**
   *Valida objeto OCDE
   *
   */
  public validaOcde(oc): boolean {
    if (oc.ocde.sector?.id && oc.ocde.division?.id && oc.ocde.grupo?.id && oc.ocde.clase?.id) {
      return true;
    } else {
      return false;
    }
  }

  /**
   *Valida objeto Scian
   *
   */
  public validaScian(obj): boolean {
    if (obj.scian.sector?.id && obj.scian.subsector?.id && obj.scian.rama?.id && obj.scian.subrama?.id && obj.scian.clase?.id) {
      return true;
    } else {
      return false;
    }
  }

  /**
   *Valida objeto Autores
   *
   */
  public validaAutores(autores): boolean {
    return autores.length >= 1;
  }

  /**
   *Valida objeto Autores
   *
   */
  public validaActoresTecno(actoresTecno): boolean {
    return actoresTecno.length >= 1;
  }

  /**
   *Valida objeto Etapa
   *
   */
  public validaEtapa(etapas): boolean {
    return etapas.length >= 1;
  }

  public validaApoyoConacyt(obj): boolean {
    if (obj.apoyo.recibioApoyoConacyt) {
      if (obj.apoyo.recibioApoyoConacyt && obj.apoyo.fondoPrograma?.id) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  // filtra catalogo por Id
  public filtraCatalogoId(cat, val) {
    const filtro = cat.filter(e => e.value.id == val);
    return filtro.length > 0 ? filtro[0].value : null;
  }

  public limpiaStorageDocumentos() {
    this.$store.commit('setFotoModificable', null);
    this.$store.commit('setPdfModificable', null);
  }

  public convertStringToDate(fecha) {
    if (fecha) {
      const partesFecha = fecha.split('-');

      // JavaScript cuenta los meses desde 0 a 11, por lo que se debe restar 1 al mes.
      const año = parseInt(partesFecha[0], 10);
      const mes = parseInt(partesFecha[1], 10) - 1;
      const dia = parseInt(partesFecha[2], 10);

      return new Date(año, mes, dia);
    }
  }

  public dateToString(date: Date): string {
    if (date && date !== undefined) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    }
  }

  public sumarDias(fecha: Date, dias: number): Date {
    const nuevaFecha = new Date(fecha);
    nuevaFecha.setDate(fecha.getDate() + dias);
    return nuevaFecha;
  }

  public sumarMeses(fecha: Date, meses: number): Date {
    if (fecha && fecha !== undefined) {
      const fechaNueva = new Date(fecha);
      fechaNueva.setMonth(fechaNueva.getMonth() + meses);

      // Ajuste para cuando el día del mes es mayor al último día del nuevo mes
      //Ejemplo ya que si es Marzo(31 dias() y suma un mes da como resultado 1 de Mayo ya que al sumar un mes completo 31 dias(por MArzo)
      //Abril solo tiene 30 asi que la diferencia da 1 de mayo
      const diaDelMes = fecha.getDate();
      if (fechaNueva.getDate() < diaDelMes) {
        // Establece la fecha al último día del mes anterior
        fechaNueva.setDate(0);
      }

      return fechaNueva;
    }
    return null;
  }

  public convertirFecha(fechaString: string): Date {
    if (fechaString) {
      if (fechaString.includes('-')) {
        const [year, month, day] = fechaString.split('-').map(val => parseInt(val));
        return new Date(year, month - 1, day);
      } else {
        const [day, month, year] = fechaString.split('/').map(val => parseInt(val));
        return new Date(year, month - 1, day);
      }
    }
  }

  public ajustarFechaPrimeroUltimo(fecha: Date, ajuste: 1 | 2): Date {
    //primero de cada mes
    if (ajuste === 1) {
      return new Date(fecha.getFullYear(), fecha.getMonth(), 1);
    }
    //fin de cada mes
    if (ajuste === 2) {
      return new Date(fecha.getFullYear(), fecha.getMonth() + 1, 0); // '0' obtiene el último día del mes anterior
    }
    return fecha;
  }
}
